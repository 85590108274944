// react imports
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../../i18n';

// MD BOOTSTRAP IMPORTS
import {
  MDBNavbarLink
} from 'mdb-react-ui-kit';


// CUSTOM COMPONENTS
import OverlayPanel from "../../../common/overlay-panel/OverlayPanel";

// SAMPLE DATA
import { AllNotifications, UnreadNotifications }from "../../../../data/notification-data";

// HELPER IMPORTS
import { getAgoText } from "../../../../data/helper-funcs";

/* 
  Notification codes:
    REVIEW_AN_ORDER
    ITEM_PURCHASED
    NEW_COMMENT
    REPLY_COMMENT
    NEW_REVIEW
    NEW_FOLLOWER
*/

const NotificationPanel = (props) => {

  const context = useContext(AppContext);
  const navigate = useNavigate();

  // states
  const [allNotifications, setAllNotifications] = useState([]);
  const [pageSize, setPageSize] = useState(12);
  const [loadingNewProducts, setLoadingNewProducts] = useState(false);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [newCount, setNewCount] = useState(0);


  // MULTILANGUAGE
  const { t } = i18n;

  // TEMPLATES
  const notificationTemplate = (notification) => {
    return (
      <div
        className={`notification ${notification.status === "NEW" ? 'orange-dot' : ''}`}
        key={notification.notificationId}
        onClick={() => onGetNotificationById(notification.notificationId, notification.type, notification.orderId, notification.itemId, notification.clientId)}
      >
        {notification.image?.url && (
          <img src={notification.image.url} alt={notification.image.id} />
        )}

        <div>
          <div className="notification-title">
            {notification.title}
          </div>

          <div className="notification-description">
            {notification.body}
          </div>

          <div className="notification-date">
            {getAgoText(notification.createdAt)}
          </div>
        </div>
      </div>
    );
  };
  const getBellButtonTemplate = (panelShown) => {
    return(
      <MDBNavbarLink>
        <i 
          className={`${panelShown ? 'fa-solid' : 'fa-regular'} fa-bell`}
        ></i>
      </MDBNavbarLink>
    );
  }

  const loadNewProducts = () => {
    setLoadingNewProducts(true);
    setTimeout(() => {
      setPageSize(pageSize + 12),
      context.onRenderTrue();
      setLoadingNewProducts(false);
    }, 500);
  };

  const onGetNotificationById = (id, type, orderId, itemId, clientId) => {
        context.interAxios.get('/notification/' + id, {params: {
          lang: cookies.get('mylanguage').language
        }
    },
      function(response) {
        context.onRenderTrue();
        if(type === "REVIEW_AN_ORDER"){
          navigate('/profile/order/' + orderId + '?openRatingModal=true');
        }
        if(type === "ITEM_PURCHASED"){
          navigate('/profile/order/' + orderId);
        }
        if(type === "NEW_COMMENT"){
          navigate('profile/item/' + itemId + '?commentsOpened=true');
        }
        if(type === "REPLY_COMMENT"){
          navigate('product/' + itemId + '?commentsOpened=true');
        }
        if(type === "NEW_REVIEW"){
          navigate('/profile/order/' + orderId + '?reviewsModalOpened=true');
        }
        if(type === "NEW_FOLLOWER"){
          navigate('/profile?followersModalOpened=true');
        }
      }
    );
  }

  //events
  useEffect(() => {
    if(context.isLoggedIn() && cookies.get('mylocation')?.isHasClient){
      context.interAxios.get('/client/me/notification', 
      {params: {
                  pageIndex: 0,
                  pageSize: pageSize,
                  lang: cookies.get('mylanguage').language,
                }
      },
      function(response) {
        setAllNotifications(response.data.content);
        setTotalPage(response.data.totalElements)
        setPageSize(response.data.pageSize > 12 ? response.data.pageSize : 12)
        setNewCount(response.data.newCount)
        if(response.data.totalElements > response.data.pageSize){
          context.onGetNotificationsSize(response.data.pageSize)
          setMoreProductsAvailable(true);
        }else{
          context.onGetNotificationsSize(response.data.totalElements)
          setMoreProductsAvailable(false);
    }
    context.onRender();
  }
);
    }
   

  }, [context.getState()?.isRender]);


  return (
    <OverlayPanel
      className={"notification-panel" + (allNotifications.length === 0 ? ' white' : '')}
      triggerElem={getBellButtonTemplate}
      disableScrollOnOpen={true}
    >
      <main>
        {allNotifications.filter(k => k.status === "NEW").length > 0 && (
          <div className="notification-list">
            <h2>{t('misc.unread')} <span> {newCount} </span></h2>

            {allNotifications.filter(k => k.status === "NEW").map(notificationTemplate)}
          </div>
        )}

        {allNotifications.filter(k => k.status !== "NEW").length > 0 && (
          <div className="notification-list">
            <h2>{t('misc.all')}</h2>

            {allNotifications.filter(k => k.status !== "NEW").map(notificationTemplate)}
          </div>
        )}
        
        {moreProductsAvailable && (
          <div className="product-list pt-4">
            <button
              className={"gray-link" + (loadingNewProducts ? " loading" : "")}
              onClick={loadNewProducts}
            >
              <i className="fa-regular fa-arrow-rotate-right"></i>
              {t('misc.load_more')}
            </button>
          </div>
        )}

        {allNotifications.length === 0 && (
          <p className="no-notification">{t('misc.no_notification')}</p>
        )}

      </main>
    </OverlayPanel>
  );
};

export default withTranslation()(NotificationPanel);
