// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CONTEXT
import AppContext from "../../../AppContext";

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @returns
 */
const BookmarkOnlyModal = (props) => {
  // REACT ROUTER STUFF
  const navigate = useNavigate();

  // CONTEXT
  const context = useContext(AppContext);

  // EVENTS
  const clickOnRegister = () => {
    props.hideModal();

    setTimeout(() => {
      context.openLoginModal();
    }, 300);
  };

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <MDBModal
      className="standard-jc-modal bookmark-modal"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
      appendToBody={true}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <h3>{t('general_modals.bookmark.title')}</h3>

            <p>{t('general_modals.bookmark.text')}</p>

            <button className="jc-btn black" onClick={clickOnRegister}>
              {t('general_modals.bookmark.button_text')}
            </button>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(BookmarkOnlyModal);
