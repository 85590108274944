// react imports
import React, { useContext } from "react";

// CUSTOM IMPORTS
import RatingCard from "../rating-card/RatingCard";




/**
 * Props:
 * @param ratings
 * @param onReportRating?: (reportId) => any
 * @param exactDate?: boolean
 * @param displayItemName? boolean
 * @returns 
 */
const RatingsList = ({ratings, exactDate, displayItemName, onReportRating, textcount}) => {
  return (
    <div className="ratings-list">
      {ratings?.map(rating => (
        <RatingCard
          key={rating.id}
          id={rating.id}
          buyer={rating.buyer}
          star={rating.star}
          created={rating.created}
          exactDate={exactDate}
          displayItemName={displayItemName}
          item={rating.item}
          content={rating.content}
          textcount={textcount}
          onReportRating={onReportRating}
        />          
      ))}
    </div>
  );
};

export default RatingsList;