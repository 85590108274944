// react imports
import React from "react";

// REACT ROUTER IMPORTS
import { useNavigate } from "react-router-dom";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../../../i18n';

// MDBOOTSTRAP IMPORTS
import {
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
} from 'mdb-react-ui-kit';
import "mdb-react-ui-kit/dist/css/mdb.min.css";

/**
 * 
 * @param closeSidenav: () => void
 * @param logoutClicked: () => void
 * @returns 
 */
const SidenavContentUserMenu = ({
  closeSidenav,
  logoutClicked,
}) => {
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="sidenav-content user-menu">
      <MDBSideNavMenu>
        <MDBSideNavItem onClick={() => {
          closeSidenav();
          navigate('/profile')
        }}>
          <MDBIcon 
            far 
            icon='user' 
          />

          <span>{t('header.user_menu.my_profile')}</span>
        </MDBSideNavItem>

        <MDBSideNavItem onClick={() => {
          closeSidenav();
          navigate('/profile/settings')
        }}>
          <MDBIcon 
            far 
            icon='cog' 
          />

          <span>{t('header.user_menu.settings')}</span>
        </MDBSideNavItem>

        <MDBSideNavItem onClick={() => {
          closeSidenav();
          logoutClicked();
        }}>
          <MDBIcon 
            far 
            icon='right-from-bracket' 
          />

          <span>{t('header.user_menu.logout')}</span>
        </MDBSideNavItem>
      </MDBSideNavMenu>
    </div>
  );
};

export default withTranslation()(SidenavContentUserMenu);
