// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import AppContext from '../../../AppContext';

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @returns
 */
const PleaseVerifyModal = (props) => {
  const context = useContext(AppContext);

  // STATES
  const [verificationClicked, setVerificationClicked] = useState(false);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  // EVENTS
  const clickOnLoginButton = () => {
    props.hideModal();

    setTimeout(() => {
      context.openLoginModal();
    }, 300);
  };

  return (
    <MDBModal
      className="standard-jc-modal w-615 please-login"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.please_verify.title')}</h3>

            <p>{t('general_modals.please_verify.text')}</p>

            <button
              className="jc-btn black"
              onClick={() => {
                setVerificationClicked(true);

                context.interAxios.post('/stripe/verify', {}, {}, function (response) {
                  window.open(response.data.url);

                  setTimeout(() => {
                    setVerificationClicked(false);
                    props.hideModal();
                  }, 400);
                }, function(error) {
                  props.hideModal();

                  if (error.response?.data?.message) {
                    if (error.response?.data?.message.indexOf('is not a valid phone number') !== -1) {
                      const phoneNumber = error.response?.data?.message.split('is not a valid phone number')[0];

                      alert(t('misc.not_valid_phone_number', { phoneNumber }));
                    } else {
                      alert(error.response?.data?.message);
                    }                    
                  } else {
                    alert(error.message);
                  }

                  setTimeout(() => {
                    setVerificationClicked(false);
                  }, 400);
                });
              }}
            >
              {verificationClicked && <i className="fa-regular fa-spinner-third spinning"></i>}
              {!verificationClicked && t('general_modals.please_verify.button_text')}
            </button>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(PleaseVerifyModal);
