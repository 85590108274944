// react imports
import React, { useState, useContext, useEffect } from "react";

// react router dom
import { useNavigate } from "react-router";

// custom imports
import AppContext from "../../../AppContext";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// COOKIES
import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Props
 * @param visible: boolean
 * @param orderId: string
 * @param hideModal: () => void
 * @param reviewClicked: (order) => void
 * @returns
 */
const PleaseReviewTheSeller = (props) => {
  // APP STATE
  const context = useContext(AppContext);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  // STATES
  const [order, setOrder] = useState();

  // load order
  useEffect(() => {
    if (props.orderId) {
      // ezt az ordert kell használni: 2132
      context.interAxios.get(`/order/${props.orderId}`, {params: {lang: cookies.get('mylanguage').language}},  function(response) {
        const order = response.data;

        if (context.isLoggedInIdIs(order.buyer.id)) {
          setOrder(response.data);
        }
      });
    }
  }, [props.orderId]);

  return (
    <MDBModal
      className="standard-jc-modal w-615 please-review"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.please_review.title')}</h3>

            {!order && (
              <p>
                <i className="fa-regular fa-arrows-rotate spinning"></i>
                {t('general_modals.please_review.loading_text', { orderId: props.orderId })}
              </p>
            )}

            {order && (
              <>
                <p>{t('general_modals.please_review.text')}</p>

                <button
                  className="jc-btn black"
                  onClick={props.reviewClicked}
                >
                  {t('general_modals.please_review.button_text', { sellerName: order.seller.publicName })}
                </button>
    
                <a
                  className="brown-link"
                  onClick={props.hideModal}
                >
                  {t('general_modals.please_review.skip_text')}
                </a>
              </>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(PleaseReviewTheSeller);
