// react imports
import React, { useContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// react router dom
import { useNavigate } from "react-router";

// CUSTOM IMPORTS
import RatingsList from "../../common/ratings-list/RatingsList";
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


/**
 * Props
 * @param ratings: list of array
 * @param sellerId: long
 * @param visible: true
 * @param onReportRating: (ratingId:number) => any
 * @param displayItemName?: boolean
 * @param hideModal: () => void
 * @returns
 */
const RatingsModal = (props) => {
  // STATES
  const [displayAllRatings, setDisplayAllRatings] = useState(false);
  const [ratings, setRatings] = useState();

  // MULTILANGUAGE
  const { t } = i18n;

  // REACT ROUTER PARAMS - for processing url#
  const context = useContext(AppContext);

  useEffect(() => {
    if (props.visible) {
        setTimeout(() => {
        document.body.style.overflowY = 'hidden';
      }, 500);
    }
  }, [props.visible])

  useEffect(() => {
    
    context.interAxios.get('/client/' + props.sellerId + '/review',
      function(response) {
        setRatings(response.data.content);
        context.onRenderFalseRatingModal();
      }
    );
  }, [context.getState()?.isRenderRating])

  return (
    <MDBModal
      className="standard-jc-modal ratings"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.ratings.title')}</h3>
            {ratings && (
                <RatingsList 
                ratings={ratings}
                exactDate={false}
                displayItemName={props.displayItemName}
                onReportRating={props.onReportRating}
                textcount={20}
                />
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(RatingsModal);
