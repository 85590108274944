// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CONTEXT
import AppContext from '../../../AppContext';

// CUSTOM IMPORTS
import RatingsStar from "../../common/ratings-star/RatingsStar";
import JCTextarea from "../../form-components/jc-textarea/JCTextarea";

// IMAGE IMPORT
import userImage from "../../../images/user.png";


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param order: {
 *  title: string,
 *  timestamp: timestamp,
 *  seller: {
 *    id: number,
 *    image: { id: number, url: string },
 *    publicName: string,
 *    rating: { stars: number },
 *    profileUrl: string
 *  }
 * }
 * @param orderId: number
 * @param reviewSent: () => void
 * @returns
 */
const ReviewSellerModal = (props) => {
  const context = useContext(AppContext);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  // STATE
  const [rating, setRating] = useState(0);
  const [ratingText, setRatingText] = useState('');
  const [reviewState, setReviewState] = useState('SETTING'); // SETTING, SENDING, DONE

  // EVENTS
  const sendReview = () => {
    const reviewObject = {  
      content: ratingText,
      star: rating
    };

    setReviewState('SENDING');

    // setTimeout(() => {
    //   setReviewState('DONE');
    //   props.reviewSent(rating);
    // }, 2000);

    // return;

    context.interAxios.post(`/order/${props.orderId}/review`, reviewObject, function(response) {
        setReviewState('DONE');
        props.reviewSent(rating);
      }
    );
  };

  const orderDate = new Date(props.order.timestamp);

  return (
    <MDBModal
      className="standard-jc-modal w-615 review-seller"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.review_seller.title')}</h3>

            <div className="gray-panel product-seller">
              <header>
                {props.order.title}

                <span>
                  {orderDate.getFullYear()}
                  .
                  {(orderDate.getMonth() < 10 ? '0' : '') + orderDate.getMonth()}
                  .
                  {(orderDate.getDay() < 10 ? '0' : '') + orderDate.getDay()}
                </span>
              </header>

              <div className="seller-panel">
                <div className="seller">
                  <img src={props.order.seller?.image?.url ? props.order.seller?.image?.url : userImage} alt={props.order.seller?.publicName} />
                  {props.order.seller?.publicName}
                </div>

                <RatingsStar
                  rating={props.order.seller?.rating.stars}
                />
              </div>
            </div>

            {reviewState !== 'DONE' && (
              <>
                <h2>{t('general_modals.review_seller.your_rating_h2')}</h2>

                <div className="gray-panel your-rating">
                  <RatingsStar
                    rating={rating}
                    setRating={setRating}
                  />
                </div>

                <h2>{t('general_modals.review_seller.review_h2')}</h2>

                <JCTextarea
                  value={ratingText}
                  setValue={setRatingText}
                  maxCharacterNumber={200}
                />
              
                <button
                  className="jc-btn black"
                  onClick={sendReview}
                  disabled={rating === 0 ? 'disabled' : ''}
                >
                  {reviewState === 'SETTING' && t('general_modals.review_seller.button_text')}
                  {reviewState === 'SENDING' && (<i className="fa-regular fa-arrows-rotate spinning"></i>)}
                </button>
              </>
            )}

            {reviewState === 'DONE' && (
              <>
              <p className="review-sent">{t('general_modals.review_seller.review_sent')}</p>

              <button
                className="jc-btn black"
                onClick={props.hideModal}
              >
                Close
              </button>
              </>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(ReviewSellerModal);
