const { REACT_APP_BACKEND_URL } = process.env;

export function backendUrl() {
    //return "http://localhost:8080/api/v3";
    //return "https://q1.interb2b.com:8757/api/v3"
    //return "https://stage.jandc-world.ch:8758/api/v3"

    return REACT_APP_BACKEND_URL;

    //return "https://prod.jandc-world.ch:8747/api/v3"
}

export function ownStyleCSSUrl() {
    return backendUrl() + "/style.css";
}
