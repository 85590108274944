export const Conditions = [
  { text: 'New - with tags', value: 318 },
  { text: 'New - unused item', value: 319 },
  { text: 'Very good', value: 318 },
  { text: 'Good', value: 319 },
  { text: 'Acceptable condition', value: 318 }
];

export const SortOptionsDe = [
  { text: 'Preis niedrig bis hoch', value: ['PRICE', 'ASC'] },
  { text: 'Preis hoch bis niedrig', value: ['PRICE', 'DESC'] },
  { text: 'Upload-Zeit von niedrig bis hoch', value: ['UPLOAD_TIMESTAMP', 'ASC'] },
  { text: 'Upload-Zeit von hoch bis niedrig', value: ['UPLOAD_TIMESTAMP', 'DESC'] },
];

export const SortOptionsEn = [
  { text: 'Price low to high', value: ['PRICE', 'ASC'] },
  { text: 'Price high to low', value: ['PRICE', 'DESC'] },
  { text: 'Upload time low to high', value: ['UPLOAD_TIMESTAMP', 'ASC'] },
  { text: 'Upload time high to low', value: ['UPLOAD_TIMESTAMP', 'DESC'] },
];

/*export const SortOptions = [ 
  { text_de: 'Keine', text_en: 'None',  value: ['PRICE', 'ASC'] },
  { text_de: 'Preis niedrig bis hoch', text_en: 'Price low to high',  value: ['PRICE', 'ASC'] },
  { text_de: 'Preis hoch bis niedrigt', text_en: 'Price high to low', value: ['PRICE', 'DESC'] },
  { text_de: 'Upload-Zeit von niedrig bis hoch', text_en: 'Upload time low to high', value: ['UPLOAD_TIMESTAMP', 'ASC'] },
  { text_de: 'Upload-Zeit von hoch bis niedrig', text_en: 'Upload time high to low', value: ['UPLOAD_TIMESTAMP', 'DESC'] },
];*/

export const FollowedUsersOptions = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
];

export const JCWORLD_WEB_VISITED_FLAG = 'JCWORLD_WEB_VISITED_FLAG';

export const passwordTestFunction = (password) => {
  console.log(password, /[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password));

  return /[a-z]/.test(password) && // lowercase
    /[A-Z]/.test(password) && // uppercase
    /[0-9]/.test(password);   // number
};


export const Sizes = [
  { text: 'XS', value: 16 },
  { text: 'S', value: 36 },
  { text: 'M', value: 37 },
  { text: 'L', value: 38 },
  { text: 'XL', value: 39 },
];

export const getSizes = (isItGerman) => {
  return [
    { id: 1, en: 'EU/XXS', de: 'EU/XXS' },
    { id: 2, en: 'EU/XS', de: 'EU/XS' },
    { id: 3, en: 'EU/S', de: 'EU/S' },
    { id: 4, en: 'EU/M', de: 'EU/M' },
    { id: 5, en: 'EU/L', de: 'EU/L' },
    { id: 6, en: 'EU/XL', de: 'EU/XL' },
    { id: 7, en: 'EU/XXL', de: 'EU/XXL' },
    { id: 8, en: 'EU/3XL', de: 'EU/3XL' },
    { id: 9, en: 'EU/4XL', de: 'EU/4XL' },
    { id: 10, en: 'EU/5XL', de: 'EU/5XL' },
    { id: 11, en: 'EU/6XL', de: 'EU/6XL' },
    { id: 12, en: 'EU/7XL', de: 'EU/7XL' },
    { id: 13, en: 'EU/M/L', de: 'EU/M/L' },
    { id: 14, en: 'DE/16', de: '16' },
    { id: 15, en: 'DE/17', de: '17' },
    { id: 16, en: 'DE/18', de: '18' },
    { id: 17, en: 'DE/19', de: '19' },
    { id: 18, en: 'DE/20', de: '20' },
    { id: 19, en: 'DE/21', de: '21' },
    { id: 20, en: 'DE/22', de: '22' },
    { id: 21, en: 'DE/23', de: '23' },
    { id: 22, en: 'DE/24', de: '24' },
    { id: 23, en: 'DE/25', de: '25' },
    { id: 24, en: 'DE/26', de: '26' },
    { id: 25, en: 'DE/27', de: '27' },
    { id: 26, en: 'DE/28', de: '28' },
    { id: 27, en: 'DE/29', de: '29' },
    { id: 28, en: 'DE/30', de: '30' },
    { id: 29, en: 'DE/31', de: '31' },
    { id: 30, en: 'DE/32', de: '32' },
    { id: 31, en: 'DE/33', de: '33' },
    { id: 32, en: 'DE/34', de: '34' },
    { id: 33, en: 'DE/35', de: '35' },
    { id: 34, en: '39,5', de: '39,5' },
    { id: 35, en: 'DE/36', de: 'DE/36' },
    { id: 36, en: 'DE/36,5', de: 'DE/36,5' },
    { id: 37, en: 'DE/37', de: 'DE/37' },
    { id: 38, en: 'DE/38', de: 'DE/38' },
    { id: 39, en: 'DE/38,5', de: 'DE/38,5' },
    { id: 40, en: 'DE/39', de: 'DE/39' },
    { id: 41, en: 'DE/40', de: 'DE/40' },
    { id: 42, en: 'DE/40,5', de: 'DE/40,5' },
    { id: 43, en: 'DE/41', de: 'DE/41' },
    { id: 44, en: 'DE/41,5', de: 'DE/41,5' },
    { id: 45, en: 'DE/42', de: 'DE/42' },
    { id: 46, en: 'DE/42,5', de: 'DE/42,5' },
    { id: 47, en: 'DE/43', de: 'DE/43' },
    { id: 48, en: 'DE/43,5', de: 'DE/43,5' },
    { id: 49, en: 'DE/44', de: 'DE/44' },
    { id: 50, en: 'DE/44,5', de: 'DE/44,5' },
    { id: 51, en: 'DE/45', de: 'DE/45' },
    { id: 52, en: 'DE/45,5', de: 'DE/45,5' },
    { id: 53, en: 'DE/46', de: 'DE/46' },
    { id: 54, en: 'DE/46,5', de: 'DE/46,5' },
    { id: 55, en: 'DE/47', de: 'DE/47' },
    { id: 56, en: 'DE/47,5', de: 'DE/47,5' },
    { id: 57, en: 'DE/48', de: 'DE/48' },
    { id: 58, en: 'DE/48,5', de: 'DE/48,5' },
    { id: 59, en: 'DE/49', de: 'DE/49' },
    { id: 60, en: 'DE/49,5', de: 'DE/49,5' },
    { id: 61, en: 'DE/50', de: 'DE/50' },
    { id: 62, en: 'DE/51', de: 'DE/51' },
    { id: 63, en: 'DE/52', de: 'DE/52' },
    { id: 64, en: 'DE/53', de: 'DE/53' },
    { id: 65, en: 'DE/54', de: 'DE/54' },
    { id: 66, en: 'DE/55', de: 'DE/55' },
    { id: 67, en: 'DE/56', de: 'DE/56' },
    { id: 68, en: 'DE/57', de: 'DE/57' },
    { id: 69, en: 'DE/58', de: 'DE/58' },
    { id: 70, en: 'DE/59', de: 'DE/59' },
    { id: 71, en: 'DE/60', de: 'DE/60' },
    { id: 72, en: 'DE/61', de: 'DE/61' },
    { id: 73, en: 'DE/62', de: 'DE/62' },
    { id: 74, en: 'DE/63', de: 'DE/63' },
    { id: 75, en: 'DE/64', de: 'DE/64' },
    { id: 76, en: 'DE/65', de: 'DE/65' },
    { id: 77, en: 'DE/66', de: 'DE/66' },
    { id: 78, en: 'DE/67', de: 'DE/67' },
    { id: 79, en: 'DE/68', de: 'DE/68' },
    { id: 80, en: 'DE/69', de: 'DE/69' },
    { id: 81, en: 'DE/70', de: 'DE/70' },
    { id: 82, en: '0-1', de: '0-1' },
    { id: 83, en: '1-2', de: '1-2' },
    { id: 84, en: '2-4', de: '2-4' },
    { id: 85, en: '4-6', de: '4-6' },
    { id: 86, en: '6-9', de: '6-9' },
    { id: 87, en: '9-12', de: '9-12' },
    { id: 88, en: '12-18', de: '12-18' },
    { id: 89, en: '18-24', de: '18-24' },
    { id: 90, en: 'DE/98', de: 'DE/98' },
    { id: 91, en: 'DE/104', de: 'DE/104' },
    { id: 92, en: 'DE/110', de: 'DE/110' },
    { id: 93, en: 'DE/116', de: 'DE/116' },
    { id: 94, en: 'DE/122', de: 'DE/122' },
    { id: 95, en: 'DE/128', de: 'DE/128' },
    { id: 96, en: 'DE/134', de: 'DE/134' },
    { id: 97, en: 'DE/140', de: 'DE/140' },
    { id: 98, en: 'DE/146', de: 'DE/146' },
    { id: 99, en: 'DE/152', de: 'DE/152' },
    { id: 100, en: 'DE/158', de: 'DE/158' },
    { id: 101, en: 'DE/164', de: 'DE/164' },
    { id: 102, en: 'DE/170', de: 'DE/170' },
    { id: 103, en: 'DE/176', de: 'DE/176' },
    { id: 104, en: 'DE/182', de: 'DE/182' },
    { id: 105, en: 'DE/188', de: 'DE/188' },
    { id: 106, en: '1-3', de: '1-3' },
    { id: 107, en: '3-6', de: '3-6' },
    { id: 108, en: '7-12', de: '7-12' },
    { id: 109, en: 'ab 12', de: 'ab 12' },
    { id: 110, en: 'Damen', de: 'Women' },
    { id: 111, en: 'Herren', de: 'Men' },
    { id: 112, en: 'Baby', de: 'Baby' },
    { id: 113, en: 'Mädchen', de: 'Girls' },
    { id: 114, en: 'Jungen', de: 'Boys' },
    { id: 115, en: 'Unisex', de: 'Unisex' },
    { id: 116, en: 'For all ages', de: 'Für jedes Alter' },
    { id: 117, en: '0-1 year', de: '0-1 Jahre' },
    { id: 118, en: '1+ year', de: '1+ Jahr' },
    { id: 119, en: '2+ year', de: '2+ Jahre' },
    { id: 120, en: '3+ year', de: '3+ Jahre' },
    { id: 121, en: '4+ year', de: '4+ Jahre' },
    { id: 122, en: '5+ year', de: '5+ Jahre' },
    { id: 123, en: '6+ year', de: '6+ Jahre' },
    { id: 124, en: '7+ year', de: '7+ Jahre' },
    { id: 125, en: '8+ year', de: '8+ Jahre' },
    { id: 126, en: '9+ year', de: '9+ Jahre' },
    { id: 127, en: '10+ year', de: '10+ Jahre' },
    { id: 128, en: '11+ year', de: '11+ Jahre' },
    { id: 129, en: '12+ year', de: '12+ Jahre' },
    { id: 130, en: '13+ year', de: '13+ Jahre' },
    { id: 131, en: '14+ year', de: '14+ Jahre' },
    { id: 132, en: '15+ year', de: '15+ Jahre' },
    { id: 133, en: '16+ year', de: '16+ Jahre' },
    { id: 135, en: 'Bis zu 1 Monat', de: 'Bis zu 1 Monat' },
    { id: 136, en: '1-3 months', de: '1-3 Monate' },
    { id: 137, en: '3-6 months', de: '3-6 Monate' },
    { id: 138, en: '6-9 months', de: '6-9 Monate' },
    { id: 139, en: '9-12 months', de: '9-12 Monate' },
    { id: 140, en: '12-18 months', de: '12-18 Monate' },
    { id: 141, en: '18-24 months', de: '18-24 Monate' },
    { id: 142, en: '24-36 months', de: '24-36 Monate' },
    { id: 143, en: '4 years', de: '4 Jahre' },
    { id: 144, en: '5 years', de: '5 Jahre' },
    { id: 145, en: '6 years', de: '6 Jahre' },
    { id: 146, en: '7 years', de: '7 Jahre' },
    { id: 147, en: '8 years', de: '8 Jahre' },
    { id: 148, en: '9 years', de: '9 Jahre' },
    { id: 149, en: '10 years', de: '10 Jahre' },
    { id: 150, en: '11 years', de: '11 Jahre' },
    { id: 151, en: '12 years', de: '12 Jahre' },
    { id: 152, en: '13 years', de: '13 Jahre' },
    { id: 153, en: '14 years', de: '14 Jahre' },
    { id: 154, en: '15 years', de: '15 Jahre' },
    { id: 155, en: '65AA', de: '65AA' },
    { id: 156, en: '65A', de: '65A' },
    { id: 157, en: '65B', de: '65B' },
    { id: 158, en: '65C', de: '65C' },
    { id: 159, en: '65D', de: '65D' },
    { id: 160, en: '65E(DD)', de: '65E(DD)' },
    { id: 161, en: '65F', de: '65F' },
    { id: 162, en: '70AA', de: '70AA' },
    { id: 163, en: '70A', de: '70A' },
    { id: 164, en: '70B', de: '70B' },
    { id: 165, en: '70C', de: '70C' },
    { id: 166, en: '70D', de: '70D' },
    { id: 167, en: '70E(DD)', de: '70E(DD)' },
    { id: 168, en: '70F', de: '70F' },
    { id: 169, en: '75AA', de: '75AA' },
    { id: 170, en: '75A', de: '75A' },
    { id: 171, en: '75B', de: '75B' },
    { id: 172, en: '75C', de: '75C' },
    { id: 173, en: '75D', de: '75D' },
    { id: 174, en: '75E(DD)', de: '75E(DD)' },
    { id: 175, en: '75F', de: '75F' },
    { id: 176, en: '75G', de: '75G' },
    { id: 177, en: '80AA', de: '80AA' },
    { id: 178, en: '80A', de: '80A' },
    { id: 179, en: '80B', de: '80B' },
    { id: 180, en: '80C', de: '80C' },
    { id: 181, en: '80D', de: '80D' },
    { id: 182, en: '80E(DD)', de: '80E(DD)' },
    { id: 183, en: '80F', de: '80F' },
    { id: 184, en: '80G', de: '80G' },
    { id: 185, en: '80H', de: '80H' },
    { id: 186, en: '85AA', de: '85AA' },
    { id: 187, en: '85A', de: '85A' },
    { id: 188, en: '85B', de: '85B' },
    { id: 189, en: '85C', de: '85C' },
    { id: 190, en: '85D', de: '85D' },
    { id: 191, en: '85E(DD)', de: '85E(DD)' },
    { id: 192, en: '85F', de: '85F' },
    { id: 193, en: '85G', de: '85G' },
    { id: 194, en: '85H', de: '85H' },
    { id: 195, en: '90AA', de: '90AA' },
    { id: 196, en: '90A', de: '90A' },
    { id: 197, en: '90B', de: '90B' },
    { id: 198, en: '90C', de: '90C' },
    { id: 199, en: '90D', de: '90D' },
    { id: 200, en: '90E(DD)', de: '90E(DD)' },
    { id: 201, en: '90F', de: '90F' },
    { id: 202, en: '90G', de: '90G' },
    { id: 203, en: '90H', de: '90H' },
    { id: 204, en: '95AA', de: '95AA' },
    { id: 205, en: '95A', de: '95A' },
    { id: 206, en: '95B', de: '95B' },
    { id: 207, en: '95C', de: '95C' },
    { id: 208, en: '95D', de: '95D' },
    { id: 209, en: '95E(DD)', de: '95E(DD)' },
    { id: 210, en: '95F', de: '95F' },
    { id: 211, en: '95G', de: '95G' },
    { id: 212, en: '95H', de: '95H' },
    { id: 213, en: '100A', de: '100A' },
    { id: 214, en: '100B', de: '100B' },
    { id: 215, en: '100C', de: '100C' },
    { id: 216, en: '100D', de: '100D' },
    { id: 217, en: '100E(DD)', de: '100E(DD)' },
    { id: 218, en: '100F', de: '100F' },
    { id: 219, en: '100G', de: '100G' },
    { id: 220, en: '100H', de: '100H' },
    { id: 221, en: '105A', de: '105A' },
    { id: 222, en: '105B', de: '105B' },
    { id: 223, en: '105C', de: '105C' },
    { id: 224, en: '105D', de: '105D' },
    { id: 225, en: '105E(DD)', de: '105E(DD)' },
    { id: 226, en: '105F', de: '105F' },
    { id: 227, en: '105G', de: '105G' },
    { id: 228, en: '105H', de: '105H' },
    { id: 229, en: '110B', de: '110B' },
    { id: 230, en: '110C', de: '110C' },
    { id: 231, en: '110D', de: '110D' },
    { id: 232, en: '110E(DD)', de: '110E(DD)' },
    { id: 233, en: '110F', de: '110F' },
    { id: 234, en: '110G', de: '110G' },
    { id: 235, en: '110H', de: '110H' },
    { id: 236, en: '115B', de: '115B' },
    { id: 237, en: '115C', de: '115C' },
    { id: 238, en: '115D', de: '115D' },
    { id: 239, en: '115E(DD)', de: '115E(DD)' },
    { id: 240, en: '115F', de: '115F' },
    { id: 241, en: '115G', de: '115G' },
    { id: 242, en: '115H', de: '115H' },
    { id: 243, en: '120B', de: '120B' },
    { id: 244, en: '120C', de: '120C' },
    { id: 245, en: '120D', de: '120D' },
    { id: 246, en: '120E(DD)', de: '120E(DD)' },
    { id: 247, en: '120F', de: '120F' },
    { id: 248, en: '120G', de: '120G' },
    { id: 249, en: '120H', de: '120H' },
    { id: 250, en: '125B', de: '125B' },
    { id: 251, en: '125C', de: '125C' },
    { id: 252, en: '125D', de: '125D' },
    { id: 253, en: '130B', de: '130B' },
    { id: 254, en: '130C', de: '130C' },
    { id: 255, en: '130D', de: '130D' },
    { id: 256, en: 'One Size', de: 'One Size' },
  ].map(langObject => ({
    value: langObject.id,
    text: isItGerman ? langObject.de : langObject.en
  }));
};

export const getAmounts = (quantity = null) => {
  return [...Array(99).keys()].map(number => ({
    value: (number+1),
    text: (number+1).toString(),
    defaultSelected: (number+1) === quantity
  }));
};
