// react imports
import React, { useState, useRef, useEffect } from "react";

// SIDENAV CONTENTS
import CustomBsSidenav from "../../custom-bs-sidenav/CustomBsSidenav";
import SidenavContentButtonsCategories from "./sidenav-contents/SidenavContentButtonsCategories";
import SidenavContentUserMenu from "./sidenav-contents/SidenavContentUserMenu";

// MDBOOTSTRAP IMPORTS
import {
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  mdb
} from 'mdb-react-ui-kit';
import "mdb-react-ui-kit/dist/css/mdb.min.css";


/**
 * Props:
 * @param opened: boolean
 * @param setOpened: (boolean) => void
 * @param logoutClicked: () => void
 * @param mobileFilterPanelOpen: () => void
 * @param context
 * @returns 
 */
const HeaderMobileSidenav = ({
  opened,
  setOpened,
  logoutClicked,
  mobileFilterPanelOpen,
  context
}) => {
  // STATES
  const [visibleContent, setVisibleContent] = useState('');

  // effects
  useEffect(() => {
    if (opened) {
      setVisibleContent('');
    }
  }, [opened]);

  return (
    <CustomBsSidenav 
      className="mobile-header" 
      id="jcworld-sidenav"
      shown={opened}
      closeSidenav={() => setOpened(false)}
      clickOutside={() => setOpened(false)}
    >

      <MDBSideNavMenu className="nav-icons">
        <MDBSideNavItem>
          <MDBIcon 
            far 
            icon='arrow-left' 
            onClick={() => setVisibleContent('')}
            style={{opacity: visibleContent !== '' ? 1 : 0}}
          />

          <MDBIcon 
            far 
            icon='times' 
            onClick={() => setOpened(false)}
          />
        </MDBSideNavItem>
      </MDBSideNavMenu>

      <div className={`sidenav-contents ${visibleContent}`}>
        <SidenavContentButtonsCategories
          closeSidenav={() => setOpened(false)}
          showUserMenu={() => setVisibleContent('user-menu')}
          mobileFilterPanelOpen={mobileFilterPanelOpen}
          context={context}
        />
  
        <SidenavContentUserMenu 
          closeSidenav={() => {
            setOpened(false)
          }}
          logoutClicked={logoutClicked}
        />
      </div>
    </CustomBsSidenav>
  );
};

export default HeaderMobileSidenav;