// REACT IMPORTS
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// MDBOOTSTRAP IMPORTS
import { MDBIcon, MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';


// IMAGE IMPORTS
import appleStoreBadge from "../../../images/mobile/apple-store-badge.jpg";
import googlePlayBadge from "../../../images/mobile/google-play-badge.jpg";

import AppContext from "../../../AppContext";

/**
 * Props:
 * @param slides: array of {
 *  imageUrl: string
 *  active: boolean,
 *  title: string
 *  text: string
 *  buttons: array of {
 *   type: 'APP_STORE' | 'GOOGLE_PLAY' | 'TEXT',
 *   link: string,
 *   iconClass: string,
 *   text: string,
 *  }
 * }
 * @returns
 */
const ImageSlider = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  // helper funcs
  const getButton = (button, key) => {
    const buttonClasses = ['jcis__slider-button'];

    if (button.type === 'TEXT') {
      return (
        <button 
          key={key} 
          className="jcis__slider-button text-button"
          onClick={() => {
            if (button.link[0] === '/') {
              navigate(button.link);
            } else {
              window.location = button.link;
            }
          }}
        >
          {button.iconClass && <MDBIcon far icon={button.iconClass} />}
          {button.text}
        </button>
      );
    }

    if (button.type === 'SIGNUP') {
      if (context.isLoggedIn()) {
        return '';
      }
      
      return (
        <button 
          key={key} 
          className="jcis__slider-button text-button"
          onClick={() => context.openLoginModal(false)}
        >
          {button.iconClass && <MDBIcon far icon={button.iconClass} />}
          {button.text}
        </button>
      );
    }

    return (
      <button 
        key={key} 
        className="jcis__slider-button store-button"
        onClick={() => {
          window.location.href = button.type === 'APP_STORE' ? 
          'https://apps.apple.com/us/app/j-c-world/id6449838203' : 
          'https://play.google.com/store/apps/details?id=com.swisstomato.jncworld';
        }}
      >
        {button.type === 'APP_STORE' && (
          <img src={appleStoreBadge} alt="App Store Link" />
        )}
        {button.type === 'GOOGLE_PLAY' && (
          <img src={googlePlayBadge} alt="Google Play Badge" />
        )}
      </button>
    );
  };
  const slideTitleProcess = (title) => {

    const isThereEndingMark =  title[title.length - 1] === '?' ||title[title.length - 1] === '!' || title[title.length - 1] === '.';

    const endingMark = isThereEndingMark ? title[title.length - 1] : '';

    if (endingMark) {
      title = title.slice(0, title.length-1);
    }

    const titleParts = title.split(' ');

    for (let index = 0; index < titleParts.length; index++) {
      const titlePart = titleParts[index];

      if (!titlePart.includes('<span>')) {
        continue;
      }

      const wordWithoutSpan = titlePart.replace('<span>', '').replace('</span>', '');

      if (wordWithoutSpan.length > 8) {
        titleParts[index] = titleParts[index].replace('<span>', `<span className="letters-9plus">`);
      }

      if (wordWithoutSpan.length === 8) {
        titleParts[index] = titleParts[index].replace('<span>', `<span className="letters-8">`);
      }

      if (wordWithoutSpan.length > 5) {
        titleParts[index] = titleParts[index].replace('<span>', `<span className="letters-6plus">`);
      }

      if (wordWithoutSpan.length > 4) {
        titleParts[index] = titleParts[index].replace('<span>', `<span className="letters-5plus">`);
      }
    }

    title = titleParts.join(' ');

    if (endingMark) {
      title += endingMark;
    }

    return title;
  };

  // TEMPLATES
  const getCarouselItem = (slide, index) => {
    return (
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={index+1}
        key={index}
        src={slide.imageUrl}
        alt='...'
      >
        <div className="slide-content">
          <h2 dangerouslySetInnerHTML={{ __html: slideTitleProcess(slide.title) }}></h2>
          {slide.text && (
            <p>{slide.text}</p>
          )}

          <div className="slide-buttons">
            {slide.buttons.map((button, bIndex) => getButton(button, `slide-${index}-button-${bIndex}`))}
          </div>
        </div>
      </MDBCarouselItem>
    );
  };

  return (
    <div className="container-fluid jc-mdbs-image-slider">
      <MDBCarousel 
        showControls 
        showIndicators
        interval={5000}
      >
        {props.slides.map(getCarouselItem)}
      </MDBCarousel>
    </div>
  );
};

export default ImageSlider;
