// react imports
import React, { useContext, useState, useEffect } from "react";

// react router dom
import { useNavigate } from "react-router";
import AppContext from '../../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM IMPORTS
import RatingsStar from "../../common/ratings-star/RatingsStar";
import { isObjectEmpty } from "../../../data/helper-funcs";

// IMAGE IMPORTS
import userImage from "../../../images/user.png";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

/**
 * Props
 * @param userId: number
 * @param imageUrl: string
 * @param name: string
 * @param rating: number
 * @param displayFollowButton: boolean
 * @param followed: boolean
 * @returns 
 */
const FollowUserItem = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  // STATES
  const [followed, setFollowed] = useState(props.followed);
  const [rating, setRating] = useState(props.rating);
  const [clientProfile, setClientProfile] = useState({});

  // EVENTS
  const goToUser = () => {
    navigate(`/user-profile/${props.id}`);
    context.onRenderTrue();
    props.hideModal();
  };

  const addFollowed = () => {
    context.interAxios.put('/follow/'+ props.id,
      function(response) {
        context.onRenderTrueBookmark();
        //props.hideModal();
      }
    )
  }

  const removeFollowed = () => {
    context.interAxios.delete('/follow/'+ props.id,
      function(response) {
        context.onRenderTrueBookmark();
        //props.hideModal();
      }
    )
  }

  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    context.interAxios.get('/client/' + props.id, function(response) {
       setClientProfile(response.data);
       context.onRender();
    })
}, [context.getState()?.isRender]);
  return (
    <div className="follow-user-item">
      <img src={props.image?.url ? props.image?.url : userImage} alt={props.id} onClick={goToUser}/>

      <div className="name-ratings">
        {cookies.get('mylocation') === undefined || cookies.get('mylocation').clientId !== props.id ?
        <div 
          className="name"
          onClick={goToUser}
        >
          {props.publicName}
        </div>: 
        <div 
          
        >
          {props.publicName}
        </div>}
        {clientProfile.rating && (
          <RatingsStar rating={clientProfile.rating.stars} />
        )}
        
      </div>

      {!isObjectEmpty(clientProfile) && context.isLoggedIn() && props.displayFollowButton && cookies.get('mylocation')?.clientId !== props.id && (
        <button 
          className={"jc-btn black follow-state" + (clientProfile.isFollowed ? " followed" : " unfollowed")}
          onClick={clientProfile.isFollowed ? removeFollowed : addFollowed}
        >
          <i className={"fa-regular fa-user" + (clientProfile.isFollowed ? "-minus" : "-plus")}></i>
          {clientProfile.isFollowed ? t('misc.unfollow') : t('misc.follow')}
        </button>
      )}
      {cookies.get('mylocation') === undefined || cookies.get('mylocation').clientId !== props.id && (
      <i 
        className="fa-regular fa-angle-right"
        onClick={() => navigate(`/user-profile/${props.id}`)}
      ></i>)}
    </div>
  );
};

export default FollowUserItem;