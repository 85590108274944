// react imports
import React, { useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


import { Link } from "react-router-dom";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param icon: string
 * @returns
 */


const KauferschutzModal = (props) => {
  // MULTILANGUAGE
  const { t } = i18n;
  return (
    <MDBModal
      className="standard-jc-modal w-615"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

          <img src={props.icon} alt="SelectSize Icon" />

            <h3>{t('general_modals.kauferschutz_modal.title')}</h3>


            <a href="/faq" className="jc-btn black" style={{maxWidth: '350px'}} target="_blank" onClick={props.hideModal}>
              {t('general_modals.kauferschutz_modal.button_text_null')}
            </a>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(KauferschutzModal);
