// react imports
import React, { useContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM COMPONENTS IMPORTS
import AppContext from '../../../AppContext';

import { MDBRow } from "mdb-react-ui-kit";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import emptyImage from '../../../images/No-added-item.svg'


const EmptyTab = (props) => {
  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  // STATES
  const [followed, setFollowed] = useState(false);
  

  // MULTILANGUAGE
  const { t } = i18n;

 

  useEffect(() => {
      
  }, [context.getState()?.isRender]);



  return (
    <div className="emptyImage">
      <MDBRow center className="py-3">
      <img src={emptyImage}/>
      </MDBRow>
      <MDBRow center className="pt-4">
        <span className="d-flex align-items-center justify-content-center">{props.text}</span>
      </MDBRow>
    </div>
  );
};

export default withTranslation()(EmptyTab);
