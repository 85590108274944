// REACT IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBInput, 
  MDBCol, 
  MDBTextArea, 
  MDBDatepicker,
  MDBSelect
} from 'mdb-react-ui-kit';


import { MDBFileUpload } from 'mdb-react-file-upload';

// 3RD PARTY IMPORTS
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from 'yup';
import Toggle from 'react-toggle';

// CUSTOM OWN COMPONENTS
import DatePickerField from '../../common/datepicker-with-formik/DatePickerFormik';
import MDBDatePickerField from '../../common/datepicker-with-formik/MDBDatePickerFormik';
import SingleImageUploader from '../../form-components/image-uploader/SingleImageUploader';
import AccountCreatedModal from '../../modals/account-created-modal/AccountCreatedModal';
import AccountSavedModal from '../../modals/account-saved-modal/AccountSavedModal';
import PayrexxVerificationModal from '../../modals/payrexx-verification-modal/PayrexxVerificationModal';
import ReturnPolicyModal from '../../modals/return-policy-modal/ReturnPolicyModal';

import CountryModal from '../../modals/country-modal/CountryModal';
import VatModal from '../../modals/vat-modal/VatModal';

// STYLE IMPORTS
import "react-datepicker/dist/react-datepicker.css";
import "react-toggle/style.css";

// OTHER IMPORTS
import PasswordInput from '../../common/password-input/PasswordInput';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import postalCodes from 'switzerland-postal-codes';
import ProfileDeleteModal from "../../modals/profile-delete-modal/ProfileDeleteModal";

/**
 * Props:
 * @param mode: SIGN_UP | EDIT
 * @param type: PRIVATE | BUSINESS
 * @param user?: {
 *  firstName: string,
 *  lastName: string,
 *  publicName: string,
 *  emailAddress: string,
 *  phoneNumber: string,
 *  Address: string,
 *  postalCode: string,
 *  city: string,
 *  imageUrl: string
 * }
 * @returns
 */
const ProfileForm = ({mode, type, user}) => {
  if (mode === 'EDIT' && !user) {
    throw new Exception('"user" property is needed for EDIT mode');
  }

  const context = useContext(AppContext);

  const navigate = useNavigate();

  // STATES
  const [startDate, setStartDate] = useState(new Date());
  const [hidePassword, setHidePassword] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadedCities, setLoadedCities] = useState(null);

  // MODAL VISIBILITY STATES
  const [accountCreatedModalVisible, setAccountCreatedModalVisible] = useState(false);
  const [accountSavedModalVisible, setAccountSavedModalVisible] = useState(false);
  const [payrexxModalVisible, setPayrexxModalVisible] = useState(false);
  const [returnPolicyModalVisible, setReturnPolicyModalVisible] = useState(false);
  const [countryModalVisible, setCountryModalVisible] = useState(false);
  const [vatModalVisible, setVatModalVisible] = useState(false);
  const [tcVersion, setTcVersion] = useState(null);
  const [ppVersion, setPpVersion] = useState(null);
  const [deleteProfileModalVisible, setDeleteProfileModalVisible] = useState(false);

  // FORM STATES
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [serverError, setServerError] = useState('');

  // HELPER
  const isItSignUp = mode === 'SIGN_UP';
  const isItEdit = mode === 'EDIT';
  const isItBusiness = type === 'BUSINESS';
  const isItPRIVATE = type === 'PRIVATE';

  // MULTILANGUAGE
  const { t } = i18n;

  // helper func
  const dateFormatValidation = date => {
    const dateParts = date.split('-');

    return dateParts.length === 3 && 
      dateParts[0].length === 4 && /^\d+$/.test(dateParts[0]) && (dateParts[0].startsWith('19') || dateParts[0].startsWith('20')) &&
      dateParts[1].length === 2 && /^\d+$/.test(dateParts[1]) && dateParts[1] !== '00' &&
      dateParts[2].length === 2 && /^\d+$/.test(dateParts[2]) && dateParts[2] !== '00';
  };

  // FORMIK VALIDATON OBJECT
  const getYupValidationObject = () => {
    const yupValidationObject = {
      firstName: Yup.string().required(t('error_messages.first_name_required')),
      lastName: Yup.string().required(t('error_messages.last_name_required')),
      publicName: Yup.string().required(t('error_messages.public_name_required')),
      email: Yup.string()
        .required(t('error_messages.email_address_required'))
        .email(t('error_messages.email_invalid')),
      lang: Yup.string().required(t('error_messages.language_required')),
      phoneNumber: Yup.string().required(t('error_messages.phone_number_required')),
      birthDay: Yup.string()
        .required(t('error_messages.birthday_required'))
        .test('birthday_format', t('error_messages.date_format'), dateFormatValidation),
      address: Yup.string().required(t('error_messages.address_required')),
      postalCode: Yup.string().required(t('error_messages.postal_code_required')),
      city: Yup.string().required(t('error_messages.city_required')),
    };

    if (isItBusiness) {
      // yupValidationObject.returnPolicy = Yup.string()
      //   .required(t('error_messages.return_policy_required'))
      //   .url(t('error_messages.url_invalid'));

      yupValidationObject.companyName = Yup.string()
        .required(t('error_messages.company_name_required'));
    }

    if (isItSignUp) {
      yupValidationObject.termsAndCondition = Yup.boolean()
        .required()
        .oneOf([true], t('error_messages.accept_terms_conditions'));

      yupValidationObject.privacyPolicy = Yup.boolean()
        .required()
        .oneOf([true], t('error_messages.accept_privacy_policy')),

      yupValidationObject.password = Yup.string()
        .required(t('error_messages.password_required'))
        .min(8, t('error_messages.password_8_characters'))
        .matches(/[a-z]/, t('error_messages.password_small_large_letters_number'))
        .matches(/[A-Z]/, t('error_messages.password_small_large_letters_number'))
        .matches(/[0-9]/, t('error_messages.password_small_large_letters_number'));

      yupValidationObject.confirmPassword = Yup
        .string()
        .required(t('error_messages.password_confirmation'))
        .oneOf([Yup.ref('password'), null], t('error_messages.password_match'));
    }

    return yupValidationObject;
  };
  const getYupInitialValuesObject = () => {
    let initialValuesObject = {
      firstName: '',
      lastName: '',
      publicName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      lang: '',
      birthDay: '',
      address: '',
      postalCode: '',
      city: '',
      country: t('misc.switzerland'),
      vat: false,
      imageUrl: null,
      termsAndConditionsVersionAccepted: null,
      privacyPolicyVersionAccepted: null
    };

    if (isItEdit) {
      initialValuesObject = Object.assign(initialValuesObject, user);
      initialValuesObject = {...initialValuesObject, address: user.address.address,
                                                     postalCode: user.address.postcode,
                                                     city: user.address.location,
                                                     lang: user.lang,
                                                     country: user.address.countryCode === "CH" ? t('misc.switzerland') : "",
                                                     imageUrl: user.image !== null ? user.image.url : "",
                                                     returnPolicy: user.returnPolicy,
                                                     paymentProviderVerificationState: user.paymentProviderVerificationState,
                                                     companyName: user.companyName}
    }

    if (isItSignUp) {
      initialValuesObject.termsAndCondition = false,
      initialValuesObject.privacyPolicy = false;

      if (type === 'BUSINESS') {
        initialValuesObject.returnPolicy = '';
        initialValuesObject.companyName = '';
      }
    }

    return initialValuesObject;
  };

  // HELPER FUNCS
  const startLoadingCities = (postalCode) => {
    setLoadingCities(false);
    if (postalCodes[postalCode]) {
      setLoadedCities(postalCodes[postalCode].split(" - "));
    }
  };
  const getCitiesOptions = (selectedCity) => {
    return [
      {
        text: t('misc.placeholder_select'),
        value: '',
        defaultSelected: selectedCity === ''
      },
      ...loadedCities.map(city => ({
        text: city,
        value: city,
        defaultSelected: city === selectedCity
      }))
    ];
  };

  // effects
  useEffect(() => {
    context.interAxios.get('/terms-and-conditions/json', function(response) {
      setTcVersion(response.data.version);
    });
    context.interAxios.get('/privacy-policy/json', function(response) {
      setPpVersion(response.data.version);
    });
    
    if (isItEdit && user.address.postcode !== 0) {
      startLoadingCities(user.address.postcode);
    }
  }, []);




  let postalCodeSearchTimeoutId = null;

  /*window.onbeforeunload = function(){
    saveFormData();
    return 'Are you sure you want to leave?';
  }*/

 /* window.onunload = function(){
    saveFormData();
    //return 'Are you sure you want to leave?';
  }*/

  const saveFormData = () => {
    alert("hello");
  }

  /*window.addEventListener("beforeunload", function (e) {
    saveFormData();
  
    (e || window.event).returnValue = null;
    return null;
  });*/

  //window.onpopstate = history.onpushstate = function(e) { alert('hello') }

 

  return (
    <>
      <Formik
        initialValues = {getYupInitialValuesObject()}
        onSubmit = {(values) => {
          // return;
          
          setIsFormSubmitting(true);
          setServerError('');

          if(isItSignUp){
            context.interAxios.post('/auth/requestregister',
            {
              email: values.email,
              password: values.password,
              clientType: type,
              lang: values.lang.toLowerCase()
              }, function(response) {
                context.onSignIn(response.data);
                if(context.isLoggedIn){
                  context.interAxios.put('/client/me',
                  {
                      lastName: values.lastName,
                      firstName: values.firstName,
                      publicName: values.publicName,
                      companyName: values.companyName,
                      email: values.email,
                      clientType: type,
                      birthDay: values.birthDay,
                      phoneNumber: values.phoneNumber,
                      address: {
                        location: values.city,
                        countryCode: "CH",
                        postcode: values.postalCode,
                        address: values.address
                      },
                      vat: values.vat,
                      returnPolicy: type === 'BUSINESS' ? values.returnPolicy : '',
                      termsAndConditionsVersionAccepted: tcVersion,
                      privacyPolicyVersionAccepted: ppVersion
                    }, function(response) {
                      const clientId = response.data.id;
                      if(values.imageFile !== undefined && values.imageFile !== null){
                        const formData = new FormData
                        formData.append("file", values.imageFile);
                        let prom=[];
                        prom.push(context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                                      context.interAxios.put('/client/me/image/' + response.data.id, function(response) {
                                        setIsFormSubmitting(false);
                                      });
                          
                        }));
                        Promise.all(prom).then(() => context.onLogout());
                      }else{
                        setIsFormSubmitting(false);
                        context.onLogout();
                      }
                      setAccountCreatedModalVisible(true);
                  });
                }
                
            }, function (error) {
              setServerError(error.message);
              setIsFormSubmitting(false);
            });

          }
          if(isItEdit){
            context.interAxios.put('/client/me',
            {
                clientType: type,
                lastName: values.lastName,
                firstName: values.firstName,
                publicName: values.publicName,
                companyName: values.companyName,
                email: values.email,
                birthDay: values.birthDay,
                lang: values.lang.toLowerCase(),
                phoneNumber: values.phoneNumber,
                address: {
                  location: values.city,
                  countryCode: "CH",
                  postcode: values.postalCode,
                  address: values.address
                },
                vat: values.vat,
                notifications: user.notifications,
                returnPolicy:  type === 'BUSINESS' ? values.returnPolicy : '',
                termsAndConditionsVersionAccepted: tcVersion,
                privacyPolicyVersionAccepted: ppVersion
              }, function(response) {
                  const clientId = response.data.id;
                  if(values.imageFile !== undefined && values.imageFile !== null){
                    const formData = new FormData
                    formData.append("file", values.imageFile);
                    context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                      context.interAxios.put('/client/me/image/' + response.data.id, function(response) {
                        cookies.set('mylocation', {
                            location: values.city,
                            clientType: type,
                            clientId: clientId,
                            clientImageUrl: response.data.image.url,
                            clientEmail: response.data.email,
                            clientName: response.data.publicName,
                            phoneNumber: response.data.phoneNumber,
                            notifications: response.data.notifications
                          }, { path: '/'}
                        );
                        cookies.set('mylanguage', {
                            language: response.data.lang
                          }, { path: '/'}
                        );
                        
                        setIsFormSubmitting(false);
                      });

                      setTimeout(() => context.onRenderTrue(), 400);
                    });
                  } else {
                    cookies.set('mylocation', {
                        location: values.city,
                        clientType: type,
                        clientId: clientId,
                        clientImageUrl: values.imageUrl,
                        clientEmail: response.data.email,
                        clientName: response.data.publicName,
                        phoneNumber: response.data.phoneNumber,
                        notifications: response.data.notifications
                      }, { path: '/'}
                    );
                    cookies.set('mylanguage', {
                        language: response.data.lang
                      }, { path: '/'}
                    );
                    
                    setIsFormSubmitting(false);
                    setTimeout(() => context.onRenderTrue(), 400);
                  }
                  
                  if (values.imageFile === undefined) {
                    setIsFormSubmitting(false);
                  }

                  if(values.imageFile === null){
                    context.interAxios.delete('/client/me/image', function(response) { setIsFormSubmitting(false); });
                  }
                  
                  if (mode === 'EDIT') {
                    setAccountSavedModalVisible(true);
                  }
            });
          }


        }}
        validationSchema={Yup.object(getYupValidationObject())}
      >
        {formik => {
          return (
            <form onSubmit={formik.handleSubmit} className="profile-form" autoComplete="off">
              <div className="white-panel">
                {/** COMPANY NAME **/}
                {(type === 'BUSINESS') && (
                  <MDBRow>
                    <MDBCol md='5'>
                      <label htmlFor="companyName">{t('profile_form.company_name')}*</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <input
                        id='companyName'
                        name='companyName'
                        type='text'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                      />

                      {formik.touched.companyName && formik.errors.companyName ? (
                        <div className="invalid-feedback">{formik.errors.companyName}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>
                )}

                {/** FIRST NAME **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="firstName">{t('profile_form.first_name')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='firstName'
                      name='firstName'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />

                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="invalid-feedback">{formik.errors.firstName}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** LAST NAME **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="lastName">{t('profile_form.last_name')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='lastName'
                      name='lastName'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />

                  {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="invalid-feedback">{formik.errors.lastName}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** PUBLIC NAME **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="publicName">{t('profile_form.public_name')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='publicName'
                      name='publicName'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.publicName}
                    />

                    {formik.touched.publicName && formik.errors.publicName ? (
                      <div className="invalid-feedback">{formik.errors.publicName}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** EMAIL **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="email">{t('profile_form.email_address')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='email'
                      name='email'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      readOnly={isItEdit}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">{formik.errors.email}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** PASSWORDs **/}
                {isItSignUp && (
                  <>
                    <MDBRow>
                      <MDBCol md='5'>
                        <label htmlFor="password">{t('profile_form.password')}*</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <PasswordInput
                          id='password'
                          name='password'
                          type='password'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />

                        {formik.touched.password && formik.errors.password ? (
                          <div className="invalid-feedback">{formik.errors.password}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md='5'>
                        <label htmlFor="confirmPassword">{t('profile_form.confirm_password')}*</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <PasswordInput
                          id='confirmPassword'
                          name='confirmPassword'
                          type='confirmPassword'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                        />

                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                          <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </>
                )}

                {/** LANG **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="language">{t('profile_form.language')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <MDBSelect
                          /*label="Language"
                          selectAll={false}
                          displayedLabels={1}
                          optionsSelectedLabel='..'*/
                      className={`normal language`}
                      preventFirstSelection={formik.values.lang !== '' ? false : true}
                      value={formik.values.lang}
                      data={[
                        { text: 'DE', value: 1, defaultSelected: formik.values.lang.toUpperCase() === 'DE' },
                        { text: 'EN', value: 2, defaultSelected: formik.values.lang.toUpperCase() === 'EN' },
                      ]}
                      onValueChange={(e) => {
                        formik.setFieldValue('lang', e.text);
                      }}
                    />

                    {formik.touched.lang && formik.errors.lang ? (
                      <div className="invalid-feedback">{formik.errors.lang}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** PHONE NUMBER **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="phoneNumber">{t('profile_form.phone_number')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    {/*<input
                      id='phoneNumber'
                      name='phoneNumber'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                    />*/}
                    <PhoneInput
                      country="ch"
                      preferredCountries={['ch']}
                      id='phoneNumber'
                      name='phoneNumber'
                      type='text'
                      value={formik.values.phoneNumber}
                      onChange={(phone) => formik.setFieldValue('phoneNumber', '+' + phone)}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div className="invalid-feedback">{formik.errors.phoneNumber}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>
                

                {/** BIRTHDAY **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="birthDay">{t('profile_form.birthday')} *</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    {/*<DatePickerField
                      name="birthDay"
                    />*/}
                    <MDBDatePickerField
                      name="birthDay"
                    />
                    {formik.touched.birthDay && formik.errors.birthDay ? (
                      <div className="invalid-feedback">{formik.errors.birthDay}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** ADDRESS **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="address">{t('profile_form.address')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='address'
                      name='address'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />

                    {formik.touched.address && formik.errors.address ? (
                      <div className="invalid-feedback">{formik.errors.address}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** POSTAL CODE **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="postalCode">{t('profile_form.postal_code')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='postalCode'
                      name='postalCode'
                      type='number'
                      className={loadingCities ? 'disabled' : ''}
                      onChange={(e) => {
                        if (loadingCities) {
                          return;
                        }

                        if (e.currentTarget.value.length > 4) {
                          return;
                        }

                        formik.handleChange(e);

                        clearTimeout(postalCodeSearchTimeoutId);

                        if (formik.values.city) {
                          formik.setFieldValue('city', '');
                        }

                        if (loadedCities) {
                          setLoadedCities(false);
                        }

                        if (e.target.value.length === 4) {

                          postalCodeSearchTimeoutId = setTimeout(() => {
                            setLoadingCities(true);

                            setTimeout(() => {
                              startLoadingCities(e.target.value);
                            }, 2000);
                          }, 400);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.postalCode}
                    />

                    {loadingCities && <i className="fa-regular fa-spinner-third at-input-end spinning"></i>}
                    

                    {formik.touched.postalCode && formik.errors.postalCode ? (
                      <div className="invalid-feedback">{formik.errors.postalCode}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** CITY **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="city">{t('profile_form.city')}*</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    {/* <input
                      id='city'
                      name='city'
                      type='text'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    /> */}

                    {!loadedCities && (
                      <label className="form-text">
                        {t('user_settings.please_type_postal_code')}
                      </label>
                    )}

                    {loadedCities && (
                      <MDBSelect
                        /*label="Language"
                        selectAll={false}
                        displayedLabels={1}
                        optionsSelectedLabel='..'*/
                        className={`normal`}
                        data={getCitiesOptions(formik.values.city)}
                        onValueChange={(e) => {
                          formik.setFieldValue('city', e.value);
                        }}
                        disabled={loadingCities}
                      />
                    )}

                    {formik.touched.city && formik.errors.city ? (
                      <div className="invalid-feedback">{formik.errors.city}</div>
                    ) : null}
                  </MDBCol>
                </MDBRow>


                {/** COUNTRY **/}
                <MDBRow>
                  <MDBCol md='5'>
                    <label htmlFor="country">{t('profile_form.country')}</label>
                  </MDBCol>
                  <MDBCol md='7'>
                    <input
                      id='country'
                      name='country'
                      type='text'
                      value={formik.values.country}
                      readOnly
                    />

                    <i
                      className="fa-regular fa-info-circle at-input-end"
                      onClick={() => setCountryModalVisible(true)}
                    ></i>
                  </MDBCol>
                </MDBRow>


                {/** RETURN POLICY **/}
                {(type === 'BUSINESS') && (
                  <MDBRow>
                    <MDBCol md='5'>
                      <label htmlFor="returnPolicy">
                        {t('profile_form.link_to_return_policy')}
                      </label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <input
                        id='returnPolicy'
                        name='returnPolicy'
                        type='url'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.returnPolicy}
                      />

                      <i
                        className="fa-regular fa-info-circle at-input-end"
                        onClick={() => setReturnPolicyModalVisible(true)}
                      ></i>

                      {formik.touched.returnPolicy && formik.errors.returnPolicy ? (
                        <div className="invalid-feedback">{formik.errors.returnPolicy}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>
                )}


                {/** VAT **/}
                {isItSignUp && (
                  <>
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>
                          {t('profile_form.vat')}
                          <i
                            className="fa-regular fa-info-circle"
                            onClick={() => setVatModalVisible(true)}
                          ></i>
                        </label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <Toggle
                          checked={formik.values.vat}
                          name='vat'
                          id='vat'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="jc-toggle"
                        />
                      </MDBCol>
                    </MDBRow>
                  </>
                )}

                {/** TERMS&CONDITIONS **/}
                {formik.values.termsAndConditionsVersionAccepted !==  tcVersion && (
                  <>
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>
                            {t('profile_form.accept')} 
                            <a href="/terms-and-conditions" target="_blank"> {t('profile_form.terms_and_conditions')} </a> *
                        </label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <Toggle
                          checked={formik.values.termsAndCondition}
                          name='termsAndCondition'
                          id='termsAndCondition'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="jc-toggle"
                        />

                        {formik.touched.termsAndCondition && formik.errors.termsAndCondition ? (
                          <div className="invalid-feedback">{formik.errors.termsAndCondition}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </>
                )}

                {/** PRIVACY POLICY **/}
                {formik.values.privacyPolicyVersionAccepted !== ppVersion  && (
                  <>
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>
                          {t('profile_form.accept')} 
                          <a href="/legal-notices#privacy-policy" target="_blank"> {t('profile_form.privacy_policy')} </a> *
                        </label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <Toggle
                          checked={formik.values.privacyPolicy}
                          name='privacyPolicy'
                          id='privacyPolicy'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="jc-toggle"
                        />

                        {formik.touched.privacyPolicy && formik.errors.privacyPolicy ? (
                          <div className="invalid-feedback">{formik.errors.privacyPolicy}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
              </div>


              {/** IMAGES **/}
              <div className="white-panel">
                <h3>
                  {(type === 'BUSINESS') ? t('profile_form.company_logo') : t('profile_form.add_photo')}
                </h3>

                <SingleImageUploader
                  uploadBtnMessage={t('profile_form.upload')}
                  formikImageFieldName='imageFile'
                  formikImageUrlName='imageUrl'
                  imageUrl={formik.values.imageUrl}
                />

                {/* {formik.touched.imageUrl && formik.errors.imageUrl ? (
                  <div className="invalid-feedback">{formik.errors.imageUrl}</div>
                ) : null} */}
              </div>


              {/** BUTTONS **/}
              <div className={"last-buttons-row" + (!isItSignUp ? ' to-right' : '')}>
                {!isItSignUp && (
                  <button type="button" className="jc-btn delete"  onClick={() => setDeleteProfileModalVisible(true)}>
                    <i className="fa-regular fa-trash"></i>
                    {t('misc.delete_profile')}
                  </button>
                )}

                <button type="submit" className="jc-btn black" >
                  {isFormSubmitting && <i className="fa-regular fa-spinner-third spinning"></i>}

                  {!isFormSubmitting && (
                    <>
                      <i className="fa-regular fa-check"></i>
                      {isItSignUp ? t('profile_form.create_account') : t('misc.save_changes')}
                    </>
                  )}
                </button>

                {formik.submitCount > 0 && !formik.isValid ? (
                  <div className="invalid-feedback">
                    {t('error_messages.general')}
                  </div>
                ) : null}

                {serverError && (
                  <div className="invalid-feedback">
                    This email address is already in use.
                  </div>
                )}
              </div>
            </form>
          )}
        }
      </Formik>

      <AccountSavedModal
        visible={accountSavedModalVisible}
        hideModal={() => setAccountSavedModalVisible(false)}
      />

      <AccountCreatedModal
        visible={accountCreatedModalVisible}
        hideModal={() => setAccountCreatedModalVisible(false)}
      />

      <PayrexxVerificationModal
        visible={payrexxModalVisible}
        hideModal={() => setPayrexxModalVisible(false)}
      />

      <ReturnPolicyModal
        visible={returnPolicyModalVisible}
        hideModal={() => setReturnPolicyModalVisible(false)}
      />

      <CountryModal
        visible={countryModalVisible}
        hideModal={() => setCountryModalVisible(false)}
      />

      <VatModal
        visible={vatModalVisible}
        hideModal={() => setVatModalVisible(false)}
      />

      <ProfileDeleteModal
        visible={deleteProfileModalVisible}
        hideModal={() => setDeleteProfileModalVisible(false)}
        onDelete={() => {
          context.interAxios.delete('/client/me', function(response) {
            context.onLogout();
            navigate('/marketplace?profileDeleted=true');
            setDeleteProfileModalVisible(false);
          });
        }}
      />
    </>
  );
};

export default withTranslation()(ProfileForm);

