import React, { parentonent } from "react";

import axios from 'axios';
import { backendUrl } from '../env';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Paraméterek:
// process.env.REACT_APP_BACKEND_URL helyett backendUrl() from '../env'

/* Használat:
 * import InterB2BAxios from '../Service/InterB2BAxios';
 * nem kell: import axios from 'axios';
 * nem kell: cookies használata, token és myUserId átrakása state-be (hacsak más miatt nem szükséges)
 *
 * A komponens konstruktorában:
 *
 * this.interAxios = new InterB2BAxios(this);
 *
 * Ahol axios GET-hívás szükséges, ott a következőt kell kiadni:
 *
 * this.interAxios.get('/api/url/tobbi/resze', function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * })
 *
 * Ahol axios POST-hívás szükséges, ott a következőt kell kiadni:
 * this.interAxios.post('/api/url/tobbi/resze', data_amit_elkuldunk_pl_formData, [extraConfig], function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * }[, function(error) {
 *   // amit akkor kell csinálni, ha nem sikerült a hívás (pl. signin) - nem kötelező
 * }]);
 *
 * extraConfig paraméterben lévő adatokat hozzáfésüli az axios configjához (token és canceltoken)
 * Az extraConfig formátuma:
 * {
 *   params: { 'a': 'b'},
 *   headers: { 'c': 'd'}
 * }
 *
 * Ha a komponens lekerül a képernyőről (componentWillUnmount), akkor abban
 * hívd meg a this.interAxios.cancel() függvényt
 */

import i18n from '../i18n';

class InterAxios {
  constructor(name, getToken, backendErrorHandlerFunc) {
    this.name = name;
    this.cancelTokenSource = axios.CancelToken.source();
    //this.authToken = cookies?.get('authtoken')?.token;
    this.getToken = getToken;
    this.errorHandling.bind(this);
    this.cancelHandling.bind(this);
    this.get.bind(this);
    this.post.bind(this);
    this.delete.bind(this);
    this.put.bind(this);
    this.refreshUserData.bind(this);

    this.backendErrorHandlerFunc = backendErrorHandlerFunc;
  }


  get(urlEnding, extraConfig, thenFunction, catchFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);

    let promise = axios.get(backendUrl()+urlEnding, config)
		.then(thenFunction)
    .catch((error) => {
      if (catchFunction) {
        catchFunction(error);
      } else {
        this.catchFunction(this, error);
      }
    });
    return promise;
  }

  post(urlEnding, data, extraConfig, thenFunction, errorFunction) {    
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    // console.log(urlEnding, data, extraConfig, thenFunction, errorFunction);


    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.post(backendUrl()+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }


  delete(urlEnding, extraConfig, thenFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);

    let promise = axios.delete(backendUrl()+urlEnding, config)
    .then(thenFunction)
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }

  put(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof data === "function") { // data and extraConfig is missing
      errorFunction = extraConfig;
      thenFunction = data;
    }
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.put(backendUrl()+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error, errorFunction));
    return promise;
  }

  cancel(message) {
    this.cancelTokenSource.cancel(message);
  }

  refreshUserData(userData) {
    if (userData !== undefined) {
      this.authToken = userData.token();
    } else {
      console.log('userData is not defined!');
    }
  }

  setToken(jwt) {
    this.authToken = jwt;
  }

  // should be private

  shortJwt() {
    if (this.authToken === undefined)
      return 'no_token';
    let parts = this.authToken.split('.');
    return parts[2].substring(0,10);
  }

  addDefaultConfig(extraConfig) {
    let config = extraConfig ?? {};
    config.headers = {...config.headers, 'Authorization': 'Bearer ' + this.getToken()};
    config.cancelToken = this.cancelTokenSource.token;
    config.validateStatus = function(status) { return status >= 200 && status < 250 };
    return config;
  }

  catchFunction(self, error, errorFunction) {   
    if (axios.isCancel(error)) {
      self.cancelHandling(error);
    } else {
      if (errorFunction === undefined) {
        self.errorHandling(error);
      } else {
        if (errorFunction(error)) {
          self.errorHandling(error);
        }
      }
    }
  }

  errorHandling(error) {
    if (error?.response?.data?.message && error.response.data.message.indexOf('_') !== -1) {
      this.backendErrorHandlerFunc(error.response.data.message);
    } else {
      console.log(error);
    }
  }

  cancelHandling(error) {
    console.log('Request canceled', error.message ?? '');
  }

}

export default InterAxios;
