import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { MDBDatepicker } from 'mdb-react-ui-kit';


export const MDBDatePickerFormik = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const date = new Date();
  date.setFullYear( date.getFullYear() - 18 );
  return (
    <div className="datepicker-form">
        <MDBDatepicker  inline
                        customIcon='fa-regular fa-calendar-alt fa-lg'
                        format='yyyy-mm-dd'
                        max={date}
                        min={new Date(1900, 1, 1)}
                        {...field}
                        {...props}
                        selected={(field.value && new Date(field.value)) || null}
                        onChange={val => {
                          setFieldValue(field.name, val);
                        }}/>
    </div>
  );
};

export default MDBDatePickerFormik;
