// react imports
import React, { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM COMPONENTS IMPORTS
import AppContext from '../../../AppContext';
import SellerCard from "../../common/seller-card/SellerCard";
import ProductListItem from "../../common/product-list/product-list-item/ProductListItem";
import RatingsModal from "../../modals/ratings-modal/RatingsModal";
import EmptyTab from "../EmptyTab/EmptyTab";

// MODAL IMPORTS
import BookmarkOnlyModal from "../../modals/bookmark-only-modal/BookmarkOnlyModal";
import ReportModal from "../../modals/report-modal/ReportModal";

// DATA IMPORT
import { Seller as seller, SampleProducts, UserRatings } from "../../../data/product-data";
import { Users } from "../../../data/user-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import PleaseReviewModal from "../../modals/review-seller-modal/PleaseReviewModal";
import ReviewSellerModal from "../../modals/review-seller-modal/ReviewSellerModal.js";


const UserProfilePage = (props) => {
  // query params
  const [searchParams] = useSearchParams();

  // STATES
  //const [followed, setFollowed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('On sale');
  //const [rating, setRating] = useState(3);
  const [showRatingsModal, setShowRatingsModal] = useState(false);

  // MODAL STATES
  //const [bookmarkModalVisible, setBookmarkModalVisible] = useState(false);
  const [reportRatingModalVisible, setReportRatingModalVisible] = useState(false);
  const [clientRatings, setClientRatings] = useState({});
  const [ratingId, setRatingId] = useState();

  const [clientProfile, setClientProfile] = useState({});
  const [clientSellItems, setClientSellItems] = useState([]);
  const [clientBoughtItems, setClientBoughtItems] = useState([]);
  const [clientSoldItems, setClientSoldItems] = useState([]);

  const [pageSizeSell, setPageSizeSell] = useState(20);
  const [loadingNewProductsSell, setLoadingNewProductsSell] = useState(false);
  const [moreProductsAvailableSell, setMoreProductsAvailableSell] = useState(false);
  const [totalPageSell, setTotalPageSell] = useState(0);

  const [pageSizeBought, setPageSizeBought] = useState(20);
  const [loadingNewProductsBought, setLoadingNewProductsBought] = useState(false);
  const [moreProductsAvailableBought, setMoreProductsAvailableBought] = useState(false);
  const [totalPageBought, setTotalPageBought] = useState(0);

  const [pageSizeSold, setPageSizeSold] = useState(20);
  const [loadingNewProductsSold, setLoadingNewProductsSold] = useState(false);
  const [moreProductsAvailableSold, setMoreProductsAvailableSold] = useState(false);
  const [totalPageSold, setTotalPageSold] = useState(0);

  // REVIEW STATES
  const [showItem, setShowItem] = useState(false);
  const [showSold, setShowSold] = useState(false);
  const [showBought, setShowBought] = useState(false);

  // MULTILANGUAGE
  const { t } = i18n;

  // APP STATE
  const context = useContext(AppContext);

  // LOAD FUNCS
  const loadNewProductsSell = () => {
    setLoadingNewProductsSell(true);
    setTimeout(() => {
      setPageSizeSell(pageSizeSell + 20),
      context.onRenderTrue();
      setLoadingNewProductsSell(false);
    }, 500);
  };
  const loadNewProductsBought = () => {
    setLoadingNewProductsBought(true);
    setTimeout(() => {
      setPageSizeBought(pageSizeBought + 20),
      context.onRenderTrue();
      setLoadingNewProductsBought(false);
    }, 500);
  };
  const loadNewProductsSold = () => {
    setLoadingNewProductsSold(true);
    setTimeout(() => {
      setPageSizeSold(pageSizeSold + 20),
      context.onRenderTrue();
      setLoadingNewProductsSold(false);
    }, 500);
  };

  useEffect(() => {
      context.interAxios.get('/client/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1], function(response) {
         setClientProfile(response.data);
         context.interAxios.get('/client/' + response.data.id + '/item',
                   {params: {
                     lang: cookies.get('mylanguage').language,
                     pageIndex: 0,
                     pageSize: pageSizeSell
                   }},
         function(response) {
           setClientSellItems(response.data.content);
           setTotalPageSell(response.data.totalElements)
           setPageSizeSell(response.data.pageSize > 20 ? response.data.pageSize : 20)
           if(response.data.totalElements > response.data.pageSize){
             setMoreProductsAvailableSell(true);
           }else{
             setMoreProductsAvailableSell(false);
           }
           setShowItem(true);
         });
        /* if(response.data.clientType !== "BUSINESS"){
          context.interAxios.get('/client/' + response.data.id + '/order/buyer',
                    {params: {
                      lang: cookies.get('mylanguage').language,
                      pageIndex: 0,
                      pageSize: pageSizeBought
                    }},
          function(response) {
            setClientBoughtItems(response.data.content);
            setTotalPageBought(response.data.totalElements)
            setPageSizeBought(response.data.pageSize > 10 ? response.data.pageSize : 10)
            if(response.data.totalElements > response.data.pageSize){
              setMoreProductsAvailableBought(true);
            }else{
              setMoreProductsAvailableBought(false);
            }
            setShowBought(true);
          });
         }*/
         context.interAxios.get('/client/' + response.data.id + '/order/seller',
                   {params: {
                     lang: cookies.get('mylanguage').language,
                     pageIndex: 0,
                     pageSize: pageSizeSold
                   }},
         function(response) {
             setClientSoldItems(response.data.content);
             setTotalPageSold(response.data.totalElements)
             setPageSizeSold(response.data.pageSize > 20 ? response.data.pageSize : 20)
             if(response.data.totalElements > response.data.pageSize){
               setMoreProductsAvailableSold(true);
             }else{
               setMoreProductsAvailableSold(false);
             }
             setShowSold(true);
         });
      });
      context.interAxios.get('/client/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1] + '/review', function(response) {
        setClientRatings(response.data)
      });

  }, [cookies.get('mylanguage').language, context.getState()?.isRender]);

  return (
    <div className="jc-page white profile user-profile">
      <div className="container-fluid mw-1300">
        {clientProfile.id !== undefined ?
         <>
          <SellerCard
            image={clientProfile.image?.url}
            name={clientProfile.publicName}
            sellerId={clientProfile.id}
            location={clientProfile.location?.location}
            rating={clientProfile.rating?.stars}
            openRatingModal={() => setShowRatingsModal(true)}
            displayFollowButton={true}
            followed={clientProfile.isFollowed}
            reportEnabled={true}
          />

          <RatingsModal
            ratings={clientRatings}
            sellerId={window.location.pathname.split("/")[window.location.pathname.split("/").length-1]}
            visible={showRatingsModal}
            hideModal={() => setShowRatingsModal(false)}
            displayItemName={true}
          />

          <ReportModal
            visible={reportRatingModalVisible}
            hideModal={() => setReportRatingModalVisible(false)}
            type={"CLIENT"}
            id={ratingId}
          />

          <div className="jc-tabs">
            <div 
              className={"jc-tab" + (selectedTab === 'On sale' ? ' active' : '')}
              onClick={() => setSelectedTab('On sale')}
            >
              {t('user_profile.on_sale')} ({ clientSellItems.length})
            </div>
            <div 
              className={"jc-tab" + (selectedTab === 'Sold' ? ' active' : '')}
              onClick={() => setSelectedTab('Sold')}
            >
              {t('user_profile.sold')} ({ clientSoldItems.length})
            </div>
            {/*<clientProfile.clientType !== "BUSINESS" ?
            <div
              className={"jc-tab" + (selectedTab === 'Bought' ? ' active' : '')}
              onClick={() => setSelectedTab('Bought')}
            >
              {t('user_profile.bought')}  ({ clientBoughtItems.length})
          </div>:false*/}
          </div>
        </>:false}
      </div>

      <div className="gray-section">
        <div className="container-fluid mw-1300">
          {(selectedTab === 'On sale' &&  showItem===true && (<>
            {clientSellItems.length > 0 ?  
              <div className="product-list">
                {clientSellItems.map(product => (
                  <ProductListItem
                    {...product} 
                    key={product.id}
                    displayBookmarkIcon={true}
                    displayCheckoutButton={false}
                  />
                ))}
              </div> : <EmptyTab text={t('user_profile.on_sale_3')}/>
            }
             {moreProductsAvailableSell && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link" + (loadingNewProductsSell ? " loading" : "")}
                  onClick={loadNewProductsSell}
                >
                  <i className="fa-regular fa-arrow-rotate-right"></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}</>
          ))}

          {(selectedTab === 'Sold' && showSold===true && (<>
            {clientSoldItems.length > 0 ?  
              <div className="product-list">
                {clientSoldItems.map(product => (
                  <ProductListItem
                    {...product} 
                    key={product.id}
                    displayBookmarkIcon={true}
                    displayCheckoutButton={false}
                    isOrderItem={true}
                    orderType={"Sold"}
                  />
                ))}
              </div> : <EmptyTab text={t('user_profile.sold_3')}/>
            }
           
            {moreProductsAvailableSold && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link" + (loadingNewProductsSold ? " loading" : "")}
                  onClick={loadNewProductsSold}
                >
                  <i className="fa-regular fa-arrow-rotate-right"></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}</>
          ))}

          {/*(selectedTab === 'Bought' && (<>
            {clientBoughtItems.length > 0 ?  
              <div className="product-list">
                {clientBoughtItems.map(product => (
                  <ProductListItem
                    {...product} 
                    key={product.id}
                    displayBookmarkIcon={true}
                    displayCheckoutButton={false}
                    isOrderItem={true}
                    orderType={"Bought"}
                  />
                ))}
              </div> : <EmptyTab text={t('user_profile.bought_2')}/>
            }
             {moreProductsAvailableBought && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link" + (loadingNewProductsBought ? " loading" : "")}
                  onClick={loadNewProductsBought}
                >
                  <i className="fa-regular fa-arrow-rotate-right"></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}</>
             ))*/}

        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UserProfilePage);
