// REACT IMPORTS
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

const JCSupport = (props) => {
  // NAVIGATION
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="jc-page gray-bc generic-text-page">
      <div className="container-fluid mw-1040">
        <h1>
          <i 
            className="fa-regular fa-arrow-left"
            onClick={() => navigate(-1)}
          ></i>
          {t('misc.jc_world_support')}
        </h1>
        
        <div className="white-panel">
          <h2>Egestas lobortis neque velit</h2>

          <p>Pharetra ultrices suspendisse ornare est. Nam ut et sodales vitae egestas odio vivamus volutpat suspendisse. A velit a pellentesque odio eu magna sed aliquet in. Elit pharetra non urna mattis pretium proin vulputate lectus. Dolor vivamus ut adipiscing nisi volutpat volutpat. Faucibus proin platea lectus purus.</p>

          <p>Scelerisque semper augue malesuada facilisi enim scelerisque. Gravida turpis enim neque posuere. Duis ut rhoncus egestas convallis eu facilisi. Elit ultrices dignissim in sodales egestas nec sit vehicula sollicitudin. Pretium arcu ac volutpat pharetra. Venenatis mauris diam sit vel tortor nunc. A at consectetur vitae consequat nibh lacus. In lorem non molestie consequat ac quis aliquet mattis. Aliquam nec scelerisque in volutpat facilisis ac. Nibh sem arcu vestibulum tellus nisl. Nunc nibh dictum faucibus etiam sed urna.</p>

          <h2>Egestas lobortis neque velit</h2>

          <p>Pharetra ultrices suspendisse ornare est. Nam ut et sodales vitae egestas odio vivamus volutpat suspendisse. A velit a pellentesque odio eu magna sed aliquet in. Elit pharetra non urna mattis pretium proin vulputate lectus. Dolor vivamus ut adipiscing nisi volutpat volutpat. Faucibus proin platea lectus purus.</p>

          <p>Scelerisque semper augue malesuada facilisi enim scelerisque. Gravida turpis enim neque posuere. Duis ut rhoncus egestas convallis eu facilisi. Elit ultrices dignissim in sodales egestas nec sit vehicula sollicitudin. Pretium arcu ac volutpat pharetra. Venenatis mauris diam sit vel tortor nunc. A at consectetur vitae consequat nibh lacus. In lorem non molestie consequat ac quis aliquet mattis. Aliquam nec scelerisque in volutpat facilisis ac. Nibh sem arcu vestibulum tellus nisl. Nunc nibh dictum faucibus etiam sed urna.</p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(JCSupport);
