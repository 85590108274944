// REACT IMPORTS
import { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// MDBOOTSTRAP IMPORTS
import { MDBInputGroup, MDBBtn } from 'mdb-react-ui-kit';

// 3RD PARTY IMPORTS
import { Collapse } from 'react-collapse';
import AppContext from '../../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MODAL IMPORTS
import ReportModal from "../../modals/report-modal/ReportModal";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// IMAGE IMPORTS
import userImage from "../../../images/user.png";

// HELPER FUNCS
import { getAgoText } from "../../../data/helper-funcs";
import PleaseRegisterToCommentModal from "../../modals/please-register-to-comment-modal/PleaseRegisterToCommentModal";
import OnlyForPrivateUserModal from "../../modals/only-for-private-user-modal/OnlyForPrivateUserModal";


/**
 * Props:
 * @param comments 
 * @param product
 * @returns 
 */
const CommentsSection = ({ product }) => {
  // MULTILANGUAGE
  const { t } = i18n;

  // NAVIGATE
  const navigate = useNavigate();

  // CONTEXT
  const context = useContext(AppContext);

  // REFS
  const commentInputRef = useRef();
  const commentSectionRef = useRef();
  
  // STATES
  const [commentsSectionIsOpened, setCommentsSectionIsOpened] = useState(false);
  const [numberOfDisplayedComments, setNumberOfDisplayedComments] = useState(3);
  const [showAllComments, setShowAllComments] = useState(false);
  const [commentToReply, setCommentToReply] = useState(null);
  const [editedReply, setEditedReply] = useState(null);
  const [commentInputValue, setCommentInputValue] = useState('');
  const [comments, setComments] = useState([]);
  const [isNewComments, setIsNewComments] = useState(false);
  const [isCommentInputInFocus, setIsCommentInputInFocus] = useState(false);
  const [commentUploading, setCommentUploading] = useState(false);
  
  // MODAL STATES
  const [showReportModal, setShowReportModal] = useState(false);
  const [registerToCommentModalShown, setRegisterToCommentModalShown] = useState(false);
  const [onlyForPrivateUsersModalShown, setOnlyForPrivateUsersModalShown] = useState(false);  

  // EVENTS
  const clickOnReply = (comment) => {
    setCommentToReply(comment);
    if (editedReply) {
      setEditedReply(null);
      setCommentInputValue('');
    }
    if (commentToReply) {
      setCommentToReply(null);
      setCommentInputValue('');
    }
  };
  const clickOnEditReply = (comment) => {
    setCommentToReply(null);
    setEditedReply(comment);
    setCommentInputValue(comment.reply.content);
  };
  const clickOnCloseEditReply = () => {
    setEditedReply(null);
    setCommentToReply(null);
    setCommentInputValue('');
  };
  const focusOnCommentInput = () => {
    setIsCommentInputInFocus(true);

    if (!context.isLoggedIn()) {
      commentInputRef.current.blur();
      setRegisterToCommentModalShown(true);
    }

    if (!commentToReply && !editedReply && context.isLoggedInBusiness()) {
      commentInputRef.current.blur();
      setOnlyForPrivateUsersModalShown(true);
    }
  };
  const goToUserLink = (userId) => {
    navigate(context.isLoggedInIdIs(userId) ? '/profile' : `/user-profile/${userId}`);
  };

  // TEMPLATES
  const getCommentTemplate = (level = 1) => (comment, index) => {
    const senderName = comment.sender?.publicName ? comment.sender?.publicName : t('misc.deleted_user');

    return (
      <div className="comment" key={index}>
        <img 
          src={comment.sender?.image?.url ? comment.sender?.image?.url : userImage} 
          alt={senderName}
          onClick={() => goToUserLink(comment.sender?.id)}
          className="pointer"
        />
        <div className="comment-body">
          <p>
            <strong 
              className="pointer"
              onClick={() => goToUserLink(comment.sender?.id)}
            >
              {senderName}
            </strong> {comment.content}
          </p>

          <div className="comment-metas">
            <div className="comment-meta">{getAgoText(comment.timestamp)}</div>

            {!comment.reply && context.isLoggedInIdIs(product.seller?.id) && (
              <div 
                className="comment-meta pointer reply"
                onClick={() => clickOnReply(comment)}
              >
                {t('product.reply')}
              </div>
            )}

            {comment.reply && context.isLoggedInIdIs(product.seller?.id) && (
              <div 
                className="comment-meta pointer reply"
                onClick={() => clickOnEditReply(comment)}
              >
                {t('product.edit_reply')}
              </div>
            )}
          </div>
        </div>

        {comment.reply && (
          <div className="comment-list">
            <img 
              src={comment.reply.sender?.image?.url ? comment.reply.sender?.image?.url : userImage} 
              alt={comment.reply.sender?.publicName}
              onClick={() => goToUserLink(comment.sender?.id)}
              className="pointer"
            />
            <div className="comment-body">
              <p className="pointer" onClick={() => goToUserLink(comment.reply.sender?.id)}>
                <strong>{comment.reply.sender?.publicName}</strong> {comment.reply.content}
              </p>

              <div className="comment-metas">
                <div className="comment-meta">{getAgoText(comment.reply.timestamp)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const getReplySection = () => {
    return (
      <section className="reply-to">
        <i 
          className="fa-regular fa-times"
          onClick={() => clickOnCloseEditReply(null)}
        ></i>

        <label className="reply-to-label">
          {t('product.reply_to')}
          <strong>{commentToReply.sender?.publicName}</strong>
        </label>
        <div className="comment-text">
          {commentToReply.content}
        </div>
      </section>
    );
  };
  const getEditReplySection = () => {
    return (
      <section className="reply-to edit">
        <i 
          className="fa-regular fa-times"
          onClick={() => clickOnCloseEditReply()}
        ></i>

        <label className="reply-to-label">
          {t('product.reply_to')}
          <strong>{editedReply.reply?.sender?.publicName}</strong>
        </label>
        <div className="comment-text">
          {editedReply.reply?.content}
         </div>
      </section>
    );
  }; 

  const sendComment = () => {
    if(commentInputValue !== ''){
      setIsCommentInputInFocus(false);
      setCommentUploading(true);

      if (commentToReply) {
        context.interAxios.put('/comment/' + commentToReply.id , {content: commentInputValue}, function(response) {
          context.onRenderTrue();
          setCommentInputValue('');
          setIsNewComments(true);
          setCommentUploading(false);
          clickOnCloseEditReply();
        })
      } else {
        if(editedReply){
          context.interAxios.put('/comment/' + editedReply.id , {content: commentInputValue}, function(response) {
            context.onRenderTrue();
            setCommentInputValue('');
            setIsNewComments(true);
            setCommentUploading(false);
            clickOnCloseEditReply();
          })
        }else{
          context.interAxios.post('/item/' + product.id + '/comment', {content: commentInputValue}, function(response) {
            context.onRenderTrue();
            setCommentInputValue('');
            setIsNewComments(true);
            setCommentUploading(false);
          })
        }
      }
    }
  };

  // LOAD COMMENTS
  useEffect(() => {
    
    if(product.id){
      context.interAxios.get('/item/' + product.id + '/comment',
      function(response) {
       setComments(response.data);
       setIsNewComments(false);
     })
    }
    
  }, [isNewComments, product]);

  // SCROLL TO COMMENTS IF URL PARAM
  useEffect(() => {
    if (location.search.indexOf('commentsOpened') !== -1) {
        if (commentSectionRef && Array.isArray(comments)) {
          setCommentsSectionIsOpened(true);
          commentSectionRef.current.scrollIntoView();
        }
    }
  }, [commentSectionRef, comments]);

  return (
    <section className="comments" ref={commentSectionRef}>
      <header
        className="pointer"
        onClick={() => setCommentsSectionIsOpened(!commentsSectionIsOpened)}
      >
        {t('product.comments')}
        <i
          className="fa-regular fa-angle-up"
          style={{
            transform: commentsSectionIsOpened ? 'none' : 'rotateX(180deg)',
            transition: '400ms all'
          }}
        ></i>
      </header>

      <Collapse isOpened={commentsSectionIsOpened}>
        {Array.isArray(comments) && comments.length > 0 && (
          <div className="comment-list">
            {comments?.slice(0, numberOfDisplayedComments - 1).map(getCommentTemplate())}
          </div>
        )}

        {!Array.isArray(comments) || comments.length == 0 && (
          <p className="no-comments">{t('product.no_comments')}</p>
        )}

        {(comments.length >= numberOfDisplayedComments) && (
          <button
            className="gray-link load-more"
            onClick={() => setNumberOfDisplayedComments(numberOfDisplayedComments + 3)}
          >
            <i className="fa-regular fa-arrow-rotate-right"></i>
            {t('misc.load_more')}
          </button>
        )}

        <div className="add-comment">
          {context.isLoggedIn() && commentToReply && getReplySection()}

          {context.isLoggedIn() && editedReply && getEditReplySection()}

          {((!context.isLoggedIn()) || commentToReply || editedReply || (!context.isLoggedInIdIs(product.seller?.id))) && (
            <div className="image-and-text">
              <img 
                src={cookies.get('mylocation')?.clientImageUrl ? cookies.get('mylocation')?.clientImageUrl : userImage} 
                alt="Profile Image for comment" 
              />

              <MDBInputGroup>
                <input 
                  className='form-control' 
                  placeholder={t('product.add_comment')} 
                  type='text'
                  value={commentInputValue}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      sendComment();
                    }
                  }}
                  onChange={e => {
                    if (e.target.value.length <= 201) { // limit to 200
                      setCommentInputValue(e.target.value);
                    } else {
                      if (e.nativeEvent.inputType === 'insertFromPaste') {
                        setCommentInputValue(e.target.value.substring(0, 200));
                      }
                    }
                  }}
                  onFocus={focusOnCommentInput}
                  onBlur={() => setIsCommentInputInFocus(false)}
                  ref={commentInputRef}
                />
                <MDBBtn outline onClick={sendComment} className={isCommentInputInFocus ? 'color' : ''}>
                  {!commentUploading && <i className="fa-regular fa-paper-plane"></i>}
                  {commentUploading && <i className="fa-regular fa-spinner-third spinning"></i>}
                </MDBBtn>
              </MDBInputGroup>
            </div>
          )}
        </div>
      </Collapse>

      <PleaseRegisterToCommentModal
        visible={registerToCommentModalShown}
        hideModal={() => setRegisterToCommentModalShown(false)}
      />

      <OnlyForPrivateUserModal
        visible={onlyForPrivateUsersModalShown}
        hideModal={() => setOnlyForPrivateUsersModalShown(false)}
      />
    </section>
  );
};

export default CommentsSection;