// react imports
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../AppContext';
import Moment from 'moment';
import axios from "axios";
import { backendUrl } from '../../../env';

// 3RD PARTY IMPORTS
import { Formik } from "formik";
import * as Yup from 'yup';
import Toggle from "react-toggle";

// DATA IMPORTS
import { SampleProducts } from "../../../data/product-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import ThankYouPanel from "../../common/thank-you-panel/ThankYouPanel";


const products = SampleProducts.sort(() => .5 - Math.random()).slice(0,1);


const CheckoutConfirmationPage = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const params = useParams();
  const [searchParams] = useSearchParams();

  //STATE
  const [order, setOrder] = useState();
  const [takeoverMode, setTakeoverMode] = useState();
  const [priceWithShipping, setPriceWithShipping] = useState();
  // MULTILANGUAGE
  const { t } = i18n;

  //events
  //events
  useEffect(() => {
    if(context.isLoggedIn()){
      context.interAxios.get('/order/' + params.orderId ,
                      {
                        lang: cookies.get('mylanguage').language,
                      },
        function(response) {
          setOrder(response.data);
          setTakeoverMode(response.data.shippingOption.name === 'POST' ? 'Shipping' : 'Pick Up');
          setPriceWithShipping(response.data.shippingOption.name === 'POST' ? response.data.shippingOption.price + response.data.grossPrice : 0);
        }
      )

    }else{
      axios.get(backendUrl() +'/order/' + params.orderId +'?lang=' +  cookies.get('mylanguage').language, 
        {headers: {
          'Authorization': 'Bearer ' + searchParams.get("authToken")
          }
        }).then(function(response) {
          setOrder(response.data);
          setTakeoverMode(response.data.shippingOption.name === 'POST' ? 'Shipping' : 'Pick Up');
          setPriceWithShipping(response.data.shippingOption.name === 'POST' ? response.data.shippingOption.price + response.data.grossPrice : 0);
        })
    }
    
  }, []);

  return (
    
    <div className="jc-page gray-bc checkout checkout-confirmation">
      {order && (
        <div className="container">

          <ThankYouPanel
            h1Title={t('misc.thank_you')}
            message={t('checkout.order_placed', { order_id: order.id})}
          />

          <div className="white-panel rounded confirm">
            {(t('checkout.email_sent', { email: order.buyer.email}))}
            <br /><br />
            {t(('checkout.time_placed'))} <strong>{Moment(order.timestamp).format('DD/MM/YYYY HH:MM')}</strong>
          </div>

          <div className="white-panel rounded checkout-items">
            <h3>{t('misc.item', { count: 1})}</h3>


              <div className="item">
                <div className="sceenshot-wrapper">
                  <img src={order.firstImage.url} />
                </div>

                <div className="item-content">
                  <header>{order.title}</header>

                  <div className="product-size">
                    {t('misc.size')}
                    <button className="jc-btn white" disabled>
                      {order.size.name}
                    </button>
                  </div>
                </div>

                <div className="price">
                  {order.grossPrice}
                  <span>CHF</span>
                </div>
              </div>
            
          </div>
          {takeoverMode === "Shipping" && (
            <div className="white-panel rounded shipping">
            <h3>{t('misc.shipping')}</h3>

            <div className="checkout-address billing-address">
              <div className="name">
                {order.buyer.publicName}
              </div>
              <div className="address-text">
                {order.shippingAddress.address} <br />
                {order.shippingAddress.location + ", " + t('misc.switzerland')} <br />
                {order.buyer.phoneNumber} 
              </div>
            </div>
          </div>
          )}
          

          <div className="white-panel rounded payment">
            <div className="checkout-amounts">
            {takeoverMode === "Shipping" && (<>
              <div className="amount-row">
                {t('checkout.subtotal')}

                <span className="price">
                {order.grossPrice - order.shippingOption.price}
                  <span>CHF</span>
                </span>
              </div>
              
                <div className="amount-row">
                  {t('misc.shipping')}
                  <span className="price">
                    {order.shippingOption.price}
                  <span>CHF</span></span>
                </div>
                </>)}

              

              <div className="amount-row">
                {t('misc.total')}

                <span className="price total">
                  {takeoverMode === "Shipping" ?
                  <>
                    {order.grossPrice}
                  </>:
                  <>
                    {order.grossPrice}
                  </>}
                          
                  <span>CHF</span>
                </span>
              </div>
            </div>
          </div>

          <button
            className="jc-btn black back-to-marketplace"
            onClick={() => navigate('/marketplace')}
          >
            {t('checkout.back_to_marketplace')}
          </button>
          </div>
      )}
      
    </div>
  );
};

export default withTranslation()(CheckoutConfirmationPage);
