// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// CUSTOM COMPONENTS
import AppContext from '../../../AppContext';
import JCInput from "../../form-components/jc-input/JCInput";
import JCTextarea from "../../form-components/jc-textarea/JCTextarea";
import ImageUploader from "../../form-components/image-uploader/ImageUploader";
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";
import ConditionInfoModal from "../../modals/general-info-modal/ConditionInfoModal";

// 3RD PARTY IMPORTS
import { MDBCol, MDBRow, MDBSelect } from "mdb-react-ui-kit";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import Toggle from "react-toggle";
import { Formik } from "formik";
import * as Yup from 'yup';

// HELPER FUNCS
import { getSelectChangeFunc, getSingleSelectChangeFunc } from "../../../data/helper-funcs";

// sample data
import { userData } from "../../../data/user-data";
import { Categories } from "../../../data/product-categories";
import {
  Conditions as InitialConditions,
  Sizes as InitialSizes,
  getSizes, getAmounts
} from "../../../data/general-models";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import { useMemo } from "react";


const ItemForm = ({product}) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  // REF FOR FORMIK
  const formikRef = useRef(null);

  // MULTILANGUAGE
  const { t, language } = i18n;

  // STATES
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [currentVat, setCurrentVat] = useState(-1);
  const [shippingCost, setShippingCost] = useState(0);
  const [deepestCategoryLevelReachedAlready, setDeepestCategoryLevelReachedAlready] = useState(false);

  // MODAL STATES
  const [pickUpModalShown, setPickUpModalShown] = useState(false);
  const [shippingCostModalShown, setShippingCostModalShown] = useState(false);
  const [conditionModalShown, setConditionModalShown] = useState(false);

  // title&description saved in cookies (they are saved when clicking on the "how to sell" button)
  const savedTitleAndDesc = useMemo(() => {
    const storedValues = cookies.get('new_item_values');

    // remove cookies after 3 seconds (because this runs for multiple times)
    setTimeout(() => {
      cookies.remove('new_item_values', { path: '/' });
    }, 3000)

    // ha nincs elmentve semmi a cookies-ban
    if (!storedValues) {
      return {
        title: '',
        description: '',
        productId: null
      };
    }

    const validUntil = new Date(storedValues.validUntil);

    // ha lejárt a cookie
    if (validUntil < new Date()) {      
      return {
        title: '',
        description: '',
        productId: null
      };
    }

    
    return {
      title: storedValues.title ? storedValues.title : '', // must return valid string for Formik
      description: storedValues.description ? storedValues.description : '', // must return valid string for Formik
      productId: storedValues.productId
    }
  }, []);

  // HELPER FUNCS
  const getTotalPrice = (price) => {
    return (cookies.get('mylocation')?.clientIsVat === true) ? (+price * (1 + currentVat/100)) : +price;
  };
  const getTotalPriceWithShippingCost = (price, shippingCost) => {
    return getTotalPrice(price) + +shippingCost
  };
  const getSubSubCategoryOptions = (topCategoryId, subCategoryId, selectedSubSubCategoryId = -1) => {
    const allCategories = context.getState()?.allCategory ? context.getState()?.allCategory.categories : [];

    return [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedSubSubCategoryId === -1,
        disabled: true
      },
      ...allCategories.filter(k => (k.type === "SUBSUBCATEGORY" && k.parentId === subCategoryId)).map(subSubCategory => ({
        text: subSubCategory.name,
        value: subSubCategory.id,
        defaultSelected: selectedSubSubCategoryId === subSubCategory.id
      }))
    ];
  };
  const getSubCategoryOptions = (topCategoryId, selectedSubCategoryId = -1) => {
    const allCategories = context.getState()?.allCategory ? context.getState()?.allCategory.categories : [];

    return [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedSubCategoryId === -1,
        disabled: true
      },
      ...allCategories.filter(k => (k.type === "SUBCATEGORY" && k.parentId === topCategoryId)).map(subCategory => ({
        text: subCategory.name,
        value: subCategory.id,
        defaultSelected: selectedSubCategoryId === subCategory.id
      }))
    ];
  };
  const getTopCategoryOptions = (selectedTopCategoryId = -1) => {
    const allCategories = context.getState()?.allCategory ? context.getState()?.allCategory.categories : [];

    return [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedTopCategoryId === -1,
        disabled: true
      },
      ...allCategories.filter(k => (k.type === "CATEGORY")).map(topCategory => ({
        text: topCategory.name,
        value: topCategory.id,
        defaultSelected: topCategory.id === selectedTopCategoryId
      }))
    ];
  };
  const getConditionOptions = (selectedConditionId = -1) => {
    const conditions = context.getState()?.conditions ? context.getState()?.conditions : [];

    const options = [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedConditionId === -1,
        disabled: true
      },
      ...conditions.map(condition => ({
        ...condition,
        defaultSelected: condition.value === selectedConditionId
      }))
    ];

    return options;
  };
  const getSizeOptions = (selectedSizeId = -1, currentSizesAndAmounts) => {
    const sizes = context.getState()?.sizeComponents ? context.getState()?.sizeComponents : [];

    const options = [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedSizeId === -1,
        disabled: true
      },
      ...sizes.map(size => ({
        ...size,
        defaultSelected: size.value === selectedSizeId
      }))
    ];

    // FOR BUSINESS SIZE
    if (Array.isArray(currentSizesAndAmounts)) {
      options.forEach(option => {
        if (
          currentSizesAndAmounts.some(csaa => csaa.sizeId === option.value) && 
          option.value !== selectedSizeId
        ) {
          option.disabled = true;
        }
      });
    }

    return options;
  };
  const validatePhotos = photos => {
    return (Array.isArray(photos) && photos.length !== 0);
  };
  const isTheDeepestCategoryLevelSelected = (categoryIds) => {
    const allCategories = context.getState()?.allCategory ? context.getState()?.allCategory.categories : [];


    // if subCategory is not even selected - FALSE
    if (!categoryIds.subCategoryId || categoryIds.subCategoryId === -1) {
      return false;
    }

    // if a "childless" subCategory is selected - TRUE
    if (allCategories.filter(k => (k.type === "SUBSUBCATEGORY" && k.parentId === categoryIds.subCategoryId)).length === 0) {
      return true;
    }

    // subSub is selected?
    return (!isNaN(categoryIds.subSubCategoryId) && categoryIds.subSubCategoryId > 0);
  };
  const getInitialDescription = () => {
    const storedValues = cookies.get('new_item_values');

    // ha nincs elmentve semmi a cookies-ban
    if (!storedValues || !storedValues.description) {
      return (!product) ? '' : product.description;
    }

    const validUntil = new Date(storedValues.validUntil);

    // ha lejárt a cookie
    if (validUntil < new Date()) {
      cookies.remove('new_item_values');
      return (!product) ? '' : product.description;
    }

    // ha új termék
    if (!product) {
      return storedValues.description;
    }

    // ha nem ugyanaz a termék van szerkesztés alatt
    if (product.id !== storedValues.productId) {
      return product.description || '';
    }

    // ha ugyanaz a termék van szerkesztés alatt mint ami a cookie-ban van
    return storedValues.description;
  }
  const getInitialValues = () => {
    if (!product) {
      return {
        title: savedTitleAndDesc.title,
        description: savedTitleAndDesc.description,
        categoryIds: {
          topCategoryId: -1,
          subCategoryId: -1,
          subSubCategoryId: -1,
        },
        conditionId: -1,
        sizeId: -1,
        sizesAndAmounts: [{
          sizeId: -1,
          amount: 1
        }],
        additional_information: '',
        photos: [],
        price: 0,
        totalPrice: 0,
        shippingCost: 0,
        totalPriceWithShippingCost: 0,
        shippingAvailable: false,
        pickUpAvailable: false,
        shippingMethods: [],
      };
    }


    const pickupShippingMethod = product.shippingOptions.find(so => so.name === "PICKUP");
    const postShippingMethod = product.shippingOptions.find(so => so.name === "POST");
    const shippingMethods = [];

    if (pickupShippingMethod) {
      shippingMethods.push('PICKUP');
    }

    if (postShippingMethod) {
      shippingMethods.push('POST');
    }


    const initialValues = {
      title: (product.id == savedTitleAndDesc.productId) ? savedTitleAndDesc.title : product.title,
      description: (product.id == savedTitleAndDesc.productId) ? savedTitleAndDesc.description : product.description,
      categoryIds: {
        topCategoryId: product.category.id,
        subCategoryId: (product.subCategory) ? product.subCategory.id : -1,
        subSubCategoryId: (product.subsubCategory) ? product.subsubCategory.id : -1,
      },
      conditionId: (product.condition) ? product.condition.id : -1,
      sizeId: product.availableSizes[0].sizeId,
      sizesAndAmounts: product.availableSizes,
      additional_information: product?.additionalInformation ? product.additionalInformation : '',
      price: product ? product.price?.net : '',
      totalPrice: getTotalPrice(product?.price?.net ? product.price.net : 0),
      shippingCost: postShippingMethod ? postShippingMethod.price : '',
      shippingAvailable: postShippingMethod ? true : false,
      pickUpAvailable: pickupShippingMethod ? true : false,
      shippingMethods: shippingMethods,
      photos: product.images,
      totalPriceWithShippingCost: getTotalPriceWithShippingCost(product?.price?.net ? product?.price?.net : 0, postShippingMethod ? postShippingMethod.price : 0)
    };

    return initialValues;
  };
  const getValidationObject = () => {
    const validationObject = {
      title: Yup.string()
        .required(t('error_messages.title_required'))
        .max(80),
      price: Yup.number()
        .required(t('error_messages.price_required'))
        .min(10, t('error_messages.price_minimum')),
      totalPrice: Yup.number()
        .required(t('error_messages.price_required'))
        .max(999999, t('error_messages.total_price')),
      description: Yup.string().required(t('error_messages.description_required')),
      categoryIds: Yup.object().test('every_category_selected', t('error_messages.please_select_a_top_category'), isTheDeepestCategoryLevelSelected),
      conditionId: Yup.number().min(1, t('error_messages.please_select_a_condition')),
      photos: Yup.array().test('at-least-one-photo', t('error_messages.one_photo_needed'), photos => photos.filter(p => !p.toBeRemoved).length > 0),
      shippingMethods: Yup.array().min(1, !context.isLoggedInBusiness() ? t('error_messages.one_shipping_method_needed') : t('error_messages.one_shipping_method_needed_business')),
      shippingCost: Yup.number().when('shippingAvailable', {
        is: (shippingAvailable) => shippingAvailable,
        then: () => Yup.number().required(t('error_messages.shipping_cost_required')).min(0, t('error_messages.shipping_cost_minimum')),
      }),
      totalPriceWithShippingCost: Yup.number().max(999999, t('error_messages.total_price_with_sipping_cost'))
    };

    if (!context.isLoggedInBusiness()) {
      validationObject.sizeId = Yup.number().min(1, t('error_messages.please_select_a_size'));
    } else {
      validationObject.sizesAndAmounts = Yup.array()
        .test('sizes-selected', t('error_messages.select_size_everywhere'), saas => saas.filter(saa => saa.sizeId === -1).length === 0);
    }

    return validationObject;
  };

  // EVENTS
  const cancelClicked = () => {
    if (product) {
      navigate(`/profile/item/${product.id}`);
    } else {
      navigate('/marketplace');
    }
  };


  useEffect(() => {
    // redirect if not logged in
    if (!context.isLoggedIn()) {
      navigate('/marketplace');
    }

    if (selectedCategoryId) {
      context.onGetActueleSizeComponents(selectedCategoryId);
    }

    if (!selectedCategoryId && product) {
      if (product.subsubCategory) {
        setSelectedCategoryId(product.subsubCategory.id);  
        context.onGetActueleSizeComponents(product.subsubCategory.id);
      } else {
        if (product.subCategory) {
          setSelectedCategoryId(product.subCategory.id);  
          context.onGetActueleSizeComponents(product.subCategory.id);
        } else {
          setSelectedCategoryId(product.category.id);  
          context.onGetActueleSizeComponents(product.category.id);
        }
      }
    
    }

    context.onGetConditions();
  }, [cookies.get('mylanguage').language])

  useEffect(() => {
    context.interAxios.get('/vat/current',
    function(response) {
      setCurrentVat(response.data.rate);
    }
    ); 

  }, [])

  return (
    <>
      {(currentVat !== -1) && (
        <Formik
          innerRef={formikRef}
          initialValues = {getInitialValues()}
          onSubmit = {(values) => {
            // return;
            
            const sizeArray = [];

            if (!context.isLoggedInBusiness()) {
              const selectedSizeOption = getSizeOptions(values.sizeId).find(s => s.defaultSelected);

              sizeArray.push({ sizeId: selectedSizeOption.value, name: selectedSizeOption.text, amount: 1});
            } else {
              values.sizesAndAmounts.forEach((saa, index) =>{
                sizeArray.push({
                  sizeId: saa.sizeId,
                  amount: saa.amount,
                  name: context.getState()?.sizeComponents.find(sc => sc.value === saa.sizeId)?.text
                });
              });
            }

            const shippingOptionsArray = [];
            if(values.pickUpAvailable){
              shippingOptionsArray.push({name: "PICKUP", price: 0})
            }
            if(values.shippingAvailable){
              shippingOptionsArray.push({name: "POST", price: values.shippingCost})
            }

            setIsFormSubmitting(true);

            if(!product){
              if(values.photos !== undefined) {
                let prom=[];
                let array = [];
                for(let i=0; i<values.photos.length; i++){
                  const formData = new FormData
                  formData.append("file", values.photos[i].file);
                  prom.push(context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                    array[i]=response.data.id;
                  }));
                }
                Promise.all(prom).then(() => 
                  context.interAxios.post('/myitem',
                              {
                                images: array,
                                categoryId: values.categoryIds.topCategoryId,
                                subCategoryId: values.categoryIds.subCategoryId !== -1 ? values.categoryIds.subCategoryId : undefined,
                                subsubCategoryId: values.categoryIds.subSubCategoryId !== -1 ? values.categoryIds.subSubCategoryId : undefined,
                                title: values.title,
                                netPrice: values.price,
                                description: values.description,
                                availableSizes: sizeArray,
                                conditionId: values.conditionId,
                                additionalInformation: values.additional_information,
                                returnPolicy: values.returnPolicy !== undefined ? values.returnPolicy : undefined,
                                shippingOptions: shippingOptionsArray
                              }
                  , function(response) {
                    navigate(`/profile/item/add/confirmation/${response.data.id}`);
                  })
                );
              }
            }
            
            

            //Update
            if(product){
              if(values.photos !== undefined) {
                let prom=[];
                let array = values.photos;
                for(let i=0; i<values.photos.length; i++){
                  if(!values.photos[i].toBeRemoved && values.photos[i].new){
                    const formData = new FormData
                    formData.append("file", values.photos[i].file);
                    prom.push(context.interAxios.post('/media', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
                      array[i].id=response.data.id;
                    }));
                  }
                }
                
                Promise.all(prom).then(() => {
                  context.interAxios.put('/myitem/' + product.id, 
                              {
                                images: array.filter((k)=>(!k.toBeRemoved)).map((k)=>(k.id)),
                                categoryId: values.categoryIds.topCategoryId,
                                subCategoryId: values.categoryIds.subCategoryId !== -1 ? values.categoryIds.subCategoryId : undefined,
                                subsubCategoryId: values.categoryIds.subSubCategoryId !== -1 ? values.categoryIds.subSubCategoryId : undefined,
                                title: values.title,
                                netPrice: values.price,
                                description: values.description,
                                availableSizes: sizeArray,
                                conditionId: values.conditionId,
                                additionalInformation: values.additional_information,
                                returnPolicy: values.returnPolicy !== undefined ? values.returnPolicy : undefined,
                                shippingOptions: shippingOptionsArray
                              }
                  , function(response) {
                    navigate(`/profile/item/${product.id}`);
                  })}
                );
              }
            }

          }}
          validationSchema={Yup.object(getValidationObject())}
        >
          {formik => {

            const topCategoryOptions = getTopCategoryOptions();

            const subCategoryOptions = (formik.values.categoryIds.topCategoryId > 0) ? 
              getSubCategoryOptions(formik.values.categoryIds.topCategoryId) : 
              [];

            const subSubCategoryOptions = (formik.values.categoryIds.subCategoryId > 0) ? 
              getSubSubCategoryOptions(formik.values.categoryIds.topCategoryId, formik.values.categoryIds.subCategoryId) : 
              [];

            const deepestCategorySelected = isTheDeepestCategoryLevelSelected(formik.values.categoryIds);

            if (deepestCategorySelected) {
              setDeepestCategoryLevelReachedAlready(true);
            }

            return (
              <form onSubmit={formik.handleSubmit} className="profile-form">
                <div className="white-panel">
                  
                  {/** TITLE **/}
                  <MDBRow>
                    <MDBCol md='5'>
                      <label htmlFor="title">{t('product_form.title')} *</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <JCInput
                        value={formik.values.title}
                        onChangeHandler={formik.handleChange}
                        onBlurHandler={formik.handleBlur}
                        maxCharacterNumber={80}
                        id='title'
                        name='title'
                      />

                      {formik.touched.title && formik.errors.title ? (
                        <div className="invalid-feedback">{formik.errors.title}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>

                  {/** DESCRIPTION **/}
                  <MDBRow>
                    <MDBCol md='5' className="align-items-start">
                      <label htmlFor="description">{t('product_form.description')} *</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <JCTextarea
                        value={formik.values.description}
                        setValue={(newDescription) => {
                          formik.setFieldValue('description', newDescription);
                        }}
                        onBlurHandler={formik.handleBlur}
                        maxCharacterNumber={200}
                        id='description'
                        name='description'
                      />

                      {formik.touched.description && formik.errors.description ? (
                        <div className="invalid-feedback">{formik.errors.description}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>

                  {/** HOW TO SELL EFFECTIVELY **/}
                  <MDBRow>
                    <MDBCol className="d-flex justify-content-end" sm="12">
                      <a
                        className="jc-btn white sell-effectively"
                        onClick={() => {
                          let newItemValues = {
                            validUntil: new Date(new Date().getTime() + 10 * 60000), // set validation for only 10 minutes
                            title: formik.values.title,
                            description: formik.values.description
                          };


                          if (product?.id) {
                            newItemValues.productId = product.id;
                          }

                          cookies.set('new_item_values', newItemValues, { path: '/' });

                          navigate('/faq');
                        }}
                      >
                        <i className="fa-regular fa-info-circle"></i>
                        {t('product_form.how_to_sell_effectively')}
                      </a>
                    </MDBCol>
                  </MDBRow>

                  {/* {(type === 'BUSINESS') && "Are you required to pay VAT?"} */}
                </div>

                <div className="white-panel">

                  {/*** TOPCATEGORY ***/}
                  <MDBRow>
                    <MDBCol md='5'>
                      <label>{t('product_form.category')} *</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <MDBSelect
                        data={getTopCategoryOptions(formik.values.categoryIds.topCategoryId)}
                        onValueChange={(e) => {
                          context.onGetActueleSizeComponents(e.value);

                          formik.setValues({
                            ...formik.values,
                            categoryIds: {
                              topCategoryId: e.value,
                              subCategoryId: -1,
                              subSubCategoryId: -1,
                            },
                            sizeId: -1,
                            sizesAndAmounts: formik.values.sizesAndAmounts.map(saa => ({ ...saa, sizeId: -1}))
                          });

                          setSelectedCategoryId(e.value);
                        }}
                        className="big"
                      />

                      {/* {formik.touched.topCategoryId && formik.errors.topCategoryId ? (
                        <div className="invalid-feedback">{formik.errors.topCategoryId}</div>
                      ) : null} */}
                    </MDBCol>
                  </MDBRow>


                  {/*** SUBCATEGORY ***/}
                  {(formik.values.categoryIds.topCategoryId > -1) && (subCategoryOptions.length > 1) && (
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>{t('product_form.subcategory')} *</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <MDBSelect
                          data={getSubCategoryOptions(formik.values.categoryIds.topCategoryId, formik.values.categoryIds.subCategoryId)}
                          onValueChange={(e) => {
                            context.onGetActueleSizeComponents(e.value === -1 ? formik.values.categoryIds.topCategoryId : e.value);

                            formik.setValues({
                              ...formik.values,
                              categoryIds: {
                                topCategoryId: formik.values.categoryIds.topCategoryId,
                                subCategoryId: e.value,
                                subSubCategoryId: -1,
                              },
                              sizeId: -1,
                              sizesAndAmounts: formik.values.sizesAndAmounts.map(saa => ({ ...saa, sizeId: -1}))
                            });

                            setSelectedCategoryId(e.value);
                          }}
                          className="big"
                        />
                      </MDBCol>
                    </MDBRow>
                  )}


                  {/*** SUBSUBCATEGORY ***/}
                  {(formik.values.categoryIds.subCategoryId > -1) && (subSubCategoryOptions.length > 1) && (
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>{t('product_form.subsubcategory')} *</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <MDBSelect
                          data={getSubSubCategoryOptions(formik.values.categoryIds.topCategoryId, formik.values.categoryIds.subCategoryId, formik.values.categoryIds.subSubCategoryId)}
                          onValueChange={(e) => {
                            context.onGetActueleSizeComponents(e.value === -1 ? formik.values.categoryIds.topCategoryId : e.value);

                            formik.setValues({
                              ...formik.values,
                              categoryIds: {
                                topCategoryId: formik.values.categoryIds.topCategoryId,
                                subCategoryId: formik.values.categoryIds.subCategoryId,
                                subSubCategoryId: e.value,
                              },
                              sizeId: -1,
                              sizesAndAmounts: formik.values.sizesAndAmounts.map(saa => ({ ...saa, sizeId: -1}))
                            });

                            setSelectedCategoryId(e.value);
                          }}
                          className="big display-value"
                        />
                      </MDBCol>
                    </MDBRow>
                  )}


                  {/** CATEGORIES VALIDATION MESSAGE **/}
                  {(formik.touched.categoryIds && formik.errors.categoryIds) && (
                    <MDBRow className="category-error">
                      <MDBCol md='5'></MDBCol>
                      <MDBCol md="7">
                        <div className="invalid-feedback">{formik.errors.categoryIds}</div>
                      </MDBCol>
                    </MDBRow>
                  )}


                  {/*** CONDITION ***/}
                  <MDBRow>
                    <MDBCol md='5'>
                      <label>{t('product_form.condition')} *
                      <i
                            className="fa-regular fa-info-circle"
                            onClick={() => setConditionModalShown(true)}
                          ></i></label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <MDBSelect
                        data={getConditionOptions(formik.values.conditionId)}
                        onValueChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            conditionId: e.value,
                          });
                        }}
                        className={`big `}
                      />

                      {formik.touched.conditionId && formik.errors.conditionId ? (
                        <div className="invalid-feedback">{formik.errors.conditionId}</div>
                      ) : null}
                    </MDBCol>
                    <ConditionInfoModal
                          visible={conditionModalShown}
                          hideModal={() => setConditionModalShown(false)}
                          infoIcon="fa-regular fa-info-circle"
                          title={t('product_form.condition_information')}
                          text_very_good={t('product_form.rarely_used_item_in_top_condition')}
                          text_good={t('product_form.used_item_with_possible_defects_and_signs_of_use')}
                          text_acceptable={t('product_form.often_used_item_with_possible_defects_and_signs_of_use')}
                          conditionArray={context.getState()?.conditions}
                        />
                  </MDBRow>
                 


                  {/*** SIZE (PRIVATE) ***/}
                  {(formik.values.categoryIds.subCategoryId > -1) && (!context.isLoggedInBusiness()) && (
                    <MDBRow>
                      <MDBCol md='5'>
                        <label>{t('product_form.size')} *</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <MDBSelect
                          data={getSizeOptions(formik.values.sizeId)}
                          onValueChange={(e) => {
                            formik.setValues({
                              ...formik.values,
                              sizeId: e.value,
                            });
                          }}
                          className={`big`}
                        />

                        {formik.touched.sizeId && formik.errors.sizeId ? (
                          <div className="invalid-feedback">{formik.errors.sizeId}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  )}


                  {/*** ADDITIONAL INFORMATION ***/}
                  <MDBRow>
                    <MDBCol md='5' className="align-items-start">
                      <label htmlFor="additional-information">{t('product_form.additional_information')}</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <JCTextarea
                        value={formik.values.additional_information}
                        setValue={(newAdditionalInformation) => {
                          formik.setFieldValue('additional_information', newAdditionalInformation);
                        }}
                        maxCharacterNumber={200}
                        id='additional_information'
                        name='additional_information'
                      />
                    </MDBCol>
                  </MDBRow>

                </div>

                {/*** SIZES AND AMOUNTS (BUSINESS USER) ***/}
                {(formik.values.categoryIds.subCategoryId > -1) && (context.isLoggedInBusiness()) && (
                  <div className="white-panel size-and-amounts">
                    <MDBRow>
                      <MDBCol md='5' className="align-items-start">
                        <label>{t('product_form.sizes')} *</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        {formik.values.sizesAndAmounts.map((sizeAndAmount, index) => (
                          <div 
                            className="size-amount-row"
                            key={index}
                          >

                            <div>
                              <label>{t('product_form.size')}</label>

                              <MDBSelect
                                data={getSizeOptions(sizeAndAmount.sizeId, formik.values.sizesAndAmounts)}
                                onValueChange={(e) => {
                                  const newSizesAndAmounts = [...formik.values.sizesAndAmounts];
                                  
                                  newSizesAndAmounts[index].sizeId = e.value;

                                  formik.setValues({
                                    ...formik.values,
                                    sizesAndAmounts: newSizesAndAmounts,
                                  });
                                }}
                                className={`big`}
                              />

                            </div>
                            
                            <div>
                              <label>{t('product_form.quantity')}</label>

                              <MDBSelect
                                data={getAmounts(sizeAndAmount.amount)}
                                onValueChange={(e) => {
                                  const newSizeQuantities = [...formik.values.sizesAndAmounts];
                                  
                                  newSizeQuantities[index].amount = e.value;

                                  formik.setValues({
                                    ...formik.values,
                                    sizesAndAmounts: newSizeQuantities,
                                  });
                                }}
                                className={`big`}
                              />
                            </div>

                            <i 
                              className={"fa-regular fa-minus-circle" + ((index === 0) ? ' hidden' : '')}
                              onClick={() => {
                                if (index === 0) {
                                  return;
                                }

                                formik.setFieldValue('sizesAndAmounts', formik.values.sizesAndAmounts.filter((_, index2) => index !== index2));
                              }}
                            ></i>
                          </div>
                        ))}

                        {formik.values.sizesAndAmounts.length < context.getState()?.sizeComponents?.length && (
                          <button 
                            type="button" className="jc-btn white add-size"
                            onClick={() => {
                              formik.setFieldValue('sizesAndAmounts', [
                                ...formik.values.sizesAndAmounts,
                                {
                                  sizeId: -1,
                                  amount: 1
                                }
                              ]);
                            }}
                          >
                            <i className="fa-regular fa-plus-circle"></i>

                            {t('product_form.add_size')}
                          </button>
                        )}
                        
                        {formik.touched.sizesAndAmounts && formik.errors.sizesAndAmounts ? (
                          <div className="invalid-feedback">{formik.errors.sizesAndAmounts}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  </div>
                )}

                {/** IMAGES **/}
                <div className="white-panel">
                  <h2>{t('product_form.add_photos_title')}</h2>
                  <ImageUploader
                    selectedImages={formik.values.photos}
                    setSelectedImages={(photos) => {
                      formik.setFieldValue('photos', photos);
                    }}
                    uploadBtnMessage={t('product_form.upload')}
                    heroImageSelectable={true}
                  />

                  {formik.touched.photos && formik.errors.photos ? (
                    <div className="invalid-feedback">{formik.errors.photos}</div>
                  ) : null}

                  <div className="helper-text">
                    <i className="fa-regular fa-info-circle"></i>
                    {t('product_form.photos_helper_text')}
                  </div>
                </div>


                <div className="white-panel price-shipping">

                  {/** PRICE **/}
                  <MDBRow>
                    <MDBCol md='5' className="align-items-start pt-3">
                      <label>{t('product_form.price')}</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <input
                        name="price"
                        id="price"
                        type="number"
                        value={formik.values.price}
                        onChange={(value) => formik.setValues({
                            ...formik.values,
                            price: value.target.value,
                            totalPrice: getTotalPrice(value.target.value),
                            totalPriceWithShippingCost: getTotalPriceWithShippingCost(value.target.value, formik.values.shippingCost)
                          })
                        }
                        onBlur={formik.handleBlur}
                      />

                      <span className="at-input-end">CHF</span>

                      {formik.touched.price && formik.errors.price ? (
                        <div className="invalid-feedback">{formik.errors.price}</div>
                      ) : null}

                        {cookies.get('mylocation')?.clientIsVat === true && (
                          <div className="price-row">
                            <span className="label">{"VAT " + currentVat + "%"}</span>
                            <span className="price">
                              {(parseInt(formik.values.price)*currentVat/100).toFixed(2)}
                              <span>CHF</span>
                            </span>
                          </div>
                        )}
                      
                      <div className="price-row">
                        <span className="label"> {t('product_form.total')} </span>
                        <span className="price total">
                          {formik.values.totalPrice.toFixed(2)}
                          
                          <span>CHF</span>
                        </span>
                      </div>

                      {formik.touched.price && formik.errors.totalPrice ? (
                        <div className="invalid-feedback">{formik.errors.totalPrice}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>


                  {/** SHIPPING VIA MAIL **/}
                  <MDBRow>
                    <MDBCol md="12">
                      <div className='toggle-with-label no-pointer'>
                        <Toggle
                          checked={formik.values.shippingAvailable}
                          name='shippingAvailable'
                          id='shippingAvailable'
                          onChange={e => {
                            formik.setValues({
                              ...formik.values,
                              shippingAvailable: e.target.checked,
                              shippingMethods: e.target.checked ? [...formik.values.shippingMethods, 'POST'] : formik.values.shippingMethods.filter(sm => sm !== 'POST'),
                              shippingCost: 0,
                              totalPriceWithShippingCost: getTotalPriceWithShippingCost(formik.values.price, 0)
                            });

                            // formik.validateForm();
                          }}
                          className="jc-toggle"
                        />

                        {t('product_form.shipping_label')}
                      </div>
                    </MDBCol>
                  </MDBRow>


                  {/**SHIPPING COST **/}
                  {formik.values.shippingAvailable && (
                    <MDBRow>
                      <MDBCol md='5' className="align-items-start pt-3">
                        <label>
                          {t('product_form.shipping_cost')}
                          <i
                            className="fa-regular fa-info-circle"
                            onClick={() => setShippingCostModalShown(true)}
                          ></i>
                        </label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <input
                          name="shippingCost"
                          id="shippingCost"
                          type="number"
                          value={formik.values.shippingCost}
                          onChange={(value) => formik.setValues({
                              ...formik.values,
                              shippingCost: value.target.value,
                              totalPriceWithShippingCost: getTotalPriceWithShippingCost(formik.values.price, value.target.value)
                            })
                          }
                          onBlur={formik.handleBlur}
                        />

                        <span className="at-input-end">CHF</span>

                        {formik.touched.shippingCost && formik.errors.shippingCost ? (
                          <div className="invalid-feedback">{formik.errors.shippingCost}</div>
                        ) : null}

                        <div className="price-row total">
                          <span className="label"> {t('product_form.total')} </span>
                          <span className="price total">
                            {formik.values.totalPriceWithShippingCost.toFixed(2)}
                            <span>CHF</span>
                          </span>
                        </div>

                        {formik.touched.shippingCost && formik.errors.totalPriceWithShippingCost ? (
                          <div className="invalid-feedback">{formik.errors.totalPriceWithShippingCost}</div>
                        ) : null}

                        <GeneralInfoModal
                          visible={shippingCostModalShown}
                          hideModal={() => setShippingCostModalShown(false)}
                          infoIcon="fa-regular fa-info-circle"
                          title={t('general_modals.shipping_cost_modal.title')}
                          text={t('general_modals.shipping_cost_modal.text')}
                          buttonText={t('general_modals.shipping_cost_modal.button_text')}
                          buttonClick={() => {
                            setShippingCostModalShown(false);
                            window.open('https://www.post.ch/de/pakete-versenden/pakete-schweiz', '_blank');
                          }}
                        />
                      </MDBCol>
                    </MDBRow>
                  )}


                  {/** PICK UP **/}
                  {!context.isLoggedInBusiness() && (
                    <MDBRow>
                    <MDBCol md="12">
                      <div className='toggle-with-label no-pointer pick-up'>
                        <Toggle
                          checked={formik.values.pickUpAvailable}
                          name='pickUpAvailable'
                          id='pickUpAvailable'
                          onChange={e => {
                            formik.setFieldValue('pickUpAvailable', e.target.checked);
                            
                            if (e.target.checked) {
                              formik.setFieldValue('shippingMethods', [...formik.values.shippingMethods, 'PICKUP']);
                            } else {
                              formik.setFieldValue('shippingMethods', formik.values.shippingMethods.filter(sm => sm !== 'PICKUP'));
                            }
                          }}
                          className="jc-toggle"
                        />

                        {t('product_form.pick_up_available')}
                        <i
                          className="fa-regular fa-info-circle"
                          onClick={() => setPickUpModalShown(true)}
                        ></i>
                      </div>

                      <GeneralInfoModal
                        visible={pickUpModalShown}
                        hideModal={() => setPickUpModalShown(false)}
                        infoIcon="fa-regular fa-info-circle"
                        title={t('general_modals.pick_up_available_modal.title')}
                        text={t('general_modals.pick_up_available_modal.text')}
                      />
                    </MDBCol>
                    </MDBRow>
                  )}
                  

                  {formik.touched.shippingMethods && formik.errors.shippingMethods ? (
                    <div
                      className="invalid-feedback"
                      style={{marginTop: '20px'}}
                    >{formik.errors.shippingMethods}</div>
                  ) : null}

                  {formik.touched.totalPrice && formik.errors.totalPrice ? (
                    <div
                      className="invalid-feedback"
                      style={{marginTop: '20px'}}
                    >{formik.errors.totalPrice}</div>
                  ) : null}
                </div>

                <div className="last-buttons-row to-right">
                  <button
                    type="button"
                    className="jc-btn transparent"
                    onClick={cancelClicked}
                  >
                    {t('misc.cancel')}
                  </button>

                  <button type="submit" className="jc-btn black">
                    {!isFormSubmitting && (
                      <>
                        <i className="fa-regular fa-check"></i>
                        {t('misc.submit')}
                      </>
                    )}

                    {isFormSubmitting && <i className="fa-regular fa-spinner-third spinning"></i>}
                  </button>

                  {formik.submitCount > 0 && !formik.isValid ? (
                    <div className="invalid-feedback">
                      {t('error_messages.general')}
                    </div>
                  ) : null}
                </div>
              </form>
            )}
          }
        </Formik>
      )}
    </>
  );
};

export default withTranslation()(ItemForm);
