// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import AppContext from '../../../AppContext';

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param titleText?: string (the default is coming from the translation.json)
 * @returns
 */
const OnlyForPrivateUserModal = (props) => {
  const context = useContext(AppContext);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  // EVENTS
  const clickOnLoginButton = () => {
    props.hideModal();

    setTimeout(() => {
      context.openLoginModal();
    }, 300);
  };

  return (
    <MDBModal
      className="standard-jc-modal w-615 please-login"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>
              {props.titleText ? props.titleText : t('general_modals.only_for_private_user.title')}
            </h3>

            <p>{t('general_modals.only_for_private_user.text')}</p>

            {/* <button
              className="jc-btn black"
              onClick={() => navigate('/sign-up')}
            >
              {t('general_modals.register_to_comment.button_text')}
            </button> */}

          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(OnlyForPrivateUserModal);
