// REACT IMPORTS
import { useReducer, useState } from "react";

// MDBOOTSTRAP IMPORTS
import { MDBLightbox, MDBLightboxItem } from 'mdb-react-ui-kit';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


// 3RD PARTY IMPORTS
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";

// IMAGES
import service1 from '../../../images/service1.png';
import service2 from '../../../images/service2.png';
import service3 from '../../../images/service3.png';
import wedding from '../../../images/wedding.jpg';
import slider5Image from '../../../images/slider-5-image.jpg';
import fdiImage from '../../../images/fdi.jpg';


// IMAGE URLS - MODEl
/*const sliderImages = [
  service1,
  service2,
  service3,
  wedding,
  slider5Image,
  fdiImage
];*/

// REDUCER RELATED TYPES
// type SliderState = {
//   currentIndex;
//   highlightedIndex;
// };
// type SliderStateChange = {
//   eventType: 'PAGE_CHANGED' | 'IMAGE_SELECTED';
//   payload | number;
// };

// REDUCER FUNCTION
const sliderImagesReducer = (prevStateOfSlider, action) => {
  switch (action.eventType) {
    case 'IMAGE_SELECTED':
      return {...prevStateOfSlider, highlightedIndex: props.sliderImages.indexOf(action.payload.toString())}

    default:
      const prevHighligtedIndex = prevStateOfSlider.highlightedIndex;
      const prevIndex = prevStateOfSlider.currentIndex;

      const newIndex = +action.payload;
      let newHighlightedIndex = prevStateOfSlider.highlightedIndex;

      if (prevIndex < newIndex) {
        newHighlightedIndex++;
      }

      if (prevIndex > newIndex) {
        newHighlightedIndex--;
      }

      return { currentIndex: newIndex, highlightedIndex: newHighlightedIndex };
  }
}

const ProductImages = ( props ) => {
  // STATES
  const [highlightedImageIndex, setHighlightedImageIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);

  // SWIPER INTANCE
  const [swiperInstance, setSwiperInstance] = useState(null);


  // DOM events
  const clickOnImage = (imageUrl) => {
    dispatchSliderStateChange({
      eventType: 'IMAGE_SELECTED',
      payload: imageUrl
    });
  };
  const clickOnLeft = () => {
    const newHighlightedIndex = highlightedImageIndex === 0 ? (props.sliderImages.length - 1) : (highlightedImageIndex - 1);
    setHighlightedImageIndex(newHighlightedIndex);
    swiperInstance.slideTo(newHighlightedIndex);
  };
  const clickOnRight = () => {
    const newHighlightedIndex = highlightedImageIndex === (props.sliderImages.length - 1) ? 0 : (highlightedImageIndex + 1);
    setHighlightedImageIndex(newHighlightedIndex);
    swiperInstance.slideTo(newHighlightedIndex);
  };

  if (Array.isArray(props.sliderImages) && props.sliderImages.length === 1) {
    setTimeout(() => {
      document.getElementsByTagName('body')[0].classList.add('one-product-image')
    }, 1000);
  } else {
    setTimeout(() => {
      document.getElementsByTagName('body')[0].classList.remove('one-product-image')
    }, 1000);
  }


  const inputArray = [1, 2, 3, 4, 5];
  const outputArray1 = inputArray.slice(3)
  const outputArray2 = inputArray.slice(0, 3)
  for(let i=0; i<outputArray2.length; i++){
    outputArray1.push(outputArray2[i])
  }

  // HELPER FUNCS
  const onImagesArray = (index) => {
    const inputArray = props.sliderImages;
    const outputArray1 = inputArray.slice(index)
    const outputArray2 = inputArray.slice(0, index)
    for(let i=0; i<outputArray2.length; i++){
      outputArray1.push(outputArray2[i])
    }
    setSliderImages(outputArray1);
    setOpen(true)
  }
  const doesItHaveOnly1Image = () => {
    return Array.isArray(props.sliderImages) && props.sliderImages.length === 1;
  };



  return (
    <div className={"product-images" + (doesItHaveOnly1Image() ? ' no-thumbnails' : '')}>
      {!doesItHaveOnly1Image() && (
        <>
          <div className="carousel-wrapper noselect">
            {/* <Swiper
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              direction={'vertical'}
            >
              {sliderImages.map((sliderImage, index) => (
                <SwiperSlide key={index}>Slide 1</SwiperSlide>
              ))}
            </Swiper> */}

            {props.sliderImages && (
              <Swiper
                spaceBetween={20}
                slidesPerView={4}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                direction='vertical'
                allowTouchMove={false}
              >
                {props.sliderImages.map((sliderImage, index) => (
                  <SwiperSlide
                    key={index}
                    className={index === highlightedImageIndex ? 'highlighted' : ''}
                  >
                    <img
                      src={sliderImage}
                      onClick={() => setHighlightedImageIndex(index)}
                    />
                  </SwiperSlide>
                ))}
                {/* <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide> */}
              </Swiper>
            )}
          </div>
        </>
      )}

      <div className="highlighted-wrapper noselect">
        
        {/*** LEFT BUTTON ****/}
        {Array.isArray(props.sliderImages) && props.sliderImages.length > 1 && (
          <button className="step left" onClick={clickOnLeft}>
            <i className="fa-light fa-angle-left"></i>
          </button>
        )}

        {/*** IMAGES ****/}
        {Array.isArray(props.sliderImages) && props.sliderImages.length > 0 && (<>
          {/*<MDBLightbox fontAwesome='pro'>
            {props.sliderImages.map((sliderImage, index) => (
              <MDBLightboxItem
                key={sliderImage}
                src={sliderImage}
                fullscreenSrc={sliderImage}
                className={index === highlightedImageIndex ? 'highlighted' : ''}
              />
            ))}
            </MDBLightbox>*/}
            
              
                <MDBLightbox>
                  {props.sliderImages.map((sliderImage, index) => (
                    <button key={index} type="button" className="lightbox-button" onClick={() => onImagesArray(index)}>
                      <img
                        src={sliderImage}
                        className={index === highlightedImageIndex ? 'highlighted' : ''}
                      />
                    </button>
                  ))}
                </MDBLightbox>
              

              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={sliderImages.map(k => {return ({src: k})})}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
              />
            </>
        )}

        {/*** RIGHT BUTTON ****/}
        {Array.isArray(props.sliderImages) && props.sliderImages.length > 1 && (
          <button className="step right" onClick={clickOnRight}>
            <i className="fa-light fa-angle-right"></i>
          </button>
        )}

        <button className="open-lightbox">
          <i className="fa-regular fa-arrows-maximize"></i>
        </button>
      </div>
    </div>
  );
};

export default ProductImages;
