// react imports
import React, { useContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM COMPONENTS IMPORTS
import AppContext from '../../../AppContext';
import SellerCard from "../../common/seller-card/SellerCard";
import ProductListItem from "../../common/product-list/product-list-item/ProductListItem";
import RatingsModal from "../../modals/ratings-modal/RatingsModal";
import EmptyTab from "../EmptyTab/EmptyTab";

// MODAL IMPORTS
import BookmarkOnlyModal from "../../modals/bookmark-only-modal/BookmarkOnlyModal";

// DATA IMPORT
import { Seller as seller, SampleProducts, UserRatings } from "../../../data/product-data";
import { Users } from "../../../data/user-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import ReportModal from "../../modals/report-modal/ReportModal";

// IMAGE IMPORT
import userImage from '../../../images/user.png';

import Moment from 'moment';
import MessageModal from "../../modals/message-modal/MessageModal";
import MessageSendModal from "../../modals/message-send-modal/MessageSendModal";
import MessageDeleteModal from "../../modals/message-delete-modal/MessageDeleteModal";
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";
import MessageThreadDeleteModal from "../../modals/message-thread-delete-modal/MessageThreadDeleteModal";
import { getAgoText } from "../../../data/helper-funcs";

import replay_icon from '../../../icons/replay-icon.svg'

const messagesModel = {
  content: [],
  pageSize: 0,
  pageIndex: 0,
  numberOfPages: 0,
  totalElements: 0
};

for (let i = 1; i < 11; i++) {
  messagesModel.content.push({
    id: i,
    sentAt: new Date().getTime(),
    partner: {
      id: 123,
      image: {
        id: 123,
        url: userImage
      },
      firstName: "John",
      lastName: "Doe",
      publicName: "JohnDoePublicName"
    },
    itemName: "Sunglasses",
    textStart: "blablabla...",
    status: "NEW",
    direction: Math.random() < 0.5 ? "SENT" : "FROM"
  });
}

const messageThreads = {
  content: [],
  pageSize: 0,
  pageIndex: 0,
  numberOfPages: 0,
  totalElements: 5
};

for (let i = 1; i < 11; i++) {
  messageThreads.content.push({
    id: 123,
    sentAt: new Date().getTime(),
    partner: {
      id: 123,
      image: {
        id: 123,
        url: userImage
      },
      firstName: "John",
      lastName: "Doe",
      publicName: "John Doe"
    },
    itemName: "Sunglasses",
    orderId: i,
    textStart: "blablabla...",
    status: i < 4 ? 'NEW' : '',
    amountSent: 3,
    amountReceived: 2
  });
}

const MessagesPage = (props) => {
  const context = useContext(AppContext);

  // STATES
  const [incomingMessages, setIncomingMessages] = useState([]);
  const [threads, setThreads] = useState();
  const [messagesInThread, setMessagesInThread] = useState();
  const [selectedThreadId, setSelectedThreadId] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(false);
  const [totalPageMessage, setTotalPageMessage] = useState(0);
  const [pageSizeMessage, setPageSizeMessage] = useState(5);
  const [moreProductsAvailableMessage, setMoreProductsAvailableMessage] = useState(false);
  // MODAL STATES
  const [idMessageModal, setIdMessageModal] = useState();
  const [messageSendModalVisible, setMessageSendModalVisible] = useState(false);
  const [messageSendModalRecipient, setMessageSendModalRecipient] = useState();
  const [messageSendModalSubject, setMessageSendModalSubject] = useState();
  const [messageSendModalOrderId, setMessageSendModalOrderId] = useState();

  const [idDeleteMessageModal, setIdDeleteMessageModal] = useState();

  const [oldMessagesModalVisible, setOldMessagesModalVisible] = useState(false);

  const [deleteThreadIdModal, setDeleteThreadIdModal] = useState(false);

  // MULTILANGUAGE
  const { t } = i18n;

  // TEMPLATE
  const getMessageTemplate = (message) => {
    return (
      <div 
        className={"email" + (message?.status === "NEW" ? ' unread' : '')}
        key={message.id}
        onClick={() => {context.onRenderTrueBookmark(); setIdMessageModal(message?.id)}}
      >
        {message.direction !== "SENT" ?
        <img 
          src={message?.partner?.image?.url ? message?.partner?.image?.url : userImage} 
          alt={message?.partner?.publicName}
        />:
         <img 
         id="replay"
          src={replay_icon} 
          alt={"replay"}
        />}
        
        <div className="subject-and-from">
        {message.direction === "SENT" ? 
        <>
        <div className="subject">{t('messages.you')}</div>
        </>:
        <>
        <div className={message?.status === "NEW" ? "subject bold" : "subject"}>{message?.partner ? " " +  message?.partner?.firstName + " " +  message?.partner?.lastName : t('messages.deleted_user')}</div>
        </>}
          
        </div>

        {message?.sentAt && (
          <div className="date">
            {Moment(message?.sentAt).format('DD.MMM.YYYY HH:mm')}
          </div>
        )}
      </div>
    );
  };
  

  // TESTING
  // useState(() => {
  //   setMessageSendModalRecipient({ id: 13, publicName: 'John Doe' });
  //   setMessageSendModalSubject('Sunglasses');
  //   setMessageSendModalOrderId(3020);

  //   setMessageSendModalVisible(true);
  // }, []);


  // EVENTS
  const selectThread = (id) => {
    setMessagesInThread(undefined);

    if (selectedThreadId === id) {
      setSelectedThreadId(undefined);
    } else {
      setSelectedThreadId(id);
    }
    context.interAxios.get('/message/byorder/' + id, {
      params: {
          pageIndex: 0,
          pageSize: 5
        }
      },function(response) {
      setMessagesInThread(response.data);
      setTotalPageMessage(response.data.totalElements)
      setPageSizeMessage(response.data.pageSize > 5 ? response.data.pageSize : 5)
      context.setMessageLoading(false)
      if(response.data.totalElements > response.data.pageSize && response.data.totalElements > 5){
        
        setMoreProductsAvailableMessage(true);
      }else{
        
        setMoreProductsAvailableMessage(false);
      }
    })

    setTimeout(() => {
      //setMessagesInThread(messagesModel);

      if (window.innerWidth < 650) {
        document.getElementsByClassName('sell-now')[0].style.display = 'none';
      }
    }, 1500);
  };
  const onDeleteMessageById = (id) => {
    context.interAxios.delete('/message/' + id, function(response) {
      context.onRenderTrueBookmark();
    })
  }

  const onDeleteMessageGroupById = (id) => {
    context.interAxios.delete('/message/byorder/' + id, function(response) {
      context.onRenderTrueBookmark();
    })
  }


  useEffect(() => {
    if (!context.isLoggedIn()) {
      context.openLoginModal(false);
      return;
    }
    context.interAxios.get('/message/byorder', {
      params: {
          pageIndex: 0,
          pageSize: pageSize
        }
      },function(response) {
      setThreads(response.data);
      setTotalPage(response.data.totalElements)
      setPageSize(response.data.pageSize > 5 ? response.data.pageSize : 5)
      context.setItemsLoading(false)
      if(response.data.totalElements > response.data.pageSize && response.data.totalElements > 5){
        
        setMoreProductsAvailable(true);
      }else{
        
        setMoreProductsAvailable(false);
      }
    })
    if(selectedThreadId !== undefined){
      selectThread(selectedThreadId)
    }
    setTimeout(() => {
      //setThreads(messageThreads);

      context.onRender();
    }, [1000])

  }, [context.getState()?.isRender]);

  const loadNewProducts = () => {
    //context.setScrollOnLoadMore();
    context.setItemsLoading(true)
    setPageSize(pageSize + 5);
    context.onRenderTrue();
  };

  const loadNewMessages = () => {
    //context.setScrollOnLoadMore();
    context.setMessageLoading(true)
    //setPageSizeMessage(pageSizeMessage + 5);
    context.interAxios.get('/message/byorder/' + selectedThreadId, {
      params: {
          pageIndex: 0,
          pageSize: pageSizeMessage +5
        }
      },function(response) {
      setPageSizeMessage(pageSizeMessage + 5);
      setMessagesInThread(response.data);
      setTotalPageMessage(response.data.totalElements)
      setPageSizeMessage(response.data.pageSize > 5 ? response.data.pageSize : 5)
      context.setMessageLoading(false)
      if(response.data.totalElements > response.data.pageSize && response.data.totalElements > 5){
        
        setMoreProductsAvailableMessage(true);
      }else{
        
        setMoreProductsAvailableMessage(false);
      }
    })
  };
  
  return (
    <div className="jc-page white messages message-box">
      <div className="container-fluid mw-1300 ">
        <h1 className="messages-group">
          {t('messages.messages')}
          <i 
            className="fa-regular fa-info-circle pointer"
            onClick={() => setOldMessagesModalVisible(true)}
          ></i>
        </h1>

        {/* <div className="jc-tabs tabs-2">
          <div
            className={"jc-tab" + (selectedTab === 'Inbox' ? ' active' : '')}
            onClick={() => setSelectedTab('Inbox')}
          >
            <i className="fa-solid fa-inbox-in"></i>
            {t('messages.inbox')}
          </div>
          <div
            className={"jc-tab" + (selectedTab === 'Sent' ? ' active' : '')}
            onClick={() => setSelectedTab('Sent')}
          >
            <i className="fa-solid fa-inbox-out"></i>
            {t('messages.sent')}
          </div>
        </div> */}

        <div className="email-section">
          {threads?.content?.length === 0 && (
            <div className="d-flex justify-content-center align-items-center" style={{width: "100%"}}>
            <EmptyTab/>
            </div>
          )}

          {threads?.content?.length > 0 && (
            <>
              <div className="threads">
                {threads.content.map(thread => {
                  return (
                    <div 
                      className={'thread-row' + 
                                  (selectedThreadId === thread.orderId ? ' selected' : '') + 
                                  (thread.status === 'NEW' ? ' unread' : '')
                                } 
                      onClick={() => {selectThread(thread.orderId); setPageSizeMessage(5)}}
                      key={thread.orderId}
                    >
                     {/* <i className={'fa-solid fa-envelopes'}></i>*/}
      
                      <img 
                        src={thread?.partner?.image?.url ? thread?.partner?.image?.url : userImage} 
                        alt={thread.partner?.firstName + thread.partner?.lastName}
                      />
      
                      <div className="subject-and-partner">
                        <div className="subject">
                          {thread.itemName}
                        </div>

                        <div className="partner">  
                          {thread.partner?.firstName + thread.partner?.lastName}
                        </div>
                      </div>
      
                      <div className="thread-infos">
                        <div className="date">
                          {getAgoText(thread?.sentAt)}
                          {/*Moment(thread?.sentAt).format('DD.MM.YYYY HH:mm')*/}
                        </div>
                        <div className="meta-infos">
                          <div title={"Messages received: " + (thread.amountReceived ? thread.amountReceived : 0)}>
                            <i className="fa-solid fa-inbox-in"></i>
                            <span>{thread.amountReceived ? thread.amountReceived : 0}</span>
                          </div>
                          <div title={"Messages sent: " + (thread.amountSent ? thread.amountSent : 0)}>
                            <i className="fa-solid fa-inbox-out"></i>
                            <span>{(thread.amountSent ? thread.amountSent : 0)}</span>
                          </div>
      
                          <i 
                            className="fa-solid fa-trash-alt pointer"
                            onClick={(e) => {
                              e.stopPropagation();

                              setDeleteThreadIdModal(thread.orderId);
                            }}
                          ></i>
                        </div>
                      </div>
      
                    </div>
                  );
                })}
                 {moreProductsAvailable && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link"}
                  onClick={loadNewProducts}
                >
                  <i 
                    className={"fa-regular fa-arrow-rotate-right" + (context.getState().itemsLoading ? " spinning" : "")}
                  ></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}
              </div>

              <div className={"emails" + (selectedThreadId ? ' with-emails mobile-visible' : '')}>
                <i 
                  className="fa-regular fa-xmark"
                  onClick={() => {
                    document.getElementsByClassName('sell-now')[0].style.display = 'flex';
                    setSelectedThreadId(undefined);
                  }}
                ></i>

                {selectedThreadId && !messagesInThread && (
                  <i className="fa-regular fa-spinner-third spinning"></i>
                )}

                {selectedThreadId && Array.isArray(messagesInThread?.content) && (
                  <div className="email-list">
                    <div className="group-title">{messagesInThread?.content[0].itemName} </div>
                    {messagesInThread.content.map((m, n) => getMessageTemplate(m, n))}
                    {moreProductsAvailableMessage && (
                      <div className="product-list pt-4">
                        <button
                          className={"gray-link"}
                          onClick={loadNewMessages}
                        >
                          <i 
                            className={"fa-regular fa-arrow-rotate-right" + (context.getState().messagesLoading ? " spinning" : "")}
                          ></i>
                          {t('misc.load_more')}
                        </button>
                      </div>
                    )}
                  </div>
                )}
                
                {!selectedThreadId && (<p className="text-center py-3 my-2">{t('messages.please_select_message_group')}</p>)}
              </div>
            </>
          )}
        </div>
       
      </div>

      <MessageModal
        messageId={idMessageModal}
        hideModal={() => setIdMessageModal(null)}
        reply={(orderId, recipientId, recipientPublicName, subject) => {
          setIdMessageModal(null);
          setTimeout(() => {
            setMessageSendModalRecipient({ id: recipientId, publicName: recipientPublicName });
            setMessageSendModalSubject(subject);
            setMessageSendModalOrderId(orderId);
            
            setMessageSendModalVisible(true);
          }, 500);
        }}
        delete={(messageId) => {
          setIdMessageModal(null);
          onDeleteMessageById(messageId)
          setTimeout(() => {
            setIdDeleteMessageModal(messageId);
          }, 500);
        }}
      />

      <MessageSendModal
        visible={messageSendModalVisible}
        hideModal={() => setMessageSendModalVisible(false)}
        recipient={messageSendModalRecipient}
        subject={messageSendModalSubject}
        orderId={messageSendModalOrderId}
      />

      <MessageDeleteModal
        messageId={idDeleteMessageModal}
        hideModal={() => setIdDeleteMessageModal(null)}
      />

      <MessageThreadDeleteModal
        orderId={deleteThreadIdModal}
        onDeleteMessageGroupById={(messageId) => {onDeleteMessageGroupById(messageId)}}
        hideModal={() => setDeleteThreadIdModal(false)}
      />

      <GeneralInfoModal
        visible={oldMessagesModalVisible}
        hideModal={() => setOldMessagesModalVisible(false)}
        infoIcon="fa-regular fa-info-circle"
        text={t('messages.old_messages_text')}
      />

    </div>
  );
};

export default withTranslation()(MessagesPage);
