// react imports
import React, { useState, useEffect } from "react";

// 3RD PARTY IMPORTS
import { Popover } from 'react-tiny-popover';


/**
 * Props:
 * @param className: string
 * @param triggerElemClassName: string
 * @param triggerElem: JSX or function which returns JSX
 * @param containerStyle: CSSStyleDeclaration (basic css style in object style)
 * @param clickOnTriggerElem?: () => {}
 * @param closeOnClickingOutside? () => boolean
 * @param disableScrollOnOpen?: boolean
 * @returns 
 */
const OverlayPanel = (props) => {
  // STATES
  const [showOverlayPanel, setShowOverlayPanel] = useState(false);

  // EFFECTS
  useEffect(() => {
    window.addEventListener('scroll', () => setShowOverlayPanel(false));
  }, []);

  // EVENTS
  const clickOutsideHandler = (e) => {
    if (!props.closeOnClickingOutside || props.closeOnClickingOutside(e)) {
      setShowOverlayPanel(false);
    }
  };


  useEffect(() => {
    if (props.disableScrollOnOpen) {
      if (showOverlayPanel) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  }, [showOverlayPanel]);

  return (
    <Popover
      isOpen={showOverlayPanel}
      positions={['bottom']}
      reposition={true}
      onClickOutside={clickOutsideHandler}
      containerClassName={props.className}
      containerStyle={props.containerStyle}
      content={props.children}
      clickOutsideCapture={false}
    >
      <div className={"trigger-elem-wrapper " + props.triggerElemClassName} onClick={() => setShowOverlayPanel(!showOverlayPanel)}>
        {(typeof props.triggerElem === 'function') ? props.triggerElem(showOverlayPanel) : props.triggerElem}
      </div>
      
    </Popover>
  );
};

export default OverlayPanel;