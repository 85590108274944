// react imports
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import AppContext from '../../../AppContext';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';


// MEDIA IMPORTS
import videoThumbnail from '../../../images/video-thumbnail.jpg';
import faqImage from '../../../images/faq-image.jpg';

// CUSTOM COMPONENTS
import ResponsiveVideoPlayer from "../../common/responsive-video-player/ResponsiveVideoPlayer";

// HELPER FUNCS
import { renderTextWithNewLines } from "../../../data/helper-funcs";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

const FAQPage = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  
  //STATES
  const [faqList, setFaqList] = useState({});

  // HELPER FUNCS
  const getFaqElemsToRender = (list) => {
    return cookies.get('mylocation')?.clientType ? 
      list.filter(k => k.audience === "BOTH" || k.audience === cookies.get('mylocation').clientType) : 
      [...list];
  };

  // TEMPLATES
  const getFaqItemTemplate = (faqItem, index) => {
    // console.log(faqItem);
    const question = <div dangerouslySetInnerHTML={{__html: faqItem.question}}></div>
    const answer = <div dangerouslySetInnerHTML={{__html: faqItem.answer}}></div>
    return (
      <MDBAccordionItem collapseId={index+1} headerTitle={question} key={index}>
        {faqItem.video && (
          <ResponsiveVideoPlayer
            url={faqItem.video.url}
            thumbnailUrl={faqItem.image.url}
          />
        )}

        {!faqItem.video && faqItem.image?.url && (
          <img src={faqItem.image?.url} alt="FAQ image" />
        )}
        
        <p>
          {answer}
        </p>
      </MDBAccordionItem>
    );
  };

  //events
  useEffect(() => {
    context.interAxios.get('/faq-group/with-faq',
                  {params: {
                    lang: cookies.get('mylanguage').language
                  }},
     function(response) {
      setFaqList(response.data);
      context.onRender();
    })
  }, [context.getState()?.isRender]);
  
  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="jc-page gray-bc faq">
      <div className="container-fluid mw-1040">
        <h1>
          <i className="fa-regular fa-arrow-left" onClick={() => navigate(-1)}></i>
          {t('misc.faq')}
        </h1>
        {faqList.length > 0 && (
          <>
            {getFaqElemsToRender(faqList).map((k,n) => {return (
              <div className="white-panel" key={n}>
                <h2><div dangerouslySetInnerHTML={{__html: k.name}}></div></h2>

                {/*<MDBAccordion initialActive={n} alwaysOpen={true}>*/}
                <MDBAccordion>
                  {getFaqElemsToRender(k.faq).map(getFaqItemTemplate)}
                </MDBAccordion>
              </div>
            )})} 
          </>
        )}

        {/*<div className="white-panel">
          <h2>How to buy?</h2>

          <MDBAccordion initialActive={1} alwaysOpen={true}>
            <MDBAccordionItem collapseId={1} headerTitle='Placerat duis id nibh eget sollicitudin?'>
              <ResponsiveVideoPlayer
                url='https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
                thumbnailUrl={videoThumbnail}
              />

              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle='Pretium mattis dignissim feugiat ipsum non dolor.'>
              <img src={faqImage} alt="FAQ image" />

              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle='At tellus nunc commodo amet ornare morbi quis mattis.'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={4} headerTitle='Pretium mattis dignissim feugiat ipsum non dolor.'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={5} headerTitle='Placerat duis id nibh eget sollicitudin?'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
          </MDBAccordion>
        </div>

        <div className="white-panel">
          <h2>How to sell?</h2>

          <MDBAccordion initialActive={1} alwaysOpen={true}>
            <MDBAccordionItem collapseId={1} headerTitle='Placerat duis id nibh eget sollicitudin?'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle='Pretium mattis dignissim feugiat ipsum non dolor.'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle='At tellus nunc commodo amet ornare morbi quis mattis.'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={4} headerTitle='Pretium mattis dignissim feugiat ipsum non dolor.'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={5} headerTitle='Placerat duis id nibh eget sollicitudin?'>
              <p>Lorem ipsum dolor sit amet, sea ea feugiat postulant, id quaerendum accommodare duo, id mel eirmod dissentiunt. Id iracundia euripidis per, an nec delenit accumsan, cu qui fierent instructior. Cibo scripta fastidii eum ei, his an simul urbanitas abhorreant. At mei fugit menandri definiebas, in tale fugit salutandi nec, nec albucius definiebas ea.</p>

              <p>Ea aperiam officiis cotidieque pri. Exerci oporteat convenire an est. Ea ius accusam delectus gubergren, ut fastidii detracto ius. His et nisl eius volutpat, in paulo fabulas suscipit sed, mei modus audiam deleniti cu. In usu magna nominati imperdiet, ex mei primis persequeris. Convenire consetetur vim in.</p>
            </MDBAccordionItem>
          </MDBAccordion>
              </div>*/}
      </div>
    </div>
  );
};

export default withTranslation()(FAQPage);