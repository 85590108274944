// react imports
import { useEffect, useContext, useState } from "react";

// REACT ROUTER DOM
import { useNavigate } from "react-router-dom";

// MDBOOTSTRAP IMPORTS
import { MDBInputGroup, MDBBtn, MDBSelect, MDBTooltip } from 'mdb-react-ui-kit';

// DATA IMPORTS
import { Sizes, getSizes } from "../../../data/general-models";
import { SampleProducts } from "../../../data/product-data";
import { SampleComments } from "../../../data/comment-data";

// CUSTOM IMPORTS
import PaymentMethodList from "../../common/payment-method-list/PaymentMethodList";
import CommentsSection from "../comments-section/CommentsSection";
import RatingCard from "../rating-card/RatingCard";

// MODAL IMPORTS
import ReportModal from "../../modals/report-modal/ReportModal";
import PleaseLoginModal from "../../modals/please-login-modal/PleaseLoginModal";
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";
import ReturnPolicyModal from "../../modals/return-policy-modal/ReturnPolicyModal";
import KauferschutzModal from "../../modals/kauferschutz-modal/KauferschutzModal";
import BookmarkOnlyModal from "../../modals/bookmark-only-modal/BookmarkOnlyModal";
import PleaseSelectSizeModal from "../../modals/please-select-size-modal/PleaseSelectSizeModal";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../AppContext';

// IMAGE IMPORTS
import iconKauferschutz from "../../../images/icon-Kauferschutz.png";
import ProductDeleteModal from "../../modals/product-delete-modal/ProductDeleteModal";


/**
 *
 * @param product
 * @param setProduct
 * @param sellable: boolean
 * @param isOrderItem?: boolean
 * @param clickOnSendReview?: () => any
 * @returns
 */
const ProductDetails = ({
  product, 
  setProduct, 
  sellable,
  isOrderItem,
  clickOnSendReview
}) => {
  // STATES
  const [selectedSizeId, setSelectedSizeId] = useState(product.size?.length === 1 ? product.size[0].sizeId : -1);
  const [bookmarked, setBookmarked] = useState(product?.bookmarked);
  const [bookmarkModalVisible, setBookmarkModalVisible] = useState(false);
  const [sizeModalVisible, setSizeModalVisible] = useState(false);
  const [deleteProductModalVisible, setDeleteProductModalVisible] = useState(false);

  // MODAL STATES
  const [showPleaseLoginModal, setShowPleaseLoginModal] = useState(false);
  const [showAdditionInformationModal, setShowAdditionInformationModal] = useState(false);
  const [showReturnPolicyModal, setShowReturnPolicyModal] = useState(false);
  const [showKauferschutzModal, setShowKauferschutzModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  // MULTILANGUAGE
  const { t, language } = i18n;

  // CONTEXT
  const context = useContext(AppContext);

  // REACT ROUTER
  const navigate = useNavigate();

  // HELPER FUNCS
  const getSizeOptions = () => {
    const productSizeArray = [
      {
        text: t('misc.select'),
        value: -1,
        defaultSelected: selectedSizeId === -1,
        disabled: true
      }
    ]
    if(product && product.size) {
      var newArray = productSizeArray.concat(product.size.filter(k => k.amount > 0).map(s => ({
        text: s.name,
        value: s.sizeId,
        defaultSelected: s.sizeId === selectedSizeId
      })))
    }
    return (product && product.size) ? 
    newArray : [];
  };
  const clickOnBookmarkIcon = (id) => {
    
    if(context.isLoggedIn()){
      setBookmarkModalVisible(false);
      if(bookmarked){
        
        
        context.interAxios.delete('/bookmark/' + id,
        function(response) {
          context.onRenderTrueBookmark();
          setBookmarked(false);
        })
      }else{
        
        context.interAxios.put('/bookmark/' + id,
                    {params: {
                      lang: cookies.get('mylanguage').language
                    }},
        function(response) {
          context.onRenderTrueBookmark();
          setBookmarked(true);
        })
      }
      
    }else{
      setBookmarkModalVisible(true);
    }
    
  };
  const clickOnCheckoutButton = () => {
    if (context.isLoggedIn()) {
      navigate(`/checkout/${product.id}/${selectedSizeId}`);
      //navigate(`/user-profile/${product.seller.id}`)}
      return;
    }

    setShowPleaseLoginModal(true);
  };

  // TEMPLATES
  const getShippingOptionsTemplate = (shippingOptions) => {
    if (!shippingOptions) {
      return;
    }

    const shippingOption = shippingOptions.find(so => so.name !== 'PICKUP');
    const pickupOption = shippingOptions.find(so => so.name === 'PICKUP');

    return (
      <>
        <div className="cell">
          <div className="shopping-info shipping">
            <i className="fa-regular fa-truck-fast"></i>
            {t('misc.shipping')}
          </div>

          {shippingOption && (
            <div className="price">
              {shippingOption.price}
              <span>CHF</span>
            </div>
          )}

          {!shippingOption && (
            <div className="unavailable">
              Not available
            </div>
          )}
        </div>

        <div className="cell">
          <div className="shopping-info">
            <i className="fa-regular fa-handshake"></i>
            {pickupOption ? t('misc.pick_up_available') : t('misc.pick_up')}
          </div>

          {pickupOption && ( 
            <div className="price">
              {pickupOption.price}
              <span>CHF</span>
            </div>
          )}

          {!pickupOption && (
            <div className="unavailable">
              Not available
            </div>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    product && product.size && (
      setSelectedSizeId(product.size?.length === 1 ? product.size[0].sizeId : -1)
    )
    product && product.bookmarked && (
      setBookmarked(product.bookmarked)
    )
    
  }, [product]);

  return (
    <div className="product-details">
      <h1>
        {product.title}

        {context.isLoggedInIdIs(product.seller?.id) && (!window.location.pathname.startsWith('/profile/order')) && (
          <span className="product-actions">
            <MDBTooltip
              tag='span'
              wrapperClass='icon-tooltip'
              title={t('product.edit_item')}
            >
              <i className="fa-regular fa-pencil" onClick={() => navigate(`/profile/item/${product.id}/edit`)}></i>
            </MDBTooltip>

            <MDBTooltip
              tag='span'
              wrapperClass='icon-tooltip'
              title={t('product.delete_item')}
            >
              <i className="fa-regular fa-trash-alt" onClick={() => setDeleteProductModalVisible(true)}></i>
            </MDBTooltip>

            <ProductDeleteModal
              visible={deleteProductModalVisible}
              item_name={product.title}
              hideModal={() => setDeleteProductModalVisible(false)}
              onDelete={() => {
                context.interAxios.delete('/myitem/' + product.id,
                  function(response) {
                    setDeleteProductModalVisible(false);
                    navigate('/marketplace')
                });
              }}
            />
          </span>
        )}
      </h1>
      {!isOrderItem && (
        <div className="product-location">
        <i className="fa-regular fa-map-marker-alt"></i>

        {product.location?.location}
      </div>
      )}

      <div className="product-price price">
        {product.price?.gross} <span>CHF</span>
      </div>

      <div className="product-text">
        {product.description}
      </div>

      <div className="product-metas">
        <div className="product-meta">
          <strong>
            {t(product.size?.length === 1 ? 'product.size' : 'product.size_multiple')}
          </strong>
          {
            product.size?.length === 1 ? (
              <button className="jc-btn white" disabled>
                {product.size[0].name}
              </button>
            ) : (
                <MDBSelect
                  data={getSizeOptions()}
                  onValueChange={(e) => {
                    setSelectedSizeId(e.value);
                  }}
                  className="big display-value size"
                />
            )
          }
        </div>

        <div className="product-meta">
          <strong>{t('misc.condition')}</strong>
          <button className="jc-btn white" disabled>
            {product.condition?.name}
          </button>
        </div>
      </div>
      
      {sellable && !isOrderItem &&(
        <>
          {!context.isLoggedInIdIs(product.seller?.id) && (
            <>
              <button
                className="jc-btn black"
                onClick={product.size?.length > 1 && selectedSizeId === -1 ? () => setSizeModalVisible(true) : clickOnCheckoutButton}
              >
                <i className="fa-regular fa-bag-shopping"></i>
                {t('misc.checkout')}
              </button>

              <div
                className="product-shopping-list"
                onClick={() => clickOnBookmarkIcon(product.id)}
                >
                <i className={`${(bookmarked) ? 'fa-solid' : 'fa-regular'} fa-bookmark`}></i>

                {t(bookmarked ? 'product.remove_from_shopping_list' : 'product.add_to_shopping_list')}
              </div>
            </>
          )}

          <PleaseLoginModal
            visible={showPleaseLoginModal}
            hideModal={() => setShowPleaseLoginModal(false)}
            productId={product.id}
            sizeId={selectedSizeId}
          />

          <BookmarkOnlyModal
            visible={bookmarkModalVisible}
            hideModal={() => setBookmarkModalVisible(false)}
          />

          <PleaseSelectSizeModal
            visible={sizeModalVisible}
            hideModal={() => setSizeModalVisible(false)}
          />

          <section className="infos-table">
            <div className="row takeover">
              {getShippingOptionsTemplate(product.shippingOptions)}
            </div>

            <div className="row payment">
              <div className="cell">
                <div className="shopping-info">
                  <i className="fa-regular fa-credit-card"></i>
                  {t('misc.payments')}
                </div>

                <PaymentMethodList />
              </div>
            </div>

            <div className="row misc">
              <div 
                className={"cell" + (product.returnPolicy === undefined ? ' justify-content-start' : '')}
              >
                <div
                  className="shopping-info pointer"
                  onClick={() => setShowAdditionInformationModal(true)}
                >
                  <i className="fa-regular fa-info-circle"></i>
                  {t('misc.additional_information')}
                </div>

                <GeneralInfoModal
                  visible={showAdditionInformationModal}
                  hideModal={() => setShowAdditionInformationModal(false)}
                  infoIcon="fa-regular fa-info-circle"
                  title={t('general_modals.product_additional_information.title')}
                  text={product.additionalInformation}
                />
                
                {product.returnPolicy !== undefined ? <>
                  <div
                    className="shopping-info pointer"
                    onClick={() => setShowReturnPolicyModal(true)}
                  >
                    <i className="fa-regular fa-arrow-rotate-left"></i>
                    {t('product.return_policy')}
                  </div>

                  <ReturnPolicyModal
                    returnPolicy={product.returnPolicy}
                    visible={showReturnPolicyModal}
                    hideModal={() => setShowReturnPolicyModal(false)}
                  />
                </>:false}

                <div
                  className="shopping-info pointer kauferschutz"
                  onClick={() => setShowKauferschutzModal(true)}
                >
                  <img src={iconKauferschutz} alt="Käuferschutz Icon" />
                  {t('product.buyer_protection')}
                </div>
                <KauferschutzModal
                    icon={iconKauferschutz}
                    visible={showKauferschutzModal}
                    hideModal={() => setShowKauferschutzModal(false)}
                  />
              </div>
            </div>
          </section>
        </>
      )}

      {isOrderItem && product && (
        <>
          {!product.buyerReview && product.seller?.id !== cookies.get('mylocation').clientId && (
            <button
              className="jc-btn black"
              onClick={clickOnSendReview}
            >
              <i className="fa-regular fa-star"></i>
              {t('general_modals.review_seller.button_text')}
            </button>
          )}

          {product.buyerReview && context.isLoggedInIdIs(product.buyerReview?.buyer?.id) && (
            <section className="my-rating">
              <header>{t('product.my_rating')}</header>
              <RatingCard
                id={product.buyerReview.id}
                buyer={product.buyerReview.buyer}
                star={product.buyerReview.star}
                created={product.buyerReview.created}
                item={product.buyerReview.item}
                textcount={20}
                content={product.buyerReview.content}
                displayItemName={true}
                exactDate={true}
                onReportRating={false}
              />
            </section>
          )}
        </>
      )}

      {!isOrderItem && product &&(
        <>
          <CommentsSection
            comments={product.comments ? product.comments : []}
            product={product}
          />
          
          <hr />
          
          <a
            className="gray-link pb-4"
            onClick={() => setShowReportModal(true)}
          >
            {t('product.report_item_or_comment')}
          </a>
        </>
      )}
        <ReportModal
          visible={showReportModal}
          hideModal={() => setShowReportModal(false)}
          type={"ITEM"}
          id={product.id}
        />

      {/* <CommentsSection
        comments={SampleComments}
        product={product}
      /> */}
    </div>
  );
};

export default ProductDetails;
