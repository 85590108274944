// CUSTOM COMPONENT IMPORTS
import { useContext, useState, useEffect } from "react";

// CUSTOM COMPONENTS
import ProductListItem from "./product-list-item/ProductListItem";
import BookmarkOnlyModal from '../../modals/bookmark-only-modal/BookmarkOnlyModal';
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// DATA IMPORTS
import { SampleProducts } from "../../../data/product-data";


const ProductList = (props) => {
  
  const context = useContext(AppContext);

  // states
  const [reloadingOnParamsChange, setReLoadingOnParamsChange] = useState(false);
  const [loadingNewProducts, setLoadingNewProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);
  const [imgsLoaded, setImgsLoaded] = useState(false)

  useEffect(() => {
    if(props.itemsList.content !== undefined ) {
      const loadImage = image => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image()
          loadImg.src = image
          loadImg.onload = () =>
            resolve(image)
          loadImg.onerror = err => reject(err)
        })
      }
  
      Promise.all(props.itemsList.content.filter(k => k.firstImage !== null).map(image => loadImage(image.firstImage.url)))
        .then(() => context.isProductlistImages())
        .catch(err => console.log("Failed to load images", err))
    }
    
  }, [props.itemsList.content])

  return (

    <div className="product-list">
    
      {props.itemsList.content?.map(product => (
        <ProductListItem
          {...product}
          key={product.id}
          displayBookmarkIcon={true}
          enableScrollOnLoading={props.enableScrollOnLoading}
        />
      ))}
      
    </div>
  );
};

export default ProductList;
