// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import AppContext from '../../../AppContext';

/**
 * Props
 * @param visible: true
 * @param continuePurchase: () => void
 * @param cancelPurchase: () => void
 * @returns
 */
const CancelStripeOrderModal = (props) => {
  const context = useContext(AppContext);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;


  return (
    <MDBModal
      className="standard-jc-modal w-615 cancel-stripe-order"
      show={true}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.cancel_stripe_checkout.title')}</h3>

            <p>{t('general_modals.cancel_stripe_checkout.text')}</p>

            <button
              className="jc-btn black"
              onClick={props.cancelPurchase}
            >
              {t('general_modals.cancel_stripe_checkout.cancel_text')}
            </button>

            <a
              className="brown-link"
              onClick={props.continuePurchase}
            >
              {t('general_modals.cancel_stripe_checkout.continue_text')}
            </a>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(CancelStripeOrderModal);
