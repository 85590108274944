// react imports
import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import AppContext from '../../../../AppContext';

// MD BOOTSTRAP IMPORTS
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarLink,
  MDBBtn,
  MDBSelect,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../../i18n';

// 3RD PARTY IMPORTS
import { Popover } from 'react-tiny-popover';
import MultiRangeSlider from "multi-range-slider-react";

// HELPER FUNCS
import { getSingleSelectChangeFunc, getSelectChangeFunc } from "../../../../data/helper-funcs";

// CUSTOM COMPONENTS
import OverlayPanel from "../../../common/overlay-panel/OverlayPanel";
import CustomBsSidenav from "../../custom-bs-sidenav/CustomBsSidenav";

// DATA IMPORTS
import {
  Sizes as InitialSizes,
  //getSizes,
  Conditions as InitialConditions,
  SortOptionsDe as InitialSortOptionsDe,
  SortOptionsEn as InitialSortOptionsEn,
  FollowedUsersOptions as InitialFollowedUsersOptions
} from "../../../../data/general-models";

import selected_icon from "../../../../icons/check-icon.png";
//const minMaxPriceRange = {first: 0, second: 100};

/**
 * 
 * @param mobileFilterPanelOpened: boolean
 * @param setMobileFilterPanelOpened: (boolean) => void
 * @returns 
 */
const FilterPanel = ({ mobileFilterPanelOpened, setMobileFilterPanelOpened }) => {
  // MULTILANGUAGE
  const { t, language } = i18n;

  const getInitialFollowerOptions = () => [
    { text: t('filter_panel.yes'), value: 'true' },
    { text: t('filter_panel.no'), value: 'false' },
  ];

  // STATES
  const context = useContext(AppContext);
  //const [prevLanguage, setPrevLanguage] = useState(language);
  const [sizes, setSizes] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [sortOptions, setSortOptions] = useState(cookies.get('mylanguage').language === "en" ? [...InitialSortOptionsEn] :  [...InitialSortOptionsDe]);
  const [followedUsers, setFollowedUsers] = useState(getInitialFollowerOptions());//
  const [mobileOrDesktop, setMobileOrDesktop] = useState(window.innerWidth > 992 ? 'desktop' : 'mobile');
  
  
  // PRICE RANGE STATES
  const [minMaxPriceRange, setMinMaxPriceRange] = useState({first: 0, second: 1000});
  const [priceRangeValues, setPriceRangeValues] = useState(minMaxPriceRange);
  const [priceRangeLabels, setPriceRangeLabels] = useState(minMaxPriceRange);

  // SIDENAV STATES
  const [mobileFilterMenuOpened, setMobileFilterMenuOpened] = useState(true);

  // SEARCH PARAMS
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  // REFS
  const filterIconRef = useRef(null);

  // TEMPLATES
  const filterButtonTemplate = (active) => (
    
    <MDBNavbarLink>
      <i className={`fa-${active ? 'solid' : 'regular'} fa-filter`} ref={filterIconRef}></i>
      <span className="filter-trigger-text">Filter</span>
    </MDBNavbarLink>
    
  );

  const getSidenavOption = (originalObjects, setterFunc, multipleSelection = true) => (option) => (
    
    <MDBSideNavLink
      key={option.value}
      onClick={() => {
        const newObjects = [
          {text: option.text, value: option.value, icon: selected_icon,}, ...originalObjects.filter(k => k.value !== option.value).map(k => {
            return {text: k.text, value: k.value}
          })
        ];
        setterFunc(newObjects);
       /* console.log(option)
        setterFunc((currentOptions) => {
          const newOptions = currentOptions.map(co => {
            const newOption = {...co};
            
            if (multipleSelection && newOption.value === option.value) {
              if (newOption.defaultSelected) {
                delete newOption.defaultSelected;
              } else {
                newOption.defaultSelected = true;
              }
            }

            if (!multipleSelection) {
              if (newOption.value === option.value) {
                newOption.defaultSelected = true;
                
              } else {
                delete newOption.defaultSelected;
              }
            }
            return newOption;
          })
          return newOptions;
          
        });*/
      }}
    >
      {option.text}
  
      {option.icon && (
        <i className="fa-regular fa-check"></i>
      )}
    </MDBSideNavLink>
  );

  // EVENTS
  const applyFilters = () => {
    // setting query params in react router v6:
    // https://ultimatecourses.com/blog/navigate-to-url-query-strings-search-params-react-router

    const createParamString = (filterObjects) => {
      // console.log(filterObjects)
      //const params = [];
      filterObjects.forEach((filterObj) => {
        if (filterObj.icon) {
          //params.push(filterObj.value);
        }
      });
      //return params.join(',');
      return filterObjects.filter(k => k.icon !== undefined)[0].value;
    };

    //let constructedSearchParams =  {};

    // if there is selected sizes
    if (sizes.filter(size => size.icon).length > 0) {
      //constructedSearchParams.sizes = createParamString(sizes);
      //console.log(createParamString(sizes))
      context.onGetSizeIdParams(createParamString(sizes));
    }

    // if there is selected conditions
    if (conditions.filter(condition => condition.icon).length > 0) {
      //constructedSearchParams.conditions = createParamString(conditions);
      //console.log(createParamString(conditions))
      context.onGetConditionIdParams(createParamString(conditions));
    }

    // if there is selected sort options
    if (sortOptions.filter(sortOption => sortOption.icon).length > 0) {
      //constructedSearchParams.sortOptions = createParamString(sortOptions);
      //console.log(createParamString(sortOptions))
      context.onGetSort_byParams(createParamString(sortOptions)[0]);
      context.onGetSort_dirParams(createParamString(sortOptions)[1]);
    }

    // if followed user is selected
    if (followedUsers.filter(followedUser => followedUser.icon).length > 0) {
      //constructedSearchParams.followedUsers = createParamString(followedUsers);
      //console.log(createParamString(followedUsers))
      context.onGetOnlyFollowedParams(createParamString(followedUsers));
    }

    // if the price range is changed from the initial values
    if (priceRangeValues.first !== minMaxPriceRange.first || priceRangeValues.second !== minMaxPriceRange.second) {
      //constructedSearchParams.priceRangeValues = `${priceRangeValues.first},${priceRangeValues.second}`;
      //console.log(priceRangeValues.first + ", " + priceRangeValues.second);
      context.onGetPriceParams(priceRangeValues.first, priceRangeValues.second);
    }

    

    if (location.pathname.includes('/marketplace')) {
      //setSearchParams(constructedSearchParams);
      context.onRenderTrue();
      filterIconRef.current.click();
      context.setScrollOnMarketplaceAfterFilter();
    } else {
      navigate({
        pathname: '/marketplace',
        //search: `${createSearchParams(constructedSearchParams)}`
      });
      context.setScrollOnMarketplaceAfterFilter();
      context.onRenderTrue();
      filterIconRef.current.click();
    }
  };
  
  const resetFilters = () => {
    console.log("belép");
    setSizes(sizes.map(size => ({
      text: size.text,
      value: size.value,
      //defaultSelected: false
    })));
    setConditions(conditions.map(size => ({
      text: size.text,
      value: size.value,
      //defaultSelected: false
    })));
    setSortOptions(sortOptions.map(size => ({
      text: size.text,
      value: size.value,
      //defaultSelected: false
    })));
    setFollowedUsers(followedUsers.map(size => ({
      text: size.text,
      value: size.value,
      //defaultSelected: false
    })));
    setPriceRangeValues(minMaxPriceRange);
    setPriceRangeLabels(minMaxPriceRange);
    context.clearFilterState();
    //context.onRenderTrue();
  };

  useEffect(() => {
    if(context.getState()?.onResetFilter){
      setSizes(sizes.map(size => ({
        text: size.text,
        value: size.value,
        //defaultSelected: false
      })));
      setConditions(conditions.map(size => ({
        text: size.text,
        value: size.value,
        //defaultSelected: false
      })));
      setSortOptions(sortOptions.map(size => ({
        text: size.text,
        value: size.value,
        //defaultSelected: false
      })));
      setFollowedUsers(followedUsers.map(size => ({
        text: size.text,
        value: size.value,
        //defaultSelected: false
      })));
      setPriceRangeValues(minMaxPriceRange);
      setPriceRangeLabels(minMaxPriceRange);
      context.clearFilterState();
      context.onRender();
    }
  }, [context.getState()?.isRender]);

  useEffect(() => {
    // Load all Size
    context.interAxios.get('/size', {params: {lang: cookies.get('mylanguage').language}},
      function(response) {
        setSizes(response.data.map(k => {return ({text: k.name, value: k.id})}));
      }
    );
    // Load all Condition
    context.interAxios.get('/condition', {params: {lang: cookies.get('mylanguage').language}},
      function(response) {
        setConditions(response.data.map(k => {return ({text: k.name, value: k.id})}));
      }
    );
    // Load original price range
    context.interAxios.get('/item/minmaxprice',
      function(response) {
        setMinMaxPriceRange({first: response.data.minPrice, second: response.data.maxPrice});
        setPriceRangeValues({first: response.data.minPrice, second: response.data.maxPrice})
        setPriceRangeLabels({first: response.data.minPrice, second: response.data.maxPrice})
      }
    );

    setFollowedUsers(followedUsers.map(fu => {
      return {
        ...fu,
        text: t('filter_panel.' + (fu.value === 'true' ? 'yes' : 'no'))
      }
    }));
  }, [cookies.get('mylanguage').language]);

  useEffect(() => {
    setSortOptions(cookies.get('mylanguage').language === "en" ? [...InitialSortOptionsEn] :  [...InitialSortOptionsDe])
  }, [cookies.get('mylanguage').language]);

  /*useEffect(() => {
    // reset filters when navigating away from marketplace
    if (!location.pathname.includes('marketplace')) {
      resetFilters();
      return;
    }
  }, [location.search]);*/


  // load filters from the url (when page load)
  useEffect(() => {
    const filters = Object.fromEntries([...searchParams]);

    if (filters.sizes) {
      const sizesFromUrl = filters.sizes.split(',');

      // setSizes(InitialSizes.map(size => ({
      //   text: size.text,
      //   value: size.value,
      //   defaultSelected: sizesFromUrl.includes(size.value)
      // })));
    }

    if (filters.conditions) {
      const conditionsFromUrl = filters.conditions.split(',');

      setConditions(conditions.map(condition => ({
        text: condition.text,
        value: condition.value,
        defaultSelected: conditionsFromUrl.includes(condition.value)
      })));
    }

    if (filters.priceRangeValues) {
      const priceRangeValuesFromUrl = filters.priceRangeValues.split(',');

      setPriceRangeValues({first: priceRangeValuesFromUrl[0], second: priceRangeValuesFromUrl[1]});
    }

    if (filters.followedUsers) {
      const followedUsersFromUrl = filters.followedUsers.split(',');

      setFollowedUsers(getInitialFollowerOptions().map(followedUser => ({
        text: followedUser.text,
        value: followedUser.value,
        defaultSelected: followedUsersFromUrl.includes(followedUser.value)
      })));
    }

    if (filters.sortOptions) {
      const sortOptionsFromUrl = filters.sortOptions.split(',');
      console.log(cookies.get('mylanguage').language)
      if(cookies.get('mylanguage').language === "en"){
        setSortOptions(InitialSortOptionsEn.map(sortOption => ({
          text: sortOption.text,
          value: sortOption.value,
          defaultSelected: sortOptionsFromUrl.includes(sortOption.value)
        })));
      }else {
        setSortOptions(InitialSortOptionsDe.map(sortOption => ({
          text: sortOption.text,
          value: sortOption.value,
          defaultSelected: sortOptionsFromUrl.includes(sortOption.value)
        })));
      }
     
    }
  }, []);


  // listening to resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobileOrDesktop(window.innerWidth > 1080 ? 'desktop' : 'mobile');
    });
  }, []);

  // MAIN RENDER FUNCS

  const getSingleSelectChange = (originalObjects, setterFunc) => (selectedObjects) => {
    const newObjects = [
      {text: selectedObjects.text, value: selectedObjects.value, icon: selected_icon,}, ...originalObjects.filter(k => k.value !== selectedObjects.value).map(k => {
        return {text: k.text, value: k.value}
      })
    ];
    setterFunc(newObjects);
  };
  const toggleMobileFilterMenuOpened = filterMenuString => {
    if (mobileFilterMenuOpened === filterMenuString) {
      setMobileFilterMenuOpened('');
    } else {
      setMobileFilterMenuOpened(filterMenuString);
    }
  };
  const isPriceRangeSet = () => {
    return ((minMaxPriceRange.first !== priceRangeValues.first || minMaxPriceRange.second !== priceRangeValues.second));
  };



  const renderDesktopFilterPanel = () => (
    <OverlayPanel
      className={`filter-panel ${i18n.language}`}
      triggerElem={filterButtonTemplate}
      closeOnClickingOutside={() => false}
    >
      <main className="container-fluid">
        <MDBSelect
          className={`size ${sizes?.filter(k => k.icon !== undefined).length > 0 ? 'orange-dot' : ''}`}
          label={sizes?.filter(size => size.icon).length > 0 ? "" : t('filter_panel.size')}
          data={sizes}
          displayedLabels={2}
          optionsSelectedLabel='..'
          preventFirstSelection={sizes?.filter(size => size.icon).length > 0 ? false : true}
          visibleOptions={6}
          onValueChange={getSingleSelectChange(sizes, setSizes)}
        />

        <MDBSelect
          className={`condition ${conditions.filter(k => k.icon !== undefined).length > 0 ? 'orange-dot' : ''}`}
          label={conditions.filter(condition => condition.icon).length > 0 ? "" : t('filter_panel.condition')}
          data={conditions}
          selectAll={false}
          displayedLabels={1}
          optionsSelectedLabel='..'
          preventFirstSelection={conditions.filter(condition => condition.icon).length > 0 ? false : true}
          onValueChange={getSingleSelectChange(conditions, setConditions)}
        />

        <MDBSelect
          className={`sort ${sortOptions.filter(k => k.icon !== undefined).length > 0 ? 'orange-dot' : ''}`}
          label={sortOptions.filter(sortOption => sortOption.icon).length > 0 ? "" : t('filter_panel.sort')}
          data={sortOptions}
          selectAll={false}
          displayedLabels={1}
          optionsSelectedLabel='..'
          preventFirstSelection={sortOptions.filter(sortOption => sortOption.icon).length > 0 ? false : true}
          onValueChange={getSingleSelectChange(sortOptions, setSortOptions)}
        />

        <MDBDropdown 
          className={"price-range" + (isPriceRangeSet() ? ' orange-dot' : '')}
        >
          <MDBDropdownToggle
            tag='a'
            className={'nav-link'}
            role='button'
          >
            {t('filter_panel.price_range')}
          </MDBDropdownToggle>

          <MDBDropdownMenu className='p-4 text-muted'>
            <MultiRangeSlider
              min={minMaxPriceRange.first}
              max={minMaxPriceRange.second}
              step={5}
              minValue={priceRangeValues.first}
              maxValue={priceRangeValues.second}
              minCaption={priceRangeLabels.first + ' CHF'}
              maxCaption={priceRangeLabels.second + ' CHF'}
              ruler={false}
              barLeftColor="#A09EA64D"
              barRightColor="#A09EA64D"
              barInnerColor="#C8866E"
              thumbLeftColor="#ffffff"
              thumbRightColor="#ffffff"
              className="jc-multirange-slider"
              onChange={(e) => {
                setPriceRangeValues({first: e.minValue, second: e.maxValue});
                // setPriceRangeLabels({first: e.minValue, second: e.maxValue});
              }}
              onInput={(e) => {
                setPriceRangeLabels({first: e.minValue, second: e.maxValue});
              }}
            />

            {/* <MDBMultiRange
              tooltips
              getValues={(newPriceRangeValues) => setPriceRangeValues(newPriceRangeValues)}
              defaultValues={priceRangeValues}
            /> */}
          </MDBDropdownMenu>
        </MDBDropdown>

        {context.isLoggedIn() && (
          <MDBSelect
            className={`followed-users ${followedUsers.filter(k => k.icon !== undefined).length > 0 ? 'orange-dot' : ''}`}
            label={followedUsers.filter(followedUser => followedUser.icon).length > 0 ? "" : t('filter_panel.only_followed_users')}
            data={followedUsers}
            selectAll={false}
            displayedLabels={1}
            optionsSelectedLabel='..'
            preventFirstSelection={followedUsers.filter(followedUser => followedUser.icon).length > 0 ? false : true}
            onValueChange={getSingleSelectChange(followedUsers, setFollowedUsers)}
          />
        )}

        <button
          className='jc-btn black apply'
          type='button'
          onClick={applyFilters}
        >
          {t('filter_panel.apply')}
        </button>

        <a className="reset-link" onClick={() => { filterIconRef.current.click(); resetFilters(); }}>
          {t('filter_panel.reset_filters')}
        </a>
      </main>
    </OverlayPanel>
  );
  const renderMobileSidenav = () => (
    <>
      <div
        className="trigger-elem-wrapper"
        onClick={() => setMobileFilterPanelOpened(true)}
      >
        {filterButtonTemplate()}
      </div>

      <CustomBsSidenav
        shown={mobileFilterPanelOpened}
        className="filter-sidenav"
        closeSidenav={() => setMobileFilterMenuOpened(false)}
        clickOutside={() => setMobileFilterMenuOpened(false)}
      >

        <MDBSideNavMenu className="nav-icons">
          <MDBSideNavItem>
            <MDBIcon 
              far 
              icon='arrow-left' 
              onClick={() => setMobileFilterPanelOpened('')}
            />

            <MDBIcon 
              far 
              icon='times' 
              onClick={() => setMobileFilterPanelOpened(false)}
            />
          </MDBSideNavItem>
        </MDBSideNavMenu>

        <MDBSideNavMenu>
          {/* <MDBSideNavItem className="times">
            <MDBSideNavLink>
              <MDBIcon
                far
                icon='times'
                onClick={() => setMobileFilterPanelOpened(false)}
              />
            </MDBSideNavLink>
          </MDBSideNavItem> */}


          {/***** SIZE *****/}
          <MDBSideNavItem className={"filter size " + (sizes.filter(i => i.icon !== undefined).length > 0 ? 'orange-dot' : '')}>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={mobileFilterMenuOpened === 'size-menu'} onClick={() => toggleMobileFilterMenuOpened('size-menu')}>
              {t('filter_panel.size')}
            </MDBSideNavLink>
            <MDBSideNavCollapse show={mobileFilterMenuOpened === 'size-menu'}>
              <div className="size-filters">
                {sizes.map(getSidenavOption(sizes, setSizes, false))}
              </div>
            </MDBSideNavCollapse>
          </MDBSideNavItem>


          {/***** CONDITION *****/}
          <MDBSideNavItem className={"filter " + (conditions.filter(c => c.icon !== undefined).length > 0 ? 'orange-dot' : '')}>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={mobileFilterMenuOpened === 'condition-menu'} onClick={() => toggleMobileFilterMenuOpened('condition-menu')}>
              {t('filter_panel.condition')}
            </MDBSideNavLink>
            <MDBSideNavCollapse show={mobileFilterMenuOpened === 'condition-menu'}>
              {conditions.map(getSidenavOption(conditions, setConditions, false))}
            </MDBSideNavCollapse>
          </MDBSideNavItem>


          {/***** SORT *****/}
          <MDBSideNavItem className={"filter " + (sortOptions.filter(so => so.icon !== undefined).length > 0 ? 'orange-dot' : '')}>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={mobileFilterMenuOpened === 'sort-menu'} onClick={() => toggleMobileFilterMenuOpened('sort-menu')}>
              {t('filter_panel.sort')}
            </MDBSideNavLink>
            <MDBSideNavCollapse show={mobileFilterMenuOpened === 'sort-menu'}>
              {sortOptions.map(getSidenavOption(sortOptions, setSortOptions, false))}
            </MDBSideNavCollapse>
          </MDBSideNavItem>


          {/***** PRICE RANGE *****/}
          <MDBSideNavItem className={"filter price-range " + (isPriceRangeSet() ? ' orange-dot' : '')}>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={mobileFilterMenuOpened === 'price-range-menu'} onClick={() => toggleMobileFilterMenuOpened('price-range-menu')}>
            {t('filter_panel.price_range')}
            </MDBSideNavLink>
            <MDBSideNavCollapse show={mobileFilterMenuOpened === 'price-range-menu'}>
              <div className="mobile-range-slider-wrapper">
                {mobileFilterMenuOpened === 'price-range-menu' && (
                  <MultiRangeSlider
                    min={minMaxPriceRange.first}
                    max={minMaxPriceRange.second}
                    step={5}
                    minValue={priceRangeValues.first}
                    maxValue={priceRangeValues.second}
                    minCaption={priceRangeLabels.first + ' CHF'}
                    maxCaption={priceRangeLabels.second + ' CHF'}
                    ruler={false}
                    barLeftColor="#A09EA64D"
                    barRightColor="#A09EA64D"
                    barInnerColor="#C8866E"
                    thumbLeftColor="#ffffff"
                    thumbRightColor="#ffffff"
                    className="jc-multirange-slider"
                    onChange={(e) => {
                      setPriceRangeValues({first: e.minValue, second: e.maxValue});
                      // setPriceRangeLabels({first: e.minValue, second: e.maxValue});
                    }}
                    onInput={(e) => {
                      if (priceRangeValues.first != e.minValue || priceRangeValues.second != e.maxValue) {
                        setPriceRangeLabels({first: e.minValue, second: e.maxValue});
                      }
                    }}
                  />
                )}
              </div>
              
              {/* <MDBMultiRange
                tooltips
                getValues={(newPriceRangeValues) => setPriceRangeValues(newPriceRangeValues)}
                defaultValues={priceRangeValues}
              /> */}
            </MDBSideNavCollapse>
          </MDBSideNavItem>

          {/***** FOLLOWED USERS *****/}
          {context.isLoggedIn() && (
          <MDBSideNavItem className={"filter " + (followedUsers.filter(fu => fu.icon !== undefined).length > 0 ? 'orange-dot' : '')}>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={mobileFilterMenuOpened === 'followed-users-menu'} onClick={() => toggleMobileFilterMenuOpened('followed-users-menu')}>
            {t('filter_panel.only_followed_users')}
            </MDBSideNavLink>
            <MDBSideNavCollapse show={mobileFilterMenuOpened === 'followed-users-menu'}>
              {followedUsers.map(getSidenavOption(followedUsers, setFollowedUsers, false))}
            </MDBSideNavCollapse>
          </MDBSideNavItem>
          )}

          <MDBSideNavItem className="buttons">
            <MDBSideNavLink onClick={() => { filterIconRef.current.click(); resetFilters(); }}>
              {t('filter_panel.reset_filters')}
            </MDBSideNavLink>

            <MDBSideNavLink 
              className="jc-btn black"
              onClick={() => {
                applyFilters();
                setMobileFilterPanelOpened(false);
              }}
            >
              {t('filter_panel.apply')}
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </CustomBsSidenav>
      
    </>
  );

  return (
    <>
      {mobileOrDesktop === 'desktop' && renderDesktopFilterPanel()}
      {mobileOrDesktop === 'mobile' && renderMobileSidenav()}
    </>
  );
};

export default FilterPanel;
