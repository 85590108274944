import React, { useContext, useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AppContext from '../../AppContext';
import CancelStripeOrderModal from '../modals/cancel-stripe-order-modal/CancelStripeOrderModal'
import axios from "axios";
import { backendUrl } from '../../env';
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../i18n';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import CheckoutForm from './CheckoutForm';

import { MDBContainer } from "mdb-react-ui-kit";

import { StripeKey } from "../../data/loginkeys";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(StripeKey.stripeKey);

function Payment() {
  const { state } = useLocation();
  
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const {email} = Object.fromEntries([...searchParams]);
  const [submitText, setSubmitText] = useState(null);
  const [outWarning, setOutWarning] = useState(0);

  const options = {
    // passing the client secret obtained in step 3
    clientSecret: state.clientSecret,
    // Fully customizable with appearance API.
    appearance: {theme: 'flat'},
  };

  const { t } = i18n;

  useEffect(() => {
    setSubmitText(t('checkout.pay_now'))
  }, [context.getState()?.isRender]);
  
  const paymentCancel = () => {
    navigate(
      '/checkout/' + state.productId + '/' + state.sizeId
    )
  }


  useEffect(() => {
    return () => {
      
        if(context.isLoggedIn()){
          context.interAxios.post('/order/' + state.orderId + '/cancel',  function(response) {});
        }else{
          axios.post(backendUrl() +'/order/' + state.orderId + '/cancel', 
          undefined,
          {headers: {
            'Authorization': 'Bearer ' + state.authToken
            }
          }).then(function(response) {})
        }
        
     
    }
  }, []);


  return (
    <MDBContainer style={{maxWidth: "600px"}}>
      <div className="stripe-container py-5">
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm id={state.orderId} authToken={state.authToken} submitText={submitText} paymentCancel={paymentCancel}/>
        </Elements>
      </div>
    </MDBContainer>
    
  );
};

export default Payment;