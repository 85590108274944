import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import 'mdb-react-multi-carousel/dist/css/multi-carousel.min.css';
import 'mdb-react-file-upload/dist/css/file-upload.min.css';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
