// react imports
import React, { useState, useContext, useEffect } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";
import Moment from 'moment';
import { renderTextWithNewLines } from "../../../data/helper-funcs";


/**
 * Props
 * @param messageId: true
 * @param hideModal: () =>  {}
 * @param reply: (orderId, recipientId, recipientPublicName, subject) => {}
 * @param delete: (messageId) => {}
 * @returns
 */
const MessageModal = (props) => {
  const context = useContext(AppContext);

  // MULTILANGUAGE
  const { t } = i18n

  // STATE
  const [message, setMessage] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [legalDate, setLegalDate] = useState();
  const [senderLoggedIn, setSenderLoggedIn] = useState(false);
  

  // HELPER FUNCS
  const getName = (id, name) => {
    return (context.isLoggedInIdIs(id) ? t('messages.you') : name);
  };
  const closeModal = () => {
    props.hideModal();
    
    setTimeout(() => {
      setMessage(null);
    }, 500);
  };


  useEffect(() => {
    if (!props.messageId) {
      return;
    }

    if (message?.id !== props.messageId) {
      setMessage(null);
    }
    context.interAxios.get('/message/' + props.messageId, function(response) {
      setMessage(response.data)
      //setFullName(response.data.sender)
      const now = new Date();
      now.setDate(now.getDate() - 30)
      setLegalDate(new Date(response.data.order.timestamp)>now)
      setTimeout(() => {
        context.onRenderTrueBookmark()
      }, 500)
    })

   /* setTimeout(() => {
      
    }, 1000)*/
  }, [props.messageId]);
  return (
    <MDBModal
      className="standard-jc-modal w-615 message-modal"
      show={Number.isInteger(props.messageId)}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none'  type='button' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

           {message?.sender===null && (
              <div className="alert alert-warning mt-1" role="alert">
                <i className="fa-regular fa-info-circle me-2"></i>
                {t('messages.deleted_user_text')}
              </div>    
            )}

            {!message && (
              <i className="fa-regular fa-spinner-third spinning"></i>
            )}

            {message && (
             <>
                <header>
                  <div className="header-content">
                    <h2>{message?.order?.title}</h2>
  
                    <div>
                      <strong>{t('messages.from')}: </strong> {message?.sender ? getName(message?.sender?.id, " " + message?.sender?.firstName + " " + message?.sender?.lastName) : t('messages.deleted_user')}
                    </div>
                    <div>
                      <strong>{t('messages.to')}: </strong> {message?.recipient ? getName(message?.recipient?.id, " " + message?.recipient?.firstName + " " + message?.recipient?.lastName) : t('messages.deleted_user')}
                    </div>
                    <div className="date">
                      <strong><i className="fa-regular fa-clock"></i> </strong> {Moment(message?.sentAt).format('DD.MM.YYYY HH:mm')}
                    </div>
                  </div>
                </header>
                
                <div className="text">
                  {renderTextWithNewLines(message?.text)}
                </div>

                <div className={"buttons" + (!message?.sender ? ' justify-content-end' : '')}>
                  {context.isLoggedInIdIs(message?.recipient?.id) && legalDate && message?.sender && (
                    <button 
                      className="jc-btn reply brown"
                      onClick={() => props.reply(message.order.id, message.sender.id, message?.sender?.firstName + " " + message?.sender?.lastName, message.order.title)}
                    >
                      <i className="fa-regular fa-reply"></i>
                      <span>{t('messages.reply')}</span>
                    </button>
                  )}
                {/*
                  <button 
                    className="jc-btn white delete"
                    onClick={() => props.delete(message.id)}
                  >
                    <i className="fa-regular fa-trash-alt"></i>
                    <span>{t('messages.delete')}</span>
                  </button>*/}
                </div>
             </>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default MessageModal;
