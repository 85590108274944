import heroImg1 from '../images/hero-slider/Hero-img-1.jpg';
import heroImg2 from '../images/hero-slider/Hero-img-2.jpg';
import heroImg3 from '../images/hero-slider/Hero-img-3.jpg';
import appleStoreBadge from "../images/mobile/apple-store-badge.png";
import googlePlayBadge from "../images/mobile/google-play-badge.png";

export const SlideSampleData = [
  { 
    imageUrl: heroImg1, 
    active: true, 
    title: 'Do you have <span>questions</span> or want to <span>know</span> how it works?',
    buttons: [{ 
      type: 'TEXT',
      iconClass: 'info-circle', // mdbootstrap icon class: https://mdbootstrap.com/docs/react/content-styles/icons/
      text: 'Go to the FAQ', 
      link: '/faq'
    }]
  },
  { 
    imageUrl: heroImg2, 
    active: false, 
    title: '<span>Download</span> the app now and use it <span>easily</span> on your mobile phone.',
    buttons: [ 
      {
        type: 'APP_STORE',
        link: 'https://google.com'
      },
      {
        type: 'GOOGLE_PLAY',
        link: 'https://google.com'
      },
    ]
  },
  { 
    imageUrl: heroImg3, 
    active: false, 
    title: 'Become a <span>seller</span> and give your items a <span>second</span> or even third life!',
    buttons: [{ 
      type: 'SIGNUP',
      iconClass: '', // mdbootstrap icon class: https://mdbootstrap.com/docs/react/content-styles/icons/
      text: 'Sign up now', 
    }]
  }
];

export const SlideSampleDataDE = [
  { 
    imageUrl: heroImg1, 
    active: true, 
    title: 'Hast du Fragen oder möchtest du wissen, wie es funktioniert? ',
    buttons: [{ 
      type: 'TEXT',
      iconClass: 'info-circle', // mdbootstrap icon class: https://mdbootstrap.com/docs/react/content-styles/icons/
      text: 'Zu den FAQs', 
      link: '/faq'
    }]
  },
  { 
    imageUrl: heroImg2, 
    active: false, 
    title: 'Jetzt App herunterladen und bequem auf dem Handy nutzen!',
    buttons: [ 
      {
        type: 'APP_STORE',
        link: 'https://apps.apple.com/us/app/j-c-world/id6449838203'
      },
      {
        type: 'GOOGLE_PLAY',
        link: 'https://play.google.com/store/apps/details?id=com.swisstomato.jncworld'
      },
    ]
  },
  { 
    imageUrl: heroImg3, 
    active: false, 
    title: 'Werde Verkäufer: in und gib deinen Gegenständen ein zweites oder sogar drittes Leben! ',
    buttons: [{ 
      type: 'SIGNUP',
      iconClass: '', // mdbootstrap icon class: https://mdbootstrap.com/docs/react/content-styles/icons/
      text: 'Jetzt registrieren!', 
    }]
  }
];
