// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import AppContext from "../../../AppContext";

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @returns
 */
const AccountCreatedModal = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  // EVENTS
  const clickOnGoToMarketplace = () => {
    props.hideModal();
    navigate('/marketplace');
  };

  // TEMPLATES
  const modalContent = () => (
    <div className="login-content">
      <h2>Login</h2>

      <form onSubmit={submitHandler}>
        <input
          className={emailMessages.length > 0 ? 'error' : ''}
          placeholder="Email"
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {renderErrorMessages(emailMessages)}

        <input
          className={passwordMessages.length > 0 ? 'error' : ''}
          placeholder="Password"
          id="password"
          type={hidePassword ? 'password' : 'text'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {hidePassword && <i className="fa-regular fa-eye toggle-password" onClick={() => setHidePassword(!hidePassword)}></i>}
        {!hidePassword && <i className="fa-regular fa-eye-slash toggle-password" onClick={() => setHidePassword(!hidePassword)}></i>}

        {renderErrorMessages(passwordMessages)}

        <p>
          <a className="forgot-password" onClick={forgotPasswordClicked}>
            Forgot password
          </a>
        </p>

        <button
          className="jc-btn black"
          type="submit"
        >
          Login
        </button>
      </form>

      <p>
        Don't have an account yet?
        <a onClick={() => setDisplaySignup(true)} className="sign-up">Sign up</a>
      </p>

      <hr data-centered-content="or" />

      <div className="social-icons">
        <button className="jc-btn white">
          <img src={loginAppleIcon} alt="Apple Icon" />
          Apple
        </button>
        <button className="jc-btn white">
          <img src={loginGoogleIcon} alt="Google Icon" />
          Google
        </button>
        <button className="jc-btn white">
          <img src={loginFacebookIcon} alt="Facebook Icon" />
          Facebook
        </button>
      </div>
    </div>
  );

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <MDBModal
      className="standard-jc-modal email-verified"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <i className="fa-regular fa-check main-modal-icon"></i>

            <h3>{t('general_modals.email_verified.title')}</h3>

            <button 
              className="jc-btn black" 
              onClick={() => {
                props.hideModal();
                searchParams.delete('emailVerified');
                setSearchParams(searchParams);

                setTimeout(() => {
                  context.openLoginModal();
                }, 400);
              }}
            >
              {t('general_modals.email_verified.button_text')}
            </button>

            <a
              className="brown-link"
              onClick={() => {
                props.hideModal();

                setTimeout(() => {
                  navigate('/marketplace');
                }, 400);
              }}
            >
              {t('general_modals.email_verified.brown_link')}
            </a>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(AccountCreatedModal);

