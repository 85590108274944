import i18n from '../i18n';
const { t } = i18n;


export const getAgoText = (dateTime: Date, agoText: boolean = true):string =>  {
  // based on https://muffinman.io/blog/javascript-time-ago-function/

  // const getFullText = (num: number, text: string) => 
  //   `${num} ${agoText ? text : ''}`;

  const now = new Date();
  const diff = now.getTime() - dateTime;

  const seconds = Math.round(diff / 1000);

  if (seconds < 10) {
    return 'now';
  }

  if (seconds < 60) {
    return t('misc.seconds_ago', {seconds: seconds});
  }

  const minutes = Math.round(seconds / 60);

  if (minutes < 60) {
    return t('misc.minutes_ago', {minutes: minutes});
  }
  
  const hours = Math.round(seconds / 3600);

  if (hours < 24) {
    return t('misc.hours_ago', {hours: hours});
  }

  const days = Math.round(seconds / 86400);

  if (days < 7) {
    return t('misc.days_ago', {days: days});
  }

  const weeks = Math.round(seconds / 604800);

  if (weeks < 5) {
    return t('misc.weeks_ago', {weeks: weeks});
  }

  const months = Math.round(seconds / 2419200);

  if (months < 12) {
    return t('misc.months_ago', {months: months});
  }

  const years = Math.round(seconds / 31536000);
  return t('misc.years_ago', {years: years});
}


/**
 * @param email: string
 * @return array: string of messages
 */
export const validateEmail = email => {
  const errorMessages = [];

  if (!email || typeof email !== "string" || email.length === 0) {
    errorMessages.push("Email shouldn't be empty!");
    return errorMessages;
  }
  
  // email format validation: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  if (!String(email).toLowerCase().match(/^\S+@\S+\.\S+$/)) {
    errorMessages.push('Please enter a valid email address.');
  }

  return errorMessages;
};



/**
 * @param password: string
 * @param passwordAgain?: string | undefined
 * @return array: string of messages
 */
export const validatePassword = (password, passwordAgain) => {
  const errorMessages = [];

  if (!password || typeof password !== "string" || password.length === 0) {
    errorMessages.push("Password shouldn't be empty!");
    return errorMessages;
  }

  if (password.length < 8) {
    errorMessages.push("Password should be at least 8 characters!");
  }

  if (!/\d/.test(password)) {
    errorMessages.push("Password should contain number characters!");
  }

  if (password === password.toLowerCase()) {
    errorMessages.push("Password should contain uppercase characters!");
  }

  if (password === password.toUpperCase()) {
    errorMessages.push("Password should contain lowercase characters!");
  }

  if (typeof passwordAgain !== 'undefined' && password !== passwordAgain) {
    errorMessages.push("Passwords doesn't match!")
  }

  return errorMessages;
}


export const toKebabCase = (s: string) => {
  return s.toLowerCase().replace(/ /g, '-');
};

export const decodeKebabCase = (s: string) => {
  return s[0].toUpperCase() + s.replace(/-/g, ' ').slice(1);
};

/**
 * Function that handles MDBootstrap Select onValueChange event, but allows only single selection
 * @param setterFunc: setter function returned by a useState call 
 * @returns 
 */
export const getSingleSelectChangeFunc = (setterFunc, allowEmpty = true) => (selectedObjects) => {
  console.log(selectedObjects);
  
  setterFunc((currentObjects) => {
    const newObjects = currentObjects.map(currentObject => {  
      const foundObjects = selectedObjects.filter(selectedObject => selectedObject.value === currentObject.value);

      // NO SELECTION - ENABLED
      if (allowEmpty) {
        currentObject.defaultSelected = foundObjects.length === 1 && !currentObject.defaultSelected;
        return {...currentObject};
      }

      // NO SELECTION - DISABLED

      if (selectedObjects.length === 0) {
        return {...currentObject};
      }

      if (selectedObjects.length === 1) {
        currentObject.defaultSelected = foundObjects.length === 1;
        return {...currentObject};
      }

      if (foundObjects.length === 0) {
        currentObject.defaultSelected = false;
        return {...currentObject};
      }

      currentObject.defaultSelected = !currentObject.defaultSelected;
      return {...currentObject};
    });

    return newObjects;
  });
};

/**
 * Function that handles MDBootstrap Select onValueChange event, allows multiple selection
 * @param originalObjects: inital values (that was given to useState)
 * @param setterFunc: setter function returned by a useState call 
 * @returns 
 */
export const getSelectChangeFunc = (originalObjects, setterFunc) => (selectedObjects) => {
  console.log(selectedObjects);
  const newObjects = originalObjects.map(object => {
    // console.log(selectedObjects);

    if (Array.isArray(selectedObjects)) {
      const foundObjects = selectedObjects.filter(selectedObject => selectedObject.value === object.value);

      object.defaultSelected = foundObjects.length === 1;
    } else {
      object.defaultSelected = selectedObjects.value === object.value;
    }

    return {...object};
  });
    
  setterFunc(newObjects);
};


/**
 * ADD LEADING ZEROS IF NEEDED
 * implementation: https://stackoverflow.com/questions/2998784/how-to-output-numbers-with-leading-zeros-in-javascript
 * @param num
 * @param size
 */
export const addLeadingZeros = (num, size) => {
  num = num.toString();

  while (num.length < size) num = "0" + num;

  return num;
}


/**
 * Convert new line characters in text to new lines
 */
export const renderTextWithNewLines = (text) => {
  return (
    <>
      {text.split('\n').map(t => (
        <span style={{'display': 'block'}}>
          {t}
        </span>
      ))}
    </>
  );
};



// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}



import { postalCodesData } from "./postal-codes-full";

/**
 * Converts city to postal codes array
 * @param cityText: string
 * @returns [...postalcodes: number] OR empty array city is invalid
 */
export const locationConverter = (cityText) => {
  const postalCodes = [];

  for (const postalCode in postalCodesData) {
    if (postalCodesData[postalCode].filter(city => city.name.toLowerCase() === cityText.toLowerCase()).length > 0) {
      postalCodes.push(postalCode);
    }
  }
  
  return postalCodes;
};


export const getScrollBarWidth = () => {
  // CODE SNIPPET FROM: https://stackoverflow.com/questions/986937/how-can-i-get-the-browsers-scrollbar-sizes
  var inner = document.createElement('p');
  inner.style.width = "100%";
  inner.style.height = "200px";

  var outer = document.createElement('div');
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild (inner);

  document.body.appendChild (outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  var w2 = inner.offsetWidth;
  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild (outer);

  return (w1 - w2);
};

// https://www.freecodecamp.org/news/check-if-an-object-is-empty-in-javascript/
export const isObjectEmpty = (o) => {
  return Object.keys(o).length === 0 && o.constructor === Object;
}


// I HATE SAFARI
export const IS_SAFARI = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));