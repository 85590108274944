// react imports
import React, { useState, useEffect, useContext } from "react";

// CUSTOM COMPONENTS
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// COOKIES IMPORTS
import Cookies from 'universal-cookie';
import ProductListItem from "../../common/product-list/product-list-item/ProductListItem";
import ThankYouPanel from "../../common/thank-you-panel/ThankYouPanel";
const cookies = new Cookies();



const SellItemConfirmationPage = () => {
  // CONTEXT
  const context = useContext(AppContext);

  // STATES
  const [product, setProduct] = useState(null);

  useEffect(() => {
    context.interAxios.get(
      '/item/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1],

      {params: { lang: cookies.get('mylanguage').language }},

      response => setProduct(response.data)
    );
  }, [cookies.get('mylanguage').language]);

  // MULTILANGUAGE
  const { t, language } = i18n;


  return (
    <div className="jc-page gray-bc sell-item-confirmation">
      <ThankYouPanel
        h1Title={t('product_uploaded.title')}
        message={t('product_uploaded.text')}
      />

      {product && (
        <div className="d-flex justify-content-center">
          <ProductListItem
            id={product.id}
            title={product.title}
            location={product.location}
            availableSizes={product.size}
            grossPrice={product.price.gross}
            firstImage={product.images[0]}
            displayBookmarkIcon={false}
            enableScrollOnLoading={false}
            linkToMyItem={true}
            fixWidth295={true}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SellItemConfirmationPage);
