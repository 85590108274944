// react imports
import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useSearchParams, useNavigate, useLocation } from "react-router-dom";

import AppContext from '../../AppContext';

// 3RD PARTY IMPORTS
import Moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Helmet } from "react-helmet";

// IMPORT LAYOUT COMPONENTS
import Footer from "./footer/Footer";
import Header from "./header/Header";
import ScrollToTop from "./scroll-to-top/ScrollToTop";

// MODAL IMPORTS
import LoginSignupModal from "../modals/login-signup-modal/LoginSignupModal";
import ForgotPasswordModal from "../modals/forgot-password-modal/ForgotPasswordModal";
import PleaseLoginForSellItemModal from "../modals/please-login-for-sell-item/PleaseLoginForSellItemModal";
import FillInYourProfileInformation from "../modals/fill_in_your_profile_information/FillInYourProfileInformation";
import PleaseVerifyModal from "../modals/please-verify-modal/PleaseVerifyModal";
import BackendErrorModal from "../modals/backend-error-modal/BackendErrorModal";
import EmailNotVerifiedModal from "../modals/email-not-verified-modal/EmailNotVerifiedModal";
import Only100ProductModal from "../modals/only-100-product-modal/Only100ProductModal";

// MODEL IMPORTS
import { JCWORLD_WEB_VISITED_FLAG } from "../../data/general-models";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../i18n';
import EmailVerifiedModal from "../modals/email-verified-modal/EmailVerifiedModal";

// HELPER FUNCS
import { getScrollBarWidth, IS_SAFARI } from "../../data/helper-funcs";



function Layout({ children }) {
  const context = useContext(AppContext);
  const { search } = useLocation();

  // STATES
  const [loggedIn, setLoggedIn] = useState(false);
  const [isRender, setIsRender] = useState(false);

  // MODAL STATES
  const [displayForgotPasswordModal, setDisplayForgotPasswordModal] = useState(false);
  const [displayPleaseLoginForSellItemModal, setDisplayPleaseLoginForSellItemModal] = useState(false);
  const [displayPleaseFillInYourProfileInformationModal, setDisplayPleaseFillInYourProfileInformationModal] = useState(false);
  const [displayPleaseVerifyModal, setDisplayPleaseVerifyModal] = useState(false);
  const [displayEmailVerifiedModal, setDisplayEmailVerifiedModal] = useState(false);
  const [displayAgeBelow13Modal, setDisplayAgeBelow13Modal] = useState(false);
  const [only100ProductVisible, setOnly100ProductVisible] = useState(false);
  const [displayEmailNotVerifiedModal, setDisplayEmailNotVerifiedModal] = useState(false);

  // react router navigation
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // display login modal first in session
 useEffect(() => {
    /*setTimeout(() => {
      const visitedFlag = sessionStorage.getItem(JCWORLD_WEB_VISITED_FLAG);

      if (!visitedFlag) {
        sessionStorage.setItem(JCWORLD_WEB_VISITED_FLAG, true);
        setDisplayLogin(true);
      }

    }, 60000);*/
    setTimeout(() => setIsRender(true), 1000);
  }, []);

  // events
  const loginEvent = (email, password, callbackOnSuccess = null, callbackOnError = null) => {

    context.interAxios.post('/auth/login', {password: password, email: email}, function(response) {
      context.onSignIn(response.data);
      setLoggedIn(true);
      context.closeLoginModal();

      if (callbackOnSuccess) {
        callbackOnSuccess();
      }
    }, function(error) {
      if (callbackOnError) {
        callbackOnError(error);
      } else {
        alert(t('misc.incorrect_login'));
      }
    });

  };
  const displayForgotPasswordModalEvent = () => {
    context.closeLoginModal();
    setTimeout(() => setDisplayForgotPasswordModal(true), 400);
  };
  const hideForgotPasswordModalEvent = () => {
    setDisplayForgotPasswordModal(false);
    //setTimeout(() => context.openLoginModal(), 400);
  };


  const showLogoutClicked = () => {
    context.onLogout();
    setLoggedIn("false");
    navigate('/marketplace');
  }

  // TESTING FORGOR PASSWORD MODAL
  // useEffect(() => {
  //   setTimeout(() => setDisplayForgotPasswordModal(true), 500);
  // }, []);

  // OPEN EMAIL VERIFIED MODAL
  useEffect(() => {
    if (location.search.indexOf('emailVerified=') !== -1) {
      context.interAxios.get('/auth/emailvalidate', {params: {token: Object.fromEntries([...searchParams]).token}}, function(response) {
        setDisplayEmailVerifiedModal(true);
      }); 
    }
  }, []);

  // MULTILANGUAGE
  const { t } = i18n;

  // CALCULATE SCROLLBAR WIDTH
  useEffect(() => {
    document.documentElement.style.setProperty('--scrollbar-width', `${getScrollBarWidth()}px`);
  }, []);

  useEffect(() => {
    setDisplayPleaseFillInYourProfileInformationModal(context.getState()?.editProfileModal)
  }, [context.getState()?.editProfileModal]);

  
  const cookiesIsAccepted = (getCookieConsentValue() && getCookieConsentValue() !== 'false');

  return (
    <>
      <Helmet>
        <title>{t('head.title')}</title>
        <meta name="description" content={t('head.description')} />
        <meta property="og:title" content={t('head.title')} />
        <meta property="og:description" content={t('head.description')} />
      </Helmet>
      
      {isRender ? <>
        <Header
          context={context}
          loggedIn={context.isLoggedIn()}
          logoutClicked={showLogoutClicked}
        />

        <ScrollToTop />

        <main className={i18n.language + (IS_SAFARI ? ' safari' : '')}>
          {children}
        </main>
        <Footer />


        {window.location.pathname !== "/profile/item/add" && window.location.pathname !== "/sign-up" && (
          <button
            className={"sell-now" + (!getCookieConsentValue() ? ' cookie-banner' : '')}
            onClick={() => {
              const handleStripeState = (response) => {
                if (response.data.state === 'ENABLED') {
                  navigate('/profile/item/add');
                } else {
                  setDisplayPleaseVerifyModal(true);
                }
              };

              if (!context.isLoggedIn()) {
                setDisplayPleaseLoginForSellItemModal(true);
                return;
              }

              // if (cookies.get('mylocation')?.clientType == 'PRIVATE') {
              //   // TODO: check if the user has 100 active products already
              //   setOnly100ProductVisible(true);
              // }
              context.interAxios.get('/client/me/sell-more', function(response) {
                
                if(response.data.canSellMore === true){
                  context.interAxios.get('/stripe/state', {}, handleStripeState);
                }else{
                  setOnly100ProductVisible(true);
                  return;
                }
              }); 
              
            }}
          >
            <i className="fa-solid fa-plus-circle"></i>
            {t('misc.sell_now')}
          </button>
        )}

        <PleaseLoginForSellItemModal
          visible={displayPleaseLoginForSellItemModal}
          hideModal={() => setDisplayPleaseLoginForSellItemModal(false)}
        />

        <FillInYourProfileInformation
          visible={displayPleaseFillInYourProfileInformationModal}
          hideModal={() => context.onHideEditProfileModal()}
        />

        <PleaseVerifyModal
          visible={displayPleaseVerifyModal}
          hideModal={() => setDisplayPleaseVerifyModal(false)}
        />

        <EmailVerifiedModal
          visible={displayEmailVerifiedModal}
          hideModal={() => setDisplayEmailVerifiedModal(false)}
        />

        <LoginSignupModal
          visible={context.isLoginModalOpened()}
          hideModalHandler={context.closeLoginModal}
          forgotPasswordClicked={displayForgotPasswordModalEvent}
          loginEvent={loginEvent}
          openEmailNotVerifiedModal={(email) => setDisplayEmailNotVerifiedModal(email)}
        />

        <ForgotPasswordModal
          visible={displayForgotPasswordModal}
          hideModal={hideForgotPasswordModalEvent}
        />

        <Only100ProductModal
          visible={only100ProductVisible}
          hideModal={() => setOnly100ProductVisible(false)}
        />

        <EmailNotVerifiedModal
          email={displayEmailNotVerifiedModal}
          hideModal={() => setDisplayEmailNotVerifiedModal(false)}
        />

        <BackendErrorModal />

        <div className={"global-loading " + (context.isMarketplaceLoading() ? 'shown' : '')}>
          <i className="fa-solid fa-loader spinning"></i>
        </div>

        <CookieConsent
          location="bottom"
          buttonWrapperClasses="cookie-button-classes"
          contentClasses="cookie-content"
          buttonText={t('cookie_banner.accept_button_text')}
          expires={31}
          style={{ background: "#f9f9fa", color: "#252626", borderTop: "2px solid #EBEAEF" }}
          buttonClasses="accept"
          onAccept={context.onRenderTrueBookmark}
          enableDeclineButton
          declineButtonText={t('cookie_banner.decline_button_text')}
          declineButtonClasses="reject"
          onDecline={context.onRenderTrueBookmark}
        >
          {t('cookie_banner.text')}.
        </CookieConsent>

        {/* COOKIE DEPENDENT SCRIPTS */}
        {/* {cookiesIsAccepted && (
          
        )} */}

        </>:false}
    </>
  );
}

export default withTranslation()(Layout);
