// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CONTEXT
import AppContext from '../../../AppContext';

// 3RD PARTY IMPORTS
import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Props
 * @param email: string | false
 * @param hideModal: () => void
 * @returns
 */
const EmailNotVerifiedModal = (props) => {
  const context = useContext(AppContext);

  // STATES
  const [emailNotValidatedStatus, setEmailNotValidatedStatus] = useState('NOT_SENT'); // NOT_SENT, SENDING, SENT

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // HELPER FUNCS
  const hideModalWithResetState = () => {
    props.hideModal();

    setTimeout(() => setEmailNotValidatedStatus('NOT_SENT'), 400);
  };

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <MDBModal
      className="standard-jc-modal w-615 please-login"
      show={props.email}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={hideModalWithResetState}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={hideModalWithResetState}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.email_not_verified.title')}</h3>

            <p>{t('general_modals.email_not_verified.text')}</p>

            <button
              className="jc-btn black"
              disabled={emailNotValidatedStatus === 'SENT'}
              onClick={() => {
                setEmailNotValidatedStatus('SENDING');

                context.interAxios.post('/auth/send-validation-email', undefined, {params: {email: props.email, lang: cookies.get('mylanguage').language}}, function(response) {
                  setEmailNotValidatedStatus('SENT')
                });
              }}
            >
              {emailNotValidatedStatus === 'NOT_SENT' && t('general_modals.email_not_verified.button_text')}
              {emailNotValidatedStatus === 'SENDING' && <i className="fa-regular fa-spinner-third spinning"></i>}
              {emailNotValidatedStatus === 'SENT' && <i className="fa-regular fa-check"></i>}
            </button>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(EmailNotVerifiedModal);
