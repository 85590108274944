// REACT
import { useEffect, useContext, useState } from 'react';

// REACT DOM
import { useParams } from 'react-router-dom';

// IMAGE IMPORTS
import sellItemHeroImg from "../../../images/sell-item-header.jpg";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CUSTOM IMPORTS
import ItemForm from "../../form-components/item-form/ItemForm";

// CONTEXT
import AppContext from '../../../AppContext';

// COOKIES
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const EditItemPage = () => {
  const { t } = i18n;
  const params = useParams();
  const context = useContext(AppContext);

  // STATES
  const [product, setProduct] = useState(null);

  // GET PRODUCT
  useEffect(() => {
    context.interAxios.get('/myitem/' + params.productId,
      {
        params: { lang: cookies.get('mylanguage').language }
      },
      function(response) {
        setProduct(response.data);
      }
    );
  }, [params.productId]);

  return (
    <div className="jc-page gray-bc sell-item">
      <div className="container-fluid mw-1040 hero-image">
        <img src={sellItemHeroImg} />
      </div>

      <div className="container-fluid mw-1040">
        <h1>
          {t('product_form.edit_item_title')}
        </h1>

        {product && <ItemForm product={product} />}
      </div>
    </div>
  );
};

export default withTranslation()(EditItemPage);
