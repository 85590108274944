// react imports
import React, { useState, useEffect, useContext, useRef } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// REACT DOM IMPORTS
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

// CUSTOM IMPORTS
import ProductImages from "../../common/product-images/ProductImages";
import ProductScroller from "../../common/product-scroller/ProductScroller";
import RatingsStar from "../../common/ratings-star/RatingsStar";
import RatingsList from "../../common/ratings-list/RatingsList";
import RatingsModal from "../../modals/ratings-modal/RatingsModal";
import SellerCard from "../../common/seller-card/SellerCard";
import ProductDetails from "../../common/product-details/ProductDetails";
import AppContext from '../../../AppContext';
import ReviewSellerModal from "../../modals/review-seller-modal/ReviewSellerModal.js";
import MessageSendModal from "../../modals/message-send-modal/MessageSendModal";
// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// DATA IMPORTS
import { Seller as seller, UserRatings, SampleProducts } from "../../../data/product-data";
import { Users } from "../../../data/user-data";
import { isObjectEmpty } from "../../../data/helper-funcs";

const OrderItemPage = () => {
  const context = useContext(AppContext);

  // NAVIGATE
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // REACT ROUTER
  const params = useParams();
  // console.log(params.productId);

  // REFS
  const messageButtonRef = useRef(null);

  // STATES
  //const [product, setProduct] = useState(SampleProducts[Math.floor(Math.random()*SampleProducts.length)]);
  const [product, setProduct] = useState({});
  const [sellerId, setSellerId] = useState(null);
  const [buyerId, setBuyerId] = useState(null);
  const [sellerPublicName, setSellerPublicName] = useState(null);
  const [buyerPublicName, setBuyerPublicName] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [followed, setFollowed] = useState(false);
  const [clientRatings, setClientRatings] = useState();
  const [orderTimestamp, setOrderTimestamp] = useState();
  const [messageSendModalVisible, setMessageSendModalVisible] = useState(false);
  const [alreadyOpenedMessageModalByParam, setAlreadyOpenedMessageModalByParam] = useState(false);

  // MODAL STATES
  const [showRatingsModal, setShowRatingsModal] = useState(false);
  const [showReviewSellerModal, setShowReviewSellerModal] = useState(false);

  //EVENTS
  const downloadOrder = (callback) => {
    context.interAxios.get('/order/' + params.orderId,
      {params: {
        lang: cookies.get('mylanguage').language
      }},
      function(response) {
        setOrderId(response.data.id);
        
        setOrderTimestamp(response.data.timestamp);

        setProduct({additionalInformation: "",
                    buyer: response.data.buyer,
                    bookmarked: false,
                    comments: [],
                    condition: response.data.condition,
                    description: response.data.description,
                    images: [ response.data.firstImage ],
                    price: {gross: response.data.grossPrice},
                    returnPolicy: "",
                    seller: response.data.seller,
                    shippingOptions: [response.data.shippingOption],
                    similarItems: [],
                    size: [ response.data.size ],
                    title: response.data.title,
                    orderTimestamp: response.data.timestamp,
                    buyerReview: response.data.buyerReview
        });
        setSliderImages([response.data.firstImage?.url]);
        setSellerId(response.data.seller?.id);
        setBuyerId(response.data.buyer?.id);
        setSellerPublicName(response.data.seller?.publicName);
        setBuyerPublicName(response.data.buyer?.publicName)
        
        if (callback) {
          callback(response.data.buyer, response.data.buyerReview);
        }
      },
      function (error) {
        if (error.response?.data?.message && error.response?.data?.message === "user_is_not_participant_of_order") {
          navigate('/marketplace');
          return;
        }

        if (error.response?.data?.message && error.response?.data?.message.indexOf('not found') !== -1) {
          navigate('/product/not-found');
        } else {
          console.log(error);
        }
      }
    );
  };
  const clickOnBackButton = () => {
    if(searchParams.get("enableScrollOnLoading")){
      context.getProductId(searchParams.get("marketplaceProductId"));
      navigate(-1);
    }else{
      navigate(-1);
    }
    
  }

  const onAddFollowed = (id) => {
    context.interAxios.put('/follow/' + id,
      function(response) {
        setFollowed(response.data.success);
      }
    )
  }

  const onRemoveFollowed = () => {
    context.interAxios.delete('/follow/' + id,
      function(response) {
        setFollowed(!response.data.succes);
      }
    )
  }

  // MULTILANGUAGE
  const { t } = i18n;
  // download order
  useEffect(() => {
    if (!context.isLoggedIn()) {
      context.openLoginModal(false);
      return;
    }

    downloadOrder((buyer, buyerReview) => {

      if (!buyerReview && context.isLoggedInIdIs(buyer?.id) && searchParams.get('openRatingModal')) {
        setShowReviewSellerModal(true);
      }

      if (!alreadyOpenedMessageModalByParam && 
        searchParams.get('openMessageModal')
      ) {
        if( messageButtonRef?.current){
          window.scrollTo({ 
            top: messageButtonRef.current.offsetTop - 200,
            behavior: 'smooth' 
          });
        }
       
        console.log("belép ide");
        setTimeout(() => { 
          setAlreadyOpenedMessageModalByParam(true);
        }, 300);

        setTimeout(() => { 
          messageButtonRef.current.click();
        }, 400);
      }
    });
  }, [cookies.get('mylanguage')?.language, context.getState()?.isRender]);


  // OPEN REVIEWS MODAL WITH PARAM
  useEffect(() => {
    if (window.location.search.indexOf('reviewsModalOpened') !== -1) {
      setShowRatingsModal(true);
    }
  }, []);

  const now = new Date();
  now.setDate(now.getDate() - 30);

  return (
    <div className="jc-page white product order">
      <div className="container-fluid main mw-1300">
        {context.isLoggedIn() && !isObjectEmpty(product) && (
          <>
            <a className="back" onClick={clickOnBackButton}>
              <i className="fa-regular fa-arrow-left"></i>
              {t('misc.back')}
            </a>

            <ProductImages sliderImages={sliderImages}/>

            
            <ProductDetails 
              product={product}
              setProduct={setProduct}
              sellable={true}
              isOrderItem={true}
              clickOnSendReview={() => setShowReviewSellerModal(true)}
            />

                {product?.title && (
                  <ReviewSellerModal
                    visible={showReviewSellerModal}
                    hideModal={() => setShowReviewSellerModal(false)}
                    order={{
                      title: product.title,
                      timestamp: product.orderTimestamp,
                      seller: product.seller
                    }}
                    orderId={window.location.pathname.split("/")[window.location.pathname.split("/").length-1]}
                    reviewSent={rating => {
                      downloadOrder();
                    }}
                  />
                )}

                
            <section className="seller">
              <header>{context.getState()?.orderType === "Sold" ? t('user_profile.buyer') : t('user_profile.seller')}</header>
              {product.seller !== undefined ? (
                <>
                  <div className="py-3">
                    <SellerCard
                      image={context.getState()?.orderType === "Sold" ? product.buyer?.image?.url : product.seller?.image?.url}
                      name={context.getState()?.orderType === "Sold" ? product.buyer?.publicName : product.seller?.publicName}
                      sellerId={context.getState()?.orderType === "Sold" ? product.buyer?.id : product.seller?.id}
                      location={product?.location}
                      rating={context.getState()?.orderType === "Sold" ? product.buyer?.rating.stars : product.seller?.rating.stars}
                      openRatingModal={() => {setShowRatingsModal(true); context.onRenderTrueRatingModal()}}
                      followed={product.seller?.isFollowed}
                      clickOnImageAndTitle={() => navigate(`/user-profile/${context.getState()?.orderType === "Sold" ? product.buyer.id : product.seller.id}`)}
                    />
                  </div>
                  
                  
                 
                </>
              )
              :false}
               {orderTimestamp && (new Date(orderTimestamp) > now) && (
                    <>
                      {/* SELLER - LOGGED IN */}
                      {context.isLoggedInIdIs(product?.seller?.id) && (
                        <div className="py-3">
                          <button 
                            className="jc-btn white send-message" 
                            onClick={() => setMessageSendModalVisible(true)}
                            disabled={!(product?.buyer) || (product.buyer.isGuest)}
                            ref={messageButtonRef}
                          >
                            <i className="fa-regular fa-envelope"></i>
                            {t('messages.contact_buyer')}
                          </button>

                          {/* if the buyer has been deleted */}
                          {!(product?.buyer) && (
                            <div className="alert alert-warning mt-2" role="alert">
                              <i className="fa-regular fa-info-circle me-2"></i>
                              {t('messages.deleted_user_text')}
                            </div>                          
                          )}

                          {/* if the buyer is a guest user */}
                          {product?.buyer?.isGuest && (
                            <div className="alert alert-warning mt-2" role="alert">
                              <i className="fa-regular fa-info-circle me-2"></i>
                              {t('messages.guest_buyer_text')}
                            </div>                          
                          )}
                        </div>
                      )}

                      {/* BUYER - LOGGED IN */}
                      {context.isLoggedInIdIs(product?.buyer?.id) && (
                        <div className="py-3">
                          <button 
                            className="jc-btn white send-message" 
                            onClick={() => setMessageSendModalVisible(true)}
                            disabled={!(product?.seller)}
                            ref={messageButtonRef}
                          >
                            <i className="fa-regular fa-envelope"></i>
                            {t('messages.contact_seller')}
                          </button>

                          {/* if the seller has been deleted */}
                          {!(product?.seller) && (
                            <div className="alert alert-warning mt-2" role="alert">
                              <i className="fa-regular fa-info-circle me-2"></i>
                              {t('messages.deleted_user_text')}
                            </div>                          
                          )}
                        </div>
                      )}
                    </>
                  )}
            </section>
           
            {sellerId && (
              <RatingsModal
                sellerId={sellerId}
                visible={showRatingsModal}
                hideModal={() => setShowRatingsModal(false)}
                displayItemName={true}
              />
            )} 
            <MessageSendModal
              visible={messageSendModalVisible}
              hideModal={() => setMessageSendModalVisible(false)}
              recipient={context.isLoggedInIdIs(buyerId) ? { id: sellerId, publicName: sellerPublicName } : { id: buyerId, publicName: buyerPublicName }}
              subject={product.title}
              orderId={orderId}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(OrderItemPage);