// REACT IMPORTS
import { useEffect, useContext, useState } from "react";

// REACT ROUTER IMPORTS
import { useNavigate } from "react-router";

// MODAL IMPORTS
import BookmarkOnlyModal from "../../../modals/bookmark-only-modal/BookmarkOnlyModal";
import BookmarkOnlyPrivateModal from "../../../modals/bookmark-only-modal/BookmarkOnlyPrivateModal";
import PleaseSelectSizeModal from "../../../modals/please-select-size-modal/PleaseSelectSizeModal";
import ReviewSellerModal from "../../../modals/review-seller-modal/ReviewSellerModal.js";

// MDBoostrap IMPORTS
import { MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import TruncateTooltipText from "../../truncate-tooltip-text/TruncateTooltipText";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../../i18n';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../../AppContext';


/**
 * Props:
 * @param id: number
 * @param title: string
 * @param location: { countryCode: string, location: string, postcode: number }
 * @param availableSizes: array of { id: number, name: string }
 * @param grossPrice: number
 * @param firstImage?: { url: string }
 * @param displayBookmarkIcon: boolean
 * @param enableScrollOnLoading: boolean
 * @param linkToMyItem?: boolean
 * @param displayCheckoutButton?: boolean
 * @param displayUnansweredCommentsDot?: boolean
 * @param commments?: []
 * @param fixWidth295?: boolean
 * @param withoutReview?: boolean
 * @param withosellerutReview?: {}
 * @param timestamp?: number
 * @returns
 */
const ProductListItem = (props) => {
  // router hooks
  const navigate = useNavigate()
  const context = useContext(AppContext);

  // states
  const [bookmarked, setBookmarked] = useState(props.bookmarked);
  const [loggedInIsTheSeller, setLoggedInIsTheSeller] = useState(context.isLoggedInIdIs(props.sellerId));

  // MODAL STATES
  const [bookmarkModalVisible, setBookmarkModalVisible] = useState(false);
  const [bookmarkBusinessModalVisible, setBookmarkBusinessModalVisible] = useState(false);
  const [sizeModalVisible, setSizeModalVisible] = useState(false);
  const [showReviewSellerModal, setShowReviewSellerModal] = useState(false);

  // event handlers
  const goToItemPage = () => {
    if(props.isOrderItem){
      navigate(`/profile/order/${props.id}`);
    }else{
      if (props.linkToMyItem) {
        if(props.enableScrollOnLoading){
          const searchParams = new URLSearchParams();
          searchParams.append("enableScrollOnLoading", props.enableScrollOnLoading);
          searchParams.append("marketplaceProductId", props.id);
          navigate(`/profile/item/${props.id}?` + searchParams.toString());
        }else{
          navigate(`/profile/item/${props.id}`);
        }
        
      } else {
        if(props.enableScrollOnLoading){
          const searchParams = new URLSearchParams();
          searchParams.append("enableScrollOnLoading", props.enableScrollOnLoading);
          searchParams.append("marketplaceProductId", props.id);
          navigate(`/product/${props.id}?` + searchParams.toString());
        }else{
          navigate(`/product/${props.id}`);
        }
        
      }
    }
    
  };
  const clickOnBookmarkIcon = (id) => {
    
    if(context.isLoggedIn()){
      setBookmarkModalVisible(false);
      if(!context.isLoggedInBusiness()){
        setBookmarkBusinessModalVisible(false);
        if(bookmarked){
          setBookmarked(false);
          context.interAxios.delete('/bookmark/' + id,
          function(response) {
            if (location.href.indexOf('shopping-list') !== -1 || location.href.indexOf('bookmark') !== -1) {
              context.onRenderTrueBookmark()
            }
          })
        }else{
          setBookmarked(true);
          context.interAxios.put('/bookmark/' + id,
                      {params: {
                        lang: cookies.get('mylanguage').language
                    }},
          function(response) {
            if (location.href.indexOf('shopping-list') !== -1 || location.href.indexOf('bookmark') !== -1) {
              context.onRenderTrueBookmark()
            }
          })
        }
      }else{
        setBookmarkBusinessModalVisible(true);
      }
      
      
    }else{
      setBookmarkModalVisible(true);
    }
    
  };

  // helper
  const getClassName = () => {
    const classNames = ['product-list-item'];
    if ((props.commentsReadBySeller === false)) {
      classNames.push('orange-dot');
    }

    if (props.fixWidth295) {
      classNames.push('fix-width-295');
    }

    return classNames.join(' ');
  };
  const getSize = () => {
    if (!Array.isArray(props.availableSizes) || props.availableSizes.length === 0) {
      if (props?.size) {
        return props.size.name;
      } else {
        return "";
      }
    }

    if (props.availableSizes.length > 1) {
      return t('product_list_item.size_multiple');
    }

    return props.availableSizes[0].name;
  };

  // TEMPLATES
  const renderButtons = () => {
    const buttonsJSXArray = [];

    if (props.withoutReview === true) {
      buttonsJSXArray.push((
        <button className="jc-btn black" onClick={() => setShowReviewSellerModal(true)}>
           <i className="fa-regular fa-star"></i>
              {t('general_modals.review_seller.button_text')}
        </button>
      ));
    }

    if ((!loggedInIsTheSeller && props.displayCheckoutButton)) {
      buttonsJSXArray.push((
        <button 
          className="jc-btn black checkout"
          onClick={() => {
            if (!context.isLoggedIn()) {
              console.error('We should be logged in when checkout');
              return;
            }

            if (!Array.isArray(props.availableSizes) || props.availableSizes.length === 0) {
              console.error('availableSizes should be an array with at least one size');
              return;
            }


            if (props.availableSizes.length > 1) {
              setSizeModalVisible(true);
            } else {
              navigate(`/checkout/${props.id}/${props.availableSizes[0].id}`);
            }
          }}
        >
          {t('product_list_item.checkout')}
        </button>
      ));
    }

    if (props.isOrderItem && props.linkToMyItem) {
      buttonsJSXArray.push((
        <button 
          className="jc-btn white send-message"
          onClick={() => navigate(`/profile/order/${props.id}?openMessageModal=true`)}
        >
          <i className="fa-regular fa-envelope"></i>
          {props.orderType === "Sold" ? t('messages.contact_buyer') : t('messages.contact_seller')}
        </button>
      ))
    }

    if (buttonsJSXArray.length === 0) {
      return '';
    }
    
    return (
      <div className={`card-buttons`}>
        {buttonsJSXArray}
      </div>
    );
  };

  // MULTILANGUAGE
  const { t } = i18n;

  useEffect(() => {
    setBookmarked(props.bookmarked);
  }, [props.bookmarked]);

  const downloadOrder = () => {
    context.onRenderTrueBookmark();
  }

  return (
    <>
      <div 
        className={getClassName()}
        id={props.enableScrollOnLoading ? props.id : -1}
      >
        <div className="product-image-wrapper" onClick={goToItemPage}>
          <img src={props.firstImage?.url} alt={props.title} />
        </div>

        <div className="product-content">
          {(!loggedInIsTheSeller && props.displayBookmarkIcon) &&
            <i
              className={`${bookmarked ? 'fa-solid' : 'fa-regular'} fa-bookmark`}
              onClick={() => clickOnBookmarkIcon(props.id)}
            ></i>
          }

          <div className="product-title" onClick={goToItemPage}>
            <TruncateTooltipText
              text={props.title}
              breakpoints={props.fixWidth295 ? [
                { maxCharacterNumber: 27, width: 300 },
              ] : [
                { maxCharacterNumber: 9, width: 300 },
                { maxCharacterNumber: 10, width: 360 },
                { maxCharacterNumber: 11, width: 370 },
                { maxCharacterNumber: 12, width: 390 },
                { maxCharacterNumber: 13, width: 415 },
                { maxCharacterNumber: 14, width: 425 },
                { maxCharacterNumber: 15, width: 430 },
                { maxCharacterNumber: 16, width: 460 },
                { maxCharacterNumber: 16, width: 490 },
                { maxCharacterNumber: 17, width: 530 },
                { maxCharacterNumber: 18, width: 560 },
                { maxCharacterNumber: 21, width: 620 },
                { maxCharacterNumber: 22, width: 630 },
                { maxCharacterNumber: 24, width: 660 },
                { maxCharacterNumber: 25, width: 690 },
                { maxCharacterNumber: 14, width: 720 },
                { maxCharacterNumber: 16, width: 740 },
                { maxCharacterNumber: 18, width: 800 },
                { maxCharacterNumber: 20, width: 880 },
                { maxCharacterNumber: 22, width: 950 },
                { maxCharacterNumber: 24, width: 1110 },
                { maxCharacterNumber: 19, width: 1200 },
                { maxCharacterNumber: 22, width: 1285 },
                { maxCharacterNumber: 23, width: 1440 },
              ]}
            />

            {/* <MDBTooltip
              tag='div'
              title={props.title}
            >
              {props.title}
            </MDBTooltip> */}
          </div>

          <div className="product-infos">
            <div className="product-info location">
              <MDBIcon far icon="map-marker-alt" />

              <TruncateTooltipText
                text={props.isOrderItem === true ? props.seller?.location !== null ? props.seller?.locationString : props.buyer?.locationString : props.location?.location}
                breakpoints={props.fixWidth295 ? [
                  { maxCharacterNumber: 15, width: 300 },
                ] : [
                  { maxCharacterNumber: 15, width: 300 },
                  { maxCharacterNumber: 17, width: 380 },
                  { maxCharacterNumber: 18, width: 400 },
                  { maxCharacterNumber: 20, width: 440 },
                  { maxCharacterNumber: 9, width: 500 },
                  { maxCharacterNumber: 7, width: 687 },
                  { maxCharacterNumber: 10, width: 830 },
                  { maxCharacterNumber: 12, width: 900 },
                  { maxCharacterNumber: 10, width: 1200 },
                  { maxCharacterNumber: 12, width: 1240 },
                ]}
              />
              {/* <MDBTooltip
                tag='div'
                title={props.location.location}
              >
                <MDBIcon far icon="map-marker-alt" />
                {props.location.location}
              </MDBTooltip> */}
            </div>

            <div className="product-info size">
              {(t('product_list_item.size'))}: 
              <TruncateTooltipText
                text={getSize()}
                breakpoints={props.fixWidth295 ? [
                  { maxCharacterNumber: 12, width: 300 },
                ] : [
                  { maxCharacterNumber: 12, width: 300 },
                  { maxCharacterNumber: 14, width: 380 },
                  { maxCharacterNumber: 15, width: 400 },
                  { maxCharacterNumber: 17, width: 440 },
                  { maxCharacterNumber: 4, width: 500 },
                  { maxCharacterNumber: 9, width: 570 },
                  { maxCharacterNumber: 10, width: 620 },
                  { maxCharacterNumber: 11, width: 650 },
                  { maxCharacterNumber: 5, width: 687 },
                  { maxCharacterNumber: 9, width: 830 },
                  { maxCharacterNumber: 11, width: 900 },
                  { maxCharacterNumber: 9, width: 1200 },
                  { maxCharacterNumber: 11, width: 1280 },
                  { maxCharacterNumber: 11, width: 1400 },
                ]}
              />
              {/* <span> 
                {props.availableSizes !== undefined ? props.availableSizes[0].name : ""}
              </span> */}
            </div>
          </div>

          <div className="product-price price">
            {props.grossPrice.toString().slice(0, 10)}
            <span>CHF</span>
          </div>

          {renderButtons()}
          
          {/* <button className="jc-btn black">
            Checkout
          </button> */}
        </div>
      </div>

      <BookmarkOnlyModal
        visible={bookmarkModalVisible}
        hideModal={() => setBookmarkModalVisible(false)}
      />

      <ReviewSellerModal
        visible={showReviewSellerModal}
        hideModal={() => setShowReviewSellerModal(false)}
        order={{
          title: props.title,
          timestamp: props.timestamp,
          seller: props.seller
        }}
        orderId={props.id}
        reviewSent={rating => {
          downloadOrder();
        }}
      />

      <BookmarkOnlyPrivateModal
        visible={bookmarkBusinessModalVisible}
        hideModal={() => setBookmarkBusinessModalVisible(false)}
      />

      <PleaseSelectSizeModal
        visible={sizeModalVisible}
        hideModal={() => setSizeModalVisible(false)}
        goToProductPage={() => {
          navigate(`/product/${props.id}`);
        }}
      />

    </>
  );
};

export default withTranslation()(ProductListItem);
