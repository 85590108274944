// react imports
import React, { useState, useEffect, useContext } from "react";

// REACT DOM IMPORTS
import { useNavigate } from 'react-router-dom';

// CUSTOM IMPORTS
import ProductScroller from "../../common/product-scroller/ProductScroller";
import AppContext from "../../../AppContext";

// MDBOOTSTRAP IMPORTS
import { MDBAlert } from "mdb-react-ui-kit";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// IMAGE IMPORTS
import emptyImage from '../../../images/No-added-item.svg'


const NotFoundPage = () => {
  // CONTEXT
  const context = useContext(AppContext);

  // NAVIGATE
  const navigate = useNavigate();

  // STATES
  const [newItems, setNewItems] = useState();
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // MULTILANGUAGE
  const { t } = i18n;

  // EVENTS
  const newItemsDownloaded = (response) => {
    setNewItems(response.data);

    context.setItemsLoading(false);
  };
  const errorDuringItemsDownloading = (error) => {
    setAlert({alert: true, alertText: error.message});
    
    context.setItemsLoading(false);
  };

  useState(() => {
    context.setItemsLoading(true);

    context.interAxios.get('/item/new', newItemsDownloaded, errorDuringItemsDownloading);
  }, []);


  return (
    <div className="jc-page white not-found-page">
      <div className="container-fluid main mw-1300">

        <a className="back pointer" onClick={() => navigate(-1)}>
          <i className="fa-regular fa-arrow-left"></i>
          {t('misc.back')}
        </a>

        <section>
          <img src={emptyImage}/>

          <h1>404</h1>

          <p>
            {
              location.href.indexOf('product/not-found') !== -1 ? 
                t('not_found.item_no_longer_available') : 
                t('not_found.page_not_found')
            }
          </p>
        </section>
      </div>

      {newItems && (
        <ProductScroller
          h2Title={t('not_found.new_items')}
          products={newItems}
        />
      )}

      <MDBAlert
        show={alert.alert}
        color='primary'
        autohide
        position='top-center'
        offset={50}
        delay={4000}
        appendToBody
        onClosed={() => { setAlert({alert: false, alertText: ""})}}
      >
        {alert.alertText}
      </MDBAlert>
    </div>
  );
};

export default withTranslation()(NotFoundPage);