// REACT IMPORTS
import { useState, useEffect } from 'react';


// HELPER FUNCS
import { renderTextWithNewLines } from '../../../data/helper-funcs';

// IMAGES
import aboutUsImage from "../../../images/about-us/about-us.jpg";
import logoImg from "../../../images/jc-logo.svg";

const DataDeletionRequestPage = () => {


  return (
    <div className="container-fluid jc-page data-deletion-request">
     
          <header>
            <div className="d-flex justify-content-center">
              <img src={logoImg} alt="JC World Logo"/>
            </div>
          </header>
            <div className="d-flex justify-content-center">
              <span className="title-1">Company:</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="text-1">J&C World GmbH</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="title-1">Mobile App:</span>
            </div>
            <div className="d-flex justify-content-center">
              <span className="text-1">J&C World Application</span>
            </div>
            <div className="d-flex justify-content-center">
            <span className="title-2">
            Data Deletion Request
            </span>
            </div>
            <div className="d-flex justify-content-center">
            <span className="text-2">To initiate the deletion of data linked to your identity, kindly send an email to the designated contact at support@jandc-world.ch. 
              By doing so, all personal data will be permanently erased. Please note that reviews, comments, and past sold items will still be 
              visible within the app, but they will be dissociated from any specific individual and attributed to an anonymous user instead.</span>
            </div>
            <div className="d-flex justify-content-center">
            <span className="title-2">
            Datenlöschungsanfrage
            </span>
            </div>
            <div className="d-flex justify-content-center">
            <span className="text-2">Um die Löschung der mit Ihrer Identität verknüpften Daten einzuleiten, senden Sie bitte eine E-Mail an den angegebenen 
              Kontakt unter support@jandc-world.ch. Dadurch werden alle personenbezogenen Daten dauerhaft gelöscht. Bitte beachten Sie, dass Bewertungen, 
              Kommentare und frühere Verkaufsartikel weiterhin in der App sichtbar sein werden, jedoch von einer bestimmten Person abstrahiert werden und stattdessen einem anonymen Benutzer zugeordnet werden.</span>
            </div>
           
        
    </div>
  );
};

export default DataDeletionRequestPage;
