// react imports
import React, { useContext, useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../AppContext';

import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @returns
 */
const ForgotPasswordModal = (props) => {
  
  // CONTEXT
  const context = useContext(AppContext);
  const navigate = useNavigate();
  // STATES
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);

  // EVENTS
  const submitEmailAddress = (e) => {
    e.preventDefault();

    setEmailSending(true);
    context.interAxios.post('/auth/' + email + '/reset-password', {},
      function(response) {
        setEmailSending(false);
        setResetPasswordEmailSent(true);
      }
    );
  };
  const closeModal = () => {
    props.hideModal();

    setTimeout(() => {
      setEmail('');
      setEmailSending(false);
      setResetPasswordEmailSent(false);
    }, 400);
  };

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <MDBModal
      className="standard-jc-modal forgot-password"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={closeModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={closeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            {!resetPasswordEmailSent && (
              <>
                <h3>{t('general_modals.forgot_password.title')}</h3>

                <form onSubmit={(e) => submitEmailAddress(e)}>
                  <input
                    placeholder={t('general_modals.forgot_password.email')}
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={emailSending}
                    required
                  />

                  <button className="jc-btn black" type="submit">
                    {t('general_modals.forgot_password.reset_password')}

                    {emailSending && <i className="fa-regular fa-spinner-third"></i>}
                  </button>
                </form>
              </>
            )}

            {/* TODO: text needed here */}
            {resetPasswordEmailSent && (
              <>
                <h3>{t('general_modals.forgot_password.email_sent_title')}</h3>

                <p>{t('general_modals.forgot_password.email_sent_text')}</p>

                <button className="jc-btn black" onClick={closeModal}>
                  {t('general_modals.forgot_password.close_button_text')}
                </button>
              </>
            )}

          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(ForgotPasswordModal);
