// react imports
import React, { useState } from "react";

/**
 * Props:
 * @param rating: number
 * @param setRating?: (number) => void
 * @param clickOnStarHandler?: () => void
 * @returns 
 */
const RatingsStar = ({rating, setRating, clickOnStarHandler}) => {

  const clickOnStar = (num) => {
    if (setRating) {
      setRating(num);
      return;
    }

    if (clickOnStarHandler) {
      clickOnStarHandler();
    }
  };

  return (
    <div className={"jc-ratings" + ((setRating || clickOnStarHandler) ? ' pointer' : '')}>
      {[1,2,3,4,5].map(num => {
        let iclass = "fa-solid fa-star";

        if (rating < (num-0.5)) {
          iclass = "fa-solid fa-star gray";
        }

        if (rating === (num-0.5)) {
          iclass = "fa-solid fa-star-half-stroke";
        }
        
        return (
          <i 
            key={num}
            className={iclass}
            onClick={() => clickOnStar(num)}
          >
          </i>
        );
      }
      )}
    </div>
  );
};

export default RatingsStar;