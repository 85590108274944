// react imports
import React, { useContext, useState, useRef, useEffect } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM COMPONENTS
import AppContext from '../../../AppContext';
import ImageSlider from "../../common/image-slider/ImageSlider";
import ProductScroller from "../../common/product-scroller/ProductScroller";
import ProductList from '../../common/product-list/ProductList';
import TopcategoryList from '../../common/topcategory-list/TopcategoryList';

import { MDBAlert } from 'mdb-react-ui-kit';

// MODEL IMPORTS
import { SlideSampleData, SlideSampleDataDE } from "../../../data/slide-sample-data";

// IMAGE IMPORTS
import loadingAnim from "../../../images/loading-anim.json";
import userShapeImage from "../../../images/user.png";


// LOTTIE
import { Player } from '@lottiefiles/react-lottie-player';


const Marketplace = (props) => {

  // REACT ROUTER PARAMS - for processing url
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);
  // STATES
  const [itemsList, setItemsList] = useState();
  const [pageSize, setPageSize] = useState(context.getState()?.marketplacePageSize !== undefined ? context.getState()?.marketplacePageSize : 32);
  const [newItemsList, setNewItemsList] = useState();
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(false);
  //const [reloadingOnParamsChange, setReLoadingOnParamsChange] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  //MDBAlert
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // REFS
  const marketplaceRef = useRef(null);
  const scrollToAnchorRef = useRef(null);
 
  const resetFilters = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    context.clearState();
  };

  const { t } = i18n;

  // events
  const loadNewProducts = () => {
    //context.setScrollOnLoadMore();

    setPageSize(pageSize + 32),
    context.onRenderTrue();
  };

  const loadImage = image => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image()
      loadImg.src = image
      loadImg.onload = () => resolve(image)
      loadImg.onerror = err => reject(err)
    })
  }

  useEffect(() => {
    context.setItemsLoading(true);
    context.onRender();
    if (context.isLoggedIn()){
      /*context.interAxios.get('/client/me',
      function(response) {
        cookies.set('mylocation', {
            location: response.data.address.location,
            clientType: response.data.clientType,
            clientId: response.data.id,
            clientImageUrl: response.data.image?.url,
            clientEmail: response.data.email,
            clientName: response.data.publicName,
            phoneNumber: response.data.phoneNumber,
            notifications: response.data.notifications,
            clientIsVat: response.data.vat,
            isHasClient: response.data.hasClient
          }, { path: '/' }
        );
     })*/
      context.interAxios.get('/item', {
        
        params: {
            categoryId: context.getState()?.categoryIdFilter !== undefined ? parseInt(context.getState()?.categoryIdFilter) : undefined,
            subCategoryId: context.getState()?.subCategoryIdFilter !== undefined ? parseInt(context.getState()?.subCategoryIdFilter) : undefined,
            subsubCategoryId: context.getState()?.subsubCategoryIdFilter !== undefined ? parseInt(context.getState()?.subsubCategoryIdFilter) : undefined,
            location: Array.isArray(context.getState()?.postcodeFilter?.postcode) ? context.getState()?.postcodeFilter?.postcode[0] : context.getState()?.postcodeFilter?.postcode,
            sizeId: context.getState()?.sizeIdFilter?.sizeId,
            conditionId: context.getState()?.conditionIdFilter?.conditionId,
            minPrice: context.getState()?.priceFilter?.minPrice,
            maxPrice: context.getState()?.priceFilter?.maxPrice,
            onlyFollowed: context.getState()?.onlyFollowedFilter?.onlyFollowed,
            searchText: context.getState()?.searchTextFilter?.searchText,
            "sort-by": context.getState()?.sort_byFilter?.sort_by,
            "sort-dir": context.getState()?.sort_dirFilter?.sort_dir,
            lang: cookies.get('mylanguage').language,
            pageIndex: 0,
            pageSize: pageSize
          }
        },
        function(response) {
          console.log(response.data)
          Promise.all(response.data.content.filter((k, n) => n < 4).map(image => loadImage(image.firstImage !== null ? image.firstImage.url : userShapeImage)))
          .then(() => { setItemsList(response.data);
            if(response.data.totalElements === 0){
              setAlert({alert: true, alertText: t('misc.there_are_no_items')})
            }
            setTotalPage(response.data.totalElements)
            setPageSize(response.data.pageSize > 32 ? response.data.pageSize : 32)
            if(response.data.totalElements > response.data.pageSize && response.data.totalElements > 32){
              context.onGetPageSize(response.data.pageSize)
              setMoreProductsAvailable(true);
            }else{
              context.onGetPageSize(response.data.totalElements)
              setMoreProductsAvailable(false);
            }

            
          })
          .catch(err => {
            console.log("Failed to load images", err);
            context.setItemsLoading(false);
          });

          if (context.getState()?.marketplaceProductId !== undefined) {
            setTimeout(() => {
              window.scrollTo({top: document.getElementById(context.getState()?.marketplaceProductId).offsetTop + 1200, behavior: 'smooth' });
              context.setItemsLoading(false);
             }, pageSize > 32 ? pageSize * 32: 1000);
      
             setTimeout(() => {
              context.removeProductId();
             }, pageSize > 32 ? pageSize * 50 : 1500);
          } else {
            context.setItemsLoading(false);
            if (context.getState()?.scrollOnMarketplaceAfterFilter) {
              setTimeout(() => {
                if (window.innerWidth > 1080) {
                  scrollToAnchorRef.current.scrollIntoView();
                } else {
                  // window.scrollTo({top: marketplaceRef.current.getBoundingClientRect().top - 100, behavior: 'smooth' });
                  scrollToAnchorRef.current.scrollIntoView();
                }
              }, 100);
              context.unsetScrollOnMarketplaceAfterFilter();
              
            }
            if (context.getState()?.scrollOnLoadMore ) {
              setTimeout(() => {
                window.scrollTo({top: document.body.scrollHeight - 2180, behavior: 'smooth' });
              }, 100)
              context.unsetScrollOnLoadMore();
            }
          }
        }
      );
    }else{
        context.interAxios.get('/item', {
          params: {
              categoryId: context.getState()?.categoryIdFilter !== undefined ? parseInt(context.getState()?.categoryIdFilter.toString()) : undefined,
              subCategoryId: context.getState()?.subCategoryIdFilter !== undefined ? parseInt(context.getState()?.subCategoryIdFilter.toString()) : context.getState()?.questCategoryFilter?.toString(),
              //subCategoryId: "20,90,45,105,57,115,276,292,132,72,275,148,170",
              subsubCategoryId: context.getState()?.subsubCategoryIdFilter !== undefined ? parseInt(context.getState()?.subsubCategoryIdFilter.toString()) : undefined,
              location: Array.isArray(context.getState()?.postcodeFilter?.postcode) ? context.getState()?.postcodeFilter?.postcode[0] : context.getState()?.postcodeFilter?.postcode,
              sizeId: context.getState()?.sizeIdFilter?.sizeId,
              conditionId: context.getState()?.conditionIdFilter?.conditionId,
              minPrice: context.getState()?.priceFilter?.minPrice,
              maxPrice: context.getState()?.priceFilter?.maxPrice,
              onlyFollowed: context.getState()?.onlyFollowedFilter?.onlyFollowed,
              searchText: context.getState()?.searchTextFilter?.searchText,
              "sort-by": context.getState()?.sort_byFilter?.sort_by,
              "sort-dir": context.getState()?.sort_dirFilter?.sort_dir,
              lang: cookies.get('mylanguage').language,
              pageIndex: 0,
              pageSize: pageSize
            }
          },
          function(response) {
            console.log(response.data);
            Promise.all(response.data.content.filter((k, n) => n < 4).map(image => loadImage(image.firstImage !== null ? image.firstImage.url : userShapeImage)))
              .then(() => { setItemsList(response.data);
                if(response.data.totalElements === 0){
                  setAlert({alert: true, alertText: t('misc.there_are_no_items')})
                }
                setTotalPage(response.data.totalElements)
                setPageSize(response.data.pageSize > 32 ? response.data.pageSize : 32)
                if(response.data.totalElements > response.data.pageSize && response.data.totalElements > 32){
                  context.onGetPageSize(response.data.pageSize)
                  setMoreProductsAvailable(true);
                }else{
                  context.onGetPageSize(response.data.totalElements)
                  setMoreProductsAvailable(false);
                }
  
                context.setItemsLoading(false);
              })
              .catch(err => {
                console.log("Failed to load images", err);
                context.setItemsLoading(false);
              });
  
              if (context.getState()?.marketplaceProductId !== undefined) {
                setTimeout(() => {
                  window.scrollTo({top: document.getElementById(context.getState()?.marketplaceProductId).offsetTop + 1200, behavior: 'smooth' });
                  context.setItemsLoading(false);
                 }, pageSize > 32 ? pageSize * 32 : 1000);
          
                 setTimeout(() => {
                  context.removeProductId();
                 }, pageSize > 32 ? pageSize * 50 : 1500);
              } else {
                context.setItemsLoading(false);
                if (context.getState()?.scrollOnMarketplaceAfterFilter) {
                  setTimeout(() => {
                    if (window.innerWidth > 1080) {
                      scrollToAnchorRef.current.scrollIntoView();
                    } else {
                      // window.scrollTo({top: marketplaceRef.current.getBoundingClientRect().top - 100, behavior: 'smooth' });
                      scrollToAnchorRef.current.scrollIntoView();
                    }
                  }, 100);
                  context.unsetScrollOnMarketplaceAfterFilter();
                }
                if (context.getState()?.scrollOnLoadMore ) {
                  setTimeout(() => {
                    window.scrollTo({top: document.body.scrollHeight - 2180, behavior: 'smooth' });
                  }, 100)
                  context.unsetScrollOnLoadMore();
                }
              }
          }
        );
    }
    
    }, [context.getState()?.isRender]);

  // DISPLAY ALERTS
  useEffect(() => {
    if (searchParams.get('profileDeleted')) {
      setAlert({alert: true, alertText: "Profile has benne deleted!"})
    }

    context.setTopCategoriesLoading(true);
    context.interAxios.get('/item/new',
    function(response) {
      Promise.all(response.data.filter((k, n) => n < 4).map(image => loadImage(image.firstImage !== null ? image.firstImage.url : userShapeImage)))
        .then(() => { 
          setNewItemsList(response.data);
          context.setTopCategoriesLoading(false);
        })
        .catch(err => {
          console.log("Failed to load images", err);
          context.setTopCategoriesLoading(false);
        });
      }
    ); 
  }, []);


  return (
    <div className="marketplace jc-page white" >
      <ImageSlider slides={i18n.language === 'en' ? SlideSampleData : SlideSampleDataDE}/>

      <div className="position-relative">
        <TopcategoryList h2Title={t('marketplace.top_categories')}/>
  
        <div className="new-items-section">
          <ProductScroller
            h2Title={t('marketplace.new_items')}
            enableScrollOnLoading={false} 
            products={newItemsList}
            title='Product Scroller'
          />

          <hr id="marketplace-scroll-to-anchor" ref={scrollToAnchorRef} />
        </div>
  
        <div className="marketplace-section gray-section" ref={marketplaceRef}>
          <div className="container-fluid mw-1300">
            <h1>{t('marketplace.marketplace')}</h1>
            {context.getState()?.topCategoryName !== undefined || context.getState()?.textOrPostcodeFilter ? (          
              <div 
                className={"category-breadcrumbs" + (context.getState()?.topCategoryName ? '' : ' no-category' )}
              >
                <span>
                  {context.getState()?.topCategoryName}
                </span>
  
                <a className="reset-filters" onClick={resetFilters}>
                  {t('marketplace.reset_filters')}
                </a>
              </div>
            ) : false}
            {itemsList ?
            <ProductList enableScrollOnLoading={true} itemsList={itemsList}/>: 
              <div className={"loading-layer shown"}>
                <Player
                  autoplay
                  loop
                  src={loadingAnim}
                  style={{ height: '130px' }}
                >
                </Player>
              </div>
            } 
            
            {moreProductsAvailable && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link"}
                  onClick={loadNewProducts}
                >
                  <i 
                    className={"fa-regular fa-arrow-rotate-right" + (context.getState().itemsLoading ? " spinning" : "")}
                  ></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}
          </div>
        </div>
  
        <MDBAlert
            show={alert.alert}
            color='primary'
            autohide
            position='top-center'
            offset={50}
            delay={4000}
            appendToBody
            onClosed={() => { setAlert({alert: false, alertText: ""})}}
        >{alert.alertText}</MDBAlert>

        </div>

    </div>


  );
};

export default withTranslation()(Marketplace);
