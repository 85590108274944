// react imports
import React,{ useState, useEffect, useMemo } from "react";

// MDBoostrap IMPORTS
import { MDBTooltip } from 'mdb-react-ui-kit';


/**
 * Props:
 * @param text: string
 * @param breakpoints: array of { maxCharacterNumber: number, width?: number }
 * @param className?: string
 * @returns 
 */
const TruncateTooltipText = (props) => {
  // MEMOIZED
  const sortedBPs = useMemo(() => props.breakpoints.sort((a, b) => (a.width - b.width)));
  
  // max character state
  const [maxCharacterNumber, setMaxCharacterNumber] = useState(-1);
  
  // helper funcs
  const getMaxCharacterNumber = () => {
    let mcn_to_set = -1;

    let windowWidth = window.outerWidth < window.innerWidth ? window.outerWidth : window.innerWidth;

    sortedBPs.forEach((breakpoint, index) => {
      if (windowWidth > breakpoint.width) {
        mcn_to_set = props.breakpoints[index].maxCharacterNumber < props.text?.length ? 
          (props.breakpoints[index].maxCharacterNumber - 3) : 
          -1;
      }
    });

    setMaxCharacterNumber(mcn_to_set);
  };

  // COMPONENT MOUNT
  useEffect(() => {
    getMaxCharacterNumber();

    window.addEventListener('resize', getMaxCharacterNumber);
  }, []);
  
  return (
    <span className={props.className}>
      {maxCharacterNumber > -1 && (
        <MDBTooltip
          tag='span'
          title={props.text}
        >
          {props.text.slice(0, maxCharacterNumber) + '...'}
        </MDBTooltip>
      )}

      {maxCharacterNumber === -1 && props.text}
    </span>
  );
};

export default TruncateTooltipText;