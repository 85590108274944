// REACT IMPORTS
import React, { useContext, useRef, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// CUSTOM IMPORTS
import AppContext from '../../../AppContext';
// IMAGE IMPORTS
// import categoryImg1 from "../../../images/categories/category-img-1.jpg";
// import categoryImg2 from "../../../images/categories/category-img-2.jpg";
// import categoryImg3 from "../../../images/categories/category-img-3.jpg";
// import categoryImg4 from "../../../images/categories/category-img-4.jpg";
// import categoryImg5 from "../../../images/categories/category-img-5.jpg";
import topCategorySneakers from "../../../images/top-categories/top-category-sneakers.jpg";
import topCategoryBags from "../../../images/top-categories/top-category-bags.jpg";
import topCategorySmartphones from "../../../images/top-categories/top-category-smartphones.jpg";
import topCategorySports from "../../../images/top-categories/top-category-sports.jpg";
import topCategoryWatches from "../../../images/top-categories/top-category-watches.jpg";

// LOTTIE
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import loadingAnim from "../../../images/loading-anim.json";

// 3RD PARTY IMPORTS
// import Carousel from "react-multi-carousel";
import { register as registerSwiperWebComponent } from 'swiper/element/bundle';
registerSwiperWebComponent(); // https://swiperjs.com/element#install-and-register-from-npm

// custom styles
import "react-multi-carousel/lib/styles.css";

/**
 * Props:
 * @param h2Title?: string
 * @returns
 */
const TopcategoryList = (props) => {
  // STATES
  const [topcategoryList, setTopcategoryList] = useState();


  // REFS
  const swiperElRef = useRef(null);
  const context = useContext(AppContext);
  const navigate = useNavigate();
  // EVENTS
  const slidePrev = () => swiperElRef.current.swiper.slidePrev();
  const slideNext = () => swiperElRef.current.swiper.slideNext();


  const loadImage = image => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image()
      loadImg.src = image
      loadImg.onload = () => resolve(image)
      loadImg.onerror = err => reject(err)
    })
  }

  useEffect(() => {
    context.setTopCategoriesLoading(true);

    context.interAxios.get('/topcategory', {params: {lang: cookies.get('mylanguage').language,}},
      function(response) {
        Promise.all(response.data.map(image => loadImage(image.category.image !== null ? image.category.image.url : userShapeImage)))
        .then(() => {
          setTopcategoryList(response.data);
          context.setTopCategoriesLoading(false);
        })
        .catch(err => {
          console.log("Failed to load images", err);
          context.setTopCategoriesLoading(false);
        });
    });
  }, [cookies.get('mylanguage').language]);

  return (
    <div className="top-categories-section container-fluid mw-1300">
      <h2>{props.h2Title}</h2>
      {topcategoryList ?
        <div className={"top-categories " + ((topcategoryList.length < 4) ? 'center' : '')}>
          {topcategoryList.map((product, n) => (
          <div 
            key={product.id}
            onClick={() => {
              context.onGetTopcategory(product.name, product.category.id, product.category.type);
              context.setScrollOnMarketplaceAfterFilter();
            }}
          >
            <img 
              src={product.category.image.url} 
              
            />
            <label>{product.name}</label>
          </div>
          ))} 
        </div> 
      : 
        <div className={"loading-layer shown"}>
          <Player
            autoplay
            loop
            src={loadingAnim}
            style={{ height: '130px' }}
          >
          </Player>
        </div>
      } 
      
    </div>
  );
};

export default TopcategoryList;
