// REACT IMPORTS
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// 3RD PARTY IMPORTS
import Toggle from "react-toggle";

// CUSTOM COMPONENT IMPORTS
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";
import VatModal from "../../modals/vat-modal/VatModal";
import LanguageModal from "../../modals/language-modal/LanguageModal";
import AppContext from "../../../AppContext";
import Moment from 'moment';

// MDBOOTSTRAP IMPORTS
import { MDBBtn, MDBBtnGroup } from "mdb-react-ui-kit";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const SettingsPage = (props) => {
  // REACT ROUTER 
  const navigate = useNavigate();

  // CONTEXT
  const context = useContext(AppContext);

  // MODAL STATES
  const [pushNotificationModalVisible, setPushNotificationModalVisible] = useState(false);
  const [vatModalVisible, setVATModalVisible] = useState(false);
  const [languageModalVisible, setLanguageModalVisible] = useState(false);

  // STATES
  const [pushNotificationsSetting, setPushNotificationsSetting] = useState(false);
  const [vatSetting, setVatSetting] = useState(false);
  const [clientMe, setClientMe] = useState(false);
  const [verificationState, setVerificationState] = useState(null); // values: AGE_BELOW_13, AJAX, NOT_FOUND, RESTRICTED, ENABLED
  const [verificationLink, setVerificationLink] = useState();

  // MULTILANGUAGE
  const { t } = i18n;

  // HELPER FUNCS
  const getStripeVerificationStatus = () => {
    context.interAxios.get('/stripe/state', {}, function (response) {
        setVerificationState(response.data.state);
      }
    );
  };

  // EVENT HANDLERS
  const clickOnStartVerification = (e) => {
    e.stopPropagation();
    setVerificationState('AJAX');

    context.interAxios.post('/stripe/verify', {}, {}, function (response) {
      setVerificationState('RESTRICTED');

      window.open(response.data.url);
      setVerificationLink(response.data.url);
    }, function(error) {
      
      if (error.response?.data?.message) {
        if (error.response?.data?.message.indexOf('is not a valid phone number') !== -1) {
          const phoneNumber = error.response?.data?.message.split('is not a valid phone number')[0];

          alert(t('misc.not_valid_phone_number', { phoneNumber }));
        } else {
          alert(error.response?.data?.message);
        }                    
      } else {
        alert(error.message);
      }

      setVerificationState('NOT_FOUND');

    });
  };
  const clickOnRefreshVerificationStatus = (e) => {
    e.stopPropagation();
    setVerificationState('AJAX');

    getStripeVerificationStatus();
  };
  const clickOnStripeDashboard = (e) => {
    e.stopPropagation();
    setVerificationState('AJAX');

    context.interAxios.get('/stripe/dashboard', {}, function (response) {
        window.open(response.data.url);
        
        setVerificationLink(response.data.url);
        setVerificationState('ENABLED');
      }
    );
  };

  const onPushNotification = (e) => {
    setPushNotificationsSetting(e.target.checked)
    setPushNotificationModalVisible(e.target.checked)
    onUpdateClient(e.target.checked, vatSetting);
  }

  const onVat = (e) => {
    setVatSetting(e.target.checked)
    onUpdateClient(pushNotificationsSetting, e.target.checked);
  }

  const onUpdateClient = (push, vat) => {
    context.interAxios.put('/client/me',
      {
        clientType: clientMe.clientType,
        lastName: clientMe.lastName,
        firstName: clientMe.firstName,
        publicName: clientMe.publicName,
        companyName: clientMe.companyName,
        email: clientMe.email,
        birthDay: clientMe.birthDay,
        phoneNumber: clientMe.phoneNumber,
        address: {
          location: clientMe.address.location,
          countryCode: clientMe.address.countryCode,
          postcode: clientMe.address.postcode,
          address: clientMe.address.address
        },
        vat: vat,
        notifications: push,
        returnPolicy: clientMe.returnPolicy,
        termsAndConditionsVersionAccepted: clientMe.termsAndConditionsVersionAccepted,
        privacyPolicyVersionAccepted: clientMe.privacyPolicyVersionAccepted
      }, function(response) {
        cookies.set('mylocation', {
            location: clientMe.address.location,
            clientType: clientMe.clientType,
            clientId: response.data.id,
            clientImageUrl: clientMe.image?.url,
            clientEmail: clientMe.email,
            clientName: clientMe.publicName,
            phoneNumber: clientMe.phoneNumber,
            notifications: push,
            clientIsVat: vat
          }, { path: '/'}
        );
    });
  }

  useEffect(() => {
    if(!context.isLoggedIn()) {
      // redirect if not logged in
      navigate('/marketplace');
    }
    
    context.interAxios.get('/client/me', function(response) {
      setClientMe(response.data);
      setPushNotificationsSetting(response.data.notifications)
      setVatSetting(response.data.vat)
      const ageInYears = Moment().diff(response.data.birthDay, 'years', true);
      if (ageInYears < 13) {
        setVerificationState('AGE_BELOW_13');
        return;
      }
      getStripeVerificationStatus();
    });
  }, []);

  return (
    <div className="jc-page gray-bc settings">
      <div className="container-fluid mw-1040">
        <h1>{t('misc.settings')}</h1>

        <div className="white-panel">
          <div 
            className="profile-link pointer" 
            onClick={() => navigate('/profile/edit')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-pencil"></i>
            </div>
            {t('user_settings.edit_profile')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          <div 
            className="profile-link pointer"
            onClick={() => setLanguageModalVisible(true)}
          >
            <div className="first-icon">
              <i className="fa-regular fa-globe"></i>
            </div>
            {t('misc.language')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          <div 
            className="profile-link pointer"
            onClick={() => navigate('/faq')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-message-question"></i>
            </div>
            {t('misc.faq')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          <div 
            className="profile-link pointer"
            onClick={() => navigate('/terms-and-conditions')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-file"></i>
            </div>
            {t('misc.terms_and_conditions')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          <div 
            className="profile-link pointer"
            onClick={() => navigate(cookies.get('mylanguage').language === "en" ? '/legal-notices#privacy-policy' : '/legal-notices#datenschutz')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-file"></i>
            </div>
            {t('misc.privacy_policy')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          <div 
            className="profile-link pointer"
            onClick={() => navigate('/profile/change-password')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-gear"></i>
            </div>
            {t('user_settings.change_password')}

            <i className="fa-regular fa-angle-right"></i>
          </div>

          {/* <div 
            className="profile-link pointer"
            onClick={() => navigate('/jc-world-support')}
          >
            <div className="first-icon">
              <i className="fa-regular fa-circle-info"></i>
            </div>
            {t('misc.jc_world_support')}

            <i className="fa-regular fa-angle-right"></i>
          </div> */}

          <div className="profile-link">
            <div className="first-icon">
              <i 
                className="fa-regular fa-bell"
                style={{position: 'relative', top: '2px'}}
              ></i>
            </div>
            <div className="profile-link-inner">
              {t('user_settings.push_notifications')}
              <i className="fa-regular fa-info-circle" onClick={() => setPushNotificationModalVisible(true)}></i>
            </div>

            <Toggle
              checked={pushNotificationsSetting}
              name='pushNotifications'
              id='pushNotifications'
              onChange={e => onPushNotification(e)}
              className="jc-toggle"
            />
          </div>

          <div className="profile-link">
            <div className="first-icon">
              <i 
                className="fa-regular fa-ellipsis"
                style={{position: 'relative', top: '3px'}}
              ></i>
            </div>
            <div className="profile-link-inner inline">
              {t('user_settings.im_required_to_pay_vat')}
              <i className="fa-regular fa-info-circle" onClick={() => setVATModalVisible(true)}></i>
            </div>

            <Toggle
              checked={vatSetting}
              name='vat'
              id='vat'
              onChange={e => onVat(e)}
              className="jc-toggle"
            />
          </div>

          <div className="profile-link stripe">
            <div className="stripe-link">
              <div className="first-icon">
                <i className="fa-regular fa-credit-card"></i>
              </div>
              {t('user_settings.stripe_verification')}
  
              {verificationState === 'AJAX' && (
                <i className="fa-regular fa-arrows-rotate spinning"></i>
              )}
  
              {verificationState === 'NOT_FOUND' && (
                <MDBBtn 
                  className="jc-btn brown"
                  type="button"
                  onClick={clickOnStartVerification}
                >
                  <i className="fa-brands fa-stripe-s"></i>
                  {t('user_settings.start')}
                </MDBBtn>
              )}
  
              {verificationState === 'RESTRICTED' && (
                <MDBBtnGroup>
                  <MDBBtn
                    className="jc-btn brown"
                    type="button"
                    onClick={clickOnStartVerification}
                  >
                    <i className="fa-brands fa-stripe-s"></i>
                    {t('user_settings.start_again')}
                  </MDBBtn>
                  <MDBBtn
                    className="jc-btn brown"
                    type="button"
                    onClick={clickOnRefreshVerificationStatus}
                  >
                    <i className="fa-regular fa-arrows-rotate"></i>
                    {t('user_settings.refresh_status')}
                  </MDBBtn>
                </MDBBtnGroup>
              )}
  
              {verificationState === 'ENABLED' && (
                <MDBBtn
                  className="jc-btn brown enabled"
                  type="button"
                  onClick={clickOnStripeDashboard}
                >
                  <i className="fa-solid fa-gear"></i>
                  {t('user_settings.dashboard')}
                </MDBBtn>
              )}

              {verificationState === 'AGE_BELOW_13' && (
                <div className="helper-text">
                  {t('user_settings.age_13_required_for_stripe')}
                </div>
              )}
            </div>

            {verificationLink && (
              <a 
                className="brown-link mt-2" 
                href={verificationLink} 
                target="_blank"
                onClick={() => setVerificationLink('')}
              >
                {t('user_settings.click_here_if_didnt_open')}
              </a>
            )}

            {/* {verificationError && (
              <div className="error">
                {verificationError}
              </div>
            )} */}
          </div>
        </div>
      </div>

      <LanguageModal
        visible={languageModalVisible}
        hideModal={() => setLanguageModalVisible(false)}
        languageChanged={newLanguage => console.log(`Language has changed to ${newLanguage}`)}
      />

      <GeneralInfoModal
        visible={pushNotificationModalVisible}
        hideModal={() => setPushNotificationModalVisible(false)}
        infoIcon="fa-regular fa-info-circle"
        title={t('general_modals.push_notifications.title')}
        text={t('general_modals.push_notifications.text')}
      />

      <VatModal
        visible={vatModalVisible}
        hideModal={() => setVATModalVisible(false)}
      />
    </div>
  );
};

export default withTranslation()(SettingsPage);
