// REACT IMPORTS
import React from "react";

// IMAGE IMPORTSgooglePlayBadge
import appStoreImg from "../../../images/footer/Appstore-badge-EN.svg";
import googlePlayImg from "../../../images/footer/GooglePlay-badge-EN.svg";

/**
 * Props:
 * @param type: 'APP_STORE' | 'GOOGLE_PLAY'
 */

const MobileButton = ({type}) => {
  return (
    <button 
      className="mobile-store-link"
      onClick={() => {
        window.location.href = type === 'APP_STORE' ? 
          'https://apps.apple.com/us/app/j-c-world/id6449838203' : 
          'https://play.google.com/store/apps/details?id=com.swisstomato.jncworld';
      }}
    >
      {type === 'APP_STORE' && (
        <img src={appStoreImg} alt="App Store Link"/>
      )}

      {type === 'GOOGLE_PLAY' && (
        <img src={googlePlayImg} alt="Google Play Link"/>
      )}
    </button>
  );
};

export default MobileButton;