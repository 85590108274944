// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CONTEXT
import AppContext from '../../../AppContext';

// 3RD PARTY IMPORTS
import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param productId: number
 * @param sizeId: number
 * @returns
 */
const PleaseLoginModal = (props) => {
  const context = useContext(AppContext);

  // NAVIGATION HOOK
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  // EVENTS
  const clickOnLoginButton = () => {
    props.hideModal();

    // saving product item, so the user can be redirected after logging in
    cookies.set('redirect_to_after_logging_in', {
      path: `/checkout/${props.productId}/${props.sizeId}`,
      validUntil: new Date(new Date().getTime() + 15 * 60000) // set validation for only 15 minutes
    }, { 
      path: '/'
    });

    setTimeout(() => {
      context.openLoginModal();
    }, 300);
  };

  return (
    <MDBModal
      className="standard-jc-modal w-615 please-login"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('general_modals.please_login.title')}</h3>

            <p>{t('general_modals.please_login.text')}</p>

            <button
              className="jc-btn black"
              onClick={clickOnLoginButton}
            >
              {t('general_modals.please_login.button_text')}
            </button>

            <a
              className="brown-link"
              onClick={() => navigate(`/checkout/${props.productId}/${props.sizeId}`)}
            >
              {t('general_modals.please_login.checkout_without_login')}
            </a>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(PleaseLoginModal);
