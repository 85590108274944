// react imports
import React from "react";

/**
 * Usage: setValue or onChangeHandler is required. setValue is for normal controlled input, onChangeHandler for formik controlled input
 * Props:
 * @param value: string
 * @param setValue?: (newValue) => void
 * @param onChangeHandler?: (e) => void
 * @param onBlurHandler?: (e) => void
 * @param maxCharacterNumber?: number
 * @param placeholder?: string
 * @param label?: string
 * @param id?: string
 * @param name?: string
 * @param rows?: number (default: 4)
 * @returns 
 */
const JCTextarea = ({
  value, 
  setValue, 
  onBlurHandler, 
  maxCharacterNumber, 
  placeholder,
  label, 
  id,
  name,
  rows
}) => {
  const onChange = (e) => {
    if (maxCharacterNumber && e.target.value.length >= maxCharacterNumber) {
      if (e.nativeEvent.inputType === 'insertFromPaste') {
        setValue(e.target.value.substring(0, maxCharacterNumber));
      }

      return;
    }

    setValue(e.target.value);
  };

  return (
    <div className="jc-textarea">
      {label && (
        <label>
          {label}
        </label>
      )}

      <div className="textarea-wrapper">
        {maxCharacterNumber && (
          <span className="character-counter">
            {value.length} / {maxCharacterNumber}
          </span>
        )}

        <textarea 
          className="form-control"
          rows={rows ? rows : 4}
          value={value}
          onChange={onChange}
          onBlur={onBlurHandler}
          id={id}
          name={name}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default JCTextarea;