// react imports
import React, { useContext } from "react";

// MDBOOTSTRAP IMPORTS
import { MDBCollapse, MDBBtn, MDBTooltip } from 'mdb-react-ui-kit';

// IMAGES IMPORT
import signupAppleIcon from '../../../images/signup-modal/apple-icon.svg';
import signupFacebookIcon from '../../../images/signup-modal/facebook-icon.png';
import signupGoogleIcon from '../../../images/signup-modal/google-icon.png';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

//Sociallogin

import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import axios from "axios";
import { backendUrl } from '../../../env';

import { FacebookAppId, AppleLogin } from '../../../data/loginkeys';
import AppContext from '../../../AppContext';

/**
 *
 * @param headerContent: JSX
 * @param visible: boolean
 * @param infoText: string
 * @param openArea: () => void
 * @param closeArea: () => void
 * @param clickOnSignUp: () => void
 * @param clickOnApple: () => void
 * @param clickOnGoogle: () => void
 * @param clickOnFacebook: () => void
 * @returns
 */
const BlackCollapsibleArea = (props) => {
  // MULTILANGUAGE
  const { t } = i18n;

  const context = useContext(AppContext);

//Login with Google
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log(tokenResponse);
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
  });


  return (
    <div className={"black-collapsible-area" + (props.visible ? ' opened' :'') + ` ${i18n.language}`}>
      <header onClick={() => (props.visible ? props.closeArea() : props.openArea())}>
        <div>
          {props.headerContent}
        </div>

        <MDBTooltip tag='span'  title={props.infoText}>
          <i 
            className="fa-regular fa-info-circle"
            onClick={(e) => e.stopPropagation()}
          ></i>
        </MDBTooltip>
      </header>

      <MDBCollapse show={props.visible}>
        <div className="black-area-content">
          <button className="jc-btn white" onClick={() => props.clickOnSignUp()}>
            {t('signup_modal.sign_up_with_email')}
          </button>

          <hr data-centered-content={t('login_modal.or')} className="black-bc" />

          <div className="signup-buttons">
          <AppleSignin
          authOptions={{
            clientId: AppleLogin.clientId,
            scope: AppleLogin.scope,
            redirectURI: AppleLogin.redirectURI,
            state: AppleLogin.state,
            nonce: AppleLogin.nonce,
            usePopup: AppleLogin.usePopup,
          }}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          buttonExtraChildren="Continue with Apple"
          onSuccess={(response) => {
            console.log(response);
            axios.post(backendUrl() +'/auth/apple', { 'token': response.authorization.id_token }, { headers: { 'Content-type': 'application/json'}}).then(function(response) {
              context.onSignIn(response.data);
              context.closeLoginModal();
            })
          }}
          onError={(error) => console.error(error)}
          skipScript={false}
          render={(props) => <button className="apple" {...props}>
                                <img
                                  src={signupAppleIcon}
                                  alt="Apple logo"
                                  style={{position: 'relative', bottom: '1px'}}
                                />
                              </button>}
        />
 
        <button className="google" onClick={() => googleLogin()}>
          <img src={signupGoogleIcon} alt="Google logo" />
        </button>
        
        <FacebookLogin
            appId= {FacebookAppId.appId}
            style={{
              backgroundColor: 'transparent',
              color: '#fff',
              fontSize: '17px',
              padding: 0,
              border: "none",
              borderRadius: "15px",
            }}
            children={<button className="facebook">
                        <img src={signupFacebookIcon} alt="Facebook logo" />
                      </button>} 
            onSuccess={(response) => {
                console.log('Login Success!', response);
                context.interAxios.post('/auth/facebook', {token: response.accessToken}, function(response) {
                  context.onSignIn(response.data);
                  context.closeLoginModal();
                });
            }}
            onFail={(error) => {
                console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
                console.log(response);
            }}
        />
        
          </div>
        </div>
      </MDBCollapse>
    </div>
  );
};

export default withTranslation()(BlackCollapsibleArea);
