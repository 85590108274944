import React, { Component, PureComponent } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { MDBContainer,
         MDBBtn,
         MDBModal,
         MDBModalBody,
         MDBModalHeader,
         MDBModalFooter,
         MDBModalDialog,
         MDBModalContent,
         MDBModalTitle,
       } from 'mdb-react-ui-kit';


// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
const { t } = i18n;


class CreateImageModal extends PureComponent {

  constructor(props) {
    super(props);
    this.inputFotoRef = React.createRef();
    this.state = {
        src: null
    }
  }

  componentDidMount() {
    this.onSelectFile(this.props.imageFile)
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width; //1080 / 402
    const scaleY = image.naturalHeight / image.height; //396 / 147
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {

      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({url: this.fileUrl, blob: blob});
      }, 'image/jpeg');
    });
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({crop: undefined})
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = event => {
    if(event.target.naturalWidth > 600 && event.target.naturalHeight > 300){
      const initialCrop = {
        x: 0,
        y: 0,
        width: event.target.height > event.target.width ?  event.target.width*0.75 : event.target.height*0.75,
      }

      this.setState({
        imageRef: event.target,
        crop: makeAspectCrop(initialCrop, 1)
      })
    }else{
      this.props.setShowCreateImageModal(false);
      alert("Image size not correct. Please add at least 600px wide image.")
    }

  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.state.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.state.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.onMakeCroppedImage(croppedImage);
      this.setState({ croppedImageUrl: croppedImage.url});
    }
  }

  internalHandleSave = () => {
    this.props.onSelectImage(this.state.croppedImage.blob);
    this.props.setShowCreateImageModal(false);
  }

  internalHandleCancel = () => {
      this.props.setShowCreateImageModal(false);
  }


  onMakeCroppedImage = (croppedImage) => {
    this.setState({croppedImage : croppedImage})
  }

  handleChooseFoto = () => {
    this.inputFotoRef.current.click();
  };

  render() {
    return (
      <div className="modaltemplate-scss">
        <MDBModal tabIndex='-1' show={this.props.showCreateImageModal} id='profileImage'>
          <MDBModalDialog centered  scrollable>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{t('general_modals.create_image.title')}</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>

            <div>

            {this.state.src && (
              <ReactCrop
                ruleOfThirds
                locked
                onChange={this.onCropChange}
                crop={this.state.crop}
                onComplete={this.onCropComplete}
                aspect={true}
              >
                <img alt="Crop" style={{ maxWidth: '100%'}}
                                src={this.state.src}
                                onLoad={this.onImageLoaded}
                                />
              </ReactCrop>
            )}
            {this.state.croppedImageUrl && (
              <div className="d-flex align-items-center justify-content-center">
              <img alt="Crop" style={{ maxWidth: '60%' }} src={this.state.croppedImageUrl} />
              </div>
            )}
            </div>

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="button" className="imagecrop-jc-btn transparent" onClick={this.internalHandleCancel}>
                {t('general_modals.create_image.cancel_button_text')}
              </MDBBtn>
              {this.state.croppedImage!==undefined && (
                <MDBBtn type="button" className="imagecrop-jc-btn black" onClick={this.internalHandleSave}>
                  {t('general_modals.create_image.back_button_text')}
                </MDBBtn>
              )}
              
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
    );
  }
}

export default withTranslation()(CreateImageModal);

