import profileBig from '../images/profile-big.jpg';

import profile1Img from '../images/comment-profile-1.jpg';
import profile2Img from '../images/comment-profile-2.jpg';
import profile3Img from '../images/comment-profile-3.jpg';

export const userData = {
  firstName: 'Kierra',
  lastName: 'Rosser',
  publicName: 'kierra.rosser',
  emailAddress: 'kierra.rosser@gmail.com',
  phoneNumber: '+41 32111 223',
  birthday: '2000-01-01',
  address: '14 Rue de Etorneaux',
  postalCode: '1244',
  city: 'Geneva',
  vat: true,
  imageUrl: profileBig,
  returnPolicy: 'https://google.com',
  businessProfile: true
};

export const Users = [
  {
    userId: 0,
    imageUrl: profile1Img,
    name: 'Jocelyn Press',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 1,
    imageUrl: profile2Img,
    name: 'Maria Dorwart',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 2,
    imageUrl: profile3Img,
    name: 'Maren Dokidis',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 3,
    imageUrl: profile1Img,
    name: 'Mira Saris',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 4,
    imageUrl: profile2Img,
    name: 'Kaylynn George',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 5,
    imageUrl: profile3Img,
    name: 'Aspen Lubin',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 6,
    imageUrl: profile1Img,
    name: 'Maria Calzoni',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 7,
    imageUrl: profile2Img,
    name: 'Jocelyn Press',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 8,
    imageUrl: profile3Img,
    name: 'Maren Dokidis',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 9,
    imageUrl: profile1Img,
    name: 'Kaylynn George',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 10,
    imageUrl: profile2Img,
    name: 'Mira Saris',
    rating: Math.floor(Math.random()*5)
  },
  {
    userId: 11,
    imageUrl: profile3Img,
    name: 'Maria Dorwart',
    rating: Math.floor(Math.random()*5)
  },
];
