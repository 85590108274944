// react imports
import React, { useState, useContext, useRef, useEffect } from "react";

// react router dom
import { useNavigate } from "react-router";
import AppContext from '../../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// CUSTOM IMPORTS
import JCTextarea from "../../form-components/jc-textarea/JCTextarea";

// 3RD PARTY IMPORTS
import { Formik } from "formik";
import * as Yup from 'yup';

// prime mdbootstrap imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBSelect,
  MDBTextArea
} from 'mdb-react-ui-kit';


// HELPER FUNCS
import { getSingleSelectChangeFunc } from "../../../data/helper-funcs";


const initialreasons = [
  { text: 'Select', value: '', defaultSelected: true },
  { text: 'One', value: 'One' },
  { text: 'Two', value: 'Two' },
  { text: 'Three', value: 'Three' },
  { text: 'Four', value: 'Four' },
  { text: 'Five', value: 'Five' },
];

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param reasons: array of {
 *  text: string,
 *  value: string,
 *  defaultSelected?: boolean
 * }
 * @returns
 */
const ReportModal = (props) => {
  // STATES
  const [reason, setReason] = useState('');
  const [reasonObject, setReasonObject] = useState([]);
  const [reasonTouched, setReasonTouched] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const [reportSending, setReportSending] = useState(false);
  const [reportSent, setReportSent] = useState(false);

  // MULTILANGUAGE
  const { t } = i18n;
  const context = useContext(AppContext);

  const reportSentEvent = () => {
    setReportSending(false);
    setReportSent(true);

    setTimeout(() => {
      props.hideModal();
      
      setTimeout(() => {
        setDescription('');
        setReason(reasonObject[0].value);
        setReasonTouched(false);
        setDescriptionTouched(false);
        setReportSent(false);
      }, 300);
    }, 3000);
  };
  const sendReport = () => {
    setReportSending(true);

    if(props.type === "ITEM"){
      context.interAxios.post('/item/' + props.id + '/report',
        {
          reasonId: reason,
          content: description
        },
        reportSentEvent
      );
    }

    if(props.type === "CLIENT"){
      context.interAxios.post('/client/' + props.id + '/report',
        {
          reasonId: reason,
          content: description
        },
        reportSentEvent
      );
    }

    if(props.type === "REVIEW"){
      context.interAxios.post('/review/' + props.id + '/report',
        {
          reasonId: reason,
          content: description
        },
        reportSentEvent
      );
    }    
  }

  useEffect(() => {
    context.interAxios.get('/report-reason',
              {params: {
                lang: cookies.get('mylanguage').language,
                type: props.type
              }},
    function(response) {
      setReasonObject(response.data.map(k => {return ( {text: k.text, value: k.id})}))
      setReason(response.data[0].id);
    });
  }, [cookies.get('mylanguage').language]);
  
  return (
    <MDBModal
      className="standard-jc-modal report"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            {!reportSent && (
              <>
                <h3>
                  {props.type === "REVIEW" && t('general_modals.report.title_report_review')}
                  {props.type === "ITEM" && t('general_modals.report.title_report_item')}
                  {props.type === "CLIENT" && t('general_modals.report.title_report_profile')}
                </h3>

                <MDBSelect
                  className="top-label big"
                  label={t('general_modals.report.report_reason')}
                  data={reasonObject}
                  onValueChange={sv => {
                    setReason(sv.value);
                    setReasonTouched(true);
                  }}
                />

                {reasonTouched && !reason && (
                  <div className="invalid-feedback">{t('error_messages.reason_needed')}</div>
                )}

                <JCTextarea
                  value={description}
                  setValue={(newValue) => {
                    setDescriptionTouched(true);
                    setDescription(newValue);
                  }}
                  maxCharacterNumber={200}
                  label={t('general_modals.report.description')}
                />

                {descriptionTouched && !description && (
                  <div className="invalid-feedback">{t('error_messages.description_required')}</div>
                )}

                <button
                  className="jc-btn black"
                  onClick={() => {
                    setReasonTouched(true);
                    setDescriptionTouched(true);

                    if (!reason || !description) {
                      return;
                    }

                    sendReport();
                  }}
                >
                  {reportSending && (
                    <i className="fa-regular fa-spinner-third"></i>
                  )}

                  {!reportSending && t('general_modals.report.submit')}
                </button>
              </>
            )}

            {reportSent && (
              <>
                <h3>{t('general_modals.report.report_sent_title')}</h3>
                <p className="finished">{t('general_modals.report.report_sent_text')}</p>
              </>
            )}

          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(ReportModal);
