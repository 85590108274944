// react imports
import React, { useContext, useState, useEffect } from "react";
import AppContext from '../../../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import {
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-react-ui-kit';


const HeaderLanguageSelector = (props) => {
  // STATES
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'EN' : 'DE');
  // REFS
  const context = useContext(AppContext);
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language)
    if(language === 'EN'){
      context.toEnglish();
    }else{
      context.toGerman();
    }
  };

  useEffect(() => {
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('EN')
    }else{
      setLanguage('DE')
    }
    context.onRender();
  }, [context.getState()?.isRender]);

  return (
    <MDBNavbarItem>
      <MDBDropdown className="language">
        <MDBDropdownToggle tag='a' className='nav-link' role='button'>
          {language}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem link childTag='button' onClick={() => getLanguage(language === 'EN' ? 'DE' : 'EN')}>
            {language === 'EN' ? 'DE' : 'EN'}
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    </MDBNavbarItem>
  );
};

export default HeaderLanguageSelector;
