// react imports
import React, { useState, useContext, useEffect } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';
import AppContext from "../../../AppContext";
import i18n from "../../../i18n";
import Moment from 'moment';
import { renderTextWithNewLines } from "../../../data/helper-funcs";
import JCInput from "../../form-components/jc-input/JCInput";
import JCTextarea from "../../form-components/jc-textarea/JCTextarea";


/**
 * Props
 * @param visible: boolean
 * @param hideModal: () =>  {}
 * @param recipient: { id: number, publicName: string }
 * @param subject: string
 * @param orderId: number
 * @returns
 */
const MessageSendModal = (props) => {
  const context = useContext(AppContext);

  // MULTILANGUAGE
  const { t } = i18n

  // STATE
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('NOT_SENT'); // NOT_SENT, SENDING, SENT

  // helper funcs
  const resetState = () => {
    setMessage('');
    setMessageState();
  };
  
  useEffect(() => {
    resetState();
  }, [props.orderId]);
  return (
    <MDBModal
      className="standard-jc-modal w-615 message-send-modal"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={() => {
        props.hideModal();
        resetState();
      }}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none'  type='button' onClick={() => {props.hideModal(); resetState()}}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <h3>{t('messages.send_message')}</h3>

            {messageState !== 'SENT' && (
              <>
                <div className="message-infos">
                  <div>
                    <strong>{t('messages.to')}: </strong> {props.recipient?.publicName}
                  </div>

                  <div>
                    <strong>{t('messages.subject')}: </strong> {props.subject}
                  </div>
                </div>

                <JCTextarea
                  value={message}
                  setValue={setMessage}
                  label={t('messages.text')}
                />

                <button
                  className="jc-btn black"
                  disabled={!message}
                  onClick={() => {
                    setMessageState('SENDING');
                    context.onNewMessage(props.recipient?.id, props.orderId, message)
                    setTimeout(() => {
                      setMessageState('SENT');
                    }, 2000);
                  }}
                >
                  {messageState === 'SENDING' && (
                    <i className="fa-regular fa-spinner-third"></i>
                  )}

                  {messageState !== 'SENDING' && t('messages.send')}
                </button>
              </>
            )}

            {messageState === 'SENT' && (
              <p className="brown">
                {t('messages.message_sent')}
              </p>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default MessageSendModal;
