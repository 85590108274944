// REACT IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

const scrolled = false;

const TermsAndConditionsPage = (props) => {
  const context = useContext(AppContext);
  // NAVIGATION
  const { hash } = useLocation();
  const navigate = useNavigate();

  //STATES
  const [termsAndConditions, setTermsAndConditions] = useState('');

  // MULTILANGUAGE
  const { t } = i18n;
  
  useEffect(() => {
    context.interAxios.get('/terms-and-conditions/html', 
      { 
        params: {
          lang: cookies.get('mylanguage').language
        }
      },
      function(response) {
        setTermsAndConditions(response.data);
      }
    );
  }, [context.getState()?.isRender]);
  
  

  return (
    <div className="jc-page gray-bc generic-text-page">
      <div className="container-fluid mw-1040">
        <h1 data-german="Rechtliche Hinweise">
          <i 
            className="fa-regular fa-arrow-left"
            onClick={() => navigate(-1)}
          ></i>
          {t('misc.terms_and_conditions')}
        </h1>
        
        <div className="white-panel">
          <div  className="text-terms-and-conditions" dangerouslySetInnerHTML={{__html: termsAndConditions !== undefined ? termsAndConditions.replaceAll('target="_blank"', '') : <></>}}></div>
          
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;