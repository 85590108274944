// REACT
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// IMAGE IMPORTS
import sellItemHeroImg from "../../../images/sell-item-header.jpg";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CUSTOM IMPORTS
import ItemForm from "../../form-components/item-form/ItemForm";
import AppContext from "../../../AppContext";


const SellItemPage = () => {
  const { t } = i18n;
  const navigate = useNavigate();
  const context = useContext(AppContext);

  // CHECKING USER
  useEffect(() => {
    // LOGGED IN?
    if (!context.isLoggedIn()) {
      navigate('/marketplace');
    }

    // TODO: EMAIL VERIFICATION
    // if (VERIFICATION) {
      // navigate('/marketplace');
    // }
  }, []);

  return (
    <div className="jc-page gray-bc sell-item">
      <div className="container-fluid mw-1040 hero-image">
        <img src={sellItemHeroImg} />
      </div>

      <div className="container-fluid mw-1040">
        <h1>
          {t('product_form.sell_item_title')}
        </h1>

        <ItemForm />
      </div>
    </div>
  );
};

export default withTranslation()(SellItemPage);
