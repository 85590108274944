// REACT IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

const scrolled = false;

const ContactPage = (props) => {
  const context = useContext(AppContext);
  // NAVIGATION
  const { hash } = useLocation();
  const navigate = useNavigate();

  // MULTILANGUAGE
  const { t } = i18n;

  //STATES
  const [content, setContent] = useState('');
  
  useEffect(() => {
    context.interAxios.get('/content/contact/html', 
      { 
        params: {
          lang: cookies.get('mylanguage').language
        }
      },
      function(response) {
        setContent(response.data);
      }
    );
  }, [context.getState()?.isRender]);
  
  

  return (
    <div className="jc-page gray-bc generic-text-page">
      <div className="container-fluid mw-1040">
        <h1 data-german="Rechtliche Hinweise">
          <i 
            className="fa-regular fa-arrow-left"
            onClick={() => navigate(-1)}
          ></i>
          {t('misc.contact')}
        </h1>
        
        <div className="white-panel">
          <div  className="text-terms-and-conditions" dangerouslySetInnerHTML={{__html: content}}></div>
          
        </div>
      </div>
    </div>
  );
};

export default ContactPage;