// react imports
import React, { useState, useEffect, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// CUSTOM IMPORTS
import FollowUserItem from "./FollowUserItem";
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


/**
 * Props
 * @param state: false | 'Follower' | 'Following'
 * @param hideModal: () => void
 * @param followers: array of {
 *  userId: number,
 *  imageUrl: string,
 *  name: string,
 *  rating: number
 * }
 * @param followings?: array of {
 *  userId: number,
 *  imageUrl: string,
 *  name: string,
 *  rating: number
 * }
 * @param followingTabShownDefault?: boolean
 * @param userId: number
 * @returns
 */
const FollowUsersModal = (props) => {
  const context = useContext(AppContext);

  // STATES
  const [selectedTab, setSelectedTab] = useState('Followers');
  const [displayAllFollowers, setDisplayAllFollowers] = useState(false);
  const [displayAllFollowings, setDisplayAllFollowings] = useState(false);

  // MULTILANGUAGE
  const { t } = i18n;

  // TEMPLATES
  const getFollowerList = () => (
    <div className="follow-list">
      {/*<>}
      {!displayAllFollowers && (
        <>
          {props.followers.slice(0, 7).map(follower => (
            <FollowUserItem
              key={follower.userId}
              {...follower}
              displayFollowButton={true}
              followed={Math.random() < 0.5}
            />
          ))}
          <a className="gray-link" onClick={() => setDisplayAllFollowers(true)}>
            <i className="fa-regular fa-arrow-rotate-right"></i>
            {t('misc.load_more')}
          </a>
        </>
      )}
      {displayAllFollowers && props.followers.map(follower => (
        <FollowUserItem
          key={follower.userId}
          {...follower}
          displayFollowButton={true}
          followed={Math.random() < 0.5}
        />
      ))}
      </>*/}
      {props.followers.map(follower => (
        <FollowUserItem
          key={follower.id}
          {...follower}
          displayFollowButton={true}
          hideModal={props.hideModal}
          followed={props.followed}
        />
      ))}
      {props.moreProductsAvailableFollowers && (
        <div className="product-list pt-4">
          <button
            className={"gray-link" + (props.loadingNewProductsFollowers ? " loading" : "")}
            onClick={props.loadNewProductsFollowers}
          >
            <i className="fa-regular fa-arrow-rotate-right"></i>
            {t('misc.load_more')}
          </button>
        </div>
      )}
    </div>
  );
  const getFollowingList = () => (
    <div className="follow-list">
      {/*<>}
      {!displayAllFollowings && (
        <>
          {props.followings.slice(0, 7).map(following => (
              <FollowUserItem
                key={following.userId}
                {...following}
              />
            )
          )}
          <a className="gray-link" onClick={() => setDisplayAllFollowings(true)}>
            <i className="fa-regular fa-arrow-rotate-right"></i>
            {t('misc.load_more')}
          </a>
        </>
      )}
      {displayAllFollowings && props.followings.map(following => (
        <FollowUserItem
          key={following.userId}
          {...following}
        />
      ))}</>*/}
      {props.followings.map(following => (
        <FollowUserItem
          key={following.id}
          displayFollowButton={true}
          {...following}
        />
      ))}
      {props.moreProductsAvailableFollowings && (
        <div className="product-list pt-4">
          <button
            className={"gray-link" + (props.loadingNewProductsFollowings ? " loading" : "")}
            onClick={props.loadNewProductsFollowings}
          >
            <i className="fa-regular fa-arrow-rotate-right"></i>
            {t('misc.load_more')}
          </button>
        </div>
      )}
    </div>
  );

  // EVENTS
  const getClickOnTabFunc = (tabName, numberOfUsers) => () => {
    if (numberOfUsers < 1) {
      return;
    }

    setSelectedTab(tabName);
  };

  useEffect(() => {
    if (props.state !== false) {
      setSelectedTab(props.state);
    }
  }, [props.state])

  // HELPER FUNCS
  const userIsLoggedIn = context.isLoggedInIdIs(props.userId);
  return (
    <MDBModal
      className="standard-jc-modal follow-modal"
      show={props.state !== false}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            {props.followings && (
              <>
                <div className="jc-tabs">
                  <div
                    className={"jc-tab" + (selectedTab === 'Followers' ? ' active' : '') + (!props.followers.length ? ' disabled' : '')}
                    onClick={getClickOnTabFunc('Followers', props.followersCount)}
                  >
                    {t('general_modals.follow_users.followers_tab')} ({ props.followers.length})
                  </div>
                  <div
                    className={"jc-tab" + (selectedTab === 'Following' ? ' active' : '') + (!props.followings.length ? ' disabled' : '')}
                    onClick={getClickOnTabFunc('Following', props.followingsCount)}
                  >
                    {t('general_modals.follow_users.following_tab')} ({ props.followingsCount})
                  </div>
                </div>

                {selectedTab === 'Followers' && getFollowerList()}
                {selectedTab === 'Following' && getFollowingList()}
              </>
            )}


            {!props.followings &&
              <>
                <h3>{t('general_modals.follow_users.followers_tab')}</h3>

                {getFollowerList()}
              </>
            }
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(FollowUsersModal);
