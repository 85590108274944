import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
// LAYOUT, PAGE IMPORTS
import Layout from './components/layout/Layout';
import Home from './components/pages/home/Home';
import SignUpPage from './components/pages/sign-up/SignUpPage';
import ProfileSettingsPage from './components/pages/profile/ProfileSettingsPage';
import ProfileEditPage from './components/pages/profile/ProfileEditPage';
import ProfileChangePasswordPage from './components/pages/profile/ProfileChangePasswordPage';
import MarketplacePage from './components/pages/martketplace/MarketplacePage';
import FAQPage from './components/pages/profile/FAQ';
import ShoppingListPage from './components/pages/shop/ShoppingListPage';
import CheckoutPage from './components/pages/shop/CheckoutPage';
import CheckoutConfirmationPage from './components/pages/shop/CheckoutConfirmationPage';
import ProductPage from './components/pages/product/ProductPage';
import JCWorldSupport from './components/pages/static-pages/JCWorldSupport';
import UserProfilePage from './components/pages/user-profile/UserProfilePage';
import ProfilePage from './components/pages/profile/ProfilePage';
import ProfileMyItemPage from './components/pages/profile/ProfileMyItemPage';
import SellItemPage from './components/pages/profile/SellItemPage';
import LegalNoticesPage from './components/pages/static-pages/LegalNotices';
import Payment from './components/stripe/Payment';
import AboutUsPage from './components/pages/static-pages/AboutUsPage';
import DataDeletionRequestPage from './components/pages/static-pages/DataDeletionRequestPage';
import SellItemConfirmationPage from './components/pages/profile/SellItemConfirmationPage';
import EditItemPage from './components/pages/profile/EditItemPage';
import OrderItemPage from './components/pages/product/OrderItemPage';
import ReturnPage from './components/stripe/ReturnPage';
import TermsAndConditionsPage from './components/pages/static-pages/TermsAndConditionsPage';
import ContactPage from './components/pages/static-pages/ContactPage';
import NotFoundPage from './components/pages/not-found-pages/NotFoundPage';
import MessagesPage from './components/pages/profile/MessagesPage';
import { GoogleOAuthId } from './data/loginkeys';

import './App.scss';


function App() {
  return (
    <GoogleOAuthProvider clientId={GoogleOAuthId.clientId}>
    <AppContextProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to='/marketplace' />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/settings" element={<ProfileSettingsPage />} />
            <Route path="/profile/edit" element={<ProfileEditPage />} />
            <Route path="/profile/change-password" element={<ProfileChangePasswordPage />} />
            <Route path="/profile/item/:productId" element={<ProfileMyItemPage />} />
            <Route path="/profile/item/:productId/edit" element={<EditItemPage />} />
            <Route path="/profile/item/add" element={<SellItemPage />} />
            <Route path="/profile/order/:orderId" element={<OrderItemPage />} />
            <Route path="/profile/messages" element={<MessagesPage />} />
            <Route path="/order/:orderId" element={<OrderItemPage />} />
            <Route path="/profile/item/add/confirmation/:productId" element={<SellItemConfirmationPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/user-profile/:userId" element={<UserProfilePage />} />
            <Route path="/marketplace" element={<MarketplacePage />} />
            <Route path="/marketplace/:category" element={<MarketplacePage />} />
            <Route path="/product/not-found" element={<NotFoundPage />} />
            <Route path="/product/:productId" element={<ProductPage />} />
            <Route path="/item/:productId" element={<ProductPage />} />
            <Route path="/shopping-list" element={<ShoppingListPage />} />
            <Route path="/checkout/:productId/:sizeId" element={<CheckoutPage />} />
            <Route path="/checkout/confirmation/:orderId" element={<CheckoutConfirmationPage />} />
            <Route path="/stripe/return" element={<ReturnPage />} />
            <Route path="/jc-world-support" element={<JCWorldSupport />} />
            <Route path="/legal-notices" element={<LegalNoticesPage />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/data-deletion-request" element={<DataDeletionRequestPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
