// react imports
import React from "react";

// 3RD PARTY IMPORTS
import ReactPlayer from "react-player";

/**
 * Props:
 * @param url: string
 * @param thumbnailUrl: string
 * @returns 
 */
const ResponsiveVideoPlayer = (props) => {
  return (
    <div className='responsive-video-player'>
        <ReactPlayer 
          url={props.url}
          playIcon={
            <div className="thumbnail-play">
              <i className="fa-regular fa-play"></i>
            </div>
          }
          controls={true}
          light={props.thumbnailUrl}
          playing={true}
          className='react-player'
          width='61%'
          height='100%'
        />
    </div>
  )
};

export default ResponsiveVideoPlayer;