// react imports
import React from "react";

/**
 * Props:
 * @param value: string
 * @param onChangeHandler: (e) => void
 * @param onBlurHandler: (e) => void
 * @param maxCharacterNumber?: number
 * @param id: string
 * @param name: string
 * @param label?: string
 * @returns 
 */
const JCInput = ({value, onChangeHandler, onBlurHandler, maxCharacterNumber, id, name, label}) => {
  const onChange = (e) => {
    if (!maxCharacterNumber || e.target.value.length <= maxCharacterNumber) {
      onChangeHandler(e);
    }
  };

  return (
    <div className="jc-input">
      {label && (
        <label>
          {label}
        </label>
      )}

      <div className="input-wrapper">
        {maxCharacterNumber && (
          <span className="character-counter">
            {value.length}/{maxCharacterNumber}
          </span>
        )}

        <input 
          value={value}
          onChange={onChange}
          onBlur={onBlurHandler}
          id={id}
          name={name}
        />
      </div>
    </div>
  );
};

export default JCInput;