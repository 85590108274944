// react imports
import React, { useContext, useState, useEffect } from "react";

// CUSTOM IMPORTS
import AppContext from '../../../AppContext';
import RatingsStar from "../ratings-star/RatingsStar";
import ActionMenu from "../action-menu/ActionMenu";
import { MDBAlert } from 'mdb-react-ui-kit';

// IMAGES IMPORT
import userImage from "../../../images/user.png";

// MODAL IMPORTS
import ReportModal from "../../modals/report-modal/ReportModal";
import FollowUsersModal from "../../modals/follow-users-modal/FollowUsersModal";
import PleaseLoginForFollowModal from "../../modals/please-login-for-follow/PleaseLoginForFollowModal";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

/**
 * Props:
 * @param image: string
 * @param name: string
 * @param location: string
 * @param rating: number
 * @param openRatingModal?: () => void
 * @param followers: array
 * @param followings?: array
 * @param displayFollowButton: boolean
 * @param followed: boolean
 * @param setFollowed: (boolean) => any
 * @param reportEnabled: boolean
 * @param clickOnImageAndTitle: () => any
 * @returns 
 */
const SellerCard = ({ 
  image,
  name, 
  sellerId,
  location, 
  rating,
  followed,
  openRatingModal,
  displayFollowButton,
  reportEnabled,
  clickOnImageAndTitle
}) => {
  // MODAL STATES
  const [showReportModal, setShowReportModal] = useState(false);
  const [followModalState, setFollowModalState] = useState(false);
  const [showPleaseLoginForFollowModal, setShowPleaseLoginForFollowModal] = useState(false);
  
  const [pageSizeFollowers, setPageSizeFollowers] = useState(20);
  const [loadingNewProductsFollowers, setLoadingNewProductsFollowers] = useState(false);
  const [moreProductsAvailableFollowers, setMoreProductsAvailableFollowers] = useState(false);
  const [followers, setFollowers] = useState();
  const [followersCount, setFollowersCount] = useState(0);


  const [pageSizeFollowings, setPageSizeFollowings] = useState(20);
  const [loadingNewProductsFollowings, setLoadingNewProductsFollowings] = useState(false);
  const [moreProductsAvailableFollowings, setMoreProductsAvailableFollowings] = useState(false);
  const [followings, setFollowings] = useState();
  const [followingsCount, setFollowingsCount] = useState(0);

  const [alert, setAlert] = useState({alert: false, alertText: ""});

  const [sellerIsBusiness, setSellerIsBusiness] = useState(null);

  // MULTILANGUAGE
  const { t } = i18n;
  const context = useContext(AppContext);
  // TESTING
  // setTimeout(() => {
  //   setFollowingModalVisible(true);
  // }, 500);

  //events
  const loadNewProductsFollowers = () => {
    setLoadingNewProductsFollowers(true);
    setTimeout(() => {
      setPageSizeFollowers(pageSizeFollowers + 20),
      context.onRenderTrue();
      setLoadingNewProductsFollowers(false);
    }, 500);
  };
  const loadNewProductsFollowings = () => {
    setLoadingNewProductsFollowings(true);
    setTimeout(() => {
      setPageSizeFollowings(pageSizeFollowings + 20),
      context.onRenderTrue();
      setLoadingNewProductsFollowings(false);
    }, 500);
  };
  const addFollowed = () => {
    if (!context.isLoggedIn()) {
      setShowPleaseLoginForFollowModal(true);
    } else {
      context.interAxios.put('/follow/'+ sellerId,
        function(response) {
          context.onRenderTrueBookmark();

          // setAlert({alert: true, alertText: 'You are now following ' + name});
        }
      );
    }
  }
  const removeFollowed = () => {
    context.interAxios.delete('/follow/'+ sellerId,
      function(response) {
        context.onRenderTrueBookmark();

        // setAlert({alert: true, alertText: 'You are not following ' + name + ' from now'});
      }
    )
  }

  // templates
  const actionMenuTemplate = () => {
    const itemsTemplate = [
      (
        <div className="red" onClick={() => setShowReportModal(true)}>
          <i className="fa-regular fa-diamond-exclamation"></i>
          {t('general_modals.report.report_profile')}
        </div>
      )
    ];

    return (
      <>
        <ActionMenu
          className="report-profile"
          items={itemsTemplate}
        ></ActionMenu>

        <ReportModal
          visible={showReportModal}
          hideModal={() => setShowReportModal(false)}
          type={"CLIENT"}
          id={sellerId}
        />
      </>
    );
  }


  useEffect(() => {
    if(sellerId !== undefined){
      context.interAxios.get('/client/'+ sellerId +'/followed-by',
            {params: {
              pageIndex: 0,
              pageSize: pageSizeFollowers
            }},
      function(response) {
      setPageSizeFollowers(response.data.pageSize > 20 ? response.data.pageSize : 20)
      setFollowers(response.data.content)
      setFollowersCount(response.data.totalElements)
      if (window.location.search.indexOf('followersModalOpened') !== -1) {
      setFollowModalState('Followers');
      }
      if(response.data.totalElements > response.data.pageSize){

      setMoreProductsAvailableFollowers(true);
      }else{

      setMoreProductsAvailableFollowers(false);
      }
      //context.onRender();
      }
      )
      context.interAxios.get('/client/'+ sellerId +'/follows',
            {params: {
              pageIndex: 0,
              pageSize: pageSizeFollowings
            }},
      function(response) {
      setPageSizeFollowings(response.data.pageSize > 20 ? response.data.pageSize : 20)
      setFollowings(response.data.content)
      setFollowingsCount(response.data.totalElements)
      if(response.data.totalElements > response.data.pageSize){

      setMoreProductsAvailableFollowings(true);
      }else{

      setMoreProductsAvailableFollowings(false);
      }
      context.onRender();
      }
      )

      if (sellerIsBusiness === null) {
        context.interAxios.get(`/client/${sellerId}`, function(response) {
          setSellerIsBusiness(response.data.clientType === 'BUSINESS');
        });
      }
    }
   
  }, [context.getState()?.isRender]);

  return (
    <div className="seller-card">
      {/*** PROFILE IMAGE ***/}
      <div 
        className={"seller-image" + (clickOnImageAndTitle ? ' pointer' : '')}
        onClick={() => (clickOnImageAndTitle && clickOnImageAndTitle())}
      >
        <img src={image ? image : userImage} alt={name} />
      </div>


      {/*** SELLER CARD CONTENT ***/}
      <div className="seller-card-content">
        <div 
          className={"seller-name" + (clickOnImageAndTitle ? ' pointer' : '')}
          onClick={() => (clickOnImageAndTitle && clickOnImageAndTitle())}
        >
          {name}
        </div>
        <div className="seller-location">{location}</div>
        <div className="seller-rating">
          <RatingsStar 
            rating={rating}
            clickOnStarHandler={() => {
              if (rating === 0) {
                setAlert({alert: true, alertText: t('misc.no_review_yet')});
              } else {
                openRatingModal();
              }
            }}
          />

          <i 
            className={"fa-regular fa-angle-right" + (openRatingModal ? ' pointer' : '')}
            onClick={() => (openRatingModal && openRatingModal())}
          ></i>
        </div>

        {sellerIsBusiness && (
          <div className="business-banner">
            {t('misc.business_profile')}
          </div>
        )}

        <hr />
        
        <div className="seller-follow-infos">
        {followers && (
          <div onClick={() => {
            if (followers.length) {
              setFollowModalState('Followers');
            } else {
              setAlert({alert: true, alertText: t('misc.no_followers_yet')});
            }
          }}>
          <span>{followersCount}</span> {t('misc.followers').toLowerCase()}
          </div>
        )}

        {context.isLoggedInIdIs(sellerId) && followings && (
          <div onClick={() => {
            if (followings.length) {
              setFollowModalState('Following');
            } else {
              setAlert({alert: true, alertText: t('misc.no_following_yet')});
            }
          }}>
            <span>{followingsCount}</span> {t('misc.following').toLowerCase()}
          </div>
        )}
        </div>
      </div>


      {/*** FOLLOW BUTTON ***/}
      {!context.isLoggedInIdIs(sellerId) && displayFollowButton && !followed && (
        <button 
          className="jc-btn black follow-state unfollowed"
          onClick={addFollowed}
          >
          <i className="fa-regular fa-user-plus"></i>
          <span>{t('misc.follow')}</span>
        </button>
      )}


      {/*** UNFOLLOW BUTTON ***/}
      {!context.isLoggedInIdIs(sellerId) && displayFollowButton && followed && (
        <button 
          className="jc-btn black follow-state followed"
          onClick={removeFollowed}
        >
          <i className="fa-regular fa-user-minus"></i>
          <span>{t('misc.unfollow')}</span>
        </button>
      )}
      

      {/*** ACTION MENU ***/}
      {reportEnabled && actionMenuTemplate()}
      

      <FollowUsersModal 
        state={followModalState}
        hideModal={() => setFollowModalState(false)}
        followers={followers ? followers : []}
        followersCount={followersCount}
        userId={sellerId}
        followings={context.isLoggedInIdIs(sellerId) ? (followings ? followings : []) : null}
        followed={followed}
        followingsCount={context.isLoggedInIdIs(sellerId) ? (followingsCount) : null }
        followingTabShownDefault={false}
        loadNewProductsFollowers={loadNewProductsFollowers}
        loadNewProductsFollowings={loadNewProductsFollowings}
        loadingNewProductsFollowers={loadingNewProductsFollowers}
        moreProductsAvailableFollowers={moreProductsAvailableFollowers}
        loadingNewProductsFollowings={loadingNewProductsFollowings}
        moreProductsAvailableFollowings={moreProductsAvailableFollowings}
      />

      <PleaseLoginForFollowModal
        visible={showPleaseLoginForFollowModal}
        hideModal={() => setShowPleaseLoginForFollowModal(false)}
      />

      <MDBAlert
        show={alert.alert}
        color='primary'
        autohide
        position='top-center'
        offset={50}
        delay={4000}
        appendToBody
        onClosed={() => { setAlert({alert: false, alertText: ""})}}
      >{alert.alertText}</MDBAlert>
    </div>
  );
};

export default withTranslation()(SellerCard);