// react imports
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from '../../../AppContext';
// CUSTOM COMPONENTS
import ProfileForm from '../../form-components/profile-form/ProfileForm';

// sample data
import { userData } from "../../../data/user-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

const ProfileEditPage = (props) => {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  // effects
  useEffect(() => {
    if(context.isLoggedIn()){
      context.interAxios.get('/client/me', function(response) {
        setUser(response.data);
      });
    } else {
      // redirect if not logged in
      navigate('/marketplace');
    }
  }, []);
  //console.log(user)

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="jc-page gray-bc profile-edit">
      <div className="container-fluid mw-1040">
        <h1>
          <i className="fa-regular fa-arrow-left" onClick={() => navigate(-1)}></i>
          {t('profile_form.title')}
        </h1>
        {user !== null ?
        <ProfileForm
          mode="EDIT"
          type={user.clientType === "BUSINESS" ? 'BUSINESS' : 'PRIVATE'}
          user={user}
        />:false}
      </div>
    </div>
  );
};

export default withTranslation()(ProfileEditPage);
