// react imports
import React, { useState, useContext, useRef } from "react";
import AppContext from '../../../AppContext';
// react router dom
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";

//Sociallogin

import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import { backendUrl } from '../../../env';
// mdbootstrap imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInputGroup
} from 'mdb-react-ui-kit';


// 3rd party imports
import PasswordChecklist from "react-password-checklist"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// custom imports
import PasswordInput from "../../common/password-input/PasswordInput";
import BlackCollapsibleArea from "../../common/black-collapse-area/BlackCollapsibleArea";

// helper funcs
import { validateEmail, validatePassword } from "../../../data/helper-funcs";

// images
import loginAppleIcon from '../../../images/login-modal/apple-icon.svg';
import loginFacebookIcon from '../../../images/login-modal/facebook-icon.svg';
import loginGoogleIcon from '../../../images/login-modal/google-icon.svg';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import { FacebookAppId, AppleLogin } from '../../../data/loginkeys';

/**
 * Props
 * @param visible: boolean
 * @param hideModalHandler: () => void
 * @param forgotPasswordClicked: () => void
 * @param openEmailNotVerifiedModal: (email) => void
 * @param loginEvent: (email: string, password: string) => void
 * @returns
 */
const LoginSignupModal = ({visible, hideModalHandler, forgotPasswordClicked, openEmailNotVerifiedModal, loginEvent}) => {
  const context = useContext(AppContext);

  // router hooks
  const navigate = useNavigate();

  // STATES
  const [email, setEmail] = useState('');
  const [emailMessages, setEmailMessages] = useState([]);
  const [password, setPassword] = useState('');
  const [passwordMessages, setPasswordMessages] = useState([]);
  const [displaySignup, setDisplaySignup] = useState(false);
  const [privateUserAreaVisible, setPrivateUserAreaVisible] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [emailNotValidatedStatus, setEmailNotValidatedStatus] = useState(''); // '', MESSAGE_SHOWN, SENDING, EMAIL_SENT
  const [badCredentials, setBadCredentials] = useState(false);

  // REFS
  const passwordRef = useRef();

  // EVENTS
  const handleSuccessfulLogin = () => {
    resetEveryState();

    if (location.href.indexOf('/order/') !== -1) {
      return;
    }
    if (location.href.indexOf('profile/messages') !== -1) {
      return;
    }

    const redirectToInfos = cookies.get('redirect_to_after_logging_in');

    if (redirectToInfos?.path && new Date().getTime() < new Date(redirectToInfos.validUntil).getTime()) {
      cookies.remove('redirect_to_after_logging_in', { path: '/' });
      
      navigate(redirectToInfos.path);
    } else {
      navigate('/marketplace');
    }
  };
  const handleError = (error) => {
    if (error.response.data.message === 'user_email_not_validated') {
      // setEmailNotValidatedStatus('MESSAGE_SHOWN');
      hideModalHandler();

      setTimeout(() => {
        resetEveryState();
        openEmailNotVerifiedModal(email);
      }, [400]);
    } else {
      setBadCredentials(true);
    }
  };
  const resetEveryState = () => {
    setDisplaySignup(false);
    setPrivateUserAreaVisible(false);
    setEmailNotValidatedStatus('');
    setBadCredentials(false);

    setEmail('');
    setPassword('');
  };
  const submitHandler = (event) => {
    event.preventDefault();
    setBadCredentials(false);
    setEmailNotValidatedStatus('');

    loginEvent(email, password, handleSuccessfulLogin, handleError);
  };
  const clickOnSignUp = () => {
    hideModalHandler();

    resetEveryState();

    navigate('/sign-up?mode=private');
  };
  const clickOnSignUpBusiness = () => {
    hideModalHandler();

    resetEveryState();

    navigate('/sign-up?mode=business');
  };

  // MULTILANGUAGE
  const { t } = i18n;

  //Login with Google
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      context.interAxios.post('/auth/google', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.closeLoginModal();
      });
    },
    onError: (error) => alert('Login Failed:', error)
  });


  // TEMPLATES
  const loginContent = () => (
    <div className="login-content">
      <h2>{t('login_modal.title')}</h2>

      <form onSubmit={submitHandler}>
        {/*** EMAIL INPUT ***/}
        <input
          className={emailMessages.length > 0 ? 'error' : ''}
          placeholder={t('login_modal.email')}
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Tab') {
              e.preventDefault();
              e.stopPropagation();
              passwordRef.current.focus()
            }
          }}
          required
        />


        {/*** PASSWORD INPUT ***/}
        <MDBInputGroup
          className="jc-password"
          noWrap 
          textAfter={(
            <i 
              className={"fa-regular" + (hidePassword ? ' fa-eye' : ' fa-eye-slash')}
              onClick={() => setHidePassword(!hidePassword)}
            ></i>
          )}
        >
          <input 
            type={hidePassword ? 'password' : 'text'} 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('login_modal.password')} 
            id={'password'}
            name={'password'}
            required={true}
            className="form-control"
            ref={passwordRef}
          />
        </MDBInputGroup>

        {badCredentials && (
          <p className="error">
            {t('misc.incorrect_login')}
          </p>
        )}


        {/*** FORGOT PASSWORD LINK ***/}
        <p>
          <a className="forgot-password" onClick={forgotPasswordClicked}>
            {t('login_modal.forgot_password')}
          </a>
        </p>


        {/*** LOGIN BUTTON ***/}
        <button
          className="jc-btn black"
          type="submit"
        >
          {t('login_modal.login_button')}
        </button>
      </form>


      {/*** SIGN UP LINK*/}
      <p>
        {t('login_modal.no_account_yet')}
        <a onClick={() => context.openLoginModal(true)} className="sign-up">{t('login_modal.signup_link')}</a>
      </p>

      <hr data-centered-content={t('login_modal.or')} />


      <div className="social-icons">
        <AppleSignin
          authOptions={{
            clientId: AppleLogin.clientId,
            scope: AppleLogin.scope,
            redirectURI: AppleLogin.redirectURI,
            state: AppleLogin.state,
            nonce: AppleLogin.nonce,
            usePopup: AppleLogin.usePopup,
          }}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          buttonExtraChildren="Continue with Apple"
          onSuccess={(response) => {
            console.log(response);
            axios.post(backendUrl() +'/auth/apple', { 'token': response.authorization.id_token, 'code': response.authorization.code }, { headers: { 'Content-type': 'application/json'}}).then(function(response) {
                console.log(response.data)
                context.onSignIn(response.data);
                context.closeLoginModal();
            })
          }}
          onError={(error) => console.error(error)}
          skipScript={false}
          render={(props) => <button className="jc-btn white" {...props}> <img src={loginAppleIcon} alt="Apple Icon" />
          Apple</button>}
        />

        <button className="jc-btn white" onClick={() => googleLogin()}>
          <img src={loginGoogleIcon} alt="Google Icon" />
          Google
        </button> 
        
        <FacebookLogin
            appId= {FacebookAppId.appId}
            style={{
              backgroundColor: 'transparent',
              color: '#fff',
              fontSize: '17px',
              padding: 0,
              border: "none",
              borderRadius: "15px",
            }}
            children={<div className="jc-btn white"><img src={loginFacebookIcon} alt="Facebook Icon" />
            Facebook</div>} 
            onSuccess={(response) => {
                context.interAxios.post('/auth/facebook', {token: response.accessToken}, function(response) {
                  context.onSignIn(response.data);
                  context.closeLoginModal();
                });
            }}
            onFail={(error) => {
                console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
                console.log(response);
            }}
        />
      </div>
    </div>
  );
  const signupContent = () => (
    <div className="signup-content">
      <h2>{t('signup_modal.title')}</h2>

      <BlackCollapsibleArea
        headerContent={(
          <>
            <i className="fa-regular fa-user-vneck"></i>
            {t('signup_modal.signup_as_private')}
          </>
        )}
        infoText={t('signup_modal.info_about_private_users')}
        visible={privateUserAreaVisible}
        openArea={() => { setPrivateUserAreaVisible(true); }}
        closeArea={() => setPrivateUserAreaVisible(false)}
        clickOnSignUp={clickOnSignUp}
        clickOnApple={() => console.log('Open apple registration (private)')}
        clickOnGoogle={() => console.log('Open google registration (private)')}
        clickOnFacebook={() => console.log('Open facebook registration (private)')}
      />

      <BlackCollapsibleArea
        headerContent={(
          <>
            <i className="fa-regular fa-users"></i>
            {t('signup_modal.signup_as_business')}
          </> 
        )}
        infoText={t('signup_modal.info_about_business_users')}
        visible={false}
        openArea={clickOnSignUpBusiness}
        closeArea={clickOnSignUpBusiness}
      />

      <p>
        {t('signup_modal.already_have_an_account')} <a onClick={() => context.openLoginModal()}>{t('signup_modal.login_link')}</a>
      </p>
    </div>
  );


  return (
    <MDBModal
      show={visible}
      tabIndex='-1'
      className="login-signup-modal standard-jc-modal"
      backdrop={true}
      staticBackdrop={true}
      onHidePrevented={() => { hideModalHandler(); resetEveryState(); }}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <div className="container">
            <MDBBtn className='btn-close' color='none' onClick={() => { hideModalHandler(); resetEveryState(); }}>
              <i className="fa-regular fa-times"></i>
            </MDBBtn>

            {!context.doesSigninDisplayedInLoginModal() && loginContent()}
            {context.doesSigninDisplayedInLoginModal() && signupContent()}
          </div>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default LoginSignupModal;
