// react imports
import React, { useContext, useState, useEffect } from "react";
import AppContext from '../../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBSelect
} from 'mdb-react-ui-kit';


// HELPER FUNCS
import { getSingleSelectChangeFunc } from "../../../data/helper-funcs";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param languageChanged: (string) => void
 * @returns
 */
const LanguageModal = (props) => {
  // MULTILANGUAGE
  const { t } = i18n;
  
  // STATES
  const [language, setLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'English' : 'German');
  //const [currentLanguage, setCurrentLanguage] = useState(cookies.get('mylanguage')?.language === 'en' ? 'English' : 'German');
  const [languages, setLanguages] = useState([
    { text: t('misc.english'), value: 'English', defaultSelected: cookies.get('mylanguage')?.language === 'en' },
    { text: t('misc.german'), value: 'German', defaultSelected: cookies.get('mylanguage')?.language === 'de'  }
  ]);

  // REFS
  const context = useContext(AppContext);
  // EVENTS
  const getLanguage = (language) => {
    setLanguage(language.text)
    if(language.text === 'English'){
      context.toEnglish();
      props.hideModal()
    }else{
      context.toGerman();
      props.hideModal()
    }
  };


  // detect language change
  useEffect(() => {
    //context.onRender();
    if(cookies.get('mylanguage')?.language === 'en'){
      setLanguage('English')
    }else{
      setLanguage('German')
    }
    context.onRender();
  }, [context.getState()?.isRender]);

  

  return (
    <MDBModal
      className="standard-jc-modal w-615 language-modal"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

          <i className="fa-regular fa-globe main-modal-icon"></i>

            <h3>{t('misc.language')}</h3>

            <MDBSelect
              /*label="Language"
              selectAll={false}
              displayedLabels={1}
              optionsSelectedLabel='..'*/
              data={languages}
              onValueChange={(e) => getLanguage(e)}
            />
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(LanguageModal);

