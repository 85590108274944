// REACT IMPORTS
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import { MDBContainer } from "mdb-react-ui-kit";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';
import MobileButton from "../../common/mobile-buttons/MobileButton";

// IMAGE IMPORTS
import footerLogo1 from "../../../images/footer/shield.png";
import footerLogo2 from "../../../images/footer/wallet.png";
import footerLogo3 from "../../../images/footer/check.png";
import footerLogo4 from "../../../images/footer/planet-earth.png";


const Footer = (props) => {
  const context = useContext(AppContext);

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <footer className="jc-footer">
      <div className="container-fluid mw-1300">
        <div className="footer-logos">
          <div>
            <img src={footerLogo1} alt={t('footer.buyer_protection')} />
            {t('footer.buyer_protection')}
          </div>
          <div>
            <img src={footerLogo2} alt={t('footer.secure_payment')} />
            {t('footer.secure_payment')}
          </div>
          <div>
            <img src={footerLogo3} alt={t('footer.verified_sellers')} />
            {t('footer.verified_sellers')}
          </div>
          <div>
            <img src={footerLogo4} alt="Nachhaltig" />
            {t('footer.sustainable')}
          </div>
        </div>

        <div className="footer-menus">
          <div className="footer-menu">
            <ul>
              <li className="label">
                {t('footer.help')}
              </li>
              <li>
                <Link to="/faq#sell">{t('footer.sell')}</Link>
              </li>
              <li>
                <Link to="/faq#buy">{t('footer.buy')}</Link>
              </li>
              <li>
                <Link to="/contact">{t('footer.contact')}</Link>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <ul>
              <li className="label">
                {t('footer.about_jc_world')}
              </li>
              <li>
                <Link to="/about-us">{t('footer.about_us')}</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">{t('footer.terms_and_conditions')}</Link>
              </li>
              <li>
                <Link to={cookies.get('mylanguage').language === "en" ? '/legal-notices#imprint' : '/legal-notices#imprint'}>{t('footer.imprint')}</Link>
              </li>
              <li>
                <Link to={cookies.get('mylanguage').language === "en" ? '/legal-notices#privacy-policy' : '/legal-notices#privacy-policy'}>{t('footer.privacy_policy')}</Link>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <ul>
              {/* <li className="social">
                <Link to="/">
                  <i className="fa-brands fa-twitter"></i>
                  Twitter
                </Link>
              </li>
              <li className="social">
                <Link to="/">
                  <i className="fa-brands fa-youtube"></i>
                  Youtube
                </Link>
              </li> */}
              <li className="social">
                <a href="https://www.linkedin.com/company/j-c-world/" target="_blank">
                  <i className="fa-brands fa-linkedin"></i>
                  LinkedIn
                </a>
              </li>
              <li className="social">
                <a href="https://www.instagram.com/jandc_world.ch/?igshid=MmIzYWVlNDQ5Yg%3D%3D" target="_blank">
                  <i className="fa-brands fa-instagram"></i>
                  Instagram
                </a>
              </li>
              <li className="social">
                <a href="https://vm.tiktok.com/ZGJ9Cx9Ch/" target="_blank">
                  <i className="fa-brands fa-tiktok"></i>
                  TikTok
                </a>
              </li>
              <li className="social">
                <a href="https://www.google.com/url?q=https://www.facebook.com/profile.php?id%3D100092358392437&source=gmail&ust=1685018422626000&usg=AOvVaw1EIUNtaza1nRwPBrJA9A6i" target="_blank">
                  <i className="fa-brands fa-square-facebook"></i>
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-mobile-links">
          <MobileButton type="APP_STORE" />
          <MobileButton type="GOOGLE_PLAY" />
        </div>
      </div>
    </footer>
  );
};

export default withTranslation()(Footer);
