// react imports
import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import AppContext from '../../../AppContext';
import axios from "axios";
import { backendUrl } from '../../../env';


// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';


// 3RD PARTY IMPORTS
import { Formik } from "formik";
import * as Yup from 'yup';

// CUSTOM COMPONENTS
import PasswordInput from '../../common/password-input/PasswordInput';
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const ProfileChangePasswordPage = (props) => {
  // REACT ROUTER  HOOKS
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useContext(AppContext);

  
  const {email} = Object.fromEntries([...searchParams]);


  // STATES
  const [passwordChangedModalVisible, setPasswordChangedModalVisible] = useState(false);
  const [isPasswordChangeWithToken, setIsPasswordChangeWithToken] = useState(false);
  const [passwordChangeInProgress, setPasswordChangeInProgress] = useState(false);

  // FORMIK VALIDATON OBJECT
  const getYupValidationObject = () => {
    const yupValidationObject = {
      newPassword: Yup
        .string()
        .required(t('error_messages.new_password_required'))
        .min(8, t('error_messages.new_password_8_characters'))
        .matches(/[a-z]/, t('error_messages.new_password_small_large_letters_number'))
        .matches(/[A-Z]/, t('error_messages.new_password_small_large_letters_number'))
        .matches(/[0-9]/, t('error_messages.new_password_small_large_letters_number')),

      newPasswordConfirm: Yup
        .string()
        .required(t('error_messages.password_confirmation'))
        .oneOf([Yup.ref('newPassword'), null], t('error_messages.password_match'))
    };

    if (isPasswordChangeWithToken) {
      yupValidationObject.oldPassword = Yup.string().required(t('error_messages.password_required'));
    }

    return yupValidationObject;
  };
  const getYupInitialValuesObject = () => {
    let initialValuesObject = {
      newPassword: '',
      newPasswordConfirm: '',
    };

    if (!email) {
      initialValuesObject.oldPassword = '';
    }

    return initialValuesObject;
  };

  // MULTILANGUAGE
  const { t } = i18n;

  //events
  useEffect(() => {
    if(cookies.get('authtoken')?.token){
      setIsPasswordChangeWithToken(true);
    }else{
      setIsPasswordChangeWithToken(false);
    }
    
    
  }, []);

  const passwordChangedEvent = (openLogin) => {
    setPasswordChangedModalVisible(true);
    setPasswordChangeInProgress(false);

    setTimeout(() => {
      setPasswordChangedModalVisible(false);

      if (openLogin) {
        context.openLoginModal();
      } else {
        navigate('/marketplace');
      }
    }, 3000);
  };
  const changePassword = (values) => {
    setPasswordChangeInProgress(true);

    if(isPasswordChangeWithToken){
      context.interAxios.put('/auth/password',
        {
          oldPassword: values.oldPassword,
          password: values.newPasswordConfirm
        },
         function(response) {
          passwordChangedEvent(false)
          context.onLogout();
        },
        function (error) {
          setPasswordChangeInProgress(false);
          return true;
        }
      );
    }
    else {
      axios.put(backendUrl() +'/auth/password', 
        { 
          password: values.newPasswordConfirm 
        },
        {headers: {
          'Authorization': 'Bearer ' + Object.fromEntries([...searchParams]).authToken
          }
        }).then(function(response) {
          passwordChangedEvent(true)
          context.onLogout();
        })
    }
  }


  return (
    <div className="jc-page gray-bc profile-edit">
      <div className="container-fluid mw-1040">
        <h1>
        {isPasswordChangeWithToken &&
          <i className="fa-regular fa-arrow-left" onClick={() => navigate(-1)}></i>}

          {isPasswordChangeWithToken && t('change_password.title')}
          {!isPasswordChangeWithToken && t('change_password.title_for_reset')}
        </h1>

        <Formik
          initialValues = {getYupInitialValuesObject()}
          onSubmit = {changePassword}
          validationSchema={Yup.object(getYupValidationObject())}
        >
          {formik => {

            // console.table(formik.errors);
            // console.table(formik.touched);

            return (
              <form onSubmit={formik.handleSubmit} className="profile-form password-form">
                <div className="white-panel">

                  {isPasswordChangeWithToken && (
                    <MDBRow>
                      <MDBCol md='5'>
                        <label htmlFor="oldPassword">{t('change_password.old_password')}</label>
                      </MDBCol>
                      <MDBCol md='7'>
                        <PasswordInput
                          id='oldPassword'
                          name='oldPassword'
                          type='oldPassword'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />

                        {formik.touched.oldPassword && formik.errors.oldPassword ? (
                          <div className="invalid-feedback">{formik.errors.oldPassword}</div>
                        ) : null}
                      </MDBCol>
                    </MDBRow>
                  )}

                  <MDBRow>
                    <MDBCol md='5'>
                      <label htmlFor="newPassword">{t('change_password.new_password')}</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <PasswordInput
                        id='newPassword'
                        name='newPassword'
                        type='newPassword'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />

                      {formik.touched.newPassword && formik.errors.newPassword ? (
                        <div className="invalid-feedback">{formik.errors.newPassword}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='5'>
                      <label htmlFor="newPasswordConfirm">{t('change_password.new_password_again')}</label>
                    </MDBCol>
                    <MDBCol md='7'>
                      <PasswordInput
                        id='newPasswordConfirm'
                        name='newPasswordConfirm'
                        type='newPasswordConfirm'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />

                      {formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm ? (
                        <div className="invalid-feedback">{formik.errors.newPasswordConfirm}</div>
                      ) : null}
                    </MDBCol>
                  </MDBRow>

                </div>

                <div className="last-buttons-row to-right">
                  <button type="submit" className="jc-btn black">
                    {passwordChangeInProgress && (
                      <i className="fa-regular fa-spinner-third spinning"></i>
                    )}

                    {!passwordChangeInProgress && (
                      <>
                        <i className="fa-regular fa-check"></i>

                        {isPasswordChangeWithToken && t('change_password.button')}
                        {!isPasswordChangeWithToken && t('change_password.button_for_reset')}
                      </>
                    )}
                  </button>

                  {formik.submitCount > 0 && !formik.isValid ? (
                    <div className="invalid-feedback">
                      {t('error_messages.general')}
                    </div>
                  ) : null}
                </div>
              </form>
            )}
          }
        </Formik>

        <GeneralInfoModal
          visible={passwordChangedModalVisible}
          hideModal={() => setPasswordChangedModalVisible(false)}
          infoIcon="fa-regular fa-info-circle"
          title={t('general_modals.password_changed.title')}
          text={t('general_modals.password_changed.text')}
          buttonText={t('general_modals.password_changed.button_text')}
          buttonClick={() => setPasswordChangedModalVisible(false)}
        />
      </div>
    </div>
  );
};

export default withTranslation()(ProfileChangePasswordPage);

