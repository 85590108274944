// react imports
import React, { useState, useContext } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// CUSTOM IMPORTS
import AppContext from "../../../AppContext";



const BackendErrorModal = () => {
  // MULTILANGUAGE
  const { t } = i18n;
  const context = useContext(AppContext);

  return (
    <MDBModal
      className="standard-jc-modal w-615 backend-error"
      show={context.getBackendErrorMessage()}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={context.closeBackendErrorModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={context.closeBackendErrorModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <i className="fa-regular fa-exclamation-triangle main-modal-icon"></i>

            <h3>{t(`error_messages_from_backend.${context.getBackendErrorMessage()}`)}</h3>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default BackendErrorModal;
