import React, { useEffect, useContext, useState} from 'react';
import { useNavigate, useSearchParams, useBeforeUnload  } from 'react-router-dom';
import AppContext from '../../AppContext';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { StripeReturnUrl } from '../../data/loginkeys';

import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../i18n';


const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = i18n;

  const context = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //setOutWarning(false)
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: StripeReturnUrl.url + props.id + '?authToken=' + props.authToken,
      },
    });


    if (error) {
      setErrorMessage(error.message);
    } else {
    }
  };
 
  return (
    <>
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="flex justify-content-end" style={{display: "flex"}}><button id="cancel" type="button" onClick={props.paymentCancel}>{t('checkout.cancel')}</button>
      <button type="submit" disabled={!stripe}>{props.submitText}</button></div>
      
      {errorMessage && <div>{errorMessage}</div>}
    </form>
    
    </>
    
  )
};

export default CheckoutForm;
