// react imports
import React, { useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @returns
 */
const AccountCreatedModal = (props) => {
  // router hooks
  const navigate = useNavigate();

  // EVENTS
  const clickOnGoToMarketplace = () => {
    props.hideModal();
    navigate('/profile');
  };

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <MDBModal
      className="standard-jc-modal account-created"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <i className="fa-regular fa-check main-modal-icon"></i>

            <h3>{t('general_modals.account_saved.title')}</h3>

            <p>{t('general_modals.account_saved.text')}</p>

            <button className="jc-btn black" onClick={clickOnGoToMarketplace}>
              {t('general_modals.account_saved.button_text')}
            </button>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(AccountCreatedModal);

