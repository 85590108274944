// REACT IMPORTS
import React, { useState, useEffect } from "react";

// OTHER 3RD PARTY IMPORTS
import { ReactSortable } from "react-sortablejs";

// FORMIK IMPORTS
import { useFormikContext } from "formik";

// IMPORT MODALCOMPONENT
import CreateImageModal from '../../modals/ImageUploadWithCrop/CreateImageModal.js'
import i18next from 'i18next';
import i18n from '../../../i18n';

// PRIME REACT IMPORTS
// import { ToggleButton } from 'primereact/togglebutton';

// CUSTOM COMPONENTS
// import OverlayPanel from '../overlay-panel/OverlayPanel';


// styles
// import "./ImageUploader.scss"


/**
 * Props
 * @param uploadBtnMessage: string
 * @param formikImageFieldName: string
 * @param formikImageUrlName: string
 * @param imageUrl: string
 * @returns
 */
const SingleImageUploader = (props) => {
  // STATES
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showCreateImageModal, setShowCreateImageModal] = useState(false);
  const [imageFile, setImageFile] = useState();


  // FORMIK HELPER FUNCS
  const { setFieldValue } = useFormikContext();

  const { t } = i18n;

  // MAIN FUNC
  const addImage = (file) => {
    const imageUrl = URL.createObjectURL(file);

    setSelectedImageUrl(imageUrl);

    setFieldValue(props.formikImageFieldName, file);
    setFieldValue(props.formikImageUrlName, imageUrl);
  };
  const removeImage = () => {
    setSelectedImageUrl(null);
    setFieldValue(props.formikImageFieldName, null);
    setFieldValue(props.formikImageUrlName, null);
  };
  const preventAndStop = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // EVENTS
  const onSelectImage = (event) => {
    //const selectedFiles = event.target.files;

    if (!event && event.length !== 1) {
      return;
    }

    addImage(event);

  };
  const dropOnImagesArea = (e) => {
    preventAndStop(e);

    addImage(e.dataTransfer.files);
  };

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e)
      setShowCreateImageModal(true)
      /*const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({crop: undefined})*/
    }
  };


  // on load
  useEffect(() => {
    if (props.imageUrl) {
      setSelectedImageUrl(props.imageUrl);
    }
  }, []);

  return (
    <div className={"single-image-uploader"}>
      <div
        className={`images-area`  + (selectedImageUrl ? "" : " center")}
        onDragEnter={preventAndStop}
        onDragOver={preventAndStop}
        onDrop={dropOnImagesArea}
      >
        {selectedImageUrl && (
          <div
            className="uploaded-item"
          >
            <div className="image-icons">
              <div className="image-icon" style={{backgroundColor: 'white'}}>
                <i
                  className="fa-regular fa-trash-alt"
                  onClick={removeImage}
                ></i>
              </div>
            </div>

            <img src={selectedImageUrl} alt="preview image" />
          </div>
        )}

        <label
              className='upload-button'
              htmlFor="new-files"
            >
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={onSelectFile}
                id="new-files"
              />

                <i className="fa-regular fa-upload"></i> {props.uploadBtnMessage}
            </label>
      </div>
      {showCreateImageModal && imageFile ?
      <CreateImageModal onSelectImage={onSelectImage} uploadBtnMessage={props.uploadBtnMessage} showCreateImageModal={showCreateImageModal} setShowCreateImageModal={setShowCreateImageModal} imageFile={imageFile}/>:false}
    </div>
  );
};

export default SingleImageUploader;
