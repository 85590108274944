import commentProfile1 from '../images/comment-profile-1.jpg';
import commentProfile2 from '../images/comment-profile-2.jpg';
import commentProfile3 from '../images/comment-profile-3.jpg';
import sellerImage from '../images/seller-image.jpg';
import profile from '../images/profile.jpg';

export const SampleComments = [
  {
    id: 1,
    sender: {
      id: 10,
      publicName: 'Jane Stilo',
      image: {
        url: commentProfile1,
      }
    },
    content: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
    timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
  },
  {
    id: 2,
    sender: {
      id: 11,
      publicName: 'Cynthia Gion',
      image: {
        url: commentProfile2,
      }
    },
    content: 'Hello, lorem ipsum dolor?',
    timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
    reply: [
      {
        id: 6,
        sender: {
          id: 2612,
          publicName: 'Giana Carder',
          image: {
            url: sellerImage,
          }
        },
        content: 'Yes, definitely 🙂',
        timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
      },
    ]
  },
  {
    id: 3,
    sender: {
      id: 12,
      publicName: 'Jane Stilo',
      image: {
        url: commentProfile3,
      }
    },
    content: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
    timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
  },
  {
    id: 4,
    sender: {
      id: 13,
      publicName: 'Jane Stilo',
      image: {
        url: commentProfile1,
      }
    },
    content: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
    timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
  },
  {
    id: 5,
    sender: {
      id: 14,
      publicName: 'Cynthia Gion',
      image: {
        url: commentProfile2,
      }
    },
    content: 'Hello, lorem ipsum dolor?',
    timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
    reply: [
      {
        id: 7,
        sender: {
          id: 2612,
          publicName: 'Giana Carder',
          image: {
            url: commentProfile3
          },
        },
        content: 'Yes, definitely 🙂',
        timestamp: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
    ]
  },
];