// react imports
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// CUSTOM IMPORTS
import RatingsStar from '../ratings-star/RatingsStar';
import TruncateTooltipText from "../truncate-tooltip-text/TruncateTooltipText";
import AppContext from "../../../AppContext";
import ActionMenu from "../action-menu/ActionMenu";

// IMAGE IMPORTS
import userImage from "../../../images/user.png";

// HELPER IMPORTS
import { getAgoText, addLeadingZeros } from "../../../data/helper-funcs";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import Moment from 'moment';

/**
 * Props:
 * @param id: number
 * @param buyer: { 
 *  id: number, 
 *  buyer: { 
 *    image: { url: string }
 *    publicName: string
 *  }
 * }
 * @param star: number
 * @param created: Date
 * @param item: { title: string }
 * @param textcount: number
 * @param content: string
 * @param displayItemName? boolean
 * @param exactDate?: boolean
 * @param onReportRating?: (reportId) => any
 * @returns 
 */
const RatingCard = ({
  id,
  buyer,
  star,
  created,
  item,
  textcount,
  content,
  displayItemName,
  exactDate,
  onReportRating,
  valami
}) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);

  // EVENTS
  const goToUser = (userId) => {
    if (context.isLoggedInIdIs(userId)) {
      navigate('/profile');  
    } else {
      navigate(`/user-profile/${userId}`);
    }
  };

  // MULTILANGUAGE
  const { t } = i18n;

  const buyerName = buyer?.publicName ? buyer?.publicName : t('misc.deleted_user');

  return (
    <div className="rating-card">
      <div 
        className="image-wrapper pointer"
        onClick={() => goToUser(buyer?.id)}
      >
        <img src={buyer?.image?.url ? buyer?.image?.url : userImage} alt={buyerName} />
      </div>
      <div className="rating-card-content">
        <header>
          <div className="name-and-rating">
            <div 
              className="seller-name pointer"
              onClick={() => goToUser(buyer?.id)}
            >
              {buyerName}
            </div>
          
            <RatingsStar
              rating={star}
            />
          </div>
          
          <div className="rating-info">
            <span className="rating-datetime">
              {/*!exactDate && getAgoText(created)}
              {exactDate && (
                addLeadingZeros(new Date(created).getDate(), 2) + '.' + 
                addLeadingZeros((new Date(created).getMonth()+1), 2) + '.' + 
                new Date(created).getFullYear()
              )*/}
              {Moment(created).format('DD.MM.YYYY')}
              </span>
            
            {displayItemName && (
              <TruncateTooltipText
                text={item.title}
                breakpoints={[{ maxCharacterNumber: textcount, width: 300 }]}
                className="rating-item"
              />
            )}
          </div>

          {onReportRating && (
            <ActionMenu
              className="report-rating"
              items={[
                (
                  <div className="red" onClick={() => onReportRating(id)}>
                    <i className="fa-regular fa-diamond-exclamation"></i>
                    {t('general_modals.ratings.report_false_rating')}
                  </div>
                )
              ]}></ActionMenu>
          )}
        </header>

        <p>{content}</p>
      </div>
    </div>
  );
};

export default withTranslation()(RatingCard);  