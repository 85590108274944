// react imports
import React, { useState, useEffect, useRef } from "react";

// REACT ROUTER IMPORTS
import { useNavigate } from "react-router-dom";

// MDBOOTSTRAP IMPORTS
import {
  MDBNavbarNav,
  MDBNavbarItem,
  MDBIcon,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBInputGroup,
  MDBBtn
} from 'mdb-react-ui-kit';
import "mdb-react-ui-kit/dist/css/mdb.min.css";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../../../i18n';

// CUSTOM COMPONENTS
import HeaderLanguageSelector from "../../language-selector/HeaderLanguageSelector";
import Cookies from 'universal-cookie';
import CollapseTrigger from "../../../../common/collapse-trigger/CollapseTrigger";

// 3RD PARTY
import { Collapse } from "react-collapse";

// SAMPLE DATA
import { Categories } from "../../../../../data/product-categories";

// HELPER FUNCS
import { toKebabCase, locationConverter, isNumeric } from "../../../../../data/helper-funcs";

// IMAGES
import profileImg from "../../../../../images/user.png";

const cookies = new Cookies();


const CategoriesForMobileSidenav = Categories.map(topCategory => (
  {
    id: topCategory.id,
    name: topCategory.name,
    opened: false,
    sections: topCategory.sections.map(section => (
      {
        id: section.id,
        name: section.name,
        opened: false,
        categories: section.categories
      }
    ))
  }
));


/**
 * Props:
 * @param closeSidenav: () => void
 * @param showUserMenu: () => void
 * @param mobileFilterPanelOpen: () => void
 * @param context
 * @returns 
 */
const SidenavContentButtonsCategories = ({
  closeSidenav,
  showUserMenu,
  mobileFilterPanelOpen,
  context
}) => {
  // NAVIGATE
  const navigate = useNavigate();

  // STATES
  const [focusedInput, setFocusedInput] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [locationValue, setLocationValue] = useState('');
  const [openedCategoryIds, setOpenedCategoryIds] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // REFS
  const locationInputRef = useRef();
  const searchInputRef = useRef();
  const locationInputGroupRef = useRef();
  const searchInputGroupRef = useRef();

   // MULTILANGUAGE
   const { t } = i18n;

  // EVENTS
  const getChangeCollapseStateHandler = (categoryId, isCategoryOpened) => () => {
    setOpenedCategoryIds(current => {
      const newOpenedCategoryIds = [...current];

      if (isCategoryOpened) {
        newOpenedCategoryIds.splice(newOpenedCategoryIds.indexOf(categoryId), 1);
      } else {
        newOpenedCategoryIds.push(categoryId);
      }

      return newOpenedCategoryIds;
    });
  };
  const changeSelectedCategoryId = (categoryId) => {

    () => setSelectedCategoryId(selectedCategoryId === categoryId ? null : categoryId);

     const selectedCategory = context.getState()?.allCategory?.categories.find(c => c.id === categoryId);

     if(selectedCategory.type === "CATEGORY"){
       context.onGetCategoryIdParams(categoryId, selectedCategory.name);
     }
     if(selectedCategory.type === "SUBCATEGORY"){
       context.onGetSubCategoryIdParams(categoryId, selectedCategory.name);
     }
     if(selectedCategory.type === "SUBSUBCATEGORY"){
       context.onGetSubsubCategoryIdParams(categoryId, selectedCategory.name);
     }

    context.setScrollOnMarketplaceAfterFilter();

    if (location.pathname.indexOf('marketplace') === -1) {
      context.onParamsFalse();
      navigate('/marketplace');
    }
    closeSidenav();

    // BALAZS TODO: change selected category logic
  };
  const clickOnDocument = (event) => {
    // IMPLEMENTATION:
    // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

    // let clickedOnWhichInputGroup = '';

    if (!locationInputGroupRef.current || !searchInputGroupRef.current) {
      return;
    }

    if (!locationInputGroupRef.current.contains(event.target) && !searchInputGroupRef.current.contains(event.target)) {
      setFocusedInput('');
    }
  };

  // COMPONENT DID MOUNT
  useEffect(() => {
    document.addEventListener("mousedown", clickOnDocument);
    
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", clickOnDocument);
    };
  }, []);

  // SET FOCUS ON ANIMATED INPUTS
  useEffect(() => {
    if (focusedInput === 'search') {
      searchInputRef.current.focus();
    }

    if (focusedInput === 'location') {
      locationInputRef.current.focus();
    }
  }, [focusedInput]);


  // MAIN TEMPLATES
  const getRenderSubSubCategory = (topCategory, subCategory) => (subSubCategory) => {
    return (
      <React.Fragment key={subSubCategory.id}>
        <a 
          className="category-link sub-sub"
          onClick={() => changeSelectedCategoryId(subSubCategory.id)}
        >
          {subSubCategory.name}
          
          {(
            selectedCategoryId === subCategory.id || 
            selectedCategoryId === topCategory.id ||
            selectedCategoryId === subSubCategory.id
          ) && (
            <i className="fa-regular fa-check"></i>
          )}
        </a>
      </React.Fragment>
    );
  };
  const getRenderSubCategory = (topCategory) => (subCategory) => {
    const isSubCategoryOpened = openedCategoryIds.includes(subCategory.id);

    const subSubCategories = context.getState()?.allCategory?.categories.filter(k => (k.type === "SUBSUBCATEGORY" && k.parentId === subCategory.id));

    return (
      <React.Fragment key={subCategory.id}>
        {/** SUB CATEGORY LEVEL **/}
        <CollapseTrigger
          className="category-link sub"
          stateChanged={subSubCategories.length > 0 ? getChangeCollapseStateHandler(subCategory.id, isSubCategoryOpened) : (() => changeSelectedCategoryId(subCategory.id))}
          opened={isSubCategoryOpened}
          iconClassName={subSubCategories.length > 0 && ('fa-regular fa-angle-down')}
        >
          {subCategory.name}

          {(
            subSubCategories.length === 0 &&
            (
              selectedCategoryId === subCategory.id || 
              selectedCategoryId === topCategory.id
            )
          ) && (
            <i className="fa-regular fa-check"></i>
          )}
        </CollapseTrigger>

        {subSubCategories.length > 0 && (
          <Collapse isOpened={isSubCategoryOpened} theme={{collapse: 'ReactCollapse--collapse', content: 'category-list sub-sub'}}>
            <>
              <a
                className="category-link sub all"
                onClick={() => changeSelectedCategoryId(subCategory.id)}
              >
                {t('misc.all')}
                {(selectedCategoryId === subCategory.id || selectedCategoryId === topCategory.id) && (
                  <i className="fa-regular fa-check"></i>
                )}
              </a>

              {subSubCategories.map(getRenderSubSubCategory(topCategory, subCategory))}
            </>
          </Collapse>
        )}
      </React.Fragment>
    );
  };
  const renderTopCategory = (topCategory) => {
    const isTopCategoryOpened = openedCategoryIds.includes(topCategory.id);
    const subCategories = context.getState()?.allCategory?.categories.filter(k => (k.type === "SUBCATEGORY" && k.parentId === topCategory.id));

    return (
      <div key={topCategory.id} className="category-item top">

        <CollapseTrigger
          className="category-link" 
          opened={isTopCategoryOpened}
          stateChanged={subCategories.length > 0 ? getChangeCollapseStateHandler(topCategory.id, isTopCategoryOpened) : (() => changeSelectedCategoryId(topCategory.id))}
          iconClassName={subCategories.length > 0 && ('fa-regular fa-angle-down')}
          
        >
          {topCategory.name}

          {(subCategories.length === 0 && selectedCategoryId === topCategory.id) && (
            <i className="fa-regular fa-check"></i>
          )}

        </CollapseTrigger>

        {subCategories.length > 0 && (
          <Collapse isOpened={isTopCategoryOpened} theme={{collapse: 'ReactCollapse--collapse', content: 'category-list sub'}}>
            <>
              <a
                className="category-link sub all"
                onClick={() => changeSelectedCategoryId(topCategory.id)}
              >
                {t('misc.all')}

                {topCategory.id === selectedCategoryId && (
                  <i className="fa-regular fa-check"></i>
                )}
              </a>

              {subCategories.map(getRenderSubCategory(topCategory))}
            </>
          </Collapse>
        )}
      </div>
    );
  };

  const handleLocationEnter = () => {
    setFocusedInput('');
    locationInputRef.current.blur();

    if(locationValue !== ""){
      if (isNumeric(locationValue)) { // IF LOCATION IS NUMBER
        if (locationValue.length !== 4) {
          return;
        }
  
        context.onGetPostcodeParams([locationValue].toString());
      } else { // IF LOCATION IS CITY
        const postalCodeArray = locationConverter(locationValue);
  
        if (!Array.isArray(postalCodeArray) || postalCodeArray.length === 0) {
          setAlert({ alert: true, alertText: t('header.invalid_location') });
          return;
        }
  
        context.onGetPostcodeParams(postalCodeArray.toString());
      }
  
      context.setScrollOnMarketplaceAfterFilter();
      closeSidenav();
    }else{
      window.scrollTo({ top: 0, behavior: 'smooth' });
      context.clearState();
      closeSidenav();
    }
   

    if (location.pathname.indexOf('marketplace') === -1) {
      context.onParamsFalse();
      navigate('/marketplace');
    }else{
      context.onRenderTrue();
    }
  };

  return (
    <div className="sidenav-content buttons-and-cats">
      <MDBNavbarNav className="top-bar">
        {!context.isLoggedIn() && (
          <>
          <MDBNavbarItem 
            className="login" 
            onClick={() => {
              closeSidenav();
              context.openLoginModal();
            }}
          >
            {t('header.login')}
          </MDBNavbarItem>
  
          <MDBNavbarItem 
            className="signup" 
            onClick={() => {
              closeSidenav();
              context.openLoginModal(true);
            }}
          >
            {t('header.signup')}
          </MDBNavbarItem></>
        )}

        {context.isLoggedIn() && (
          <>
            {
              <MDBNavbarItem className="user" onClick={showUserMenu}>
                <img src={cookies.get('mylocation')?.clientImageUrl ? cookies.get('mylocation')?.clientImageUrl : profileImg} alt="Profile Image" className="profile-image" />
                
                {cookies.get('mylocation')?.clientName || t('misc.sample_user')}

                <i className="fa-regular fa-angle-right"></i>
              </MDBNavbarItem>
            }
          </>
        )}

        <HeaderLanguageSelector />
      </MDBNavbarNav>


      <MDBNavbarNav className="bottom-bar">
        {/*** SEARCH ***/}
        <MDBNavbarItem>
          <MDBInputGroup
            className={"search" + ((focusedInput === 'search') ? ' focus' : '') + ((focusedInput === 'location') ? ' width-1' : '')}
            ref={searchInputGroupRef}
            noWrap
            textBefore={(
              <i
                className="fa-regular fa-search"
                onClick={() => { setFocusedInput('search') }}
              ></i>
            )}
            textAfter={(focusedInput !== 'search') ? null : (
              <i
                className="fa-regular fa-times"
                onClick={() => {
                  setSearchValue('');
                  context.clearState();
                  // closeSidenav();
                }}
              ></i>
            )}
          >
            <input
              className='form-control'
              type='text'
              tabIndex="-1"
              placeholder={(focusedInput === 'search') ? '' : t('header.search')}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFocusedInput('');
                  searchInputRef.current.blur();

                  if(e.target.value !== ""){
                    context.onGetSearchTextParams(e.target.value);
                    context.setScrollOnMarketplaceAfterFilter();
                    closeSidenav();
                  }else{
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    context.clearState();
                    closeSidenav();
                  }
                  
                  if (location.pathname.indexOf('marketplace') === -1) {
                    context.onParamsFalse();
                    navigate('/marketplace');
                  }else{
                    context.onRenderTrue();
                  }
                }
              }}
              onFocus={() => setFocusedInput('search')}
              ref={searchInputRef}
            />
          </MDBInputGroup>
        </MDBNavbarItem>


        {/*** LOCATION ***/}
        <MDBNavbarItem>
          <MDBInputGroup
            className={"location" + (focusedInput === 'location' ? ' focus' : '') + ((focusedInput === 'search') ? ' width-1' : '')}
            ref={locationInputGroupRef}
            noWrap
            textBefore={(
              <i
                className="fa-regular fa-map-marker-alt"
                onClick={() => { setFocusedInput('location') }}
              ></i>
            )}
            textAfter={(focusedInput !== 'location') ? null : (
              <i
                className="fa-regular fa-times"
                onClick={() => {
                  setLocationValue('');
                  context.clearState();
                  // closeSidenav();
                }}
              ></i>
            )}
          >
            <input
              className='form-control'
              type='text'
              tabIndex="-1"
              placeholder={focusedInput === 'location' ? '' : t('header.location_placeholder')}
              value={locationValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLocationEnter();
                }
              }}
              onChange={e => setLocationValue(e.target.value)}
              onFocus={() => setFocusedInput('location')}
              ref={locationInputRef}
            />
          </MDBInputGroup>
        </MDBNavbarItem>


        {/*** FILTER TRIGGER ***/}
        <MDBNavbarItem className="filter">
          <MDBBtn color='tertiary' onClick={mobileFilterPanelOpen}>
            <MDBIcon far icon='filter' /> 
            {focusedInput === '' && t('header.filter')}
          </MDBBtn>
        </MDBNavbarItem>


      </MDBNavbarNav>

      
      <div className="categories-menu">  
        {context.getState()?.allCategory?.categories.filter(k => k.type === "CATEGORY").map(renderTopCategory)}
      </div>
    </div>
  );
};

export default withTranslation()(SidenavContentButtonsCategories);
