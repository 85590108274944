// react imports
import React from "react";

// IMAGES IMPORTS
import paymentMethodMastercard from '../../../images/payment-methods/mastercard.jpg';
import paymentMethodVisa from '../../../images/payment-methods/visa.jpg';
import paymentAmericanExpress from '../../../images/payment-methods/american-express.jpg';
import paymentApplePay from '../../../images/payment-methods/apple-pay.png';
import paymentGooglePay from '../../../images/payment-methods/gpay.png';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

/**
 * Props
 * @param displayHeader: boolean
 * @returns 
 */
const PaymentMethodList = ({displayHeader}) => {
  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="payment-methods">
      {displayHeader && (<h3>{t('checkout.payment_options')}</h3>)}

      <div className="payment-method-list">
        <div className={`card`}>
          <img src={paymentMethodMastercard} alt="Mastercard" />
        </div>

        <div className={`card`}>
          <img src={paymentMethodVisa} alt="Visa" />
        </div>

        <div className={`card`}>
          <img src={paymentAmericanExpress} alt="American Express" />
        </div>

        <div className={`card`}>
          <img src={paymentApplePay} alt="Apple Pay" />
        </div>

        <div className={`card`}>
          <img src={paymentGooglePay} alt="Google Pay" />
        </div>
      </div>

      {/* <div className="helper-text">
        {t('product.depending_on_seller')}
      </div> */}
    </div>
  );
};

export default withTranslation()(PaymentMethodList);