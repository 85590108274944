// MODEL IMPORTS
import { Sizes } from "./general-models";

// IMAGE IMPORTS
import ProductListItemImage1 from '../images/products/product-list-item-1.jpg';
import ProductListItemImage2 from '../images/products/product-list-item-2.jpg';
import ProductListItemImage3 from '../images/products/product-list-item-3.jpg';
import ProductListItemImage4 from '../images/products/product-list-item-4.jpg';
import ProductListItemImage5 from '../images/products/product-list-item-5.jpg';
import slideImage1 from '../images/service1.png';
import slideImage2 from '../images/service2.png';
import slideImage3 from '../images/service3.png';
import slideImage4 from '../images/wedding.jpg';
import slideImage5 from '../images/slider-5-image.jpg';
import slideImage6 from '../images/fdi.jpg';
import ProfileImg1 from '../images/comment-profile-1.jpg';
import ProfileImg2 from '../images/comment-profile-2.jpg';
import ProfileImg3 from '../images/comment-profile-3.jpg';
import sellerImage from '../images/seller-image.jpg';
import commentProfileImage1 from '../images/comment-profile-1.jpg';
import commentProfileImage2 from '../images/comment-profile-2.jpg';
import commentProfileImage3 from '../images/comment-profile-3.jpg';


// MODEL IMPORTS
import { Conditions } from "./general-models";

/** SellerModel:
 * {
 *  id: number,
 *  title: string,
 *  description: string,
 *  location: string,
 *  size: string,
 *  condition: string,
 *  price: number,
 *  image: string,
 *  slideImages: string[],
 *  bookmarked: boolean,
 *  comments: array of {
 *   image: string,
 *   name: string,
 *   commentText: string,
 *   dateTime: Date
 *  }
 * }
 */
export const Seller = {
  id: 1,
  image: sellerImage,
  name: 'Giana Carder',
  location: 'Zurich, Switzerland',
  followersNumber: 220,
};

/**
 * UserRating Model: based on swagger
 */
export const UserRatings = [
  {
    "id": 1,
    "buyer": {
      "id": 1,
      "image": {
        "id": 1,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "seller": {
      "id": 1,
      "image": {
        "id": 1,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "item": {
      "firstImage": {
        "id": 1,
        "url": ProductListItemImage1
      },
      "title": "Item Name 1",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*7)),
    "content": 'Sit tortor neque et ut magna erat con vallis. Et commodo lorem sit lectus. Sit tortor neque et ut magna erat convallis. Et commodo. 🙂',
    "star": 4
  },
  {
    "id": 2,
    "buyer": {
      "id": 2,
      "image": {
        "id": 2,
        "url": ProfileImg2
      },
      "publicName": 'Maria Saris'
    },
    "seller": {
      "id": 2,
      "image": {
        "id": 2,
        "url": ProfileImg2
      },
      "publicName": 'Marley Carder'
    },
    "item": {
      "firstImage": {
        "id": 2,
        "url": ProductListItemImage2
      },
      "title": "Item Name 2",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*13)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 3
  },
  {
    "id": 3,
    "buyer": {
      "id": 3,
      "image": {
        "id": 3,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "seller": {
      "id": 3,
      "image": {
        "id": 3,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "item": {
      "firstImage": {
        "id": 3,
        "url": ProductListItemImage3
      },
      "title": "Item Name 2",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*14)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 4
  },
  {
    "id": 4,
    "buyer": {
      "id": 4,
      "image": {
        "id": 4,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "seller": {
      "id": 4,
      "image": {
        "id": 4,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "item": {
      "firstImage": {
        "id": 4,
        "url": ProductListItemImage4
      },
      "title": "Item Name 4",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*14)),
    "content": 'Sit tortor neque et ut magna erat con vallis. Et commodo lorem sit lectus. Sit tortor neque et ut magna erat convallis. Et commodo. 🙂',
    "star": 4
  },
  {
    "id": 5,
    "buyer": {
      "id": 5,
      "image": {
        "id": 5,
        "url": ProfileImg2
      },
      "publicName": 'Maria Saris'
    },
    "seller": {
      "id": 5,
      "image": {
        "id": 5,
        "url": ProfileImg2
      },
      "publicName": 'Maria Saris'
    },
    "item": {
      "firstImage": {
        "id": 5,
        "url": ProductListItemImage5
      },
      "title": "Item Name 5",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*18)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 5
  },
  {
    "id": 6,
    "buyer": {
      "id": 6,
      "image": {
        "id": 6,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "seller": {
      "id": 6,
      "image": {
        "id": 6,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "item": {
      "firstImage": {
        "id": 6,
        "url": ProductListItemImage5
      },
      "title": "Item Name 6",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*21)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 5
  },
  {
    "id": 7,
    "buyer": {
      "id": 7,
      "image": {
        "id": 7,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "seller": {
      "id": 7,
      "image": {
        "id": 7,
        "url": ProfileImg1
      },
      "publicName": 'Marley Carder'
    },
    "item": {
      "firstImage": {
        "id": 7,
        "url": ProductListItemImage5
      },
      "title": "Item Name 7",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*23)),
    "content": 'Sit tortor neque et ut magna erat con vallis. Et commodo lorem sit lectus. Sit tortor neque et ut magna erat convallis. Et commodo. 🙂',
    "star": 4
  },
  {
    "id": 8,
    "buyer": {
      "id": 8,
      "image": {
        "id": 8,
        "url": ProfileImg2
      },
      "publicName": 'Maria Saris'
    },
    "seller": {
      "id": 8,
      "image": {
        "id": 8,
        "url": ProfileImg2
      },
      "publicName": 'Maria Saris'
    },
    "item": {
      "firstImage": {
        "id": 8,
        "url": ProductListItemImage5
      },
      "title": "Item Name 8",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*24)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 5
  },
  {
    "id": 9,
    "buyer": {
      "id": 9,
      "image": {
        "id": 9,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "seller": {
      "id": 9,
      "image": {
        "id": 9,
        "url": ProfileImg3
      },
      "publicName": 'Skylar Ekstrom Bothman'
    },
    "item": {
      "firstImage": {
        "id": 9,
        "url": ProductListItemImage5
      },
      "title": "Item Name 9",
      "grossPrice": 999
    },
    "created": new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*28)),
    "content": 'Justo mauris faucibus non sed massa tristique!',
    "star": 5
  }
];

/** ProductModel: 
 * {
 *  id: number,
 *  title: string,
 *  description: string,
 *  location: string,
 *  size: string,
 *  condition: string,
 *  price: number,
 *  image: string,
 *  slideImages: string[],
 *  bookmarked: boolean,
 *  comments: array of {
 *   image: string,
 *   name: string,
 *   commentText: string,
 *   dateTime: Date
 *  }
 * }
 */
export const SampleProducts = [
  {
    id: 1,
    title: 'Zara bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage1,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 2,
    title: 'Gucci coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 350, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 3,
    title: 'Yves Saint Laurent coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 3, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 4,
    title: 'Ralph Lauren dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage4,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 5,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage5,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 6,
    title: 'Taipa coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage1,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 7,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 8,
    title: 'Taipa t-shirt', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 9,
    title: 'Yves Saint Laurent bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage4,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 10,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage5,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 11,
    title: 'Zara bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 11, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 12,
    title: 'Gucci coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 50, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 13,
    title: 'Zara bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage1,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 14,
    title: 'Gucci coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 350, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 15,
    title: 'Yves Saint Laurent coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 3, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 16,
    title: 'Ralph Lauren dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage4,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 17,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage5,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 18,
    title: 'Taipa coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage1,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 19,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 20,
    title: 'Taipa t-shirt', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 21,
    title: 'Yves Saint Laurent bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage4,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 22,
    title: 'Zara dress', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 35, 
    image: ProductListItemImage5,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 23,
    title: 'Zara bag', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 11, 
    image: ProductListItemImage2,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
  {
    id: 24,
    title: 'Gucci coat', 
    description: 'Slim fit t-shirt lorem commodo vulputate et orci proin. Elementum, turpis at lectus vivamus suspendisse.',
    location: 'Zurich', 
    size: Sizes[Math.floor(Math.random()*Sizes.length)],
    condition: Conditions[Math.floor(Math.random()*Conditions.length)],
    price: 50, 
    image: ProductListItemImage3,
    slideImages: [slideImage1, slideImage2, slideImage2, slideImage3, slideImage4, slideImage5],
    bookmarked: false,
    comments: [
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*1.5)),
          },
        ]
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
      },
      {
        image: commentProfileImage1,
        name: 'Jane Stilo',
        commentText: 'Hello, I really like this t-shirt. What is lorem ipsum dolor sit?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
      },
      {
        image: commentProfileImage1,
        name: 'Cynthia Gion',
        commentText: 'Hello, lorem ipsum dolor?',
        dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*5)),
        replies: [
          {
            image: sellerImage,
            name: 'Giana Carder',
            commentText: 'Yes, definitely 🙂',
            dateTime: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*4)),
          },
        ]
      },
    ]
  },
];


