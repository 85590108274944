// REACT IMPORTS
import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const LegalNoticesPage = (props) => {
  // MULTILANGUAGE
  const { t, language } = i18n;

  const context = useContext(AppContext);
  // NAVIGATION
  const { hash } = useLocation();
  const navigate = useNavigate();

  //STATES
  const [privacypolicy, setPrivacypolicy] = useState();
  const [impressum, setImpressum] = useState();
  
  useEffect(() => {

    const idInHash = hash.replace('#', '');
    const scrollToElement = document.getElementById(idInHash);
    /*const scrollToElement = document.getElementById(hash.replace('#', ''));
    //console.log(hash.replace('#', ''));
    if (scrollToElement && !scrolled) {
      scrollToElement.scrollIntoView();
    }*/
    
    if (idInHash) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }

    context.interAxios.get('/privacy-policy/html', { params: { lang: cookies.get('mylanguage').language }},
      function(response) {
        setPrivacypolicy(response.data);
        context.onRender();

        if (idInHash.indexOf('privacy') !== -1) {
          setTimeout(() => {
             window.scrollTo({top: 830, behavior: 'smooth'});
          }, 300);
        }

        if (idInHash.indexOf('imprint') !== -1) {
          setTimeout(() => {
             window.scrollTo({top: 100, behavior: 'smooth'});
          }, 300);
        }
        
      }
    );

    
    
  }, []);

  useEffect(() => {
    
    //[].filter.call(document.getElementsByTagName('h2'), el => el.outerText.toLowerCase().includes(hash.replace('#', '').replace('-', ' ')))[0]?.scrollIntoView();
    window.scrollTo({top: [].filter.call(document.getElementsByTagName('h2'), el => el.outerText.toLowerCase().includes(hash.replace('#', '').replace('-', ' ')))[0]?.offsetTop - 130, behavior: 'smooth' });
  }, [hash]);
  
  

  return (
    <div className="jc-page gray-bc generic-text-page legal-notices-page">
      <div className="container-fluid mw-1040">
        <h1 data-german="Rechtliche Hinweise">
          <i 
            className="fa-regular fa-arrow-left"
            onClick={() => navigate(-1)}
          ></i>
          {t('misc.legal_notices')}
        </h1>

        <div className="white-panel">
          {/* <h2 className="pt-3">{t('footer.imprint')}</h2>
          
          <div dangerouslySetInnerHTML={{__html: impressum}}></div>

          <h2 className="pt-3">
            <span id="privacy-policy">-</span>
            {t('misc.privacy_policy')}
          </h2> */}
          
          <div dangerouslySetInnerHTML={{__html: privacypolicy !== undefined ? privacypolicy.replaceAll('target="_blank"', '').replaceAll('admin/', '') : <></>}}></div>
        </div>
      </div>
    </div>
  );
};

export default LegalNoticesPage;