import React, { useEffect} from 'react';


function ReturnPage() {

  useEffect(() => {
    close();
  }, []);
  
  return (
    <>
     
    </>
    
  );
};

export default ReturnPage;