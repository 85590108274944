// react imports
import React, { useState, useEffect, useContext } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// REACT DOM
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

// CUSTOM IMPORTS
import ProductImages from "../../common/product-images/ProductImages";
import ProductDetails from "../../common/product-details/ProductDetails";
import AppContext from '../../../AppContext';
// DATA IMPORTS
import { SampleProducts } from "../../../data/product-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const ProfileMyItemPage = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const params = useParams();
  // STATES
  const [product, setProduct] = useState({});
  const [sliderImages, setSliderImages] = useState([]);

  // MULTILANGUAGE
  const { t } = i18n;

  //events
  useEffect(() => {
    context.interAxios.get('/item/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1],
                  {params: {
                    lang: cookies.get('mylanguage').language
                  }},
     function(response) {
      setProduct(response.data);
      setSliderImages(response.data.images?.map(k => {return (k.url)}));
    })
  }, []);

  return (
    <div className="jc-page white product my-item">
      <div className="container-fluid main mw-1300">
        <div className="my-item-title">
          <i 
            className="fa-regular fa-arrow-left"
            onClick={() => navigate(-1)}
          ></i>
          {t('product.my_item')}
        </div>

        <ProductImages sliderImages={sliderImages}/>

        <ProductDetails
          product={product}
          sellable={false}
          setProduct={setProduct}
        />
      </div>
    </div>
  );
};

export default withTranslation()(ProfileMyItemPage);
