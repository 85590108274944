// react imports
import React, { useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';


/**
 * Props
 * @param visible: true
 * @param hideModal: () => void
 * @param infoIcon?: string (className)
 * @param title: string
 * @param text: string
 * @param buttonText?: string
 * @param buttonIconClass?: string
 * @param buttonClick?: () => {}
 * @returns
 */
const ConditionInfoModal = (props) => {


  return (
    <MDBModal
      className="standard-jc-modal w-615 general-info"
      show={props.visible}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={props.hideModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none'  type='button' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            {props.infoIcon && (
              <i className={props.infoIcon + " main-modal-icon"}></i>
            )}

            <h3>{props.title}</h3>

            <p className="my-1">{props.conditionArray ? props.conditionArray[0].text : ''}</p>
            <p className="my-1">{props.conditionArray ? props.conditionArray[1].text : ''}</p>
            <p className="my-1">{(props.conditionArray ? props.conditionArray[2].text : '') + " (" + props.text_very_good + ")"}</p>
            <p className="my-1">{(props.conditionArray ? props.conditionArray[3].text : '') + " (" + props.text_good + ")"}</p>
            <p className="my-1">{(props.conditionArray ? props.conditionArray[4].text : '') + " (" + props.text_acceptable + ")"}</p>
            

          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ConditionInfoModal;
