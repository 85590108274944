export const Categories = [
  {
    id: 0,
    name: 'Women',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 1,
    name: 'Men',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'Kids',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'Home',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Sports',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 5,
    name: 'Electronics',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 6,
    name: 'Entertainment',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 7,
    name: 'Pet care',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
];

export const GermanCategories = [
  {
    id: 0,
    name: 'Damen',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 1,
    name: 'Herren',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'Kinder',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'Möbel & Wohnen',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Sport',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 5,
    name: 'Elektronik',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 6,
    name: 'Unterhaltung',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
  {
    id: 7,
    name: 'Haustierbedarf',
    sections: [
      {
        id: 0,
        name: 'Clothing',
        categories: [
          { id: 0, name: 'Dresses' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Tops' },
          { id: 3, name: 'Bags' },
          { id: 4, name: 'Etc' },
          { id: 5, name: 'Amet felis' },
          { id: 6, name: 'Auctor mauris' },
          { id: 7, name: 'Ut in' },
          { id: 8, name: 'Non accumsan' },
          { id: 9, name: 'Scelerisque euddd' },
          { id: 10, name: 'Adipiscing nibh' },
          { id: 11, name: 'Mi suspendisse' },
        ]
      },
      {
        id: 1,
        name: 'Shoes',
        categories: [
          { id: 0, name: 'Purus non' },
          { id: 1, name: 'Shirts' },
          { id: 2, name: 'Amet felis' },
          { id: 3, name: 'Sed orci' },
          { id: 4, name: 'Dui sed' },
          { id: 5, name: 'Ut in' },
          { id: 6, name: 'Nunc facilisis' },
          { id: 7, name: 'Amet cras' },
          { id: 8, name: 'Auctor mauris' },
          { id: 9, name: 'Non accumsan' },
        ]
      },
      {
        id: 2,
        name: 'Accessories',
        categories: [
          { id: 0, name: 'Nunc facilisis' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      },
      {
        id: 3,
        name: 'Bags',
        categories: [
          { id: 0, name: 'Prous Non' },
          { id: 1, name: 'Amet felis' },
          { id: 2, name: 'Ut in' },
          { id: 3, name: 'Non accumsan' }
        ]
      }
    ]
  },
];
