import React from 'react';

/**
  * Props properties:
  * @param className: string
  * @param opened: boolean
  * @param stateChanged: callback: () => {}
  * @param iconClassName: string
 */
const CollapseTrigger = (props) => {
  const classes = ['collapse-trigger'];

  if (props.className) classes.push(props.className);
  if (props.opened) classes.push('opened');

  return (
    <div 
      className={classes.join(' ')} 
      onClick={() => props.stateChanged()}
    >
      <div className="collapse-trigger-content">
        {props.children}
      </div>

      {props.iconClassName && 
        <i className={props.iconClassName + " collapse-trigger-arrow"}></i>
      }
    </div>
  );
}

export default CollapseTrigger;