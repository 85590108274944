// react imports
import React, { useState, useEffect, useContext } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// REACT DOM IMPORTS
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

// CUSTOM IMPORTS
import ProductImages from "../../common/product-images/ProductImages";
import ProductScroller from "../../common/product-scroller/ProductScroller";
import RatingsStar from "../../common/ratings-star/RatingsStar";
import RatingsList from "../../common/ratings-list/RatingsList";
import RatingsModal from "../../modals/ratings-modal/RatingsModal";
import SellerCard from "../../common/seller-card/SellerCard";
import ProductDetails from "../../common/product-details/ProductDetails";
import AppContext from '../../../AppContext';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// DATA IMPORTS
import { Seller as seller, UserRatings, SampleProducts } from "../../../data/product-data";
import { Users } from "../../../data/user-data";

// MDBOOTSTRAP IMPORT
import { MDBAlert } from "mdb-react-ui-kit";



const ProductPage = () => {
  const context = useContext(AppContext);

  // NAVIGATE
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // REACT ROUTER
  const params = useParams();
  // console.log(params.productId);

  // STATES
  //const [product, setProduct] = useState(SampleProducts[Math.floor(Math.random()*SampleProducts.length)]);
  const [product, setProduct] = useState({});
  const [sliderImages, setSliderImages] = useState([]);
  const [followed, setFollowed] = useState(false);
  const [clientRatings, setClientRatings] = useState();

  // MODAL STATES
  const [showRatingsModal, setShowRatingsModal] = useState(false);

  // STATE
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  //EVENTS
  const clickOnBackButton = () => {
    if(searchParams.get("enableScrollOnLoading")){
      context.getProductId(searchParams.get("marketplaceProductId"));
      context.onParamsFalse();
      navigate('/marketplace');
    }else{
      context.onParamsFalse();
      navigate(-1);
    }
    
  }

  const onAddFollowed = (id) => {
    context.interAxios.put('/follow/' + id,
      function(response) {
        setFollowed(response.data.success);
      }
    )
  }

  const onRemoveFollowed = () => {
    context.interAxios.delete('/follow/' + id,
      function(response) {
        setFollowed(!response.data.succes);
      }
    )
  }

  // MULTILANGUAGE
  const { t } = i18n;

  //events
  useEffect(() => {
    context.interAxios.get('/item/' + window.location.pathname.split("/")[window.location.pathname.split("/").length-1], 
      { 
        params: 
        { 
          lang: cookies.get('mylanguage').language
        }
      },
      function(response) {
        setProduct(response.data);
        // console.log(response.data);
        setSliderImages(response.data.images?.map(k => {return (k.url)}));6*3500
        context.interAxios.get('/client/' + response.data.seller.id + '/review', function(response) {
          setClientRatings(response.data)
          
        });
      },
      function (error) {
        console.log('Error', error);

        if (error.response?.data?.message && error.response?.data?.message.indexOf('not found') !== -1) {
          navigate('/product/not-found');
        } else {
          setAlert({
            alert: true, 
            alertText: error.message
          }); 
        }
      }
    );
  }, [cookies.get('mylanguage').language, context.getState()?.isRender, window.location.pathname.split("/")[window.location.pathname.split("/").length-1]]);
  
  return (
    <div className="jc-page white product">
      <div className="container-fluid main mw-1300">

        <a className="back" onClick={clickOnBackButton}>
          <i className="fa-regular fa-arrow-left"></i>
          {t('misc.back')}
        </a>

        {/* product-column-1 */}
          <ProductImages sliderImages={sliderImages}/>

          <section className="seller">
            <header>{t('user_profile.seller')}</header>
            {product.seller !== undefined ?
            <SellerCard
              image={product.seller?.image?.url}
              name={product.seller?.publicName}
              sellerId={product.seller?.id}
              location={product.location?.location}
              rating={product.seller?.rating.stars}
              openRatingModal={() => setShowRatingsModal(true)}
              followed={product.seller?.isFollowed}
              onAddFollowed={() => onAddFollowed(product.seller?.id)}
              onRemoveFollowed={() => onRemoveFollowed(product.seller?.id)}
              clickOnImageAndTitle={() => navigate(`/user-profile/${product.seller.id}`)}
              displayFollowButton={true}
            />:false}
          </section>

          {clientRatings && clientRatings.content.length > 0 &&(
            <section className="ratings">
              <header>{t('user_profile.ratings')}</header>

              <RatingsList 
                ratings={clientRatings.content.slice(0, 3)} 
                displayItemName={true}
                exactDate={false}
                onReportRating={false}
                textcount={200}
              />
              {clientRatings.content.length > 3 && (
                <a 
                className="gray-link" 
                  onClick={() => setShowRatingsModal(true)}
                >
                  {t('user_profile.all_ratings')} <i className="fa-regular fa-angle-right"></i>
                </a>
              )}
              

              <RatingsModal
                ratings={clientRatings}
                sellerId={product.seller?.id}
                visible={showRatingsModal}
                hideModal={() => setShowRatingsModal(false)}
                displayItemName={true}
              />
            </section>
          )}
        {/* /product-column-1 */}

        {/* product-column-2 */}
          <ProductDetails 
            product={product}
            setProduct={setProduct}
            sellable={(cookies.get('mylocation')?.clientType === "PRIVATE" || !context.isLoggedIn()) ? true : false}
          />
        {/* /product-column-2 */}
      </div>

      {Array.isArray(product.similarItems) && product.similarItems.length > 0 ?
        <ProductScroller
          h2Title={t('product.similar_items')}
          products={product.similarItems}
        />:false}

      {/* <ProductScroller
        h2Title="Similar items"
        products={SampleProducts.slice(0, 8)} title='Product Scroller'
      /> */}

      <MDBAlert
        show={alert.alert}
        color='primary'
        autohide
        position='top-center'
        offset={50}
        delay={4000}
        appendToBody
        onClosed={() => { setAlert({alert: false, alertText: ""})}}
      >{alert.alertText}</MDBAlert>
    </div>
  );
};

export default withTranslation()(ProductPage);