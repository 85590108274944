// react imports
import React, { useContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM COMPONENTS IMPORTS
import AppContext from '../../../AppContext';
import SellerCard from "../../common/seller-card/SellerCard";
import ProductListItem from "../../common/product-list/product-list-item/ProductListItem";
import RatingsModal from "../../modals/ratings-modal/RatingsModal";
import EmptyTab from "../EmptyTab/EmptyTab";

// MODAL IMPORTS
import BookmarkOnlyModal from "../../modals/bookmark-only-modal/BookmarkOnlyModal";

// DATA IMPORT
import { Seller as seller, SampleProducts, UserRatings } from "../../../data/product-data";
import { Users } from "../../../data/user-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import ReportModal from "../../modals/report-modal/ReportModal";


const ProfilePage = (props) => {
  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);
  // STATES
  const [followed, setFollowed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('For sale');
  const [showRatingsModal, setShowRatingsModal] = useState(false);
  const [clientRatings, setClientRatings] = useState({});

  const [myProfile, setMyProfile] = useState({});
  const [mySellItems, setMySellItems] = useState([]);
  const [myBoughtItems, setMyBoughtItems] = useState([]);
  const [mySoldItems, setMySoldItems] = useState([]);

  const [pageSizeSell, setPageSizeSell] = useState(20);
  const [loadingNewProductsSell, setLoadingNewProductsSell] = useState(false);
  const [moreProductsAvailableSell, setMoreProductsAvailableSell] = useState(false);
  const [totalPageSell, setTotalPageSell] = useState(0);

  const [pageSizeBought, setPageSizeBought] = useState(20);
  const [loadingNewProductsBought, setLoadingNewProductsBought] = useState(false);
  const [moreProductsAvailableBought, setMoreProductsAvailableBought] = useState(false);
  const [totalPageBought, setTotalPageBought] = useState(0);

  const [pageSizeSold, setPageSizeSold] = useState(20);
  const [loadingNewProductsSold, setLoadingNewProductsSold] = useState(false);
  const [moreProductsAvailableSold, setMoreProductsAvailableSold] = useState(false);
  const [totalPageSold, setTotalPageSold] = useState(0);

  const [reviewId, setReviewId] = useState();

  // MODAL STATES
  const [bookmarkModalVisible, setBookmarkModalVisible] = useState(false);
  const [reportRatingModalVisible, setReportRatingModalVisible] = useState(false);

  // MULTILANGUAGE
  const { t } = i18n;

  const loadNewProductsSell = () => {
    setLoadingNewProductsSell(true);
    setTimeout(() => {
      setPageSizeSell(pageSizeSell + 20),
      context.onRenderTrue();
      setLoadingNewProductsSell(false);
    }, 500);
  };
  const loadNewProductsBought = () => {
    setLoadingNewProductsBought(true);
    setTimeout(() => {
      setPageSizeBought(pageSizeBought + 20),
      context.onRenderTrue();
      setLoadingNewProductsBought(false);
    }, 500);
  };
  const loadNewProductsSold = () => {
    setLoadingNewProductsSold(true);
    setTimeout(() => {
      setPageSizeSold(pageSizeSold + 20),
      context.onRenderTrue();
      setLoadingNewProductsSold(false);
    }, 500);
  };

  useEffect(() => {
      context.interAxios.get('/client/me', function(response) {
         setMyProfile(response.data);
         context.interAxios.get('/client/' + response.data.id + '/item',
                   {params: {
                     lang: cookies.get('mylanguage').language,
                     pageIndex: 0,
                     pageSize: pageSizeSell
                   }},
         function(response) {
           setMySellItems(response.data.content);
           setTotalPageSell(response.data.totalElements)
           setPageSizeSell(response.data.pageSize > 20 ? response.data.pageSize : 20)
           if(response.data.totalElements > response.data.pageSize){
             setMoreProductsAvailableSell(true);
           }else{
             setMoreProductsAvailableSell(false);
           }
         });
         if(response.data.clientType !== "BUSINESS"){
         context.interAxios.get('/client/' + response.data.id + '/order/buyer',
                   {params: {
                     lang: cookies.get('mylanguage').language,
                     pageIndex: 0,
                     pageSize: pageSizeBought
                   }},
         function(response) {
           setMyBoughtItems(response.data.content);
           setTotalPageBought(response.data.totalElements)
           setPageSizeBought(response.data.pageSize > 20 ? response.data.pageSize : 20)
           if(response.data.totalElements > response.data.pageSize){
             setMoreProductsAvailableBought(true);
           }else{
             setMoreProductsAvailableBought(false);
           }
         });
        }
         context.interAxios.get('/client/' + response.data.id + '/order/seller',
                   {params: {
                     lang: cookies.get('mylanguage').language,
                     pageIndex: 0,
                     pageSize: pageSizeSold
                   }},
         function(response) {
             setMySoldItems(response.data.content);
             setTotalPageSold(response.data.totalElements)
             setPageSizeSold(response.data.pageSize > 20 ? response.data.pageSize : 20)
             if(response.data.totalElements > response.data.pageSize){
               setMoreProductsAvailableSold(true);
             }else{
               setMoreProductsAvailableSold(false);
             }
         });
         context.onRender();
      });
      context.interAxios.get('/client/' + cookies.get('mylocation')?.clientId + '/review', function(response) {
        setClientRatings(response.data)
      });
  }, [context.getState()?.isRender]);
  
  return (
    <div className="jc-page white profile">
      <div className="container-fluid mw-1300">
        {myProfile.id !== undefined ? (
          <>
            <SellerCard
              image={myProfile.image?.url}
              name={myProfile.publicName}
              sellerId={myProfile.id}
              location={myProfile.location?.location}
              rating={myProfile.rating?.stars}
              openRatingModal={() => setShowRatingsModal(true)}
              displayFollowButton={false}
              reportEnabled={false}
            />

            <RatingsModal
              ratings={clientRatings}
              sellerId={cookies.get('mylocation')?.clientId}
              visible={showRatingsModal}
              onReportRating={(reviewId) => {
                setShowRatingsModal(false);
                setReviewId(reviewId);

                setTimeout(() =>{
                  setReportRatingModalVisible(true);
                }, 500);
              }}
              hideModal={() => setShowRatingsModal(false)}
              displayItemName={true}
            />

            <ReportModal
              visible={reportRatingModalVisible}
              hideModal={() => setReportRatingModalVisible(false)}
              type={"REVIEW"}
              id={reviewId}
            />

            <div className="jc-tabs tabs-3">
              <div
                className={"jc-tab" + (selectedTab === 'For sale' ? ' active' : '')}
                onClick={() => setSelectedTab('For sale')}
              >
                {t('user_profile.for_sale')} ({ mySellItems.length})
              </div>
              <div
                className={"jc-tab" + (selectedTab === 'Sold' ? ' active' : '')}
                onClick={() => setSelectedTab('Sold')}
              >
                {t('user_profile.sold')}  ({ mySoldItems.length})
              </div>
              {!context.isLoggedInBusiness() && (
                <div
                  className={"jc-tab" + (selectedTab === 'Bought' ? ' active' : '')}
                  onClick={() => setSelectedTab('Bought')}
                >
                  {t('user_profile.bought')}  ({ myBoughtItems.length})
                </div>
              )}
             
            </div>
          </>
        ) : false }
      </div>

      <div className="gray-section">
        <div className="container-fluid mw-1300">
        {(selectedTab === 'For sale' && (<>
            {mySellItems.length > 0 ?  
              <div className="product-list">
              {mySellItems.map(product => (
                <ProductListItem
                  {...product}
                  key={product.id}
                  linkToMyItem={true}
                  displayCheckoutButton={false}
                  displayUnansweredCommentsDot={true}
                />
              ))}
            </div> : <EmptyTab text={t('user_profile.on_sale_2')}/>
            }
             {moreProductsAvailableSell && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link" + (loadingNewProductsSell ? " loading" : "")}
                  onClick={loadNewProductsSell}
                >
                  <i className="fa-regular fa-arrow-rotate-right"></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}</>
          ))}

          {(selectedTab === 'Sold' && (<>
            {mySoldItems.length > 0 ?  
              <div className="product-list">
              {mySoldItems.map(product => (
                <ProductListItem
                  {...product}
                  key={product.id}
                  linkToMyItem={true}
                  displayCheckoutButton={false}
                  displayUnansweredCommentsDot={true}
                  isOrderItem={true}
                  orderType={"Sold"}
                />
              ))}
            </div> : <EmptyTab text={t('user_profile.sold_2')}/>
            }
            {moreProductsAvailableSold && (
                <div className="product-list pt-4">
                  <button
                    className={"gray-link" + (loadingNewProductsSold ? " loading" : "")}
                    onClick={loadNewProductsSold}
                  >
                    <i className="fa-regular fa-arrow-rotate-right"></i>
                    {t('misc.load_more')}
                  </button>
                </div>
              )}</>
          ))}
          {(selectedTab === 'Bought' && !context.isLoggedInBusiness() && (<>
            {myBoughtItems.length > 0 ?  
              <div className="product-list">
              {myBoughtItems.map(product => (
                <ProductListItem
                  {...product}
                  key={product.id}
                  linkToMyItem={true}
                  displayCheckoutButton={false}
                  displayUnansweredCommentsDot={true}
                  isOrderItem={true}
                  orderType={"Bought"}
                  withoutReview={product.buyerReview === null ? true : false}
                />
              ))}
            </div> : <EmptyTab text={t('user_profile.bought_2')}/>
            }
            {moreProductsAvailableBought && (
              <div className="product-list pt-4">
                <button
                  className={"gray-link" + (loadingNewProductsBought ? " loading" : "")}
                  onClick={loadNewProductsBought}
                >
                  <i className="fa-regular fa-arrow-rotate-right"></i>
                  {t('misc.load_more')}
                </button>
              </div>
            )}</>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ProfilePage);
