// react imports
import React, { useContext, useState, useEffect } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';
import AppContext from '../../../AppContext';

// DATA IMPORTS
import { SampleProducts } from '../../../data/product-data';

// CUSTOM COMPONENTS
import ProductListItem from "../../common/product-list/product-list-item/ProductListItem";


const ShoppingListPage = (props) => {
  //const products = SampleProducts.sort(() => .5 - Math.random()).slice(0,3);

  // MULTILANGUAGE
  const { t } = i18n;

  const context = useContext(AppContext);

  //STATE
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(false);
  const [loadingNewProducts, setLoadingNewProducts] = useState(false);
  const [totalPage, setTotalPage] = useState(0);

  //events
 useEffect(() => {
      context.interAxios.get('/bookmark',
                    {params: {
                      lang: cookies.get('mylanguage').language,
                      pageIndex: 0,
                      pageSize: pageSize
                    }},
       function(response) {
        setProducts(response.data.content);
        setTotalPage(response.data.totalElements)
        setPageSize(response.data.pageSize > 20 ? response.data.pageSize : 20)
        if(response.data.totalElements > response.data.pageSize){
          context.onGetPageSize(response.data.pageSize)
          setMoreProductsAvailable(true);
        }else{
          context.onGetPageSize(response.data.totalElements)
          setMoreProductsAvailable(false);
        }
        context.onRender();
      })
  }, [context.getState()?.isRender]);

  const loadNewProducts = () => {
    setLoadingNewProducts(true);
    setTimeout(() => {
      setPageSize(pageSize + 20),
      context.onRenderTrue();
      setLoadingNewProducts(false);
    }, 500);
  };
  
  return (
    <div className="jc-page gray-bc">
      <div className="container-fluid mw-1300">
        <h1>
          {t('misc.shopping_list')}
        </h1>
        
        <div className="product-list">
          {products.map(product => (
            <ProductListItem 
              {...product} 
              key={product.id}
              displayBookmarkIcon={true}
              openBookmarkModal={() => setBookmarkModalVisible(true)}
              displayCheckoutButton={cookies.get('mylocation').clientType === "PRIVATE" ? true : false}
            />
          ))}
        </div>
        {moreProductsAvailable && (
            <div className="product-list pt-4">
              <button
                className={"gray-link" + (loadingNewProducts ? " loading" : "")}
                onClick={loadNewProducts}
              >
                <i className="fa-regular fa-arrow-rotate-right"></i>
                {t('misc.load_more')}
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default withTranslation()(ShoppingListPage);
