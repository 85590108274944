// react imports
import React, { useState } from "react";

// react router dom
import { useNavigate } from "react-router";

// prime react imports
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdb-react-ui-kit';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


/**
 * Props
 * @param orderId: number
 * @param hideModal: () => void
 * @param onDeleteMessageGroupById: () => void
 * @returns
 */
const MessageThreadDeleteModal = (props) => {
  // MULTILANGUAGE
  const { t } = i18n;

  // STATES
  const [deleteState, setDeleteState] = useState('NOT_DELETED'); // NOT_DELETED, DELETING, DELETED 

  const closeModal = () => {
    props.hideModal();

    setTimeout(() => setDeleteState('NOT_DELETED'), 500);
  }

  return (
    <MDBModal
      className="standard-jc-modal product-delete"
      show={Number.isInteger(props.orderId)}
      backdrop={true}
      staticBackdrop={true}
      tabIndex='-1'
      onHidePrevented={closeModal}
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle>Modal title</MDBModalTitle> */}
            <MDBBtn className='btn-close' color='none' onClick={closeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>

            <i className="fa-regular fa-exclamation-triangle main-modal-icon"></i>

            {deleteState !== 'DELETED' && (
              <>
                <h3>{t('messages.delete_thread_modal_title')}</h3>

                <p>{t('messages.delete_thread_modal_text')}</p>
    
                <button className="jc-btn black" onClick={() => {
                  setDeleteState('DELETING');
                  props.onDeleteMessageGroupById(props.orderId);
                  setTimeout(() => {
                    setDeleteState('DELETED');
                  }, 1000);
                }}>
                  {deleteState === 'DELETING' && <i className="fa-regular fa-spinner-third spinning"></i>}
    
                  {deleteState !== 'DELETING' && (
                    <>
                      <i className="fa-regular fa-trash-alt"></i>
                      {t('messages.delete_thread_modal_button_text')}
                    </>
                  )}
                </button>
    
                <a
                  className="brown-link"
                  onClick={closeModal}
                >
                  {t('messages.delete_thread_modal_cancel_text')}
                </a>
              </>
            )}

            {deleteState === 'DELETED' && (
              <p className="brown">{t('messages.thread_deleted')}</p>
            )}
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default withTranslation()(MessageThreadDeleteModal);

