import notifUser1 from "../images/notif-user.jpg";

// export class NotificationItem {
//   constructor(
//     public title: string,
//     public description: string,
//     public imageUrl: string,
//     public date: Date,
//     public readStatus: boolean
//   ) {}
// };

export const AllNotifications = [
  {
    id: 0,
    title: '1 new comment on your item',
    description: '',
    imageUrl: notifUser1,
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*12)),
    readStatus: false
  },
  {
    id: 1,
    title: 'Rate te seller!',
    description: 'Sit tortor neque et ut magna erat convallis et commodo lorem sit lectus.',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*14)),
    readStatus: false
  },
  {
    id: 2,
    title: '🎉 Congratulation! You have 1 new follower',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*17)),
    readStatus: false
  },
];

export const UnreadNotifications = [
  {
    id: 3,
    title: 'Rate te seller!',
    description: 'Sit tortor neque et ut magna erat convallis et commodo lorem sit lectus.',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600)),
    readStatus: true
  },
  {
    id: 4,
    title: '🎉 Congratulation! You have 1 new follower',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*2)),
    readStatus: true
  },
  {
    id: 5,
    title: '1 new comment on your item',
    description: '',
    imageUrl: notifUser1,
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*3)),
    readStatus: true
  },
];

export const NotificationsDifferentTimes = [
  {
    id: 6,
    title: '1 new comment on your item',
    description: '',
    imageUrl: notifUser1,
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*12)),
    readStatus: true
  },
  {
    id: 7,
    title: 'Rate te seller!',
    description: 'Sit tortor neque et ut magna erat convallis et commodo lorem sit lectus.',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*14)),
    readStatus: true
  },
  {
    id: 8,
    title: '🎉 Congratulation! You have 1 new follower',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*17)),
    readStatus: true
  },
  {
    id: 9,
    title: 'Notification from 5 days ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*5)),
    readStatus: true
  },
  {
    id: 10,
    title: 'Notification from 1 month ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*30)),
    readStatus: true
  },
  {
    id: 11,
    title: 'Notification from 4 month ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*30*4)),
    readStatus: true
  },
  {
    id: 12,
    title: 'Notification from 360 days ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*360)),
    readStatus: true
  },
  {
    id: 13,
    title: 'Notification from 1 year ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*365)),
    readStatus: true
  },
  {
    id: 14,
    title: 'Notification from 2 year ago',
    description: '',
    imageUrl: '',
    date: new Date(new Date().setSeconds(new Date().getSeconds() - 3600*24*30*25)),
    readStatus: true
  },
  {
    id: 15,
    title: 'Notification from 95',
    description: '',
    imageUrl: '',
    date: new Date('1995-12-17T03:24:00'),
    readStatus: true
  }
];

