// react imports
import React from "react";

// MDBOOTSTRAP
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

/**
 * Props:
 * @param className?: string
 * @param items: array of JSX
 * @returns 
 */
const ActionMenu = ({className, items}) => {
  return (
    <MDBDropdown className={`action-menu ${className}`}>
      <MDBDropdownToggle color='link'>
        <i className="fa-regular fa-ellipsis"></i>
      </MDBDropdownToggle>
      <MDBDropdownMenu>
        {items.map((item, num) => (
          <MDBDropdownItem key={num}>
            {item}
          </MDBDropdownItem>
        ))}
      </MDBDropdownMenu>
    </MDBDropdown>
  );
};

export default ActionMenu;