// REACT IMPORTS
import { useState, useEffect } from 'react';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

// HELPER FUNCS
import { renderTextWithNewLines } from '../../../data/helper-funcs';

// IMAGES
import aboutUsImage from "../../../images/about-us/about-us.jpg";


const AboutUsPage = (props) => {
  // MULTILANGUAGE
  const { t, language } = i18n;


  return (
    <div className="jc-page generic-text-page about-us">
      {language === 'en' && (
        <>
          <header>
            <div className="container-fluid mw-1040">
              <h1>The Home of Pre-Loved Treasures and Last-Season Wonders</h1>
            </div>
          </header>

          <div className="content container-fluid mw-1040">
            <h2>
              Welcome to J&C World
            </h2>

            <p>Fashion is our passion, sustainability our driver. It was this drive that led founder and CEO Stela Ukaj to found J&C World in 2022. </p>

            <p>We firmly believe that trading second-hand products is a fantastic way to conserve resources and give items a longer life. With our uncomplicated and stylish platform, we want to raise awareness for sustainable consumption and bring about a positive change in society.</p>

            <p>The younger generation is particularly close to our hearts. We see them as the driving force for change. With J&C World, we want to ignite enthusiasm. For a sustainable future. </p>

            <img src={aboutUsImage} />

            <p>From website usability to secure transactions and efficient customer service, your experience with us is our top priority. </p>

            <p>Become a part of our community. Together we can make a difference!</p>
          </div>
        </>
      )}

      {language === 'de' && (
        <>
          <header>
            <div className="container-fluid mw-1040">
              <h1>The Home of Pre-Loved Treasures and Last-Season Wonders</h1>
            </div>
          </header>

          <div className="content container-fluid mw-1040">
            <h2>
              Willkommen bei J&C World
            </h2>

            <p>Mode ist unsere Leidenschaft, Nachhaltigkeit unser Treiber. Aus diesem Antrieb heraus hat Founderin und CEO Stela Ukaj J&C World im Jahr 2022 gegründet. </p>

            <p>Wir sind fest davon überzeugt, dass der Handel mit Second-Hand-Produkten eine fantastische Möglichkeit bietet, Ressourcen zu schonen und Gegenständen ein längeres Leben zu schenken. Mit unserer unkomplizierten und stilvollen Plattform möchten wir das Bewusstsein für nachhaltigen Konsum stärken und einen positiven Wandel in der Gesellschaft bewirken.</p>

            <p>Besonders am Herzen liegt uns die jüngere Generation. Sie sehen wir als treibende Kraft für Veränderungen. Mit J&C World wollen wir Begeisterung entfachen. Für eine nachhaltige Zukunft. </p>

            <img src={aboutUsImage} />

            <p>Von der Benutzerfreundlichkeit der Website bis hin zu sicheren Transaktionen und einem effizienten Kundenservice – dein Erlebnis bei uns steht für uns an erster Stelle.</p>

            <p>Werde ein Teil unserer Community. Gemeinsam können wir einen Unterschied machen!</p>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(AboutUsPage);
