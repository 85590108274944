// REACT IMPORTS
import { useRef, useEffect } from "react";

/**
 * Props:
 * @param className: string
 * @param id?: string
 * @param shown: boolean
 * @param closeSidenav?: boolean
 * @param clickOutside: () => {}
 * @param children: JSX - the react default parameter
 * @returns 
 */
const CustomBsSidenav = ({
  className,
  id,
  shown,
  closeSidenav,
  clickOutside,
  children
}) => {
  // REFS
  const sidenavRef = useRef(null);

  // DETECT OUTSIDE CLICK - https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target) && clickOutside) {
        clickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidenavRef]);

  return (
    <div 
      className={`custom-bs-sidenav sidenav sidenav-primary ${shown ? 'sidenav-shown' : 'sidenav-hidden'} sidenav-right ${className}`}
      ref={sidenavRef}
      id={id}
    >
      {children}
    </div>
  );
};

export default CustomBsSidenav;