// react imports
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import { MDBBtnGroup, MDBCol, MDBRadio, MDBCheckbox } from 'mdb-react-ui-kit';


// CUSTOM IMPORTS
import PaymentMethodList from "../../common/payment-method-list/PaymentMethodList";
import AppContext from '../../../AppContext';

// IMAGES IMPORTS
import productImg from '../../../images/wedding.jpg';

// 3RD PARTY IMPORTS
import { Formik } from "formik";
import * as Yup from 'yup';
import Toggle from "react-toggle";
import postalCodes from 'switzerland-postal-codes';

// MDBOOTSTRAP IMPORTS
import { MDBSelect } from "mdb-react-ui-kit";

// DATA IMPORTS
import { SampleProducts } from "../../../data/product-data";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const products = SampleProducts.sort(() => .5 - Math.random()).slice(0,1);


const CheckoutPage = (props) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const params = useParams();
  // REF FOR FORMIK
  const formikRef = React.useRef(null);

  // STATES
  const [takeoverMode, setTakeoverMode] = useState();
  const [addressesSame, setAddressesSame] = useState(true);
  const [termsConditionsAccepted, setTermsConditionsAccepted] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [billingAddressEdited, setBillingAddressEdited] = useState(false);
  const [deliveryAddressEdited, setDeliveryAddressEdited] = useState(true);
  const [product, setProduct] = useState();
  const [buyerprovided, setBuyerprovided] = useState(false);
  const [priceWithShipping, setPriceWithShipping] = useState();
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);
  const [serverError, setServerError] = useState('');

  // POSTAL CODE + CITY STATES
  const [loadingBillingCities, setLoadingBillingCities] = useState(false);
  const [loadedBillingCities, setLoadedBillingCities] = useState(null);
  const [loadingDeliveryCities, setLoadingDeliveryCities] = useState(false);
  const [loadedDeliveryCities, setLoadedDeliveryCities] = useState(null);

  // FORMIK VALIDATON OBJECT
  const getYupValidationObject = () => {
    const yupValidationObject = {
      billingFirstName: Yup.string().required('First name is required'),
      billingLastName: Yup.string().required('Last name is required'),
      billingAddress: Yup.string().required('Address is required'),
      billingPostalCode: Yup.string().required('Postal code is required'),
      billingCity: Yup.string().required('City is required'),
      billingPhoneNumber: Yup.string().required('Phone number is required'),
      billingEmail: Yup.string().required('Email is required'),
      termsAndCondition: Yup.boolean()
        .required()
        .oneOf([true], 'You must accept the Terms and Conditions.'),
      privacyPolicy: Yup.boolean()
        .required()
        .oneOf([true], 'You must accept the Privacy Policy.')
    };

    if (!addressesSame && takeoverMode === 'Shipping') {
      yupValidationObject.deliveryFirstName = Yup.string().required('First name is required');
      yupValidationObject.deliveryLastName = Yup.string().required('Last name is required');
      yupValidationObject.deliveryAddress = Yup.string().required('Address is required');
      yupValidationObject.deliveryPostalCode = Yup.string().required('Postal code is required');
      yupValidationObject.deliveryCity = Yup.string().required('City is required');
    }

    return yupValidationObject;
  };
  const fillDeliveryAddress = (formik) => {
    formik.setFieldValue('deliveryFirstName', formik.values.billingFirstName, false);
    formik.setFieldValue('deliveryLastName', formik.values.billingLastName, false);
    formik.setFieldValue('deliveryAddress', formik.values.billingAddress, false);
    formik.setFieldValue('deliveryPostalCode', formik.values.billingPostalCode, false);
    formik.setFieldValue('deliveryCity', formik.values.billingCity, false);

    if (!isNaN(formik.values.billingPostalCode) && postalCodes[formik.values.billingPostalCode]) {
      setLoadedDeliveryCities(postalCodes[formik.values.billingPostalCode].split(" - "));
    }
  };

  // HELPER FUNCS
  const getCitiesOptions = (selectedCity, loadedCities) => {
    return [
      {
        text: t('user_settings.please_select_city'),
        value: '',
        defaultSelected: selectedCity === ''
      },
      ...loadedCities.map(city => ({
        text: city,
        value: city,
        defaultSelected: city === selectedCity
      }))
    ];
  };
  const isSaveBillingAddressButtonVisible = (formik) => {
    if (formik.errors.billingAddress ||
      formik.errors.billingCity ||
      formik.errors.billingFirstName ||
      formik.errors.billingLastName ||
      formik.errors.billingPhoneNumber ||
      formik.errors.billingPostalCode ||
      formik.errors.billingEmail
    ) {
      return false;
    }

    if (!formik.values.billingAddress ||
      !formik.values.billingCity ||
      !formik.values.billingFirstName ||
      !formik.values.billingLastName ||
      !formik.values.billingPhoneNumber ||
      !formik.values.billingPostalCode ||
      !formik.values.billingEmail
    ) {
      return false;
    }

    return true;
  };
  const isSaveDeliveryAddressButtonVisible = (formik) => {
    if (formik.errors.deliveryAddress ||
      formik.errors.deliveryCity ||
      formik.errors.deliveryFirstName ||
      formik.errors.deliveryLastName ||
      formik.errors.deliveryPostalCode 
    ) {
      return false;
    }

    if (!formik.values.deliveryAddress ||
      !formik.values.deliveryCity ||
      !formik.values.deliveryFirstName ||
      !formik.values.deliveryLastName ||
      !formik.values.deliveryPostalCode 
    ) {
      return false;
    }

    return true;
  };

  // REVALIDATE WHEN TAKEOVER MODE AND ADDRESSES CHNAGES
  useEffect(() => {
    if(product){
      formikRef.current.validateForm();
    }
  }, [takeoverMode, addressesSame, product]);

  useEffect(() => {
    context.isStripeCancelFalse();
    context.interAxios.post('/item/' + params.productId + '/checkout',
                      {
                        sizeId: params.sizeId
                      },
      function(response) {
        setProduct(response.data)
        setBuyerprovided(response.data.buyer !== null)
        setTakeoverMode(response.data.shippingOptions[0].name === 'POST' ? 'Shipping' : 'Pick Up')
        setPriceWithShipping(response.data.shippingOptions.filter(k => k.name ==="POST")[0]?.price + response.data.grossPrice)
      }
    )
  }, []);

  // SET LOADED CITIES WHEN PRODUCT LOADED
  useEffect(() => {
    if (product) {
      if (!isNaN(product.buyer?.address?.postcode) && !loadedBillingCities) {
        if (postalCodes[product.buyer?.address?.postcode]) {
          setLoadedBillingCities(postalCodes[product.buyer?.address?.postcode].split(" - "));
          setLoadedDeliveryCities(postalCodes[product.buyer?.address?.postcode].split(" - "));
        }
      }
    }
  }, [product]);


  let billingPostalCodeSearchTimeoutId = null;
  let deliveryPostalCodeSearchTimeoutId = null;

  // MULTILANGUAGE
  const { t } = i18n;

  return (
    <div className="jc-page gray-bc checkout">
      <div className="container-fluid mw-1300">
        <h1>
          {t('checkout.title')}
        </h1>
        {product && (
          <Formik
          innerRef={formikRef}
          initialValues = { {billingFirstName: product.buyer?.firstName,
                            billingLastName: product.buyer?.lastName,
                            billingAddress: product.buyer?.address?.address,
                            billingPostalCode: product.buyer?.address?.postcode,
                            billingCity: product.buyer?.address?.location,
                            billingPhoneNumber: product.buyer?.phoneNumber,
                            billingEmail: product.buyer?.email,
                            deliveryFirstName: product.buyer?.firstName,
                            deliveryLastName: product.buyer?.lastName,
                            deliveryAddress: product.buyer?.address?.address,
                            deliveryPostalCode: product.buyer?.address?.postcode,
                            deliveryCity: product.buyer?.address?.location,
                            termsAndCondition: false,
                            privacyPolicy: false}
                          }
          onSubmit = {(values) => {
            setServerError('');

            const deliveryCity = (takeoverMode === 'Shipping' && addressesSame) ? values.billingCity : values.deliveryCity; 
            const deliveryPostalCode = (takeoverMode === 'Shipping' && addressesSame) ? values.billingPostalCode : values.deliveryPostalCode; 
            const deliveryAddress = (takeoverMode === 'Shipping' && addressesSame) ? values.billingAddress : values.deliveryAddress; 
            const deliveryLastName = (takeoverMode === 'Shipping' && addressesSame) ? values.billingLastName : values.deliveryLastName; 
            const deliveryFirstName = (takeoverMode === 'Shipping' && addressesSame) ? values.billingFirstName : values.deliveryFirstName; 

            setFormHasBeenSubmitted(true);
            context.interAxios.post('/item/' + params.productId + '/pay?lang=' + cookies.get('mylanguage')?.language,
                        {
                          shippingOptionName: takeoverMode === "Pick Up" ? "PICKUP" : "POST",
                          sizeId: params.sizeId,
                          buyer: {
                            lastName: values.billingLastName,
                            firstName: values.billingFirstName,
                            email: values.billingEmail,
                            phoneNumber: values.billingPhoneNumber,
                            address: {
                              location: values.billingCity,
                              countryCode: "CH",
                              postcode: values.billingPostalCode,
                              address: values.billingAddress
                            },
                            shippingAddress: {
                              location: deliveryCity,
                              countryCode: "CH",
                              postcode: deliveryPostalCode,
                              address: deliveryAddress,
                              lastName: deliveryLastName,
                              firstName: deliveryFirstName
                            }
                          }
                        },
            function(response) {
              setFormHasBeenSubmitted(false);
              
              navigate(
                '/payment',
                {
                  state: {
                    clientSecret: response.data.clientSecret,
                    authToken: response.data.authToken,
                    orderId: response.data.orderId,
                    productId: params.productId,
                    sizeId: params.sizeId
                  }
                }
              )
            },
            function(error) {
              setFormHasBeenSubmitted(false);

              if (!(error?.response?.data?.message)) {
                return true;
              }

              setServerError(t('error_messages_from_backend.' + error?.response?.data?.message));
              return false;
            },
            );

           

            //navigate('/checkout/confirmation');
          }}
          validationSchema={Yup.object(getYupValidationObject())}
        >
          {formik => {

            return (
              <form 
                onSubmit={formik.handleSubmit} 
                className="profile-form"
                onChange={e => setServerError('')}
              >
                <div className="checkout-column-1">

                  {/* ITEM PANEL */}
                  <div className="white-panel rounded checkout-items">
                    <h3>{t('checkout.item')}</h3>

                    
                      <div className="item" key={product.id}>
                        <div className="sceenshot-wrapper">
                          <img src={product.firstImage?.url} />
                        </div>

                        <div className="item-content">
                          <header>{product.title}</header>

                          <div className="product-size">
                            {t('misc.size')}
                            <button className="jc-btn white" disabled>
                              {product.size.name}
                            </button>
                          </div>
                        </div>

                        <div className="price">
                        {product.grossPrice}
                          <span>CHF</span>
                        </div>
                      </div>
                   
                  </div>


                  {/* ADDRESSES PANEL */}
                  <div className="white-panel rounded takeover">
                    {/* <h3>{t('checkout.takeover')}</h3> */}


                    {/* TAKEOVER MODES */}
                    <div className="takeover-modes" onChange={(e) => setTakeoverMode(e.target.value)}>
                      <MDBRadio
                        name='takeoverMode'
                        id='takeoverMode1'
                        label={t('checkout.shipping')}
                        value='Shipping'
                        disabled = {!product.shippingOptions.some((option) => option.name === 'POST')}
                        defaultChecked = {takeoverMode === "Shipping"}
                      />
                      <MDBRadio
                        name='takeoverMode'
                        id='takeoverMode2'
                        label={t('checkout.pickup')}
                        value='Pick Up'
                        disabled = {!product.shippingOptions.some((option) => option.name === 'PICKUP')}
                        defaultChecked = {takeoverMode === "Pick Up"}
                      />
                    </div>


                    {/* BILLING ADDRESS DISPLAY */}
                    {!billingAddressEdited && buyerprovided && (
                      <div className="checkout-address billing-address">
                        <i
                          className="fa-regular fa-pencil"
                          onClick={() => setBillingAddressEdited(true)}
                        ></i>

                        <div className="name">
                          {formik.values.billingFirstName + " " + formik.values.billingLastName}
                        </div>
                        <div className="address-text">
                          {formik.values.billingAddress}<br />
                          {formik.values.billingPostalCode + " " + formik.values.billingCity} <br />
                          {t('checkout.switzerland')} <br />
                          {formik.values.billingPhoneNumber}
                        </div>
                      </div>
                    )}


                    {/* BILLING ADDRESS FORM */}
                    {(billingAddressEdited || !buyerprovided) &&
                      <div className="address-form">
                        <h4>{t('checkout.billing_address')}</h4>

                        <div className="address-row">
                          <label>{t('checkout.first_name')}*</label>
                          <input
                            id='billingFirstName'
                            name='billingFirstName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingFirstName}
                          />

                          {formik.touched.billingFirstName && formik.errors.billingFirstName ? (
                            <div className="invalid-feedback">{formik.errors.billingFirstName}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.last_name')}*</label>
                          <input
                            id='billingLastName'
                            name='billingLastName'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingLastName}
                          />

                          {formik.touched.billingLastName && formik.errors.billingLastName ? (
                            <div className="invalid-feedback">{formik.errors.billingLastName}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.address')}*</label>
                          <input
                            id='billingAddress'
                            name='billingAddress'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingAddress}
                          />

                          {formik.touched.billingAddress && formik.errors.billingAddress ? (
                            <div className="invalid-feedback">{formik.errors.billingAddress}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.postal_code')}*</label>
                          <input
                            id='billingPostalCode'
                            name='billingPostalCode'
                            onChange={(e) => {
                              if (loadingBillingCities) {
                                return;
                              }

                              if (e.currentTarget.value.length > 4) {
                                return;
                              }

                              formik.handleChange(e);

                              clearTimeout(billingPostalCodeSearchTimeoutId);

                              if (formik.values.billingCity) {
                                formik.setFieldValue('billingCity', '');
                              }

                              if (loadedBillingCities) {
                                setLoadedBillingCities(false);
                              }

                              if (e.target.value.length === 4) {

                                billingPostalCodeSearchTimeoutId = setTimeout(() => {
                                  setLoadingBillingCities(true);

                                  setTimeout(() => {
                                    if (postalCodes[e.target.value]) {
                                      setLoadedBillingCities(postalCodes[e.target.value].split(" - "));
                                    }

                                    setLoadingBillingCities(false);
                                  }, 1000);
                                }, 400);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingPostalCode}
                            className={loadingBillingCities ? 'disabled' : ''}
                          />

                          {loadingBillingCities && <i className="fa-regular fa-spinner-third at-input-end spinning"></i>}

                          {formik.touched.billingPostalCode && formik.errors.billingPostalCode ? (
                            <div className="invalid-feedback">{formik.errors.billingPostalCode}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.city')}*</label>
                          {/* <input
                            id='billingCity'
                            name='billingCity'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingCity}
                          /> */}

                          {!loadedBillingCities && (
                            <label className="form-text">
                              {t('user_settings.please_type_postal_code')}
                            </label>
                          )}

                          {loadedBillingCities && (
                            <MDBSelect
                              className={`normal`}
                              data={getCitiesOptions(formik.values.billingCity, loadedBillingCities)}
                              onValueChange={(e) => {
                                formik.setFieldValue('billingCity', e.value);
                              }}
                              disabled={loadingBillingCities}
                            />
                          )}

                          {formik.touched.billingCity && formik.errors.billingCity ? (
                            <div className="invalid-feedback">{formik.errors.billingCity}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.phone_number')}*</label>
                          <input
                            id='billingPhoneNumber'
                            name='billingPhoneNumber'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingPhoneNumber}
                          />

                          {formik.touched.billingPhoneNumber && formik.errors.billingPhoneNumber ? (
                            <div className="invalid-feedback">{formik.errors.billingPhoneNumber}</div>
                          ) : null}
                        </div>

                        <div className="address-row">
                          <label>{t('checkout.email_address')}*</label>
                          <input
                            id='billingEmail'
                            name='billingEmail'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingEmail}
                          />

                          {formik.touched.billingEmail && formik.errors.billingEmail ? (
                            <div className="invalid-feedback">{formik.errors.billingEmail}</div>
                          ) : null}
                        </div>

                        <button
                          className="jc-btn black save-address"
                          type="button"
                          disabled={isSaveBillingAddressButtonVisible(formik) ? '' : 'disabled'}
                          onClick={() => { setBillingAddressEdited(false); setBuyerprovided(true) }}
                        >
                          {t('checkout.save_billing_address')}
                        </button>
                      </div>
                    }


                    {/* TOGGLE */}
                    {takeoverMode === 'Shipping' && (
                      <div
                        className='toggle-with-label'
                        onClick={e => { setAddressesSame(!addressesSame); fillDeliveryAddress(formik); }}
                      >
                        <Toggle
                          checked={addressesSame}
                          name='addressesSame'
                          id='addressesSame'
                          className="jc-toggle"
                        />

                        {t('checkout.address_same')}
                      </div>
                    )}

                    {/* DELIVERY ADDRESS */}
                    {takeoverMode === 'Shipping' && !addressesSame &&
                      <div className="address-form">
                        <h4>{t('checkout.delivery_address')}</h4>

                        {!deliveryAddressEdited && (
                          <div className="checkout-address delivery-address">
                            <i
                              className="fa-regular fa-pencil"
                              onClick={() => setDeliveryAddressEdited(true)}
                            ></i>

                            <div className="name">
                              {formik.values.deliveryFirstName + " " + formik.values.deliveryLastName}
                            </div>
                            <div className="address-text">
                              {formik.values.deliveryAddress}<br />
                              {formik.values.deliveryPostalCode + " " + formik.values.deliveryCity}<br /> 
                              {t('checkout.switzerland')} 
                              {/*formik.values.deliveryPhoneNumber*/}
                            </div>
                          </div>
                        )}

                        {deliveryAddressEdited && (
                          <>
                            <div className="address-row">
                              <label>{t('checkout.first_name')}*</label>
                              <input
                                id='deliveryFirstName'
                                name='deliveryFirstName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.deliveryFirstName}
                              />

                              {formik.touched.deliveryFirstName && formik.errors.deliveryFirstName ? (
                                <div className="invalid-feedback">{formik.errors.deliveryFirstName}</div>
                              ) : null}
                            </div>

                            <div className="address-row">
                              <label>{t('checkout.last_name')}*</label>
                              <input
                                id='deliveryLastName'
                                name='deliveryLastName'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.deliveryLastName}
                              />

                              {formik.touched.deliveryLastName && formik.errors.deliveryLastName ? (
                                <div className="invalid-feedback">{formik.errors.deliveryLastName}</div>
                              ) : null}
                            </div>

                            <div className="address-row">
                              <label>{t('checkout.address')}*</label>
                              <input
                                id='deliveryAddress'
                                name='deliveryAddress'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.deliveryAddress}
                              />

                              {formik.touched.deliveryAddress && formik.errors.deliveryAddress ? (
                                <div className="invalid-feedback">{formik.errors.deliveryAddress}</div>
                              ) : null}
                            </div>

                            <div className="address-row">
                              <label>{t('checkout.postal_code')}*</label>
                              <input
                                id='deliveryPostalCode'
                                name='deliveryPostalCode'
                                onChange={(e) => {
                                  if (loadingDeliveryCities) {
                                    return;
                                  }
    
                                  if (e.currentTarget.value.length > 4) {
                                    return;
                                  }
    
                                  formik.handleChange(e);
    
                                  clearTimeout(deliveryPostalCodeSearchTimeoutId);
    
                                  if (formik.values.deliveryCity) {
                                    formik.setFieldValue('deliveryCity', '');
                                  }
    
                                  if (loadedDeliveryCities) {
                                    setLoadedDeliveryCities(false);
                                  }
    
                                  if (e.target.value.length === 4) {
    
                                    deliveryPostalCodeSearchTimeoutId = setTimeout(() => {
                                      setLoadingDeliveryCities(true);
    
                                      setTimeout(() => {
                                        if (postalCodes[e.target.value]) {
                                          setLoadedDeliveryCities(postalCodes[e.target.value].split(" - "));
                                        }
    
                                        setLoadingDeliveryCities(false);
                                      }, 1000);
                                    }, 400);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.deliveryPostalCode}
                              />

                              {loadingDeliveryCities && <i className="fa-regular fa-spinner-third at-input-end spinning"></i>}

                              {formik.touched.deliveryPostalCode && formik.errors.deliveryPostalCode ? (
                                <div className="invalid-feedback">{formik.errors.deliveryPostalCode}</div>
                              ) : null}
                            </div>

                            <div className="address-row">
                              <label>{t('checkout.city')}*</label>
                              {/* <input
                                id='deliveryCity'
                                name='deliveryCity'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.deliveryCity}
                              /> */}

                              {!loadedDeliveryCities && (
                                <label className="form-text">
                                  {t('user_settings.please_type_postal_code')}
                                </label>
                              )}

                              {loadedDeliveryCities && (
                                <MDBSelect
                                  className={`normal`}
                                  data={getCitiesOptions(formik.values.deliveryCity, loadedDeliveryCities)}
                                  onValueChange={(e) => {
                                    formik.setFieldValue('deliveryCity', e.value);
                                  }}
                                  disabled={loadingDeliveryCities}
                                />
                              )}

                              {formik.touched.deliveryCity && formik.errors.deliveryCity ? (
                                <div className="invalid-feedback">{formik.errors.deliveryCity}</div>
                              ) : null}
                            </div>


                            <button
                              className="jc-btn black save-address"
                              type="button"
                              disabled={isSaveDeliveryAddressButtonVisible(formik) ? '' : 'disabled'}
                              onClick={() => setDeliveryAddressEdited(false)}
                            >
                              {t('checkout.save_delivery_address')}
                            </button>
                          </>
                        )}
                      </div>
                    }
                  </div>

                  <div className="white-panel rounded payment-methods-panel">
                    <PaymentMethodList displayHeader={true} />
                  </div>
                </div>

                <div className="checkout-column-2">
                  <div className="white-panel rounded payment">
                    <div className="checkout-amounts">
                      <div className="amount-row">
                        {t('checkout.price')}

                        <span className="price">{product.grossPrice}<span>CHF</span></span>
                      </div>

                      <div className="amount-row">
                        {takeoverMode === "Shipping" ?
                        <>
                        {t('checkout.shipping')}
                        </>:
                        <>
                        {t('checkout.pickup')}
                        </>}

                        
                        <span className={"price "}>
                          {takeoverMode == "Shipping" ? (
                            product.shippingOptions.filter(k => k.name === "POST")[0].price
                          ) : '0'}
                          
                          <span>CHF</span>
                        </span>
                      </div>

                      <div className="amount-row">
                        {t('misc.total')}
                        
                        <span className="price total">
                        {takeoverMode === "Shipping" ?
                        <>
                          {priceWithShipping}
                        </>:
                        <>
                          {product.grossPrice}
                        </>}
                          
                        <span>CHF</span></span>
                      </div>
                    </div>


                    {/* TERMS AND CONDITION TOGGLE */}
                    <div
                      className='toggle-with-label'
                      onClick={e => setTermsConditionsAccepted(!termsConditionsAccepted)}
                    >
                      <Toggle
                        checked={formik.values.termsAndCondition}
                        name='termsAndCondition'
                        id='termsAndCondition'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="jc-toggle"
                      />

                      <label>
                        {t('checkout.accept')}

                        <a 
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open('/terms-and-conditions', '_blank');
                          }}
                          className="pointer"
                        >
                          {t('checkout.terms_and_conditions')}
                        </a>
                      </label>
                    </div>

                    {formik.touched.termsAndCondition && formik.errors.termsAndCondition ? (
                      <div className="invalid-feedback">{formik.errors.termsAndCondition}</div>
                    ) : null}


                    {/* PRIVACY POLICY TOGGLE */}
                    <div
                      className='toggle-with-label'
                    >
                      <Toggle
                        checked={formik.values.privacyPolicy}
                        name='privacyPolicy'
                        id='privacyPolicy'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="jc-toggle"
                      />

                      <label>
                        {t('checkout.accept')}

                        <a 
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open('/legal-notices#privacy-policy', '_blank');
                          }}
                          className="pointer"
                        >
                          {t('misc.privacy_policy')}
                        </a>
                      </label>
                    </div>

                    {formik.touched.privacyPolicy && formik.errors.privacyPolicy ? (
                      <div className="invalid-feedback">{formik.errors.privacyPolicy}</div>
                    ) : null}


                    <button className="jc-btn black" type="submit">
                      {formHasBeenSubmitted && <i className="fa-regular fa-spinner-third spinning"></i>}
                      
                      {!formHasBeenSubmitted && (
                        <>
                          <i className="fa-regular fa-credit-card"></i>

                          {t('checkout.pay_now')}
                        </>
                      )}
                    </button>

                    {formik.isValid && serverError && (
                      <div className="invalid-feedback">
                        {serverError}
                      </div>
                    )}

                    {formik.submitCount > 0 && !formik.isValid ? (
                      <div className="invalid-feedback">
                        {t('checkout.errors')}
                      </div>
                    ) : null}
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
        )}
        
      </div>
    </div>
  );
};

export default withTranslation()(CheckoutPage);
