// REACT IMPORTS
import React, { useContext, useRef, useEffect, useState } from "react";
import i18next from 'i18next';
import i18n from '../../../i18n';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// CUSTOM IMPORTS
import ProductListItem from "../product-list/product-list-item/ProductListItem";
import BookmarkOnlyModal from '../../modals/bookmark-only-modal/BookmarkOnlyModal';
import AppContext from '../../../AppContext';
import userShapeImage from "../../../images/user.png";

// 3RD PARTY IMPORTS
// import Carousel from "react-multi-carousel";
import { Swiper, SwiperSlide } from 'swiper/react';

import loadingAnim from "../../../images/loading-anim.json";

// custom styles
import "react-multi-carousel/lib/styles.css";
import "swiper/css";

// LOTTIE
import { Player, Controls } from '@lottiefiles/react-lottie-player';

/**
 * Props:
 * @param products: array of {id, title, location, size, price, imageUrl, bookmarked}
 * @param h2Title?: string
 * @returns 
 */
const ProductScroller = (props) => {

  const context = useContext(AppContext);

  // STATES
  const [newItemsList, setNewItemsList] = useState();

  // SWIPER INSTANCE
  const [swiperInstance, setSwiperInstance] = useState(null);

  // REFS
  const swiperElRef = useRef(null);

  // EVENTS
  const slidePrev = () => swiperInstance.slidePrev();
  const slideNext = () => swiperInstance.slideNext();

  const { t } = i18n;

  return (
    <>
      <div className="product-scroller">
        <div className="container-fluid mw-1300">
          <h2>
            {props.h2Title}
          </h2>
          {props.products ? <>
            <Swiper
              spaceBetween={10}
              slidesPerView={2}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              breakpoints={{
                501: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                721: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 40
                }
              }}
            >
              {props.products?.map((product, n) => (
                <SwiperSlide key={product.id}>
                  <ProductListItem 
                    {...product} 
                    displayBookmarkIcon={true}
                    enableScrollOnLoading={props.enableScrollOnLoading}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {props.products.length > 4 && (<>
              <button className="swiper-custom-button prev" onClick={slidePrev}>
                <i className="fa-light fa-chevron-left"></i>
              </button>

              <button className="swiper-custom-button  next" onClick={slideNext}>
                <i className="fa-light fa-chevron-right"></i>
              </button>
            </>) }
            
            </> : <div className={"loading-layer shown"}>
          <Player
            autoplay
            loop
            src={loadingAnim}
            style={{ height: '130px', width: '300px' }}
          >
          </Player>
            </div>
            }
        </div>
      </div>
    </>
  );
};

export default ProductScroller;
