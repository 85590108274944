import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

import userShapeImage from "./images/user.png";
import { FirebaseConfig } from './data/loginkeys';
import { FirebaseVapidKey } from './data/loginkeys';


const getAuthToken = () => {
    return cookies.get('authtoken')?.token;
}

const firebaseConfig = {
  apiKey: FirebaseConfig.apiKey,
  authDomain: FirebaseConfig.authDomain,
  projectId: FirebaseConfig.projectId,
  storageBucket: FirebaseConfig.storageBucket,
  messagingSenderId: FirebaseConfig.messagingSenderId,
  appId: FirebaseConfig.appId,
  measurementId: FirebaseConfig.measurementId
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

class AppContextProvider extends Component {
    state = {
    }

    constructor(props) {
      super(props);

      this.state = {
        interAxios: new InterAxios('App', getAuthToken, this.openBackendErrorModal),
        isRender: true,
        editProfileModal: false
      };
    }

    componentDidMount() {
      if(cookies.get('mylanguage') === undefined){
        this.toEnglish();
      }else{
        this.onSetLanguageParams(cookies.get('mylanguage').language);
      }
      this.onGetGuestCategoryEnglish();
      this.setState({isRender: true});
    }

    
    
    
    setState(state) {
      super.setState(function(prevState, props) {
        let newState = {...prevState, ...state};
        window.localStorage.setItem('state', JSON.stringify(newState));
        return {
          ...newState
        };
      })
    }

    onSetLanguageParams = (language) => {
      this.onGetAllCategory(language);
      this.onRenderTrue();
    }

    toGerman = () => {
      i18next.changeLanguage('de', (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        cookies.set('mylanguage', {
            language: 'de'
          }, { path: '/'}
        );
        this.onSetLanguageParams('de');
      });
      
      //moment.locale('de');
    }

    toEnglish = () => {
      i18next.changeLanguage('en', (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        cookies.set('mylanguage', {
            language: 'en'
          }, { path: '/'}
        );
        this.onSetLanguageParams('en');
      });
      
      //moment.locale('en');
    }

    getState = () => {
      let stateStr = window.localStorage.getItem('state');
      let stateObj = JSON.parse(stateStr);
      return stateObj;
    }

    clearState = () => {
      this.onParamsFalse();
      //this.onGetGuestCategoryEnglish();
      this.setState({isRender: true, 
                     topCategoryName: undefined, 
                     categoryIdFilter: undefined,
                     subCategoryIdFilter: undefined,
                     subsubCategoryIdFilter: undefined,
                     marketplacePageSize: undefined,
                     postcodeFilter: undefined,
                     searchTextFilter: undefined,
                     isSearchparams: true,
                     textOrPostcodeFilter: false,
                     sizeIdFilter: undefined, 
                     conditionIdFilter: undefined,
                     priceFilter: undefined,
                     onlyFollowedFilter: undefined,
                     sort_byFilter: undefined,
                     sort_dirFilter: undefined,
                     onResetFilter: true
                    })
    }

    clearFilterState = () => {
      this.onParamsFalse();
      //this.onGetGuestCategoryEnglish();
      this.setState({isRender: true, 
                     sizeIdFilter: undefined, 
                     conditionIdFilter: undefined,
                     priceFilter: undefined,
                     onlyFollowedFilter: undefined,
                     sort_byFilter: undefined,
                     sort_dirFilter: undefined
                    })
    }

    clearSearchFilterState = () => {
      this.onParamsFalse();
      //this.onGetGuestCategoryEnglish();
      this.setState({isRender: true, 
                     textOrPostcodeFilter: undefined,
                    })
    }

    getMyToken = () => {
          console.log(messaging);
      return getToken(messaging, {vapidKey: FirebaseVapidKey.vapidKey}).then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          //setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
          this.state.interAxios.post("/notification/subscribe", {token: currentToken}, { headers: { 'content-type': 'application/json'}}).then(function(response) {
              console.log("RESPONSE: " + response.data);
          })
        } else {
          console.log('No registration token available. Request permission to generate one.');
          //setTokenFound(false);
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
    }

    onSignIn = (responseData) => {
      cookies.set('authtoken', {
          token: responseData.authToken
        }, { path: '/'}
      );
      
      if(responseData.hasClient || responseData.authenticator == "GOOGLE" || responseData.authenticator == "FACEBOOK"){
        let self = this;
        this.state.interAxios.get('/client/me',
        function(response) {
          self.state.interAxios.post('/notification/subscribe', {
                                                                token: responseData.authToken,
                                                                lang: response.data.lang,
                                                                platform: "Web"
                                                              } ,
          function(response) {});
          cookies.set('mylocation', {
              location: response.data.address.location,
              clientType: response.data.clientType,
              clientId: response.data.id,
              clientImageUrl: response.data.image?.url,
              clientEmail: response.data.email,
              clientName: response.data.publicName,
              phoneNumber: response.data.phoneNumber,
              notifications: response.data.notifications,
              clientIsVat: response.data.vat,
              isHasClient: responseData.hasClient
            }, { path: '/' }
          );
          if(response.data.lang === 'de'){
            self.toGerman();
          }
          self.onRenderTrue();
          if((responseData.authenticator == "GOOGLE" || responseData.authenticator == "FACEBOOK") && (response.data.birthDay === null || response.data.firstName === null ||
                response.data.lastName === null || response.data.phoneNumber === null || response.data.publicName === null || response.data.address.address === null || response.data.address.location === null || response.data.address.postcode === null)){
                  self.setState({editProfileModal: true})
                }

       })
      }else{
        cookies.set('mylocation', {
          isHasClient: responseData.hasClient
        }, { path: '/' }
      );
      }
      this.setState({loggedIn: true});
      this.setState({onLogoutWithoutNoti : false})
      //this.getMyToken();
    }

    onLogout = () => {
     /* if(this.state.onLogoutWithoutNoti===false){
        this.state.interAxios.post('/notification/unsubscribe', {
          token: cookies.get('authtoken').token
              } ,
      function(response) {

      });
      }*/
      cookies.remove('authtoken', { path: '/'});
      cookies.remove('mylocation', { path: '/'});
      window.localStorage.removeItem('state');
      this.setState({loggedIn: false});
      this.onRenderTrue();
    }

    isLoggedIn = () => {

        let cookieToken = getAuthToken();
        return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
    }

    parseJwt = (token) => {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (e) {
            console.log(e);
          return null;
        }
    };


    onGetAllCategory = (language) => {
      let self = this;
      this.state.interAxios.get('/category/all', {params: {lang: language}},
       function(response) {
         self.setState({allCategory: response.data});
      })
    }

    onGetGuestCategoryEnglish = () => {
      let self = this;
      let subArray = ["Clothes", "Shoes", "Accessories", "Bags & Suitcases", "Smartphones", "Girls", "Boys"];
      let categoryArray = ["Women", "Men", "Kids", "Electronics"];
      this.state.interAxios.get('/category/all', {params: {lang: 'en'}},
       function(response) {
         let categoriesId = response.data.categories.filter(k => k.type === "CATEGORY" && categoryArray.includes(k.name)).map(k => {return k.id});
          console.log(response.data.categories.filter(k => k.type === "CATEGORY" && categoryArray.includes(k.name)))
         self.setState({questCategoryFilter: response.data.categories.filter(k => k.type === "SUBCATEGORY" && categoriesId.includes(k.parentId) && subArray.includes(k.name)).map(k => {return k.id} )})
          console.log(response.data.categories.filter(k => k.type === "SUBCATEGORY" && categoriesId.includes(k.parentId) && subArray.includes(k.name)))
         self.onRenderTrue();
      })
    }

    
    
    onGetAllSubCategory = (categoryId) => {
      let self = this;
      this.state.interAxios.get('/category/' + categoryId + '/subcategory', {params: {lang: cookies.get('mylanguage').language}},
       function(response) {
         self.setState({allSubCategory: response.data});
      })
    }

    onGetAllSubsubCategory = (subCategoryId) => {
      let self = this;
      this.state.interAxios.get('/subcategory/' + subCategoryId + '/supsubcategory', {params: {lang: cookies.get('mylanguage').language}},
       function(response) {
         self.setState({allSubsubCategory: response.data});
      })
    }

    onNewMessage = (recipientId, orderId, text) => {
      let self = this;
      console.log("itt vagyok")
      this.state.interAxios.post('/message', {
        recipientId: recipientId,
        orderId: orderId,
        text: text
      },
       function(response) {
        self.onRenderTrueBookmark();
      })
    }

    onGetTopcategory = (name, id, type) => {
      this.clearState();
      let array = [id];
      if(type === "CATEGORY"){
        this.setState({topCategoryName: name, categoryIdFilter: array, subCategoryIdFilter: undefined, subsubCategoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
      }
      if(type === "SUBCATEGORY"){
        this.setState({topCategoryName: name, subCategoryIdFilter: array, categoryIdFilter: undefined, subsubCategoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
      }
      if(type === "SUBSUBCATEGORY"){
        this.setState({topCategoryName: name, subsubCategoryIdFilter: array, subCategoryIdFilter: undefined, categoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
      }
    }

    onGetCategoryIdParams = (categoryId, name) => {
      let array = [categoryId];
      this.setState({topCategoryName: name, categoryIdFilter: array, subCategoryIdFilter: undefined, subsubCategoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
    }
    onGetSubCategoryIdParams = (subCategoryId, name) => {
      let array = [subCategoryId];
      this.setState({topCategoryName: name, subCategoryIdFilter: array, categoryIdFilter: undefined, subsubCategoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
    }
    onGetSubsubCategoryIdParams = (subsubCategoryId, name) => {
      let array = [subsubCategoryId];
      this.setState({topCategoryName: name, subsubCategoryIdFilter: array, subCategoryIdFilter: undefined, categoryIdFilter: undefined, marketplacePageSize: 20, isRender: true, questCategoryFilter: undefined})
    }
    
    onGetPostcodeParams = (postcode) => {
      this.setState({postcodeFilter: {postcode:postcode}, marketplacePageSize: 20, textOrPostcodeFilter: true, questCategoryFilter: undefined})
    }
    onGetSizeIdParams = (sizeId) => {
      this.setState({sizeIdFilter: {sizeId: sizeId}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetConditionIdParams = (conditionId) => {
      this.setState({conditionIdFilter: {conditionId: conditionId}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetPriceParams = (minPrice, maxPrice) => {
      this.setState({priceFilter: {minPrice: minPrice, maxPrice: maxPrice}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetOnlyFollowedParams = (onlyFollowed) => {
      this.setState({onlyFollowedFilter: {onlyFollowed: onlyFollowed}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetSearchTextParams = (searchText) => {
      this.setState({searchTextFilter: {searchText: searchText}, marketplacePageSize: 20, textOrPostcodeFilter: true, questCategoryFilter: undefined})
    }
    onGetSort_byParams = (sort_by) => {
      this.setState({sort_byFilter: {sort_by: sort_by}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetSort_dirParams = (sort_dir) => {
      this.setState({sort_dirFilter: {sort_dir: sort_dir}, marketplacePageSize: 20, textOrPostcodeFilter: true, onResetFilter: false, questCategoryFilter: undefined})
    }
    onGetPageIndexParams = (pageIndex) => {
      this.setState({pageIndexFilter: {pageIndex: pageIndex}})
    }
    onGetPageSizeParams = (pageSize) => {
      this.setState({pageSizeFilter: {pageSize: pageSize}})
    }

    onRender = () => {
      this.setState({isRender: false, onResetFilter: false})
    }

    onRenderTrue = () => {
      this.setState({isRender: true, isSearchparams: false})
    }

    onRenderTrueBookmark = () => {
      this.setState({isRender: true})
    }

    onRenderTrueRatingModal = () => {
      this.setState({isRenderRating: true})
    }

    onRenderFalseRatingModal = () => {
      this.setState({isRenderRating: false})
    }

    onGetPageSize = (pagesize) => {
      this.setState({marketplacePageSize: pagesize})
    }

    onGetNotificationsSize = (pagesize) => {
      this.setState({notificationsSize: pagesize})
    }

    getProductId = (id) => {
      this.setState({marketplaceProductId: id})
    }

    removeProductId = () => {
      this.setState({marketplaceProductId: undefined})
    }


    openLoginModal = (displaySignin) => {
      this.setState({
        loginModalOpened: true,
        displaySigninInLoginModal: displaySignin
      });
    }

    closeLoginModal = () => {
      this.setState({
        displaySigninInLoginModal: this.getState().displaySigninInLoginModal,
        loginModalOpened: undefined
      });
    }

    isLoginModalOpened = () => {
      return this.getState().loginModalOpened;
    }

    doesSigninDisplayedInLoginModal = () => {
      return this.getState().displaySigninInLoginModal;
    }



    isLoggedInIdIs = (id) => {
      const myLocation = cookies.get('mylocation');

      return (myLocation?.clientId === id);
    }

    isLoggedInBusiness = () => {
      const myLocation = cookies.get('mylocation');

      return (myLocation?.clientType === 'BUSINESS');
    };

    onGetActueleSizeComponents = (id) => {
      let self = this;
      this.state.interAxios.get('/category/' + id + '/size', {params: {lang: cookies.get('mylanguage').language}},
        function(response) {
          self.setState({sizeComponents: response.data.map(k => {return ({text: k.name, value: k.id})})});
        }
      );
    }

    onGetConditions = () => {
      let self = this;
      this.state.interAxios.get('/condition', {params: {lang: cookies.get('mylanguage').language}},
        function(response) {
          self.setState({conditions: response.data.map(k => {return ({text: k.name, value: k.id})})});
        }
      );
    }

    setScrollOnMarketplaceAfterFilter = () => {
      this.setState({ scrollOnMarketplaceAfterFilter: true });
    }

    unsetScrollOnMarketplaceAfterFilter = () => {
      this.setState({ scrollOnMarketplaceAfterFilter: undefined });
    }

    setScrollOnLoadMore = () => {
      this.setState({ scrollOnLoadMore: true });
    }

    unsetScrollOnLoadMore = () => {
      this.setState({ scrollOnLoadMore: undefined });
    }

    onOrderType = (orderType) => {
      this.setState({orderType: orderType});
    }

    isProductlistImages = () => {
      this.setState({productlistImages : true})
    }

    isProductscrollerImages = () => {
      this.setState({productscrollerImages : true})
    }

    isTopcategorylistImages = () => {
      this.setState({topcategorylistImages : true})
    }

    onParamsFalse = () => {
      this.setState({productlistImages : false, topcategorylistImages : false})
    }

    isDeleteClient = () => {
      this.setState({onLogoutWithoutNoti : true})
    }

    isStripeCancelTrue = () => {
      this.setState({stripCancel : true})
    }
    isStripeCancelFalse = () => {
      this.setState({stripCancel : false})
    }

    // GLOBAL ITEMS LOADING STATE
    isMarketplaceLoading = () => {
      return this.state.topCategoriesLoading || this.state.newItemsLoading || this.state.itemsLoading;
    }
    setTopCategoriesLoading = (loading) => {
      this.setState({ topCategoriesLoading: loading });
    };
    setNewItemsLoading = (loading) => {
      this.setState({ newItemsLoading: loading });
    };
    setItemsLoading = (loading) => {
      this.setState({ itemsLoading: loading });
    };
    setMessageLoading = (loading) => {
      this.setState({ messagesLoading: loading });
    };

    onHideEditProfileModal = () => {
      this.setState({editProfileModal: false})
    }

    openBackendErrorModal = (errorMessage) => {
      this.setState({
        backendErrorMsg: errorMessage
      });
    }

    closeBackendErrorModal = () => {
      this.setState({
        backendErrorMsg: undefined
      });
    }

    getBackendErrorMessage = () => {
      return this.getState().backendErrorMsg;
    }


    render() {
        return (
          <AppContext.Provider
                value={{
                    interAxios: this.state.interAxios,
                    isLoggedIn: this.isLoggedIn,
                    onSignIn: this.onSignIn,
                    onLogout: this.onLogout,
                    onGetTopcategory: this.onGetTopcategory,
                    onGetAllCategory: this.onGetAllCategory,
                    onGetAllSubCategory: this.onGetAllSubCategory,
                    onGetAllSubsubCategory: this.onGetAllSubsubCategory,
                    onGetCategoryIdParams: this.onGetCategoryIdParams,
                    onGetSubCategoryIdParams: this.onGetSubCategoryIdParams,
                    onGetSubsubCategoryIdParams: this.onGetSubsubCategoryIdParams,
                    onGetPostcodeParams: this.onGetPostcodeParams,
                    onGetSizeIdParams: this.onGetSizeIdParams,
                    onGetConditionIdParams: this.onGetConditionIdParams,
                    onGetPriceParams: this.onGetPriceParams,
                    onGetOnlyFollowedParams: this.onGetOnlyFollowedParams,
                    onGetSearchTextParams: this.onGetSearchTextParams,
                    onGetSort_byParams: this.onGetSort_byParams,
                    onGetSort_dirParams: this.onGetSort_dirParams,
                    onGetPageIndexParams: this.onGetPageIndexParams,
                    onGetPageSizeParams: this.onGetPageSizeParams,
                    getState: this.getState,
                    clearState: this.clearState,
                    clearFilterState: this.clearFilterState,
                    onRender: this.onRender,
                    onRenderTrue: this.onRenderTrue,
                    onRenderTrueBookmark: this.onRenderTrueBookmark,
                    toGerman: this.toGerman,
                    toEnglish: this.toEnglish,
                    onGetPageSize: this.onGetPageSize,
                    onGetNotificationsSize: this.onGetNotificationsSize,
                    getProductId: this.getProductId,
                    removeProductId: this.removeProductId,
                    openLoginModal: this.openLoginModal,
                    closeLoginModal: this.closeLoginModal,
                    isLoginModalOpened: this.isLoginModalOpened,
                    doesSigninDisplayedInLoginModal: this.doesSigninDisplayedInLoginModal,
                    isLoggedInIdIs: this.isLoggedInIdIs,
                    isLoggedInBusiness: this.isLoggedInBusiness,
                    onGetActueleSizeComponents: this.onGetActueleSizeComponents,
                    onGetConditions: this.onGetConditions,
                    setScrollOnMarketplaceAfterFilter: this.setScrollOnMarketplaceAfterFilter,
                    unsetScrollOnMarketplaceAfterFilter: this.unsetScrollOnMarketplaceAfterFilter,
                    onOrderType: this.onOrderType,
                    isProductlistImages: this.isProductlistImages,
                    isProductscrollerImages: this.isProductscrollerImages,
                    isTopcategorylistImages: this.isTopcategorylistImages,
                    onParamsFalse: this.onParamsFalse,
                    setScrollOnLoadMore: this.setScrollOnLoadMore,
                    unsetScrollOnLoadMore: this.unsetScrollOnLoadMore,
                    clearSearchFilterState: this.clearSearchFilterState,
                    onRenderTrueRatingModal: this.onRenderTrueRatingModal,
                    onRenderFalseRatingModal: this.onRenderFalseRatingModal,
                    isStripeCancelTrue: this.isStripeCancelTrue,
                    isStripeCancelFalse: this.isStripeCancelFalse,
                    isMarketplaceLoading: this.isMarketplaceLoading,
                    setTopCategoriesLoading: this.setTopCategoriesLoading,
                    setNewItemsLoading: this.setNewItemsLoading,
                    setItemsLoading: this.setItemsLoading,
                    onHideEditProfileModal: this.onHideEditProfileModal,
                    openBackendErrorModal: this.openBackendErrorModal,
                    closeBackendErrorModal: this.closeBackendErrorModal,
                    getBackendErrorMessage: this.getBackendErrorMessage,
                    onNewMessage: this.onNewMessage,
                    setMessageLoading: this.setMessageLoading
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppContextProvider;
