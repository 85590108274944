// REACT IMPORTS
import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

// CUSTOM COMPONENTS
import ProfileForm from '../../form-components/profile-form/ProfileForm';

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';


const SignUpPage = (props) => {
  // REACT ROUTER PARAMS
  const [searchParams] = useSearchParams();

  // helper funcs
  const isItBusinessRegistration = () => {
    return (searchParams.get('mode') === 'business');
  };

  // MULTILANGUAGE
  const { t } = i18n;
  

  return (
    <div className="jc-page gray-bc sign-up">
      <div className="container-fluid mw-1040">
        <h1>
          { isItBusinessRegistration() ? t('sign_up.title_business') : t('sign_up.title_private') }
        </h1>
        
        <ProfileForm 
          mode="SIGN_UP" 
          type={isItBusinessRegistration() ? 'BUSINESS' : 'PRIVATE'}
        />
      </div>
    </div>
  );
};

export default withTranslation()(SignUpPage);
