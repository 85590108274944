// REACT IMPORTS
import React, { useState, useRef, useEffect } from "react";

// OTHER 3RD PARTY IMPORTS
import { ReactSortable } from "react-sortablejs";

// MODA IMPORTS
import GeneralInfoModal from "../../modals/general-info-modal/GeneralInfoModal";

// FORMIK IMPORTS
import { useFormikContext } from "formik";

// MULTILANGUAGE
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import i18n from '../../../i18n';

import { MDBAlert } from 'mdb-react-ui-kit';

// PRIME REACT IMPORTS
// import { ToggleButton } from 'primereact/togglebutton';

// CUSTOM COMPONENTS
// import OverlayPanel from '../overlay-panel/OverlayPanel';


// styles
// import "./ImageUploader.scss"


/**
 * Props
 * @param selectedImages: array of {
 *  id,
 *  file?: File,
 *  url: URL.createObjectURL(selectedFile) || string,
 *  menuVisibility: false,
 *  toBeRemoved: false
 * }
 * @param setSelectedImages: (images) => void
 * @param uploadBtnMessage: string
 * @returns 
 */
const ImageUploader = ({
  selectedImages,
  setSelectedImages,
  uploadBtnMessage,
}) => {
  // STATES
  const [dragging, setDragging] = useState(false);
  const [only5ImagesModalShown, setOnly5ImagesModalShown] = useState(false);
  const [alert, setAlert] = useState({alert: false, alertText: ""});

  // MULTILANGUAGE
  const { t, language } = i18n;

  // MAIN FUNC
  const addImages = (files) => {
    let newSelectedImages = selectedImages.slice();

    const spaceForNewImages = 5 - (newSelectedImages.filter(i => !i.toBeRemoved).length);

    for (let index = 0; (index < files.length) && (index < spaceForNewImages); index++) {
      const selectedFile = files.item(index);

      const id = selectedFile.name.concat('-', selectedFile.size.toString());

      if (selectedImages.find(i => i.id === id)) {
        continue;
      }

      newSelectedImages.push({
        id,
        file: selectedFile,
        url: URL.createObjectURL(selectedFile),
        menuVisibility: false,
        new: true
      });
    }

    setSelectedImages(newSelectedImages);

    if (files.length > spaceForNewImages) {
      setOnly5ImagesModalShown(true);
    }
  };
  const removeImage = (index) => {
    let newSelectedImages = selectedImages.slice();

    const selectedImage = newSelectedImages[index];

    if (selectedImages[index].new) {
      newSelectedImages.splice(index, 1);
    } else {
      selectedImage.toBeRemoved = true;
    }

    setSelectedImages(newSelectedImages);
  };
  const reorderImages = (images) => {
    setSelectedImages([...selectedImages.filter(si => si.toBeRemoved), ...images]);
  };
  const preventAndStop = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // EVENTS
  const getRemoveImageFunc = (selectedImageId) => (e) => {
    preventAndStop(e);

    for (let index = 0; index < selectedImages.length; index++) {
      if (selectedImageId === selectedImages[index].id) {
        removeImage(index);
      }      
    }
  };
  const onSelectImage = (event) => {
    const selectedFiles = event.currentTarget.files;
    for (let index = 0; index < selectedFiles.length; index++) {
      if(selectedFiles.item(index).type !== "image/png" && selectedFiles.item(index).type !== "image/jpg" && selectedFiles.item(index).type !== "image/jpeg"){
        setAlert({alert: true, alertText: 'Incorrect file format. Please check the extension of the images you want to upload!'});
      }else{
          if (!selectedFiles) {
            return;
          }
          addImages(selectedFiles);
      }
    } 
  };

  const dropOnImagesArea = (e) => {
    preventAndStop(e);

    if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      // in this case, the event is coming from sorting, so no upload needed
      return;
    }

    addImages(e.dataTransfer.files);
  };

  // helper funcs
  const getImageMenuClasses = (isItFirstImage) => {
    const classes = ['image-menu'];

    if (isItFirstImage) {
      classes.push('hero-image');
    }

    return classes.join(' ');
  };

  
  const imagesWithoutRemoved = selectedImages.filter(si => !si.toBeRemoved);

  return (
    <div className="image-uploader multiple">
      <div 
        className={`images-area ${dragging ? 'highlight' : ''}`}
        onDragEnter={preventAndStop}
        onDragOver={preventAndStop}
        onDrop={dropOnImagesArea}
      >
        {imagesWithoutRemoved.length > 0 && (
          <ReactSortable 
            list={imagesWithoutRemoved} 
            setList={reorderImages} 
            className='uploaded-images'
          >
            {imagesWithoutRemoved.map((selectedImage, i) => (
              <div 
                key={selectedImage.id}
                className="uploaded-item"
              >
                <div className="image-icons">
                  <div className="image-icon hero-indicator">
                    <i className="fa-regular fa-images"></i>
                  </div>

                  <div 
                    className="image-icon delete"
                    onClick={getRemoveImageFunc(selectedImage.id)}
                  >
                    <i className="fa-regular fa-trash-alt"></i>
                  </div>
                  
                  {/* <div className="image-icon" style={{backgroundColor: 'white'}}>
                    <i 
                      className="fa-solid fa-trash" 
                      onClick={getRemoveImageFunc(selectedImage.id)}
                      style={{
                        color: '#c86e70',
                        fontSize: '20px',
                        cursor: 'pointer'
                      }}
                    ></i>
                  </div> */}
                </div>

                <img src={selectedImage.url} alt="preview image" />
              </div>
            ))}
          </ReactSortable>
        )}
  
        <label 
          className={`upload-button uploaded-images-${imagesWithoutRemoved.length}`} 
          htmlFor="new-files"
        >
          <input 
            type="file" 
            accept="image/jpg, image/jpeg, image/png"
            multiple
            onChange={onSelectImage}
            id="new-files"
          />
  
          <div className="full-content">
            <i className="fa-regular fa-up-to-line"></i> {uploadBtnMessage}
          </div>
  
          <div className="content">
            <i className="fa-regular fa-plus"></i>
          </div>
        </label>
      </div>
      <MDBAlert
        show={alert.alert}
        color='primary'
        autohide
        position='top-center'
        offset={50}
        delay={4000}
        appendToBody
        onClosed={() => { setAlert({alert: false, alertText: ""})}}
      >{alert.alertText}</MDBAlert>
      <GeneralInfoModal
        visible={only5ImagesModalShown}
        hideModal={() => setOnly5ImagesModalShown(false)}
        infoIcon="fa-regular fa-info-circle"
        title={t('product_form.only_5_images_allowed')}
      />
    </div>
  );
};

export default ImageUploader;